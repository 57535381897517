import { makeAutoObservable, reaction } from 'mobx';
import { ServerError } from '../../types/serverError';
import Cookies from 'js-cookie';
import { store } from '../store';

export default class CommonStore {
  error: ServerError | null = null;

  token: string | null = window.localStorage.getItem('jwt');
  authGuid: string | null = Cookies.get('auth') ?? null;
  refreshToken: string | null = window.localStorage.getItem('refreshToken');

  appLoaded = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem('jwt', token);
          store.userStore.isLoggedIn = true;
        } else {
          window.localStorage.removeItem('jwt');
          store.userStore.isLoggedIn = false;
        }
      },
    );
    reaction(
      () => this.refreshToken,
      (refreshToken) => {
        if (refreshToken) {
          window.localStorage.setItem('refreshToken', refreshToken);
        } else {
          window.localStorage.removeItem('refreshToken');
        }
      },
    );
    reaction(
      () => this.authGuid,
      (authGuid) => {
        if (authGuid) {
          Cookies.set('auth', authGuid);
        } else {
          Cookies.remove('auth');
        }
      },
    );
  }

  setServerError = (error: ServerError) => {
    this.error = error;
  };

  setToken = (token: string | null) => {
    if (token) {
      window.localStorage.setItem('jwt', token);
    } else {
      window.localStorage.removeItem('jwt');
    }
    this.token = token;
  };

  setRefreshToken = (refreshToken: string | null) => {
    this.refreshToken = refreshToken;
  };
  setAuthorized = (authorized: string | null) => {
    this.authGuid = authorized;
  };

  setAppLoaded = (value: boolean) => {
    this.appLoaded = value;
  };
}
