import { makeAutoObservable } from 'mobx';
import agent from '../../api/agent';
import {
  CheckInsuredPersonByPeselResponseDTO,
  KlInsuredPersonBRM,
  KlOrderFormM,
  KlSaveOrderResponse,
} from '../../types/kl';

export default class KlStore {
  constructor() {
    makeAutoObservable(this);
  }

  getInsuredPersonData = async (
    pesel: string,
    startDate: string,
    endDate: string,
    country: string,
    email: string,
    mainInsuredId: number | null,
  ): Promise<KlInsuredPersonBRM> => {
    try {
      return agent.Kl.getInsuredPersonData(pesel, startDate, endDate, country, email, mainInsuredId);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  checkInsuredPersonByPesel = async (
    pesel: string,
    email: string,
    sendEmail: boolean,
  ): Promise<CheckInsuredPersonByPeselResponseDTO> => {
    try {
      return agent.Kl.checkInsuredPersonByPesel(pesel, email, sendEmail);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  saveOrder = async (values: KlOrderFormM): Promise<KlSaveOrderResponse> => {
    try {
      return agent.Kl.saveOrder(values);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
}
