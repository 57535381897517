import React, { useState } from 'react';
import { TypeVerify } from './types';
import * as style from './styles';
import { history } from '../../index';
import Checkbox from '../Checkbox/Checkbox';
import { Button, Image, Spinner } from 'react-bootstrap';
import { Capacitor } from '@capacitor/core';
import { CameraSource } from '@capacitor/camera';
import { openCamera } from '../../utils/cameraUtils';

interface IEarningDocumentsFormProps {
  onFileUpload: (file: string | Blob, filename: string | Blob) => Promise<void>;
}
const EarningDocumentsForm = (props: IEarningDocumentsFormProps) => {
  const [checked, setChecked] = useState(true);
  const [file, setFile] = useState<string | Blob>('');
  const [fileName, setFileName] = useState<string | Blob>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [type, setType] = useState('');

  const onFileUpload = (file: string | Blob, filename: string | Blob) => {
    setIsSubmitting(true);
    props.onFileUpload(file, filename).finally(() => setIsSubmitting(false));
  };

  const saveFile = (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const setFileFromCamera = (blob: Blob) => {
    setFile(blob);
    setFileName(`photo_${Date.now()}.jpg`);
  };

  const handleChange = (e: any) => {
    setType(e.target.id);
    if (e.target.id !== TypeVerify.BANK) {
      setChecked(true);
      setFile('');
    } else if (e.target.id !== TypeVerify.STATEMENT) {
      setChecked(true);
      setFile('');
    } else {
      setChecked(!checked);
    }
  };

  return (
    <div style={style.mainContainer as React.CSSProperties}>
      <div className={'tw-flex'}>
        <Image
          onClick={() => history.goBack()}
          src='/assets/arrow-Left.svg'
          style={style.goBackIcon as React.CSSProperties}
        />
        <div style={style.centerFlexContainer as React.CSSProperties}>
          <p
            className={'sm:tw-text-[23px] tw-text-[20px] tw-leading-tight'}
            style={style.verifyText as React.CSSProperties}>
            Weryfikacja zatrudnienia
          </p>
        </div>
      </div>
      <Image src='/assets/personDocument.svg' style={style.photoStyles as React.CSSProperties} />
      <div className={'discountCode-bank'} style={style.flexStart as React.CSSProperties}>
        <Checkbox checked={checked} handleChange={handleChange} id={TypeVerify.STATEMENT} text='' type={type} />
        <label>Weryfikacja za pomocą dokumentu Zaświadczenie o dochodach.</label>
      </div>
      {type === TypeVerify.STATEMENT ? (
        <div>
          {Capacitor.isNativePlatform() ? (
            <div className='d-flex'>
              <div style={style.cordovaFileBox as React.CSSProperties}>
                <Image
                  onClick={() => openCamera(CameraSource.Camera, setFileFromCamera)}
                  src={!file ? '/assets/greenPhoto.svg' : '/assets/photo.svg'}
                  style={style.heightSecondPhoto as React.CSSProperties}
                />
                <div style={style.uploadFile as React.CSSProperties}>Wykonaj zdjęcie</div>
              </div>
              <div style={style.cordovaFileBox as React.CSSProperties}>
                <input capture onChange={saveFile} style={style.inputHidden as React.CSSProperties} type='file' />
                <Image
                  src={!file ? '/assets/greenDocument.svg' : '/assets/darkDocument.svg'}
                  style={style.heightSecondPhoto as React.CSSProperties}
                />
                <div style={style.uploadFile as React.CSSProperties}>Załącz zdjęcie</div>
              </div>
            </div>
          ) : (
            <div style={style.positionRelative as React.CSSProperties}>
              <input capture onChange={saveFile} style={style.inputHidden as React.CSSProperties} type='file' />
              <Image
                src={!file ? '/assets/greenPhoto.svg' : '/assets/photo.svg'}
                style={style.heightSecondPhoto as React.CSSProperties}
              />
              <div style={style.uploadFile as React.CSSProperties}>Wykonaj lub załącz zdjęcie Oświadczenia</div>
              <div style={style.greyDot} />
            </div>
          )}
        </div>
      ) : null}
      <div style={style.flexCenter as React.CSSProperties}> lub</div>
      <div className={'discountCode-bank'} style={style.flexStart as React.CSSProperties}>
        <Checkbox checked={checked} handleChange={handleChange} id={TypeVerify.BANK} text='' type={type} />
        <label>Weryfikacja za pomocą Wyciągu z konta bankowego z ostatnich 3 miesięcy</label>
      </div>
      {type === TypeVerify.BANK ? (
        <>
          <div style={style.positionRelative as React.CSSProperties}>
            <input
              accept='application/pdf'
              capture
              onChange={saveFile}
              style={style.inputHidden as React.CSSProperties}
              type='file'
            />
            <Image
              src={!file ? '/assets/greenDocument.svg' : '/assets/darkDocument.svg'}
              style={style.heightSecondPhoto as React.CSSProperties}
            />
          </div>
          <div style={style.uploadFile as React.CSSProperties}>Załącz plik PDF z Wyciągiem z konta</div>
        </>
      ) : null}
      {isSubmitting ? (
        <Button style={{ marginTop: '2rem' }} type='submit' variant='primary'>
          <span className='m-1'>
            <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
          </span>
          Trwa zapisywanie pliku...
        </Button>
      ) : (
        <Button
          disabled={!file}
          onClick={() => onFileUpload(file, fileName)}
          style={{ marginTop: '2rem' }}
          type='submit'
          variant='primary'>
          Chcę złożyć wniosek
        </Button>
      )}
    </div>
  );
};

export default EarningDocumentsForm;
