import { Image } from 'react-bootstrap';
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import CircleKMyPayments from '../CircleKMyPayments/CircleKMyPayments';
import { CircleKCardModel } from '../../../types/circleK';
import DateHelpers from '../../../helpers/DateHelpers';
import StringHelpers from '../../../helpers/StringHelpers';
import RecordStatusHelper, { RecordStatus } from '../../../helpers/RecordStatusHelper';
import { useStore } from '../../../store/store';
import CircleKLimitChange from '../CircleKLimitChange/CircleKLimitChange';
import CircleKBlockCard from '../CircleKBlockCard/CircleKBlockCard';
import CircleKUnblockCard from '../CircleKUnblockCard/CircleKUnblockCard';
import CircleKCancellationCard from '../CircleKCancellationCard/CircleKCancellationCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { CircleKContractStatus } from '../../../enums/circleKContractStatus.enums';
import CircleKApplicationForCard from '../CircleKApplicationForCard/CircleKApplicationForCard';
import MyDisclosure from '../../Disclosure/MyDisclosure';
import { twMerge } from '../../../index';
import FuelIcon from '../../Icons/FuelIcon';
import BlockIcon from '../../Icons/BlockIcon';
import UnblockIcon from '../../Icons/UnblockIcon';
import { Tooltip } from 'react-tooltip';
import { limitCardArray } from '../../../helpers/ArraySelect';

export default observer((props: { card: CircleKCardModel }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { modalStore, menuStore, circleKStore } = useStore();
  const { card } = props;

  const handleContractSign = (param: number) => {
    modalStore.openModal(<CircleKApplicationForCard contractId={param} />, 'lg', 'Wprowadź dane do umowy');
  };

  const getCardCompanyName = () => {
    return props.card.companyName;
  };

  return (
    <>
      <MyDisclosure
        defaultOpen={false}
        header={(open) => (
          <div
            className={twMerge(
              'tw-flex tw-rounded-t-lg tw-bg-nau-green-light tw-p-4 tw-text-white tw-transition-all',
              card.cardStatus === RecordStatus.NotActive && 'tw-bg-nau-green-dark',
              (card.resignation || card.cardStatus === RecordStatus.Blocked) && 'tw-bg-nau-gray',
              card.cardStatus === RecordStatus.Deleted && 'tw-bg-nau-red',
              !open && card.contractStatus !== CircleKContractStatus.New && 'tw-rounded-b-lg',
            )}>
            <div className='tw-flex tw-grow tw-flex-col tw-gap-2'>
              {card.cardName && (
                <div className='tw-flex tw-items-center tw-justify-between'>
                  <p className='tw-text-lg tw-font-semibold'>{props.card.cardName}</p>
                </div>
              )}

              <div className='tw-flex tw-items-center tw-justify-between '>
                <p className='tw-font-semibold'>{props.card.licensePlateNumber}</p>
                <p className='tw-text-right'>
                  <div>{props.card.cardNumber != null ? 'Nr karty' : null}</div>
                  {StringHelpers.formatCardNumber(props.card.cardNumber)}
                </p>
              </div>
              <div className=' tw-flex tw-items-center tw-justify-between'>
                <div className='tw-flex tw-items-center'>
                  <Image className='tw-h-12 tw-w-12' src={'/assets/card.svg'} />
                  <div className='tw-ml-2'>{getCardCompanyName()}</div>
                </div>
              </div>
            </div>
            <div className={'tw-flex tw-items-center'}>
              <FontAwesomeIcon
                className={twMerge('tw-transition-all', open && 'tw-rotate-90')}
                color='#fff'
                icon={faCaretRight}
                size='2x'
              />
            </div>
          </div>
        )}
        renderBody={(open) => (
          <div
            className={twMerge(
              'tw-flex tw-flex-col tw-gap-4 tw-bg-white tw-p-4',
              props.card.contractStatus !== CircleKContractStatus.New && 'tw-rounded-b-lg',
            )}>
            <div className={'tw-flex tw-flex-col tw-gap-2'}>
              <div className='tw-flex tw-justify-between tw-leading-snug'>
                <p>STATUS</p>
                <p>
                  {props.card.resignation ? 'Rezygnacja' : RecordStatusHelper.getRecordStatus(props.card.cardStatus)}
                </p>
              </div>
              <div className='tw-flex tw-justify-between tw-leading-snug'>
                <p>LIMIT MIĘSIECZNY</p>
                <p>{props.card.limit} zł</p>
              </div>
              <div className='tw-flex tw-justify-between tw-leading-snug'>
                <p>DATA WAŻNOŚCI</p>
                <p>{DateHelpers.formatDate(props.card.expirationDate)}</p>
              </div>
              {props.card.pin && (
                <div className='tw-flex tw-justify-between tw-leading-snug'>
                  <p>PIN</p>
                  <div className='tw-flex tw-items-center'>
                    <Image
                      className='tw-cursor-pointer'
                      onClick={() => setShowPassword(!showPassword)}
                      src={'/assets/show.svg'}
                    />
                    <div className='tw-ml-2'>
                      {showPassword
                        ? props.card.pin
                        : props.card.pin
                          ? new Array(props.card.pin.toString().length + 1).join('*')
                          : ''}
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`tw-flex tw-justify-between tw-leading-snug ${menuStore.isMobileView ? 'tw-flex-wrap' : ''}`}>
                <p>NR RACHUNKU</p>
                <p>{StringHelpers.formatBankAccount(props.card.bankAccountNumber)}</p>
              </div>
            </div>

            {!card.resignation &&
              (card.cardStatus === RecordStatus.Active || card.cardStatus === RecordStatus.Blocked) && (
                <div className='tw-flex tw-flex-col tw-gap-2'>
                  {card.cardStatus === RecordStatus.Active && (
                    <>
                      <Tooltip id='tooltip-change-fuel-card-limit' />
                      {card.limit < Math.max(...limitCardArray.map((x) => x.value)) && (
                        <div
                          className={twMerge(
                            'tw-flex tw-cursor-pointer tw-gap-2',
                            card.isLimitModificationPetition && 'tw-cursor-default tw-opacity-60',
                          )}
                          data-tooltip-content={
                            card.isLimitModificationPetition ? 'Wniosek o zmianę limitu został już złożony' : undefined
                          }
                          data-tooltip-delay-show={200}
                          data-tooltip-id='tooltip-change-fuel-card-limit'
                          onClick={() => modalStore.openModal(<CircleKLimitChange card={props.card} />, 'lg', null)}>
                          <FuelIcon className={twMerge('tw-h-5 tw-w-5 tw-text-nau-gray')} />

                          <p className={`tw-text-nau-green-light`}>Wniosek o zmianę limitu</p>
                        </div>
                      )}

                      {!!card.cardNumber && (
                        <>
                          <div
                            className={'tw-flex tw-cursor-pointer tw-gap-2'}
                            onClick={() => modalStore.openModal(<CircleKBlockCard card={props.card} />, 'lg', null)}>
                            <BlockIcon className={twMerge('tw-h-5 tw-w-5 tw-text-nau-gray')} />
                            <p className={`tw-text-nau-red`}>Zablokuj kartę</p>
                          </div>
                          <div
                            className={'tw-flex tw-cursor-pointer tw-gap-2'}
                            onClick={() =>
                              modalStore.openModal(<CircleKCancellationCard card={props.card} />, 'lg', null)
                            }>
                            <BlockIcon className={twMerge('tw-h-5 tw-w-5 tw-text-nau-gray')} />
                            <p className={`tw-text-nau-red`}>Zastrzeż Kartę</p>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {card.cardStatus === RecordStatus.Blocked && (
                    <div
                      className={'tw-flex tw-cursor-pointer tw-gap-2'}
                      onClick={() => modalStore.openModal(<CircleKUnblockCard card={props.card} />, 'lg', null)}>
                      <UnblockIcon className={twMerge('tw-h-5 tw-w-5 tw-text-nau-gray')} />
                      <p className={`tw-text-nau-green-light`}>Odblokuj kartę</p>
                    </div>
                  )}
                </div>
              )}

            {props.card.transactions.length > 0 && (
              <>
                <p>Ostatnie 4 transakcje</p>
                <CircleKMyPayments cardId={props.card.cardId} items={props.card.transactions} />
              </>
            )}
          </div>
        )}
        wrapperClassName={'tw-w-full'}
      />
      {props.card.contractStatus === CircleKContractStatus.New && (
        <div
          className='tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-b-lg tw-bg-green-500 tw-p-4 tw-text-center tw-text-lg tw-font-semibold tw-text-white tw-underline'
          onClick={() => handleContractSign(props.card.contractId)}>
          Aktywacja karty wymaga podpisania umowy
        </div>
      )}
    </>
  );
});
