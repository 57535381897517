import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { CinemaVoucherM } from '../../../types/cinemaVoucher';
import CinemaVoucherListElement from './CinemaVoucherListElement';
import { Tab } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useAutoAnimate } from '@formkit/auto-animate/react';

interface ICinemaVouchersListProps {
  codesList: CinemaVoucherM[];
  changeCinemaVoucherUsed: (id: number, isUsed: boolean) => void;
}

export default observer((props: ICinemaVouchersListProps) => {
  const [tab, setTab] = useState(0);
  const [activeCodesRef] = useAutoAnimate({ duration: 200 });
  const [usedCodesRef] = useAutoAnimate({ duration: 200 });

  const activeCodes = useMemo(() => props.codesList.filter((code) => !code.isUsed), [props.codesList]);
  const usedCodes = useMemo(() => props.codesList.filter((code) => code.isUsed), [props.codesList]);

  const changeCinemaVoucherUsed = (code: CinemaVoucherM) => {
    props.changeCinemaVoucherUsed(code.id, !code.isUsed);
  };

  return (
    <div className={''}>
      {props.codesList && props.codesList.length > 0 ? (
        <Tab.Group defaultIndex={0} onChange={(index) => setTab(index)}>
          <div className={'tw-flex sm:tw-justify-center'}>
            <Tab.List className={'tw-flex tw-divide-x tw-divide-gray-200 tw-shadow tw-w-full'}>
              {['Niewykorzystane', 'Wykorzystane'].map((tabName, index) => (
                <Tab
                  className={() =>
                    twMerge(
                      'tw-text-gray-500 hover:tw-text-gray-700 tw-py-1',
                      'tw-relative  tw-flex-1 tw-bg-white tw-h-[35px] tw-px-4 tw-text-center tw-text-sm tw-font-medium hover:tw-bg-gray-50 focus:tw-outline-none',
                      tab == index && 'tw-text-nau-green-dark ',
                    )
                  }
                  key={index}>
                  <span>{tabName}</span>
                  <span
                    className={twMerge(
                      tab == index ? 'tw-bg-nau-green-light' : 'tw-bg-transparent',
                      'tw-absolute tw-inset-x-0 tw-bottom-0 tw-h-0.5',
                    )}
                  />
                </Tab>
              ))}
            </Tab.List>
          </div>
          <Tab.Panels className={'tw-px-2 tw-py-4'}>
            <Tab.Panel className={'tw-flex tw-flex-col gap-4'} ref={activeCodesRef}>
              {activeCodes.length == 0 && (
                <div className='d-flex justify-content-center tw-py-2'>Nie masz kuponów do wyświetlenia</div>
              )}
              {activeCodes.map((code) => (
                <CinemaVoucherListElement
                  changeCinemaVoucherUsed={changeCinemaVoucherUsed}
                  cinemaVoucher={code}
                  key={code.id}
                />
              ))}
            </Tab.Panel>
            <Tab.Panel className={'tw-flex tw-flex-col gap-4'} ref={usedCodesRef}>
              {usedCodes.length == 0 && (
                <div className='d-flex justify-content-center tw-py-2'>Nie masz kuponów do wyświetlenia</div>
              )}
              {usedCodes.map((code) => (
                <CinemaVoucherListElement
                  changeCinemaVoucherUsed={changeCinemaVoucherUsed}
                  cinemaVoucher={code}
                  key={code.id}
                />
              ))}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      ) : (
        <div className='d-flex justify-content-center tw-py-4'>Nie masz kuponów do wyświetlenia</div>
      )}
    </div>
  );
});
