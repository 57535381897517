import { observer } from 'mobx-react-lite';
import * as React from 'react';
import CustomerDashboard from '../../containers/CustomerDashboard/CustomerDashboard';
import { useStore } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import SingleNotification from '../../components/SingleNotification/SingleNotification';
import * as styles from './styles';
import SubpageHeader from '../../components/SubpageHeader/SubpageHeader';

export default observer(() => {
  const { notificationStore, modalStore } = useStore();

  const handleModalNotification = (content: string, id: number, read: boolean) => {
    modalStore.openModal(<SingleNotification content={content} id={id} />, 'lg', null);
    if (!read) {
      notificationStore.readNotificationsUser(id).then(() => {
        notificationStore.notification.map((notification) => {
          if (notification.id === id) {
            return {
              ...notification,
              read: true,
            };
          }
        });
      });
    }
  };
  const handleDeleteNotification = (id: number) =>
    notificationStore
      .deleteNotificationsUser(id)
      .then(() => {
        notificationStore.notification.filter((notification) => notification.id !== id);
      })
      .then(() => {
        modalStore.closeModal();
      });
  return (
    <>
      <CustomerDashboard>
        <div className='col-md-6 offset-md-3'>
          {<SubpageHeader title={'Powiadomienia'} />}
          <div style={styles.container}>
            {notificationStore.notification.map(({ content, createDate, id, read, title }) => (
              <div
                className='d-flex justify-content-between'
                key={id}
                style={
                  { padding: '15px', background: '#fff', borderBottom: '1px solid #ececec' } as React.CSSProperties
                }>
                <div onClick={() => handleModalNotification(content, id, read)} style={styles.ReadNotification}>
                  <div>
                    <span style={read ? styles.title : styles.unreadTitle}>{title}</span>
                    <div style={{ fontSize: '13px', fontWeight: '300', paddingTop: '5px' } as React.CSSProperties}>
                      {createDate}
                    </div>
                  </div>
                </div>
                <span
                  className=''
                  onClick={() => {
                    handleDeleteNotification(id);
                  }}
                  style={{ cursor: 'pointer' } as React.CSSProperties}>
                  <FontAwesomeIcon color='#626d80' icon={faTrash} size='sm' />
                </span>
              </div>
            ))}
            {notificationStore.notification.length == 0 ? (
              <div style={{ padding: '15px', fontWeight: '500' } as React.CSSProperties}>Nie masz powiadomień</div>
            ) : null}
          </div>
        </div>
      </CustomerDashboard>
    </>
  );
});
