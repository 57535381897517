export const container = {
  backgroundColor: 'white',
  borderRadius: '10px',
  color: 'black',
  boxShadow: '0px 12px 30px #0000001a',
  WebkitBoxShadow: '0px 12px 30px #0000001a',
  height: '100%',
  maxWidth: '600px',
};

export const flexPadding = {
  display: 'flex',
  justifyContent: 'center',
  padding: '15px',
};
export const flexColumnPadding = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '15px',
} as React.CSSProperties;

export const infoSection = {
  fontSize: '20px',
  paddingTop: '20px',
  lineHeight: '24px',
  textAlign: 'center',
  display: 'flex',
  color: '#00635C',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
} as React.CSSProperties;

export const width90 = { width: '90%' } as React.CSSProperties;
export const width70 = { width: '70%' } as React.CSSProperties;
