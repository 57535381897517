export const imageDiv = {
  width: '120px',
  height: '40px',
  marginTop: '30px',
};
export const nameText = {
  color: '#ffffff',
  fontWeight: '300',
  paddingLeft: '20px',
  paddingTop: '15px',
  fontSize: '14px',
  lineHeight: '20px',
  maxWidth: '80%',
};

export const lowerMenu = {
  position: 'absolute',
  bottom: '50px',
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
} as React.CSSProperties;

export const upperMenu = {
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  marginTop: '40px',
} as React.CSSProperties;

export const menuItem = {
  display: 'flex',
  paddingLeft: '20px',
  paddingTop: '10px',
  paddingBottom: '10px',
  justifyContent: 'start',
  alignItems: 'center',
  borderTop: '1px solid #00635C',
} as React.CSSProperties;

export const subMenuItem = {
  display: 'flex',
  padding: 0,
  justifyContent: 'start',
  alignItems: 'center',
  borderTop: '1px solid #00635C',
} as React.CSSProperties;

export const firstMenuItem = {
  display: 'flex',
  paddingLeft: '20px',
  paddingTop: '10px',
  paddingBottom: '10px',
  justifyContent: 'start',
  borderTop: 'none',
} as React.CSSProperties;

export const navLink = {
  display: 'flex',
  alignItems: 'center',
  color: '#464646',
  fontWeight: '400',
  fontSize: '20px',
  textDecoration: 'none',
  lineHeight: 'auto',
  margin: '10px 0',
} as React.CSSProperties;

export const container = {
  background: '#fff',
  borderTopRightRadius: '20px',
  border: 'none',
} as React.CSSProperties;

export const mobileContainer = {
  width: '80%',
} as React.CSSProperties;
export const webContainer = {
  width: '250px',
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100vh',
  zIndex: 1040,
} as React.CSSProperties;
