import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import CinemaVoucherTabs from '../../components/CinemaVoucher/CinemaVoucherTabs/CinemaVoucherTabs';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import CinemaVoucherNotAvailable from '../../components/CinemaVoucher/CinemaVoucherNotAvailable/CinemaVoucherNotAvailable';
import { toast } from 'react-toastify';
import { useStore } from '../../store/store';
import { CinemaVoucherIsAvailableM } from '../../types/cinemaVoucher';
import ApprovalsForm from '../../components/ApprovalsForm/ApprovalsForm';
import { Benefit } from '../../enums/benefits.enums';
import CinemaVoucherHeader from '../../components/CinemaVoucher/CinemaVoucherHeader/CinemaVoucherHeader';
import { useSwrAgent } from '../../api/useSwrAgent';
import { UserPromotionE } from '../../enums/userPromotion.enums';
import { CinemaVoucherPromoDirectorFormLayout } from './CinemaVoucherPromoDirectorFormLayout';
import { CinemaVoucherPromoDirectorTeachersDayFormLayout } from './CinemaVoucherPromoDirectorTeachersDayFormLayout';

export default observer(() => {
  const { cinemaVoucherStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [available, setAvailable] = useState<CinemaVoucherIsAvailableM>({ available: false, hasApprovals: false });
  const { data: userMarketingData, isLoading: areMarketingDataLoading } = useSwrAgent().Users.GetUserMarketingData();
  const showDirectorForm =
    userMarketingData &&
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolDirectors);
  const showDirectorTeachersDayForm =
    userMarketingData &&
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolDirectorsTeachersDay);
  const renderContent = () => {
    if (!available.available) {
      return <CinemaVoucherNotAvailable />;
    }

    if (available.hasApprovals) {
      if (showDirectorTeachersDayForm) return <CinemaVoucherPromoDirectorTeachersDayFormLayout />;
      if (showDirectorForm) return <CinemaVoucherPromoDirectorFormLayout />;
      return <CinemaVoucherTabs />;
    }

    return (
      <div className='col-md-6 offset-md-3'>
        <CinemaVoucherHeader />
        <div className='tw-rounded-b-lg tw-bg-white tw-px-4 tw-pb-6 tw-pt-4'>
          <ApprovalsForm allRequired={true} benefitId={Benefit.CinemaVoucher} hideHeader={true} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    cinemaVoucherStore
      .checkCinemaVoucherIsAvailableForUser()
      .then((resp) => {
        setAvailable(resp);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  }, []);
  return (
    <>
      <LoadingComponent visible={loading || areMarketingDataLoading} />
      {renderContent()}
    </>
  );
});
