import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useStore } from '../../../store/store';
import { ErrorMessage, Form, Formik } from 'formik';
import * as styles from './styles';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';
import { UserPhoneModel } from '../../../types/user';
import { UserProfileState } from '../UserProfileState';
import { Image } from 'react-bootstrap';

export default observer(
  (props: { phone: UserPhoneModel; refreshView: (userProfileState: UserProfileState, refresh: boolean) => void }) => {
    const { modalStore, userProfileStore } = useStore();
    const handleClose = () => modalStore.closeModal();

    return (
      <div style={_.merge({}, styles.divSpacing as React.CSSProperties)}>
        <Formik
          initialValues={{
            error: null,
          }}
          onSubmit={(_, { setErrors, setSubmitting }) => {
            userProfileStore
              .deleteUserProfilePhone(props.phone.id)
              .then(() => {
                setSubmitting(false);
                toast.success('Dane zostały zmienione.');
                props.refreshView(UserProfileState.ShowPhones, true);
                handleClose();
              })
              .catch(() => {
                setSubmitting(false);
                setErrors({ error: 'Nie można skasować tego numeru telefonu.' });
              });
          }}>
          {({ handleSubmit, isSubmitting, errors }) => (
            <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
              <div style={_.merge({}, styles.center as React.CSSProperties)}>
                <Image className='redIcon' src={'/assets/mobile-screen-solid.svg'} style={styles.imageStyle} />
                <p style={styles.title}>Czy na pewno chcesz skasować numer telefonu?</p>
                <p className='mt-4' style={styles.title}>
                  Numer: {props.phone.phone}
                </p>
              </div>

              <ErrorMessage name='error' render={() => <label style={styles.labelError}>{errors.error}</label>} />

              <ButtonsSubmitCancel
                buttonTextCancel='Nie'
                buttonTextSubmit='Tak'
                cancelHandler={handleClose}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  },
);
