import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { toast } from 'react-toastify';

const base64ToUint8Array = (base64: string) => {
  const binaryString = window.atob(base64);
  const length = binaryString.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const openCamera = async (source: CameraSource, setBlobCallback: (blob: Blob) => void) => {
  try {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source,
      quality: 100,
    });
    if (photo.base64String) {
      const bytes = base64ToUint8Array(photo.base64String);
      const blob = new Blob([bytes], { type: 'image/jpg' });

      setBlobCallback(blob);
    }
  } catch (error) {
    toast.error('Nie udało się załadować zdjęcia');
  }
};
