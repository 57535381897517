import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';

export const initialValues = {
  firstName: '',
  lastName: '',
  pesel: '',
  phone: '',
  email: '',
  documentNumber: '',
  documentExpirationDate: '',
  bankAccountNumber: '',
  error: null,
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required('Pole jest wymagane'),
  lastName: Yup.string().nullable().required('Pole jest wymagane'),
  documentNumber: Yup.string().nullable().required('Pole jest wymagane'),
  documentExpirationDate: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .test('date-test', 'Nieprawidłowa data', (value) => ValidationHelper.checkDate(value ?? '')),
  bankAccountNumber: Yup.string().nullable().required('Pole jest wymagane'),
  email: Yup.string().nullable().required('Pole jest wymagane').email('Podaj poprawny adres email'),
  phone: Yup.string()
    .nullable()
    .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
    .required('Pole jest wymagane'),
  pesel: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
    .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value ?? '')),
});
