import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  EmploymentType,
  employmentTypesWithoutEndDateArray,
  walletEmploymentTypeArray,
} from '../../../../helpers/EmploymentTypeSelect';
import MySelectInput from '../../../../components/FormControls/MySelectInput/MySelectInput';
import MyTextMaskedInput from '../../../../components/FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../../../helpers/MaskHelpers';
import MyTextInput from '../../../../components/FormControls/MyTextInput/MyTextInput';
import { WalletNauContractData } from '../../../../types/walletNau';

export default function EmploymentDataFormPart() {
  const formikContext = useFormikContext<WalletNauContractData>();

  const [employmentDateInputLabel, setEmploymentDateInputLabel] = useState<string>('Data zatrudnienia');
  const [employmentEndDateInputType, setEmploymentEndDateInputType] = useState<string>('');

  const employmentTypeOnChange = (value: number) => {
    if (employmentTypesWithoutEndDateArray.includes(value)) {
      formikContext.setFieldValue('employmentEndDate', '');
      setEmploymentEndDateInputType('hidden');
    } else {
      setEmploymentEndDateInputType('');
    }

    if (value == EmploymentType.Retirement) {
      setEmploymentDateInputLabel('Data startu świadczenia emerytalnego');
    } else {
      setEmploymentDateInputLabel('Data zatrudnienia');
    }
  };

  useEffect(() => {
    employmentTypeOnChange(formikContext.values.employmentType);
  }, []);

  return (
    <>
      <MySelectInput
        label='Rodzaj zatrudnienia'
        name={`employmentType`}
        onChange={employmentTypeOnChange}
        options={walletEmploymentTypeArray}
        placeholder='Rodzaj zatrudnienia'
        value={formikContext.values.employmentType}
      />
      <MyTextMaskedInput
        label={employmentDateInputLabel}
        mask={MaskHelpers.fullDate}
        name={`employmentDate`}
        placeholder='rrrr-MM-dd'
      />
      <MyTextMaskedInput
        label='Data zakończenia umowy'
        mask={MaskHelpers.fullDate}
        name={`employmentEndDate`}
        placeholder='rrrr-MM-dd'
        type={employmentEndDateInputType}
      />
      {!formikContext.values.isNauWorker && (
        <MyTextInput label={'Dochód miesięczny netto'} name={'monthlyNetIncome'} type={'text'} />
      )}
      {!formikContext.values.isNauWorker && <MyTextInput label='Nazwa pracodawcy' name={`companyName`} />}
      <MyTextInput label='Stanowisko' name={`profession`} />
    </>
  );
}
