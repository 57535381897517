import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';

export default class FileHelper {
  static async writeFile(dataObj: Blob, fileName: string, fileType: string) {
    try {
      const base64Data = await this.blobToBase64(dataObj);
      const filePath = await this.getUniqueFilePath(fileName);

      await Filesystem.writeFile({
        path: filePath,
        data: base64Data,
        directory: Directory.Documents,
      });

      const { uri } = await Filesystem.getUri({
        directory: Directory.Documents,
        path: filePath,
      });

      this.openFile(uri, fileType);
    } catch (error: any) {
      toast.error('Nie udało się pobrać pliku');
    }
  }

  static async downloadViaBrowser(fileName: string, base64Data: string, fileType: string) {
    const link = document.createElement('a');
    link.href = `data:${fileType};base64,${base64Data}`;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  static async getUniqueFilePath(fileName: string): Promise<string> {
    let filePath = fileName;
    let counter = 1;

    while (await this.fileExists(filePath)) {
      const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
      const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
      filePath = `${fileNameWithoutExtension}(${counter})${fileExtension}`;
      counter++;
    }

    return filePath;
  }

  static async fileExists(filePath: string): Promise<boolean> {
    try {
      await Filesystem.stat({
        path: filePath,
        directory: Directory.Documents,
      });
      return true;
    } catch (error) {
      // If the file does not exist, stat will throw an error
      return false;
    }
  }

  static openFile(filePath: string, fileType: string) {
    FileOpener.open({ filePath, contentType: fileType }).then(
      () => console.log('File is opened successfully'),
      (e) => {
        console.error('Error opening file', e);
        toast.error('Nie udało się otworzyć pliku');
      },
    );
  }

  static async getFileBlob(url: string): Promise<Blob> {
    const response = await fetch(url);
    return await response.blob();
  }

  static getExtensionFromBlob(file: Blob): string {
    const type = file.type;
    switch (true) {
      case type.includes('pdf'):
        return '.pdf';
      case type.includes('png'):
        return '.png';
      case type.includes('jpg'):
        return '.jpg';
      case type.includes('gif'):
        return '.gif';
      case type.includes('xml'):
        return '.xml';
      default:
        return '';
    }
  }

  static async downloadFile(url: string, fileName: string) {
    const blob = await this.getFileBlob(url);
    if (Capacitor.isNativePlatform()) {
      await this.writeFile(blob, fileName, blob.type);
    } else {
      fileDownload(blob, fileName);
    }
  }

  static async downloadFileFromBlob(blob: Blob, fileName: string) {
    if (Capacitor.isNativePlatform()) {
      await this.writeFile(blob, fileName, blob.type);
    } else {
      fileDownload(blob, fileName);
    }
  }

  static async blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data.split(',')[1]);
      };
      reader.onerror = (error) => reject(error);
    });
  }
}
