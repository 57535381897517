import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';
import { CarInsuranceFormValues } from '../../../types/carInsurance';
import { OptionValues } from '../../FormControls/MyRadioButton/types';
import { LeadCampaignType } from '../../../types/leads';

export const carParkingPlaceTypeOptions: OptionValues[] = [
  { value: 'ulica i inne miejsce niestrzeżone', label: 'ulica i inne miejsce niestrzeżone' },
  { value: 'miejsce strzeżone na zewnątrz budynków', label: 'miejsce strzeżone na zewnątrz budynków' },
  { value: 'zamknięty teren ogrodzony', label: 'zamknięty teren ogrodzony' },
  { value: 'garaż wspólny', label: 'garaż wspólny' },
  { value: 'garaż indywidualny', label: 'garaż indywidualny' },
];
export const carGearBoxTypeOptions: OptionValues[] = [
  { value: 'skrzynia manualna', label: 'skrzynia manualna' },
  { value: 'skrzynia automatyczna', label: 'skrzynia automatyczna' },
];

export const initialValues: CarInsuranceFormValues = {
  campaignType: LeadCampaignType.Car,
  campaignTypeHumanName: 'OC_AC Samochodu',
  insuranceStartYear: undefined,
  insuranceStartMonth: undefined,
  insuranceStartDay: undefined,
  firstName: '',
  lastName: '',
  pesel: '',
  email: '',
  phone: '',
  ownerAddressStreet: '',
  ownerAddressHouseNumber: '',
  ownerAddressFlatNumber: '',
  ownerAddressPostCode: '',
  ownerAddressPlace: '',
  obtainingDrivingLicenseYear: undefined,
  obtainingDrivingLicenseMonth: undefined,
  obtainingDrivingLicenseDay: undefined,
  isCoOwner: false,
  coOwners: [],
  insuranceOc: false,
  insuranceAc: false,
  insuranceNnw: false,
  insuranceAssistance: false,
  insuranceGlassBreakage: false,
  insuranceTires: false,
  fileId: undefined,
  parkingPlace: '',
  licensePlateNumber: '',
  vinNumber: '',
  gearBoxType: '',
  equipmentVersion: '',
  currentMileage: '',
  isYoungUser: undefined,
  youngUserObtainingDrivingLicenseYear: undefined,
  youngUserObtainingDrivingLicenseMonth: undefined,
  youngUserObtainingDrivingLicenseDay: undefined,
  youngUserBirthDateYear: undefined,
  youngUserBirthDateMonth: undefined,
  youngUserBirthDateDay: undefined,
  comments: '',
};

export const validationSchema = Yup.object()
  .shape({
    insuranceStartYear: Yup.number().required('Wymagany jest rok rozpoczęcia ubezpieczenia'),
    insuranceStartMonth: Yup.number().required('Wymagany jest miesiąc rozpoczęcia ubezpieczenia'),
    insuranceStartDay: Yup.number().required('Wymagany jest dzień rozpoczęcia ubezpieczenia'),
    firstName: Yup.string().required('Wymagane jest imię'),
    lastName: Yup.string().required('Wymagane jest nazwisko'),
    pesel: Yup.string()
      .required('Pole jest wymagane')
      .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
      .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
    email: Yup.string().email('Nieprawidłowy adres email').required('Wymagany jest adres email'),
    phone: Yup.string()
      .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
      .required('Pole jest wymagane'),
    ownerAddressStreet: Yup.string().required('Wymagana jest ulica właściciela'),
    ownerAddressHouseNumber: Yup.string().required('Wymagany jest numer domu właściciela'),
    ownerAddressFlatNumber: Yup.string(),
    ownerAddressPostCode: Yup.string().required('Wymagany jest kod pocztowy właściciela'),
    ownerAddressPlace: Yup.string().required('Wymagana jest miejscowość właściciela'),
    obtainingDrivingLicenseYear: Yup.number()
      .required('Wymagany jest rok uzyskania prawa jazdy')
      .min(1900, 'Nieprawidłowy rok'),
    obtainingDrivingLicenseMonth: Yup.number()
      .required('Wymagany jest miesiąc uzyskania prawa jazdy')
      .min(1, 'Nieprawidłowy miesiąc')
      .max(12, 'Nieprawidłowy miesiąc'),
    obtainingDrivingLicenseDay: Yup.number()
      .required('Wymagany jest dzień uzyskania prawa jazdy')
      .min(1, 'Nieprawidłowy dzień')
      .max(31, 'Nieprawidłowy dzień'),
    isCoOwner: Yup.boolean(),
    coOwners: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required('Wymagane jest imię współwłaściciela'),
        lastName: Yup.string().required('Wymagane jest nazwisko współwłaściciela'),
        pesel: Yup.string()
          .required('Pole jest wymagane')
          .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
          .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
        email: Yup.string().email('Nieprawidłowy adres email').required('Wymagany jest adres email współwłaściciela'),
        phone: Yup.string()
          .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
          .required('Pole jest wymagane'),
        addressStreet: Yup.string().required('Wymagana jest ulica współwłaściciela'),
        addressHouseNumber: Yup.string().required('Wymagany jest numer domu współwłaściciela'),
        addressFlatNumber: Yup.string(),
        addressPostCode: Yup.string().required('Wymagany jest kod pocztowy współwłaściciela'),
        addressPlace: Yup.string().required('Wymagana jest miejscowość współwłaściciela'),
        obtainingDrivingLicenseYear: Yup.number()
          .required('Wymagany jest rok uzyskania prawa jazdy współwłaściciela')
          .min(1900, 'Nieprawidłowy rok'),
        obtainingDrivingLicenseMonth: Yup.number()
          .required('Wymagany jest miesiąc uzyskania prawa jazdy współwłaściciela')
          .min(1, 'Nieprawidłowy miesiąc')
          .max(12, 'Nieprawidłowy miesiąc'),
        obtainingDrivingLicenseDay: Yup.number()
          .required('Wymagany jest dzień uzyskania prawa jazdy współwłaściciela')
          .min(1, 'Nieprawidłowy dzień')
          .max(31, 'Nieprawidłowy dzień'),
      }),
    ),
    insuranceOc: Yup.boolean(),
    insuranceAc: Yup.boolean(),
    insuranceNnw: Yup.boolean(),
    insuranceAssistance: Yup.boolean(),
    insuranceGlassBreakage: Yup.boolean(),
    insuranceTires: Yup.boolean(),
    fileId: Yup.number(),
    parkingPlace: Yup.string().required('Wymagane jest miejsce parkowania'),
    gearBoxType: Yup.string().required('Wymagany jest rodzaj skrzyni biegów'),
    currentMileage: Yup.string().required('Wymagany jest aktualny przebieg'),
    isYoungUser: Yup.boolean(),
    youngUserObtainingDrivingLicenseYear: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest rok uzyskania prawa jazdy przez młodego kierowcę'),
      otherwise: Yup.number(),
    }),
    youngUserObtainingDrivingLicenseMonth: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest miesiąc uzyskania prawa jazdy przez młodego kierowcę'),
      otherwise: Yup.number(),
    }),
    youngUserObtainingDrivingLicenseDay: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest dzień uzyskania prawa jazdy przez młodego kierowcę'),
      otherwise: Yup.number(),
    }),
    youngUserBirthDateYear: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest rok urodzenia młodego kierowcy'),
      otherwise: Yup.number(),
    }),
    youngUserBirthDateMonth: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest miesiąc urodzenia młodego kierowcy'),
      otherwise: Yup.number(),
    }),
    youngUserBirthDateDay: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest dzień urodzenia młodego kierowcy'),
      otherwise: Yup.number(),
    }),
    comments: Yup.string(),
  })
  .test('is-past-date', 'Data wyjazdu nie może być wcześniejsza niż dzisiaj', function (values) {
    const { obtainingDrivingLicenseYear, obtainingDrivingLicenseMonth, obtainingDrivingLicenseDay } = values;
    if (obtainingDrivingLicenseYear && obtainingDrivingLicenseMonth && obtainingDrivingLicenseDay) {
      const startDate = new Date(
        obtainingDrivingLicenseYear,
        obtainingDrivingLicenseMonth - 1,
        obtainingDrivingLicenseDay,
      );
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (startDate > today) {
        return this.createError({
          path: 'obtainingDrivingLicenseDay',
          message: 'Data otrzymania uprawnień nie może być poźniejsza niż dziś',
        });
      }
    }
    return true;
  });
export const validationSchemaWithFile = Yup.object()
  .shape({
    obtainingDrivingLicenseYear: Yup.number()
      .required('Wymagany jest rok uzyskania prawa jazdy')
      .min(1900, 'Nieprawidłowy rok'),
    obtainingDrivingLicenseMonth: Yup.number()
      .required('Wymagany jest miesiąc uzyskania prawa jazdy')
      .min(1, 'Nieprawidłowy miesiąc')
      .max(12, 'Nieprawidłowy miesiąc'),
    obtainingDrivingLicenseDay: Yup.number()
      .required('Wymagany jest dzień uzyskania prawa jazdy')
      .min(1, 'Nieprawidłowy dzień')
      .max(31, 'Nieprawidłowy dzień'),
    insuranceOc: Yup.boolean(),
    insuranceAc: Yup.boolean(),
    insuranceNnw: Yup.boolean(),
    insuranceAssistance: Yup.boolean(),
    insuranceGlassBreakage: Yup.boolean(),
    insuranceTires: Yup.boolean(),
    fileId: Yup.number(),
    parkingPlace: Yup.string().required('Wymagane jest miejsce parkowania'),
    currentMileage: Yup.string().required('Wymagany jest aktualny przebieg'),
    isYoungUser: Yup.boolean(),
    youngUserObtainingDrivingLicenseYear: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest rok uzyskania prawa jazdy przez młodego kierowcę'),
      otherwise: Yup.number(),
    }),
    youngUserObtainingDrivingLicenseMonth: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest miesiąc uzyskania prawa jazdy przez młodego kierowcę'),
      otherwise: Yup.number(),
    }),
    youngUserObtainingDrivingLicenseDay: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest dzień uzyskania prawa jazdy przez młodego kierowcę'),
      otherwise: Yup.number(),
    }),
    youngUserBirthDateYear: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest rok urodzenia młodego kierowcy'),
      otherwise: Yup.number(),
    }),
    youngUserBirthDateMonth: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest miesiąc urodzenia młodego kierowcy'),
      otherwise: Yup.number(),
    }),
    youngUserBirthDateDay: Yup.number().when('isYoungUser', {
      is: true,
      then: Yup.number().required('Wymagany jest dzień urodzenia młodego kierowcy'),
      otherwise: Yup.number(),
    }),
    comments: Yup.string(),
  })
  .test('is-past-date', 'Data otrzymania uprawnień nie może być poźniejsza niż dziś', function (values) {
    const { obtainingDrivingLicenseYear, obtainingDrivingLicenseMonth, obtainingDrivingLicenseDay } = values;
    if (obtainingDrivingLicenseYear && obtainingDrivingLicenseMonth && obtainingDrivingLicenseDay) {
      const startDate = new Date(
        obtainingDrivingLicenseYear,
        obtainingDrivingLicenseMonth - 1,
        obtainingDrivingLicenseDay,
      );
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (startDate > today) {
        return this.createError({
          path: 'obtainingDrivingLicenseDay',
          message: 'Data otrzymania uprawnień nie może być poźniejsza niż dziś',
        });
      }
    }
    return true;
  });
