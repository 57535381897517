import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Countries } from '../../../enums/countries.enums';
import { UserAddress } from '../../../types/user';
import * as styles from './styles';

export default observer((props: { userAddress: UserAddress }) => {
  return (
    <>
      <Row className='mb-3'>
        <Col xs={9}>
          <h6 style={{ paddingTop: '12px', color: 'teal' }}>
            {props.userAddress.isCorrespondence ? 'Adres do korespondencji:' : 'Adres zamieszkania:'}
          </h6>
        </Col>
        <Col style={styles.colTextRight as React.CSSProperties}>
          <Image className='greenIcon' src={'/assets/house-user-solid.svg'} style={styles.imageStyle} />
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col xs={5}>
          <p>Ulica: </p>
        </Col>
        <Col style={styles.colTextRight as React.CSSProperties}>{props.userAddress.street}</Col>
      </Row>

      <Row className='mt-2'>
        <Col xs={5}>
          <p>Numer domu: </p>
        </Col>
        <Col style={styles.colTextRight as React.CSSProperties}>
          {props.userAddress.houseNumber}
          {props.userAddress.flatNumber != null && props.userAddress.flatNumber != ''
            ? ` / ${props.userAddress.flatNumber}`
            : null}
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col xs={5}>
          <p>Miasto: </p>
        </Col>
        <Col style={styles.colTextRight as React.CSSProperties}>{props.userAddress.place}</Col>
      </Row>

      <Row className='mt-2'>
        <Col xs={5}>
          <p>Kod pocztowy: </p>
        </Col>
        <Col style={styles.colTextRight as React.CSSProperties}>{props.userAddress.postCode}</Col>
      </Row>

      <Row className='mt-2'>
        <Col xs={5}>
          <p>Poczta: </p>
        </Col>
        <Col style={styles.colTextRight as React.CSSProperties}>{props.userAddress.post}</Col>
      </Row>

      <Row className='mt-2'>
        <Col xs={5}>
          <p>Gmina: </p>
        </Col>
        <Col style={styles.colTextRight as React.CSSProperties}>{props.userAddress.commune}</Col>
      </Row>

      <Row className='mt-2'>
        <Col xs={5}>
          <p>Dzielnica: </p>
        </Col>
        <Col style={styles.colTextRight as React.CSSProperties}>{props.userAddress.district}</Col>
      </Row>

      <Row className='mt-2'>
        <Col xs={5}>
          <p>Państwo: </p>
        </Col>
        <Col style={styles.colTextRight as React.CSSProperties}>
          {Countries.find((c) => c.value == props.userAddress.country)?.label ?? ''}
        </Col>
      </Row>
    </>
  );
});
