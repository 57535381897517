import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Image, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import { Form, Formik } from 'formik';
import MySelectInput from '../../../components/FormControls/MySelectInput/MySelectInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { initialValues, validationSchema } from './index';
import { DiscountCodeContractData } from '../../../types/discountCode';
import { toast } from 'react-toastify';
import * as styles from './styles';
import { MySelectInputOptions } from '../../../components/FormControls/MySelectInput/types';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { history } from '../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import { User } from '../../../types/user';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import FileHelper from '../../../helpers/FileHelper';
import DiscountCodeHeader from '../../../components/DiscountCode/DiscountCodeHeader/DiscountCodeHeader';
import { DiscountCodeNewContractStatuses } from '../../../enums/discountCodeContractStatus.enums';
import { Benefit } from '../../../enums/benefits.enums';
import _ from 'lodash';
import BoolToString from '../../../helpers/BoolToString';
import { AddressPromptingForm } from '../../../components/Address/AddressPromptingForm';

export default observer(() => {
  const { discountCodeStore, userProfileStore, userStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [contract, setContract] = useState<DiscountCodeContractData>(initialValues);
  const user: User | undefined = userStore.getUserData();
  const [activePhonesSelectInput, setActivePhonesSelectInput] = useState<MySelectInputOptions[]>([]);
  const [differentCorrespondenceState, setDifferentCorrespondence] = useState<boolean>(false);

  const toggleDifferentCorrespondence = () => {
    setDifferentCorrespondence(!differentCorrespondenceState);
  };

  useEffect(() => {
    loadData().finally(() => setLoading(false));
  }, []);

  const loadData = async () => {
    if (!userStore.isActive) {
      toast.error('Twoje konto nie zostało aktywowane.');
      history.push(PathRoute.PUSTY);
      return;
    }

    const contractStatus = discountCodeStore.getUserDiscountCodeContractStatus().then((contractStatus) => {
      if (!DiscountCodeNewContractStatuses.includes(contractStatus ?? 0)) {
        history.push(PathRoute.DISCOUNT_CODE);
        return;
      }
    });

    const requiredApprovals = userStore
      .hasRequiredApprovalsForBenefit(Benefit.DiscountCode)
      .then((requiredApprovals) => {
        if (!requiredApprovals) {
          history.push(PathRoute.DISCOUNT_CODE_APPROVALS);
          return;
        }
      });

    const contractData = userProfileStore.getUserProfilePhones().then(() => {
      prepareActivePhonesSelectInput();
      return userProfileStore.getUserProfile().then(() => {
        return prepareContractData();
      });
    });

    return Promise.allSettled([contractStatus, requiredApprovals, contractData]);
  };

  const prepareActivePhonesSelectInput = () => {
    if (userProfileStore && userProfileStore.userProfilePhones) {
      const activePhonesSelectInput: MySelectInputOptions[] = [];

      userProfileStore.userProfilePhones.forEach((item) => {
        if (item.isActive && item) {
          activePhonesSelectInput.push({
            value: item.phone,
            label: item.phone,
          });
        }
      });
      setActivePhonesSelectInput(activePhonesSelectInput);
    }
  };

  const fillUserDataFromProfile = () => {
    const contractCopy: DiscountCodeContractData = _.cloneDeep(contract);

    if (userProfileStore.userProfile) {
      const address = userProfileStore.userProfile?.userAddresses.find((x) => !x.isCorrespondence);
      const correspondenceAddress = userProfileStore.userProfile?.userAddresses.find((x) => x.isCorrespondence);
      const differentCorrespondence =
        (address?.street ?? '' + address?.houseNumber + address?.flatNumber + address?.place + address?.postCode) !==
        (correspondenceAddress?.street ??
          '' +
            correspondenceAddress?.houseNumber +
            correspondenceAddress?.flatNumber +
            correspondenceAddress?.place +
            correspondenceAddress?.postCode);
      contractCopy.firstName = userProfileStore.userProfile.firstName;
      contractCopy.lastName = userProfileStore.userProfile.lastName;
      contractCopy.email = user ? user.username : contractCopy.email;
      contractCopy.pesel = userProfileStore.userProfile.pesel;
      contractCopy.street = address ? address.street : '';
      contractCopy.houseNo = address ? address.houseNumber : '';
      contractCopy.flatNo = address ? address.flatNumber : '';
      contractCopy.place = address ? address.place : '';
      contractCopy.postCode = address ? address.postCode : '';
      contractCopy.correspondenceStreet = correspondenceAddress ? correspondenceAddress.street : '';
      contractCopy.correspondenceHouseNo = correspondenceAddress ? correspondenceAddress.houseNumber : '';
      contractCopy.correspondenceFlatNo = correspondenceAddress ? correspondenceAddress.flatNumber : '';
      contractCopy.correspondencePlace = correspondenceAddress ? correspondenceAddress.place : '';
      contractCopy.correspondencePostCode = correspondenceAddress ? correspondenceAddress.postCode : '';
      contractCopy.differentCorrespondece = differentCorrespondence;
      setDifferentCorrespondence(differentCorrespondence);
    }
    setContract(contractCopy);
  };

  const prepareContractData = () => {
    return discountCodeStore
      .getDiscountCodeContractUserData()
      .then((resp) => {
        setDifferentCorrespondence(resp.differentCorrespondece);
        setContract(resp);
      })
      .catch((error) => {
        toast.error(error);
        fillUserDataFromProfile();
      });
  };

  const sendWithoutFile = async () => {
    const formData = new FormData();
    const emptyBlob = new Blob();
    await formData.append('formFile', emptyBlob);
    await formData.append('fileName', 'null');
    await formData.append('NauWorker', BoolToString.ToString(contract.nauWorker));

    await discountCodeStore
      .saveDiscountCodeContractFile(formData)
      .then(() => {
        history.push(PathRoute.SIGN_CONTRACT_DISCOUNT_CODE);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3' style={styles.container}>
            <DiscountCodeHeader />
            {!contract.nauWorker && (
              <div
                style={
                  {
                    background: '#F5F5F5',
                    color: '#00635C',
                    margin: '5px',
                    borderRadius: '10px',
                    padding: '10px',
                    display: 'flex',
                  } as React.CSSProperties
                }>
                <FontAwesomeIcon color='#0bc268' icon={faCircleInfo} size='lg' />
                <div style={{ paddingLeft: '10px' } as React.CSSProperties}>
                  <p style={styles.headingTextStyle}>Wymagane dokumenty</p>
                  <p>
                    Przygotuj skan zaświadczenia o dochodach od pracodawcy lub wyciąg z konta bankowego za okres 3
                    miesięcy.
                  </p>
                  <div
                    onClick={() =>
                      FileHelper.downloadFile(
                        PathRoute.EMPLOYMENT_STATEMENT_DISCOUNT_CODE,
                        'Wzor_zaswiadczenia_o_dochodach.pdf',
                      )
                    }
                    style={styles.displayFlexEnd as React.CSSProperties}>
                    <p style={styles.marginBottomLeft as React.CSSProperties}>
                      <a style={styles.hrefLink as React.CSSProperties}>Pobierz wzór zaświadczenia</a>{' '}
                    </p>
                    <Image src='/assets/arrowRightCash.svg' style={styles.heightCursor as React.CSSProperties} />
                  </div>
                </div>
              </div>
            )}
            <div style={styles.flexCenter as React.CSSProperties}>
              <div style={{ width: '100%' } as React.CSSProperties}>
                <div style={styles.goBackLink}>
                  <Link to={PathRoute.DISCOUNT_CODE_APPROVALS}>
                    <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
                  </Link>
                </div>
                <p style={styles.headingTextStyle}>Uzupełnij informacje:</p>
                <div>
                  <br />
                  <p style={styles.formTitle}>Dane osobiste</p>
                  <Formik
                    enableReinitialize
                    initialValues={contract}
                    onSubmit={(values, { setErrors, setFieldError, setSubmitting }) =>
                      discountCodeStore
                        .saveDiscountCodeContractUserData(values)
                        .then(() => {
                          if (!contract.nauWorker) {
                            history.push(PathRoute.FILE_CONTRACT_DISCOUNT_CODE);
                          } else {
                            sendWithoutFile();
                          }
                        })
                        .catch((error) => {
                          if (Array.isArray(error)) {
                            error.forEach((element) => {
                              const fieldName = element.fieldName.charAt(0).toLowerCase() + element.fieldName.slice(1);
                              setFieldError(fieldName, element.message);
                              setSubmitting(false);
                            });
                          } else {
                            setFormError(error);
                            setSubmitting(false);
                          }
                        })
                    }
                    validationSchema={validationSchema}>
                    {({ handleSubmit, errors, values, isSubmitting }) => (
                      <Form autoComplete='off' className='tw-flex tw-flex-col tw-gap-4' onSubmit={handleSubmit}>
                        <MyTextInput label={'Imię'} name={'firstName'} />
                        <MyTextInput label='Nazwisko' name={`lastName`} />
                        <MyTextInput
                          disabled={contract != null && contract.pesel != '' && contract.pesel.length == 11}
                          label='Numer PESEL'
                          mask={MaskHelpers.pesel}
                          name={`pesel`}
                        />

                        <MySelectInput
                          className='colorBlack marginBottom2px'
                          label='Telefon komórkowy'
                          name={`phone`}
                          options={activePhonesSelectInput}
                          placeholder={null}
                          value={values.phone}
                        />

                        <MyTextInput hidden={true} label='Adres e-mail' name={`email`} />
                        <MyTextInput label='Seria i numer dowodu osobistego' name={'documentNumber'} />
                        <MyTextInput
                          label='Data ważności dowodu osobistego'
                          mask={MaskHelpers.fullDate}
                          name={`documentExpirationDate`}
                          placeholder='rrrr-MM-dd'
                        />
                        <div>
                          <p className={'tw-mb-2 tw-mt-4 tw-text-nau-green-dark'}>Dane adresowe</p>
                          <div className={'tw-flex tw-flex-col tw-gap-4'}>
                            <AddressPromptingForm
                              cityFormikName={`place`}
                              flatNoFormikName={`flatNo`}
                              houseNoFormikName={`houseNo`}
                              postCodeFormikName={`postCode`}
                              streetFormikName={`street`}
                            />
                          </div>
                        </div>
                        <MyCheckbox
                          checked={differentCorrespondenceState}
                          id='differentCorrespondence'
                          label='Inny adres korespondencyjny'
                          name={`differentCorrespondence`}
                          onChange={() => toggleDifferentCorrespondence()}
                          type='checkbox'
                        />
                        {differentCorrespondenceState && (
                          <div>
                            <p className={'tw-mb-2 tw-text-nau-green-dark'}>Adres korespondencyjny</p>
                            <div className={'tw-flex tw-flex-col tw-gap-4'}>
                              <AddressPromptingForm
                                cityFormikName={`correspondencePlace`}
                                flatNoFormikName={`correspondenceFlatNo`}
                                houseNoFormikName={`correspondenceHouseNo`}
                                postCodeFormikName={`correspondencePostCode`}
                                streetFormikName={`correspondenceStreet`}
                              />
                            </div>
                          </div>
                        )}
                        {formError && <label style={styles.errorLabel}>{formError}</label>}
                        {isSubmitting ? (
                          <Button className='btn-full-width mt-2' type='submit' variant='primary'>
                            <span className='m-1'>
                              <Spinner
                                animation='grow'
                                aria-hidden='true'
                                as='span'
                                role='status'
                                size='sm'
                                variant='light'
                              />
                            </span>
                            Trwa zapisywanie...
                          </Button>
                        ) : (
                          <Button className='btn-full-width mt-2' type='submit' variant='primary'>
                            Chcę złożyć wniosek
                          </Button>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});
