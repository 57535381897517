import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function PhoneIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      data-name='Group 1414'
      height='61.514'
      id='Group_1414'
      viewBox='0 0 39.191 61.514'
      width='39.191'>
      <defs>
        <clipPath id='clip-path_asfasdfasd'>
          <path
            d='M19.425,13.207l.522,1.907a3.362,3.362,0,0,1,4.132,4.135l1.907.524a5.343,5.343,0,0,0-6.561-6.566M19.218,6.75l.522,1.907a8.783,8.783,0,0,1,10.793,10.8l1.908.524A10.767,10.767,0,0,0,19.218,6.75M5.149,5.988A4.93,4.93,0,0,0,0,10.652v46.2a4.929,4.929,0,0,0,5.148,4.665H27.819a4.93,4.93,0,0,0,5.15-4.665V22.606H30.992V53.714H5.363v1.977H30.992v1.158a2.963,2.963,0,0,1-3.173,2.688H5.148a2.962,2.962,0,0,1-3.171-2.688v-46.2A2.963,2.963,0,0,1,5.149,7.965H16.484V5.988ZM20,.537l.523,1.907A13.2,13.2,0,0,1,36.744,18.679l1.906.522A15.187,15.187,0,0,0,20,.537'
            data-name='Path 4769'
            fill='#fff'
            id='Path_4769'
          />
        </clipPath>
      </defs>
      <g clipPath='url(#clip-path_asfasdfasd)' data-name='Group 1413' id='Group_1413'>
        <rect
          data-name='Rectangle 462'
          fill='#fff'
          height='61.514'
          id='Rectangle_462'
          transform='translate(0 0)'
          width='40.093'
        />
      </g>
    </svg>
  );
}
