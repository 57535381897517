import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Station from '../../components/Station/Station';
import { useStore } from '../../store/store';

import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import SearchStationDisclosure from '../../components/SearchStation/SearchStationDisclosure';
import { FuelCardCompany } from '../../enums/fuelCardCompany.enums';

export default observer(() => {
  const { userStore } = useStore();

  return (
    <CustomerDashboard>
      <div className='tw-mx-auto tw-max-w-2xl tw-rounded-lg tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6'>
        <div>
          <div className={'tw-text-center tw-text-3xl tw-text-nau-sea-green'}>Stacje najbliżej domu</div>
          <div className={'tw-mt-4 tw-text-center tw-text-base tw-text-nau-gray'}>Wyszukiwarka stacji paliwowych</div>
          <Station />
          <div className={'tw-mx-auto tw-flex tw-max-w-lg tw-flex-col '}>
            <SearchStationDisclosure fuelCardCompany={FuelCardCompany.CircleK} />
            <div className={'tw-w-full tw-border-b tw-border-nau-gray'}></div>
            <SearchStationDisclosure fuelCardCompany={FuelCardCompany.Shell} />
            <div className={'tw-w-full tw-border-b tw-border-nau-gray'}></div>

            <SearchStationDisclosure fuelCardCompany={FuelCardCompany.BP} />
          </div>
        </div>
      </div>
    </CustomerDashboard>
  );
});
