import { Accordion } from 'react-bootstrap';
import { Card } from 'react-bootstrap/cjs';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import CircleKMyPayments from '../CircleKMyPayments/CircleKMyPayments';
import { CircleKMyExpensesModel } from '../../../types/circleK';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

export default observer((props: { myExpenses: CircleKMyExpensesModel | null }) => {
  return (
    <Accordion alwaysOpen={true}>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          Moje wydatki CircleK
          <FontAwesomeIcon color='#0BC268' icon={faCaretRight} size='2x' />
        </Accordion.Header>
        {props.myExpenses && props.myExpenses.transactions ? (
          <Accordion.Body>
            <CircleKMyPayments cardId={null} items={props.myExpenses.transactions} />
          </Accordion.Body>
        ) : null}
      </Accordion.Item>
      <Card>
        <Card.Body>
          <div style={styles.displayBetween as React.CSSProperties}>
            <p style={styles.font as React.CSSProperties}>Suma wydatków</p>
            <p style={styles.fontHigh as React.CSSProperties}>{props.myExpenses ? props.myExpenses.expenses : 0} zł</p>
          </div>
          <div style={styles.displayBetween as React.CSSProperties}>
            <p style={styles.font as React.CSSProperties}>Suma oszczędności</p>
            <p style={styles.colorFont as React.CSSProperties}>{props.myExpenses ? props.myExpenses.savings : 0}zł</p>
          </div>
        </Card.Body>
      </Card>
    </Accordion>
  );
});
