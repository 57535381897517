import { INSTITUTION_TOKEN } from '../../constants/sessionStorageKeys';
import * as React from 'react';
import { useState } from 'react';

export default function InstitutionTokenBox() {
  const [institutionToken, setInstitutionToken] = useState<string | undefined>(
    sessionStorage.getItem(INSTITUTION_TOKEN) ?? undefined,
  );

  return (
    <>
      {!!institutionToken && (
        <div className={'tw-flex tw-justify-center'}>
          <div
            className={
              'tw-mb-4 tw-flex tw-items-center tw-justify-between tw-gap-2 tw-rounded-xl tw-border-4 tw-border-nau-green-light tw-bg-white tw-p-2 tw-leading-snug'
            }>
            <div>Kod instytucji aktywny</div>
          </div>
        </div>
      )}
    </>
  );
}
