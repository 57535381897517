import { barContainer, barLink } from './styles';
import { PathRoute } from '../../constants/pathRoute/Route';
import * as React from 'react';
import LogoHeader from '../LogoHeader/LogoHeader';

export default function UpdateRequiredBar() {
  return (
    <div className='col-md-6 offset-md-3 d-flex flex-column align-items-center'>
      <div className={'tw-flex tw-justify-center tw-pb-4'}>
        <LogoHeader />
      </div>
      <div style={barContainer}>
        <span onClick={() => window.open(PathRoute.UPDATE_APP, '_system', 'location=yes')} style={barLink}>
          Wymagana aktualizacja aplikacji
        </span>
      </div>
      <div className={'tw-text-nau-green-dark tw-text-small tw-pb-4 tw-text-center'}>
        Aplikacja mobilna wymaga aktualizacji. Prosimy o pobranie najnowszej wersji.
      </div>
      <div className={'btn btn-primary'} onClick={() => window.open(PathRoute.UPDATE_APP, '_system', 'location=yes')}>
        Aktualizuj
      </div>
    </div>
  );
}
