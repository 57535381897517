import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect } from 'react';
import * as styles from './styles';
import { useStore } from '../../../store/store';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { toast } from 'react-toastify';
import { GetViewForLimitBiggerhanZero } from './GetViewForLimitBiggerhanZero';
import { GetViewForZeroLimit } from './GetViewForZeroLimit';
import { UserLoansFromCurrentMonth } from './UserLoansFromCurrentMonth';
import { GetViewForBlocked } from './GetViewForBlocked';

export default observer(() => {
  const { loansStore } = useStore();
  const [value, setValue] = React.useState(100);
  const [paymentValue, setPaymentValue] = React.useState('2,50');
  const [showValueInput, setShowValueInput] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [availableBenefitId, setAvailableBenefitId] = React.useState(0);

  const handleChangeInputRange = (e: any) => {
    setValue(e.target.value);
    const paymentValue = e.target.value * 0.025;
    const paymentValueString = paymentValue.toFixed(2).replace('.', ',');
    setPaymentValue(paymentValueString);
  };

  const handleValueOnBlur = (e: any) => {
    let value = e.target.value;
    if (value > loansStore.reaminingLimitLoan) {
      value = loansStore.reaminingLimitLoan;
    }

    if (value < 100) {
      value = 100;
    }

    setValue(value);
    const paymentValue = value * 0.025;
    const paymentValueString = paymentValue.toFixed(2).replace('.', ',');
    setPaymentValue(paymentValueString);
  };

  const handleValueOnChange = (e: any) => {
    if (e.target.value != '0') {
      setValue(e.target.value);
    }
  };

  const getUserLoansFromCurrentMonth = () => {
    const availableBenefitId = loansStore.availableBenefitId ?? 0;
    if (availableBenefitId > 0) {
      setAvailableBenefitId(availableBenefitId);
      loansStore.getUserLoansFromCurrentMonth(availableBenefitId).then(() => {
        loansStore.getLoggedUserLimit(availableBenefitId).then(() => {
          setLoading(false);
        });
      });
    }
  };
  const sendLoanApplication = () => {
    if (value < 100) {
      toast.error('Minimalna kwota wypłaty to 100 zł.');
      return;
    }
    if (value > loansStore.reaminingLimitLoan) {
      toast.error('Wnioskowana kwota jest większa od pozostałego limitu.');
      return;
    }
    loansStore.createLoanApplication(availableBenefitId, value).then((resp) => {
      history.push(PathRoute.CONFIRM_LOAN + '/' + resp);
    });
  };
  useEffect(() => {
    getUserLoansFromCurrentMonth();
  }, []);
  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <div style={styles.containerBackground as React.CSSProperties}>
          {loansStore.isBlocked ? (
            GetViewForBlocked()
          ) : (
            <div>
              {loansStore.reaminingLimitLoan != null && loansStore.reaminingLimitLoan < 100
                ? GetViewForZeroLimit(loading, loansStore)
                : GetViewForLimitBiggerhanZero(
                    loansStore,
                    loading,
                    value,
                    setShowValueInput,
                    showValueInput,
                    handleValueOnBlur,
                    handleChangeInputRange,
                    paymentValue,
                    sendLoanApplication,
                    handleValueOnChange,
                  )}
            </div>
          )}
          {loansStore.userLoansFromCurrentMonth != null && UserLoansFromCurrentMonth(loansStore)}
        </div>
      )}
    </>
  );
});
