export const container = {
  width: '100%',
  borderRadius: '20px',
  backgroundColor: '#fff',
};
export const bodyContainer = {
  padding: '20px',
};
export const benefitsStyles = {
  display: 'flex',
  justifyContent: 'left',
  width: '100%',
  height: 'fit-content',
};

export const lowerTextStyles = {
  width: '100%',
  marginTop: '20px',
};

export const bolderTextStyles = {
  fontSize: '16px',
  paddingTop: '5px',
  color: '#00635C',
  fontWeight: '600',
};
export const normalTextStyles = {
  fontSize: '16px',
  color: '#00635C',
  fontWeight: '400',
};
export const headingTextStyles = {
  fontSize: '20px',
  lineHeight: '1.2',
  paddingTop: '5px',
  color: '#00635C',
  fontWeight: '600',
};

export const firstContainerStyles = {
  height: '100px',
  margin: '5px',
  padding: '5px',
  borderRadius: '15px',
  backgroundColor: '#FB7A32',
};
export const containerUnavailableStyles = {
  opacity: '0.5',
};

export const secondContainerStyles = {
  height: '100%',
  width: '100%',
  padding: '7px',
  borderRadius: '15px',
  border: '3px solid #fff',
  position: 'relative',
  backgroundColor: '#FB7A32',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const imageStyles = {
  height: '25px',
};
export const textCardStyles = {
  fontSize: '24px',
  lineHeight: 1,
  fontWeight: '600',
  color: '#fff',
  textAlign: 'right',
  paddingTop: '3px',
};
export const descriptionCardStyles = {
  fontSize: '12px',
  fontWeight: '400',
  color: '#fff',
  textAlign: 'right',
};
export const tooltipFirstContainerStyles = {
  padding: '5px',
  borderRadius: '15px',
  backgroundColor: '#FB7A32',
  position: 'absolute',
  color: 'white',
  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 5px 15px 5px',
};

export const tooltipSecondContainerStyles = {
  ...secondContainerStyles,
  fontWeight: '400',
  fontSize: '14px',
};

export const cursorPointer = {
  cursor: 'pointer',
};
