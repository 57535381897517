import CinemaVoucherTabs from '../../components/CinemaVoucher/CinemaVoucherTabs/CinemaVoucherTabs';
import { useSwrAgent } from '../../api/useSwrAgent';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import React from 'react';
import { CinemaVoucherPromoDirectorForm } from './CinemaVoucherPromoDirectorForm';

export const CinemaVoucherPromoDirectorFormLayout = () => {
  const { data: isUserAssignedToInstitutionAsDirector, isLoading } =
    useSwrAgent().Users.IsUserAssignedToInstitutionAsDirector();

  return (
    <div>
      <LoadingComponent visible={isLoading} />
      {isUserAssignedToInstitutionAsDirector ? <CinemaVoucherTabs /> : <CinemaVoucherPromoDirectorForm />}
    </div>
  );
};
