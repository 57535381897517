import { observer } from 'mobx-react-lite';
import React from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { SportCardHeader } from './SportCardHeader';
import UserPersonalDataLayout from '../../components/UserPersonalData/UserPersonalDataLayout';
import SportCardTabs from './SportCardTabs/SportCardTabs';

export default observer(() => {
  return (
    <>
      <CustomerDashboard>
        <div className={'tw-mx-auto tw-max-w-2xl'}>
          <SportCardHeader />
          <div>
            <UserPersonalDataLayout wrapperClassName={' tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6 tw-rounded-lg'}>
              <SportCardTabs />
            </UserPersonalDataLayout>
          </div>
        </div>
      </CustomerDashboard>
    </>
  );
});
