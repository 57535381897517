export const containerBackground = {
  backgroundColor: 'white',
  borderRadius: '36px',
  color: 'black',
  boxShadow: '0px 12px 30px #0000001a',
  WebkitBoxShadow: '0px 12px 30px #0000001a',
  height: '100%',
};

export const containerFirstSlider = {
  height: '80px',
  width: '100%',
  borderRadius: '36px 36px 0px 0px',
  backgroundImage: 'linear-gradient(to right,#00635c, #0bc268)',
  display: 'flex',
  justifyContent: 'center',
};

export const Loan = {
  marginBottom: 0,
  alignSelf: 'center',
  color: '#fff',
  fontSize: 'min(max(16px, 4vw), 22px)',
  fontFamily: 'Poppins',
  lineHeight: '42px',
  marginLeft: '15px',
  marginRight: '15px',
  textAlign: 'center',
};

export const availableText = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '30px',
  color: '#626D80',
  fontSize: '16px',
};

export const limitValue = {
  display: 'flex',
  justifyContent: 'center',
  color: '#00635C',
  fontSize: '36px',
  lineHeight: '40px',
  fontWeight: '600',
};

export const currencyStyles = {
  fontSize: '16px',
  fontWeight: '400',
  marginTop: '7px',
};

export const secondContainerSlider = {
  width: '90%',
  height: '200px',
  background: 'rgb(21 108 102)',
  margin: '0 auto',
  borderRadius: '25px',
  marginBottom: '25px',
  display: 'flex',
  position: 'relative',
};

export const limitBackground = {
  padding: '20px',
  height: '200px',
  background: `linear-gradient(to right, #00635c , #0bc268)`,
  borderRadius: '25px',
  marginBottom: '25px',
  display: 'flex',
  position: 'relative',
};

export const imagePen = {
  height: 'fit-content',
  top: '30%',
  left: '73%',
  position: 'absolute',
};

export const valueLimit = {
  fontSize: '72px',
  color: '#fff',
  top: '50%',
  position: 'absolute',
  width: '100%',
  display: 'flex',
  left: 0,
  justifyContent: 'center',
};

export const limitCurrency = {
  fontSize: '16px',
  color: '#fff',
  top: '70%',
  position: 'absolute',
  width: '100%',
  display: 'flex',
  left: 0,
  justifyContent: 'center',
};

export const moneySvg = {
  height: 'fit-content',
  position: 'absolute',
  bottom: '20px',
  right: '20px',
};

export const heightContainer = {
  marginTop: '20px',
  color: '#707070',
  display: 'flex',
  justifyContent: 'center',
  height: '40px',
};

export const displayCenter = { display: 'flex', alignItems: 'center' };

export const currencyFont = { fontWeight: '600', marginBottom: 0 };

export const flexHeight = { display: 'flex', height: '20px' };

export const displayJustify = {
  display: 'flex',
  justifyContent: 'center',
};

export const widthButton = { width: '85%', fontSize: '22px' };

export const paddingTop = { padding: '20px', marginTop: '15px' };

export const colorWeight = {
  color: '#00635C',
  fontSize: '24px',
  fontWeight: '300',
};

export const lastMonth = {
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: '300',
  color: '#6C757D',
  padding: '10px',
};

export const valueInput = {
  background: 'none',
  border: 'none',
  borderBottom: '1px solid #fff',
  color: '#fff',
  marginTop: '-47px',
  width: '200px',
  outline: 'none',
  textAlign: 'center',
} as React.CSSProperties;
