import * as React from 'react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from '../CashOut/styles';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { LoanDataCashmir } from '../../../types/loans';
import { Image } from 'react-bootstrap';

export default observer((props: { institutionId: number; userId: number; month: string }) => {
  const { loansStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [currentItems, setCurrentItems] = useState<LoanDataCashmir[]>([]);

  useEffect(() => {
    loansStore
      .getUserLoanApplicationsPerMonthForAdmin(props.institutionId, props.userId, props.month)
      .then((resp) => {
        successedDownloadData(resp);
      })
      .catch((error) => {
        console.log(`Error in promises ${error}`);
        setLoading(false);
        toast.error('Wystąpił błąd przy pobieraniu historii pożyczek');
      });
  }, []);

  const successedDownloadData = (loans?: LoanDataCashmir[]) => {
    setLoading(false);
    if (loans != undefined) {
      setCurrentItems(loans);
    }
  };

  return (
    <div style={{ display: 'flex', padding: '20px' } as React.CSSProperties}>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <div style={{ width: '100%' }}>
          {currentItems == undefined || currentItems.length == 0 ? (
            <h4>Nie masz żadnych historycznych wniosków</h4>
          ) : (
            <>
              <div style={{ marginBottom: '65px' }}>
                {currentItems.length > 0 &&
                  currentItems.map((item, i) => (
                    <div key={i}>
                      <div
                        key={item.loanCashmirId.toString()}
                        style={
                          {
                            display: 'flex',
                            padding: '20px',
                            borderTop: '1px solid #B0B6BA',
                            justifyContent: 'space-between',
                          } as React.CSSProperties
                        }>
                        <div>
                          <div>
                            <Image src='assets/inProgress.svg' />
                          </div>
                          <div style={{}}>
                            <p style={styles.textDate as React.CSSProperties}>{item.loanDate}</p>
                            <p style={styles.schoolText as React.CSSProperties}>{item.contracNbr}</p>
                          </div>
                        </div>
                        <div>
                          <p style={styles.inProgress as React.CSSProperties}>{item.loanStatusName}</p>
                          <p style={styles.amountCash as React.CSSProperties}>{item.payOutAmount} zł</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
});
