import React from 'react';
import * as styles from './styles';
import { Form, Formik } from 'formik';
import { Button, Image, Spinner } from 'react-bootstrap';
import FileHelper from '../../../helpers/FileHelper';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { useStore } from '../../../store/store';
import SkeletonTextLoader from '../../../components/Loadings/SkeletonTextLoader/SkeletonTextLoader';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LinkIcon from '../../../components/Icons/LinkIcon';
import { openLink } from '../../../utils/linkUtils';
import CashbackMoneyTable from '../CashbackInflowsTable/CashbackMoneyTable';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import CashbackCustomerSearch from '../CashbackCustomerSearch/CashbackCustomerSearch';

interface ICashbackActivationForm {
  onActivate: () => void;
}

const CashbackActivationForm = (props: ICashbackActivationForm) => {
  const { cashbackStore, modalStore } = useStore();
  const { data: cashbackBenefitInfo, isLoading } = useSwrAgent().Cashback.GetCashbackBenefitInfo();

  return (
    <div>
      <div className={'tw-py-4'}>
        {cashbackBenefitInfo?.hasOtherPolicy && (
          <div className={'tw-mb-6 tw-text-sm tw-text-red-600'}>
            Twoje dane zostały przypisane do innego nr polisy ubezepieczenia na życie. Skontaktuj się z Biurem Obsługi
            Klienta w celu wyjaśnienia pod nr tel. <a href='tel:+22226967270'>22 696 72 70</a>
          </div>
        )}
        <p className={''} style={styles.headingTextStyle}>
          Dołącz do Programu „Zwrot składki” i co miesiąc odzyskuj 50% opłaconej składki za ubezpieczenie grupowe na
          życie.
        </p>
        <div className={'tw-mb-6 tw-mt-8 tw-flex tw-items-center tw-gap-2'}>
          Twoje aktualne saldo:{' '}
          {isLoading ? (
            <SkeletonTextLoader className={'tw-w-24'} />
          ) : (
            cashbackBenefitInfo?.cashbackBalanceDto?.remainingCashback.toFixed(2) ?? '-'
          )}{' '}
          zł
        </div>
        <div className={'tw-mb-6 tw-flex tw-items-center tw-gap-2'}>
          {isLoading || !cashbackBenefitInfo ? (
            <SkeletonTextLoader className={'tw-w-28'} />
          ) : (
            <CashbackMoneyTable cashbackBenefitInfo={cashbackBenefitInfo} />
          )}
        </div>

        <p className={'tw-mt-8'} style={styles.headingTextStyle}>
          Jak to działa?
        </p>
        <p className={'tw-mt-8'} style={styles.textStyle}>
          <div className={'tw-mb-8'}>
            Zwrot jest naliczany automatycznie od daty rocznicy Twojej polisy, w każdym miesiącu, w którym opłacisz
            składkę.
          </div>
          <div className={'tw-mb-8'}>
            Zgromadzone środki możesz wykorzystać na zakup dowolnego ubezpieczenia indywidualnego dostępnego w ofercie NAU, w maksymalnej wysokości do 25% ceny ubezpieczenia.
          </div>
        </p>

        <div className={'tw-my-6 tw-flex tw-justify-center'}>
          <div className={'tw-grid tw-max-w-[400px] tw-grid-cols-2 tw-gap-4'}>
            <div
              className={'tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-gap-2'}
              onClick={() => openLink(PathRoute.CAR_INSURANCE_EXTERNAL_LINK)}>
              <Image src={'/assets/cashback/car.svg'} style={{ height: '50px' }} />
              <div className={'tw-text-center tw-transition group-hover:tw-scale-105'} style={styles.textStyle}>
                Ubezpieczenie komunikacyjne
              </div>
              <LinkIcon className={'tw-absolute tw-right-4 tw-h-5 tw-w-5 tw-text-gray-700'} />
            </div>
            <div className={'tw-flex tw-flex-col tw-items-center tw-gap-2'}>
              <Image src={'/assets/cashback/shield.svg'} style={{ height: '50px' }} />
              <div className={'tw-text-center'} style={styles.textStyle}>
                Ubezpieczenie majątkowe
              </div>
            </div>
            <div className={'tw-flex tw-flex-col tw-items-center tw-gap-2'}>
              <Image src={'/assets/cashback/plane.svg'} style={{ height: '50px' }} />
              <div className={'tw-text-center'} style={styles.textStyle}>
                Ubezpieczenie turystyczne
              </div>
            </div>
          </div>
        </div>

        <div className={'tw-rounded-2xl tw-border-2 tw-border-nau-green-light tw-p-4'} style={styles.textStyle}>
          Klikając przycisk "Aktywuj", oświadczasz, iż zapoznałeś/aś się z treścią Regulaminu Programu "Zwrot składki" i
          akceptujesz jego warunki.
        </div>

        <Formik
          initialValues={{}}
          onSubmit={(_, { setSubmitting }) => {
            cashbackStore
              .activateCashbackBenefit()
              .then(() => {
                props.onActivate();
              })
              .finally(() => setSubmitting(false));
          }}>
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form autoComplete='off' className='ui form error' onSubmit={handleSubmit}>
              {isSubmitting ? (
                <Button className='btn-full-width mt-3' disabled={!isValid || isSubmitting} type='submit'>
                  <span className='m-1'>
                    <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                  </span>
                  Zapisywanie…
                </Button>
              ) : (
                <Button className='btn-full-width mt-3' type='submit'>
                  Aktywuj
                </Button>
              )}
            </Form>
          )}
        </Formik>
        <div
          className={'tw-mt-6 tw-flex tw-w-full tw-cursor-pointer tw-justify-end tw-gap-4'}
          onClick={() => FileHelper.downloadFile(PathRoute.CASHBACK_TERMS_PDF, 'Regulamin-programu-Zwrot-Skladki.pdf')}>
          <div className={'tw-text-nau-green-2'}>Regulamin</div>
          <div>
            <Image className={'h-5 w-5'} src='/assets/arrowRightCash.svg' />
          </div>
        </div>
        {cashbackBenefitInfo && !cashbackBenefitInfo?.hasLifeCashbackPolicy && (
          <div className={'tw-mt-4 tw-flex tw-justify-center'}>
            <MyButton
              onClick={() =>
                modalStore.openModal(<CashbackCustomerSearch onSave={() => modalStore.closeModal()} />, 'lg', null)
              }
              variant={'greenDark'}>
              Aktywuj zwrot składki w placówce, <br /> w której posiadasz ubezepieczenie na życie
            </MyButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default CashbackActivationForm;
