import * as React from 'react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import './style.css';
import { CircleKTransactionsModel } from '../../../types/circleK';
import CircleKTransaction from '../CircleKTransaction/CircleKTransaction';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';

export default observer((props: { cardId: number | null }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [currentItems, setCurrentItems] = useState<CircleKTransactionsModel[]>();
  const [itemsPerPage] = useState(8);
  const { circleKStore } = useStore();

  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (props.cardId == null || props.cardId <= 0) {
      circleKStore
        .getCircleKAllTransactionByUserId()
        .then(() => {
          successedDownloadData();
        })
        .catch((error) => {
          console.log(`Error in promises ${error}`);
          setLoading(false);
          toast.error('Wystąpił błąd przy pobieraniu danych');
        });
    } else {
      circleKStore
        .getCircleKAllTransactionByCardId(props.cardId)
        .then(() => {
          successedDownloadData();
        })
        .catch((error) => {
          console.log(`Error in promises ${error}`);
          setLoading(false);
          toast.error('Wystąpił błąd przy pobieraniu danych');
        });
    }
  }, []);

  const successedDownloadData = () => {
    setLoading(false);
    setPageCount(Math.ceil(circleKStore.circleKHistoryTransaction.length / itemsPerPage));
    handlePageClick({ selected: 0 });
  };

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % circleKStore.circleKHistoryTransaction.length;
    const endOffset = newOffset + itemsPerPage;
    setCurrentItems(circleKStore.circleKHistoryTransaction.slice(newOffset, endOffset));
  };

  return (
    <div>
      {loading ? (
        <LoadingComponentPartial />
      ) : (
        <div>
          <div style={{ paddingBottom: '50px', position: 'relative' }}>
            {currentItems &&
              currentItems.map((item, i) => (
                <div key={i} style={styles.containerMarginBottom as React.CSSProperties}>
                  <CircleKTransaction transaction={item} />
                </div>
              ))}
            <div style={styles.paginationStyle as React.CSSProperties}>
              <ReactPaginate
                activeClassName='active'
                breakClassName='history-transaction-page-item'
                breakLabel='...'
                breakLinkClassName='history-transaction-page-link page-link'
                containerClassName='pagination justify-content-center'
                marginPagesDisplayed={1}
                nextClassName='history-transaction-page-item'
                nextLabel='>'
                nextLinkClassName='history-transaction-page-link page-link'
                onPageChange={handlePageClick}
                pageClassName='history-transaction-page-item'
                pageCount={pageCount}
                pageLinkClassName='history-transaction-page-link page-link'
                pageRangeDisplayed={2}
                previousClassName='history-transaction-page-item'
                previousLabel='<'
                previousLinkClassName='history-transaction-page-link page-link'
                renderOnZeroPageCount={undefined}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
