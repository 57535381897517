import React, { useEffect, useState } from 'react';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import CashbackMain from '../CashbackMain/CashbackMain';
import CashbackActivationForm from '../CashbackActivationForm/CashbackActivationForm';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import getUrls from '../../../api/getUrls';
import ApprovalsForm from '../../../components/ApprovalsForm/ApprovalsForm';
import { Benefit } from '../../../enums/benefits.enums';
import { useStore } from '../../../store/store';
import CashbackCustomerSearch from '../CashbackCustomerSearch/CashbackCustomerSearch';

const CashbackPage = () => {
  const { userStore } = useStore();
  const { data: cashbackBenefitInfo, isLoading } = useSwrAgent().Cashback.GetCashbackBenefitInfo();
  const [hasApprovals, setApprovals] = useState<boolean>(false);
  const [areApprovalsLoading, setAreApprovalsLoading] = useState<boolean>(true);

  const showCashbackTable =
    cashbackBenefitInfo?.hasLifeCashbackPolicy ||
    cashbackBenefitInfo?.hasNnwCashbackLinkPromotion ||
    (cashbackBenefitInfo?.cashbackBalanceDto &&
      (cashbackBenefitInfo?.cashbackBalanceDto.customerInflows.length > 0 ||
        cashbackBenefitInfo?.cashbackBalanceDto.customerWithdrawals.length > 0 ||
        cashbackBenefitInfo?.cashbackBalanceDto.customerInflowsPending.length > 0));

  const getCashbackView = () => {
    if (!hasApprovals) {
      return <ApprovalsForm benefitId={Benefit.Cashback} hideHeader={true} />;
    }
    if (!showCashbackTable) {
      return <CashbackCustomerSearch />;
    }
    if (cashbackBenefitInfo?.isCashbackBenefitActivated) {
      return <CashbackMain />;
    }
    return (
      <CashbackActivationForm
        onActivate={() => {
          toast.success('Benefit został aktywowany pomyślnie');
          mutate(getUrls.Cashback.getCashbackBenefitInfo);
        }}
      />
    );
  };

  useEffect(() => {
    userStore
      .hasRequiredApprovalsForBenefit(Benefit.Cashback)
      .then((resp) => {
        setApprovals(resp);
      })
      .finally(() => {
        setAreApprovalsLoading(false);
      });
  }, []);

  return <div>{isLoading || areApprovalsLoading ? <LoadingComponent /> : getCashbackView()}</div>;
};

export default CashbackPage;
