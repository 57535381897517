import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';
import { WalletNauContractData } from '../../../types/walletNau';
import { employmentTypesWithoutEndDateArray } from '../../../helpers/EmploymentTypeSelect';

export const initialValues: WalletNauContractData = {
  firstName: '',
  lastName: '',
  pesel: '',
  phone: '',
  email: '',
  documentNumber: '',
  documentExpirationDate: '',
  bankAccountNumber: '',

  street: '',
  houseNo: '',
  flatNo: '',
  place: '',
  postCode: '',
  correspondenceStreet: '',
  correspondenceHouseNo: '',
  correspondenceFlatNo: '',
  correspondencePlace: '',
  correspondencePostCode: '',
  differentCorrespondece: false,

  employmentType: 0,
  employmentDate: '',
  employmentEndDate: '',
  monthlyNetIncome: '',
  companyName: '',
  profession: '',
  monthlyWalletInstallments: '',
  creditLimitsGranted: '',
  numberOfDependents: '',
  monthlyExpenses: '',
  znp: false,
  znpBranch: '',
  znpFromApi: false,
  isNauWorker: false,
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required('Pole jest wymagane'),
  lastName: Yup.string().nullable().required('Pole jest wymagane'),
  documentNumber: Yup.string().nullable().required('Pole jest wymagane'),
  documentExpirationDate: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .test('date-test', 'Nieprawidłowa data', (value) => ValidationHelper.checkDate(value ?? ''))
    .test('date-test2', 'Data nie może być z przeszłości ', (value) => {
      const today = new Date();
      const selectedDate = new Date(value ?? '');
      return selectedDate >= today;
    }),
  bankAccountNumber: Yup.string().nullable().required('Pole jest wymagane'),
  email: Yup.string().nullable().required('Pole jest wymagane').email('Podaj poprawny adres email'),
  phone: Yup.string()
    .nullable()
    .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
    .required('Pole jest wymagane'),
  pesel: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
    .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value ?? '')),
  street: Yup.string().nullable().required('Pole jest wymagane'),
  houseNo: Yup.string().nullable().required('Pole jest wymagane'),
  place: Yup.string().nullable().required('Pole jest wymagane'),
  postCode: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .matches(RegexHelpers.postCodeRegExp, 'Nieprawidłowy kod'),
  correspondenceStreet: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  correspondenceHouseNo: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  correspondencePlace: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  correspondencePostCode: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  employmentType: Yup.number()
    .test('employment-type-test', 'Pole jest wymagane', (value) => value !== 0)
    .required('Pole jest wymagane'),
  employmentDate: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .test('date-test', 'Nieprawidłowa data', (value) => ValidationHelper.checkDate(value ?? '')),
  employmentEndDate: Yup.string()
    .nullable()
    .when('employmentType', {
      is: (type: number) => employmentTypesWithoutEndDateArray.includes(type),
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .nullable()
        .test('date-test', 'Nieprawidłowa data', (value) => ValidationHelper.checkDate(value ?? ''))
        .required('Pole jest wymagane'),
    }),
  monthlyNetIncome: Yup.string().nullable().required('Pole jest wymagane'),
  companyName: Yup.string()
    .nullable()
    .when('isNauWorker', { is: false, then: Yup.string().nullable().required('Pole jest wymagane') }),
  profession: Yup.string().nullable().required('Pole jest wymagane'),
  monthlyWalletInstallments: Yup.string().nullable().required('Pole jest wymagane'),
  creditLimitsGranted: Yup.string().nullable().required('Pole jest wymagane'),
  numberOfDependents: Yup.string().nullable().required('Pole jest wymagane'),
  monthlyExpenses: Yup.string().nullable().required('Pole jest wymagane'),
  znpBranch: Yup.string()
    .nullable()
    .when(['znp', 'znpFromApi'], {
      is: (znp: boolean, znpFromApi: boolean) => znp && !znpFromApi,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
});
