import { PROMO_CODE_KEY } from '../../constants/sessionStorageKeys';
import { Tooltip } from 'react-tooltip';
import * as React from 'react';
import { useState } from 'react';

export default function PromoCodeBox() {
  const [promoCode, setPromoCode] = useState<string | undefined>(sessionStorage.getItem(PROMO_CODE_KEY) ?? undefined);

  return (
    <>
      {!!promoCode && (
        <div className={'tw-flex tw-justify-center'}>
          <div
            className={
              'tw-mb-4 tw-flex tw-items-center tw-justify-between tw-gap-2 tw-rounded-xl tw-border-4 tw-border-nau-green-light tw-bg-white tw-p-2 tw-leading-snug'
            }>
            <div>
              Aktywny kod promocyjny:{' '}
              <strong className={'tw-whitespace-nowrap tw-text-nau-green-dark'}>{promoCode.split('?')[0]}</strong>
            </div>
            {/* <div> */}
            {/*   <TrashIcon */}
            {/*     className={'tw-text-gray-600 tw-h-5 tw-w-5 hover:tw-scale-105 tw-cursor-pointer'} */}
            {/*     data-tooltip-content='Usuń kod promocyjny' */}
            {/*     data-tooltip-delay-show={500} */}
            {/*     data-tooltip-id='tooltip-promo-code-trash' */}
            {/*     onClick={() => { */}
            {/*       setPromoCode(undefined); */}
            {/*       sessionStorage.removeItem(PROMO_CODE_KEY); */}
            {/*     }} */}
            {/*   /> */}
            {/* </div> */}

            <Tooltip id='tooltip-promo-code-trash' />
          </div>
        </div>
      )}
    </>
  );
}
