import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from '../../../store/store';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import CinemaVouchersList from '../CinemaVouchersList/CinemaVouchersList';
import _ from 'lodash';
import { useSwrAgent } from '../../../api/useSwrAgent';
import CustomCinemaVoucherUsedToast from '../CustomCinemaVoucherUsedToast/CustomCinemaVoucherUsedToast';

export default observer(() => {
  const { cinemaVoucherStore } = useStore();
  const { data: codes, isLoading, mutate } = useSwrAgent().CinemaVouchers.GetUserCinemaVouchers();
  const [toastType, setToastType] = useState<'used' | 'notUsed' | null>(null);

  const changeCinemaVoucherUsed = (id: number, isUsed: boolean) => {
    const codesCopy = _.cloneDeep(codes);
    const codeToModify = codesCopy?.find((x) => x.id === id);
    if (codeToModify) {
      codeToModify.isUsed = isUsed;
    }
    mutate(
      () => {
        return cinemaVoucherStore.changeCinemaVoucherUsed(id, isUsed).then(() => {
          if (isUsed) {
            setToastType('used');
          } else {
            setToastType('notUsed');
          }

          return codesCopy;
        });
      },
      {
        optimisticData: codesCopy,
        rollbackOnError: true,
        revalidate: false,
      },
    ).then();
  };

  return (
    <div
      id='CinemaVouchers'
      style={
        {
          background: '#fff',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          marginTop: '0',
        } as React.CSSProperties
      }>
      {toastType && <CustomCinemaVoucherUsedToast onClose={() => setToastType(null)} type={toastType} />}

      {isLoading ? (
        <div className={'tw-bg-white tw-py-4'}>
          <LoadingComponentPartial />
        </div>
      ) : (
        <CinemaVouchersList changeCinemaVoucherUsed={changeCinemaVoucherUsed} codesList={codes ?? []} />
      )}
    </div>
  );
});
