import { Spinner } from 'react-bootstrap';
import { LoadingComponentModalProps } from './types';

export default function LoadingComponentModal({
  content = 'Ładowanie...',
  roundAllCorners = true,
}: LoadingComponentModalProps) {
  const cssClassName = roundAllCorners
    ? 'loading-container-round-all-corners-modal'
    : 'loading-container-round-bottom-corners-modal';

  return (
    <div className={cssClassName}>
      <Spinner animation='grow' role='status' variant='light'>
        <span className='visually-hidden'>{content}</span>
      </Spinner>
    </div>
  );
}
