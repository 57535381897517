import clsx from 'clsx';

export interface ISkeletonTextLoaderProps {
  className?: string;
}

export default function SkeletonTextLoader(props: ISkeletonTextLoaderProps) {
  return (
    <div className={clsx('tw-h-2.5 tw-max-w-sm tw-animate-pulse', props.className)}>
      <div className={clsx('tw-h-full tw-w-full tw-rounded-full tw-bg-gray-200 tw-dark:bg-gray-700')}></div>
    </div>
  );
}
