import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';

export interface ArticleModel {
  title: string;
  short_content: string;
  photo: string;
  photo_thumb: string;
  long_content: string;
}

export default class ArticlesStore {
  articles: ArticleModel[] = new Array<ArticleModel>();
  constructor() {
    makeAutoObservable(this);
  }

  getArticles = async () => {
    try {
      const response = await agent.Article.getArticle();
      runInAction(() => {
        this.articles = response.data;
      });
    } catch (e) {
      console.error(e);
    }
  };
}
