import * as React from 'react';
import * as styles from './styles';
import Checkbox from '../../Checkbox/Checkbox';

export function SecondApprove(
  secondApprove: boolean,
  handleChangeSecondApprove: (e: any) => void,
  handleContractPdf: () => void,
) {
  return (
    <div className='col-12 mt-3' style={styles.flexCenterContent as React.CSSProperties}>
      <Checkbox checked={secondApprove} handleChange={handleChangeSecondApprove} id={''} text='' type={''} />
      <span>
        {' '}
        Oświadczam, że zapoznałem/ am się z{' '}
        <a
          onClick={() => {
            handleContractPdf();
          }}
          style={styles.hrefLink as React.CSSProperties}>
          Umową
        </a>{' '}
        oraz akceptuję jej warunki.
        <span style={{ color: 'red' }}>*</span>
      </span>
    </div>
  );
}
