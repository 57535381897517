import * as React from 'react';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import * as styles from './styles';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';

const DocumentsLoan = () => (
  <CustomerDashboard>
    <div style={styles.container as React.CSSProperties}>
      <div style={styles.containerSecond as React.CSSProperties}>
        <p style={styles.Loan as React.CSSProperties}>Wypłata od ręki</p>
      </div>
      <div style={styles.containerPhoto as React.CSSProperties}>
        <div style={styles.containerRelative as React.CSSProperties}>
          <p style={styles.textStyles as React.CSSProperties}>Jedno kliknięcie, Wypłata od ręki na konto!</p>
        </div>
      </div>
      <div style={styles.widthLineHeight as React.CSSProperties}>
        <p style={styles.monthText as React.CSSProperties}>
          Do końca miesiąca jeszcze daleko, a Ty masz nieprzewidziane wydatki? Potrzebujesz gotówki już teraz?
        </p>
      </div>
      <div style={styles.paddingSize as React.CSSProperties}>
        <p style={styles.bottomWeight as React.CSSProperties}>Poznaj benefit pracowniczy "Wypłata od ręki".</p>
      </div>
      <div style={styles.paddingWidthDiv as React.CSSProperties}>
        <p style={styles.colorText as React.CSSProperties}>
          Dzięki aplikacji będziesz mieć swobodny dostęp do swojego wynagrodzenia oraz możliwość wypłaty jego części w
          każdej chwili.
        </p>
        <p style={styles.bottomStylesText as React.CSSProperties}>
          Wystarczy jedno kliknięcie, a zarobione pieniądze trafią bezpośrednio na Twoje konto. I to częściej niż raz w
          miesiącu!
        </p>
      </div>
      <div style={styles.autoInfo as React.CSSProperties}>
        <div style={styles.flexContainer as React.CSSProperties}>
          <Image src='/assets/infoGreen.svg' style={styles.flexGreen as React.CSSProperties} />
          <p style={styles.documentText as React.CSSProperties}>Wymagane dokumenty</p>
        </div>
        <p style={styles.textInfo as React.CSSProperties}>Oświadczenie o zatrudnieniu w placówce oświatowej</p>
      </div>
      <div style={styles.autoInfo as React.CSSProperties}>
        <div style={styles.flexContainerBottom as React.CSSProperties}>
          <Image src='/assets/infoGreen.svg' style={styles.flexGreen as React.CSSProperties} />
          <p style={styles.textCenter as React.CSSProperties}>Czemu muszę udowodnić że jestem pracownikiem oświaty?</p>
        </div>
        <p style={styles.textInfoEvidence as React.CSSProperties}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer faucibus consectetur commodo. Aliquam erat
          volutpat. Nam porta fermentum ligula, eget tincidunt nisi lobortis et. Mauris pretium erat sed imperdiet
          vehicula. Nullam ultrices non sem a imperdiet. Vivamus dictum fringilla neque, a dignissim ipsum iaculis ut.
        </p>
      </div>
      <div style={styles.columnFlex as React.CSSProperties}>
        <Button style={styles.bottomSize as React.CSSProperties}>Pobierz i wydrukuj wzór oświadczenia</Button>{' '}
        <Button onClick={() => history.push(PathRoute.APPROVALS_LOAN)} style={styles.fontSize as React.CSSProperties}>
          Posiadam wymagane dokumenty. Chcę złożyć wniosek.
        </Button>
      </div>
    </div>
  </CustomerDashboard>
);

export default DocumentsLoan;
