import { observer } from 'mobx-react-lite';
import * as React from 'react';
import * as styles from './styles';
import { Image } from 'react-bootstrap';

export default observer(() => {
  return (
    <div
      id='DiscountCodes'
      style={
        {
          background: '#fff',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          marginTop: '0',
          padding: '10px',
        } as React.CSSProperties
      }>
      <div style={styles.flexCenter as React.CSSProperties}>
        <div style={styles.flexColumnContainer as React.CSSProperties}>
          <p style={styles.selfStyles as React.CSSProperties}>Dostępny limit: 0 zł</p>
          <Image src='assets/clock.svg' style={styles.heightTop as React.CSSProperties} />
          <p style={styles.selfStyles as React.CSSProperties}>Limit E-bonów został wykorzystany</p>
          {/*<Button style={styles.top as React.CSSProperties} onClick={handleConfirm}>Sprawdź historię płatności</Button>*/}
        </div>
      </div>
    </div>
  );
});
