export const mainDivEmptyCard = {
  marginBottom: '10px',
};

export const textCenterFuel = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
};

export const textStyle = {
  fontSize: '36px',
  color: '#00635C',
  fontFamily: 'Poppins',
  lineHeight: '30px',
  fontWeight: '100',
};

export const imageStyle = {
  display: 'flex',
  justifyContent: 'center',
};

export const formContainer = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  flexDirection: 'column',
};

export const textForm = {
  fontSize: '36px',
  color: '#00635C',
  fontFamily: 'Poppins',
  lineHeight: '30px',
  fontWeight: '100',
  marginTop: '15%',
};

export const imageHeight = {
  height: '50px',
};

export const formCardContainer = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const cardStyle = {
  margin: 0,
  fontSize: '16px',
  color: '#fff',
  fontWeight: '500',
  height: '55px',
};

export const containerAccordion = {
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

export const displayFlex = {
  display: 'flex',
};

export const accountNumber = { margin: 0, color: '#fff' };

export const limitRemaining = {
  margin: 0,
  fontSize: '12px',
  lineBreak: 'auto',
  width: '70px',
  lineHeight: '20px',
  paddingRight: '10px',
  textAlign: 'end',
  fontWeight: '100',
  color: '#fff',
};

export const limitRemainingText = {
  margin: 0,
  color: '#fff',
  fontSize: '32px',
};

export const currency = {
  marginLeft: '5px',
  marginBottom: '0px',
  color: '#fff',
  marginTop: '6px',
};

export const displayBetween = {
  display: 'flex',
  justifyContent: 'space-between',
};
export const cardAccordionBody = {
  overflow: 'hidden',
};

export const expirationDate = {
  fontWeight: '700',
};

export const displayStart = {
  display: 'flex',
  alignItems: 'start',
};

export const marginTopLeft = {
  marginTop: '5px',
  marginLeft: '5px',
};

export const limitForm = {
  marginLeft: '5px',
  fontSize: '24px',
  color: '#0BC268',
};

export const blockStart = {
  marginLeft: '5px',
  fontSize: '24px',
  color: '#C82333',
};

export const unblock = {
  marginLeft: '5px',
  color: '#B0B6BA',
  fontSize: '24px',
};
