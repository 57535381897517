export const mainText = {
  color: 'teal',
  textAlign: 'center',
  marginBottom: '30px',
  marginTop: '20px',
};

export const containerRegister = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '20px',
};

export const loginText = {
  textDecoration: 'auto',
  fontSize: '16px',
  color: '#00635C',
  fontWeight: '',
};

export const headingText = {
  textDecoration: 'auto',
  fontSize: '16px',
  color: '#00635C',
  fontWeight: '500',
  paddingLeft: '15px',
};

export const labelError = {
  marginTop: 5,
  color: 'red',
};

export const container = {
  height: '100%',
  width: '100%',
  borderRadius: '20px',
  backgroundColor: '#fff',
  padding: '20px',
  cursor: 'pointer',
};

export const benefitsStyles = {
  display: 'flex',
  justifyContent: 'left',
  width: '100%',
  height: 'fit-content',
};

export const benefitTextStyles = {
  fontSize: '16px',
  color: '#00635C',
  fontWeight: '600',
};

export const firstContainerStyles = {
  height: '100px',
  margin: '5px',
  borderRadius: '15px',
  backgroundImage: 'linear-gradient(to right,#00635c, #0bc268)',
};
export const containerStyles = {
  padding: '0',
};

export const secondContainerStyles = {
  height: '90%',
  width: '94%',
  borderRadius: '15px',
  border: '3px solid #fff',
  position: 'relative',
  left: '3%',
  top: '5%',
  backgroundImage: 'linear-gradient(to right,#00635c, #0bc268)',
};

export const imageStyles = {
  marginTop: '5px',
  marginLeft: '5px',
  height: '25px',
};
export const imageStylesThirteen = {
  marginTop: '5px',
  marginBottom: '-5px',
  marginLeft: '5px',
  height: '30px',
};
export const flexContainerText = {
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'end',
  textAlign: 'end',
};

export const textCardStyles = {
  fontSize: '16px',
  fontWeight: '600',
  color: '#fff',
  marginRight: '10px',
  marginBottom: '20px',
  textAlign: 'right',
};

export const displayFlex = { display: 'flex', justifyContent: 'start' };
