import * as React from 'react';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import DiscountCodeHeader from '../DiscountCodeHeader/DiscountCodeHeader';
import RejectedDiscountCodeContractBody from './RejectedDiscountCodeContractBody';

const handleConfirm = async () => {
  history.push(PathRoute.PUSTY);
};
const RejectedDiscountCodeContract = () => (
  <div>
    <div className='col-md-6 offset-md-3' style={styles.container}>
      <DiscountCodeHeader />
      <div style={styles.flexCenter as React.CSSProperties}>
        <RejectedDiscountCodeContractBody></RejectedDiscountCodeContractBody>
      </div>
    </div>
  </div>
);

export default RejectedDiscountCodeContract;
