export const marginBottomField = {
  marginBottom: '2px',
} as React.CSSProperties;

export const headingText = {
  color: '#00635c',
  textAlign: 'center',
  padding: 0,
  margin: 0,
} as React.CSSProperties;
export const subheadingText = {
  color: '#00635c',
  textAlign: 'center',
  fontSize: '18px',
  padding: 0,
  margin: 0,
} as React.CSSProperties;

export const paragraphText = {
  color: '#00635c',
  textAlign: 'left',
  paddingTop: '20px',
  fontSize: '14px',
} as React.CSSProperties;

export const goBackLink = {
  width: '30px',
} as React.CSSProperties;

export const header = {
  width: 'calc(100% - 30px)',
} as React.CSSProperties;

export const formTitle = {
  color: '#00635c',
  textAlign: 'left',
  padding: 0,
  margin: 0,
  paddingBottom: '5px',
  fontSize: '14px',
} as React.CSSProperties;

export const tabLink = {
  color: '#fff',
  fontWeight: '300',
  padding: '10px',
  cursor: 'pointer',
  textAlign: 'center',
  minWidth: '33.3%',
} as React.CSSProperties;
export const activeTabLink = {
  color: '#fff',
  fontWeight: '300',
  padding: '10px',
  cursor: 'pointer',
  borderBottom: '5px solid #0bc268',
  textAlign: 'center',
  minWidth: '33.3%',
} as React.CSSProperties;

export const tabsBox = {
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  paddingTop: '20px',
  background: '#00635C',
} as React.CSSProperties;
