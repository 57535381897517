import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { Form, Formik } from 'formik';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { Countries } from '../../../enums/countries.enums';
import {
  initialValues,
  photoVoltaicInstallationPlaceOptions,
  propertyRoofTypeOptions,
  propertyWallTypeOptions,
  validationSchema,
  validationSchemaWithFile,
} from './index';
import { toast } from 'react-toastify';
import { FormikScrollToFieldError } from '../../FormikScrollToFieldError/FormikScrollToFieldError';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { handleErrorResponse } from '../../../utils/apiErrorHandle';
import { PropertyInsuranceBasicFormValues, PropertyInsuranceFormValues } from '../../../types/propertyInsurance';
import MyCheckbox from '../../FormControls/MyCheckbox/MyCheckbox';
import MyRadioButton from '../../FormControls/MyRadioButton/MyRadioButton';
import DateHelpers from '../../../helpers/DateHelpers';
import MyTextArea from '../../FormControls/MyTextArea/MyTextArea';
import { LeadCampaignType } from '../../../types/leads';
import MyButton from '../../Buttons/MyButton/MyButton';

export default observer((props: { values: PropertyInsuranceBasicFormValues; isSent: boolean }) => {
  const { leadStore, userStore } = useStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [isSent, setIsSent] = useState<boolean>(props.isSent);
  const [isFile, setIsFile] = useState<boolean>(props.values.fileId !== undefined);
  const [isConfirmView, setIsConfirmView] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<PropertyInsuranceFormValues>(initialValues);

  const getLeadCampaignHumanName = (leadCampaignType: LeadCampaignType): string => {
    switch (leadCampaignType) {
      case 'car':
        return 'OC_AC Samochodu';
      case 'property':
        return 'Ubezpieczenie majątkowe';
      case 'trip':
        return 'Ubezpieczenie turystyczne';
      case 'simNau':
        return 'Sim Nau';
      default:
        return '';
    }
  };

  const handleChangeValue = (name: string, value: any) => {
    if (value === 'true' || value === 'false') {
      const booleanValue = value === 'true' ? true : value === 'false' ? false : value;
      setFormValues({ ...formValues, [name]: booleanValue });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  const handleInsuranceStartYearChange = (startYear?: string, startMonth?: string) => {
    if (startYear) {
      const newFormValues = { ...formValues, insuranceStartYear: parseInt(startYear) };
      if (startYear && formValues.insuranceStartDay && formValues.insuranceStartMonth) {
        const monthLength = DateHelpers.getMonthLength(
          newFormValues.insuranceStartYear,
          formValues.insuranceStartMonth,
        );
        if (formValues.insuranceStartDay > monthLength) {
          newFormValues.insuranceStartDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
    if (startMonth) {
      if (parseInt(startMonth) <= 0 || parseInt(startMonth) >= 13) {
        startMonth = formValues.insuranceStartMonth?.toString() ?? '1';
      }
      const newFormValues = { ...formValues, insuranceStartMonth: parseInt(startMonth) };
      if (formValues.insuranceStartYear && startMonth && formValues.insuranceStartDay) {
        const monthLength = DateHelpers.getMonthLength(
          formValues.insuranceStartYear,
          newFormValues.insuranceStartMonth,
        );
        if (formValues.insuranceStartDay > monthLength) {
          newFormValues.insuranceStartDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
  };
  useEffect(() => {
    userStore
      .getUserPesel()
      .then((pesel) => {
        formValues.pesel = pesel?.toString() ?? '';
      })
      .finally(() => {
        formValues.firstName = props.values.firstName;
        formValues.lastName = props.values.lastName;
        formValues.phone = props.values.phone;
        formValues.fileId = props.values.fileId;
        formValues.campaignTypeHumanName = getLeadCampaignHumanName(formValues.campaignType);
        const user = userStore.userData;
        formValues.email = user?.email ?? '';
        setFormValues(formValues);
        setIsFile(!!formValues.fileId);
        setLoading(false);
      });
  }, []);
  return (
    <>
      {isSent ? (
        <div>
          <div className='tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
            Dziękujemy za zainteresowanie naszą ofertą.
          </div>

          <div className='tw-my-8 tw-text-center tw-text-base tw-text-nau-green-dark'>
            Jesteśmy w trakcie przetwarzania Twojego wniosku.
            <br />W razie pytań zapraszany do kontaktu:
          </div>

          <div className='tw-my-8 tw-text-center tw-text-base tw-text-nau-green-dark'>
            numer telefonu - <b>22 696 72 70</b>
            <br />
            adres e-mail - <b>bok@nau.pl</b>
          </div>
        </div>
      ) : (
        <>
          {isConfirmView ? (
            <Formik
              enableReinitialize
              initialValues={formValues}
              onSubmit={(values, { setErrors, setFieldError, setSubmitting }) => {
                leadStore
                  .sendPropertyInsuranceLead(formValues)
                  .then(() => {
                    setIsSent(true);
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    toast.error('Wystąpił błąd podczas wysyłania formularza');
                    handleErrorResponse(err, setFieldError);
                  });
              }}
              validationSchema={isFile ? validationSchemaWithFile : validationSchema}>
              {({ handleSubmit, errors, values, isSubmitting }) => (
                <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                  <div className='tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
                    Prosimy o potwierdzenie informacji kontaktowych, abyśmy mogli skontaktować się w sprawie oferty
                  </div>

                  <MyTextInput
                    label='Email'
                    name='email'
                    onChange={(e) => handleChangeValue('email', e.target.value)}
                  />
                  <MyTextInput
                    label='Numer telefonu'
                    name='phone'
                    onChange={(e) => handleChangeValue('phone', e.target.value)}
                    mask={MaskHelpers.phone}
                  />
                  <div className='d-flex justify-content-center'>
                    <MyButton
                      className={'tw-mt-8 tw-w-full'}
                      type='submit'
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      loadingText={'Wysyłanie...'}
                      variant={'primary'}>
                      Wyślij
                    </MyButton>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <>
              <LoadingComponent content='Ładowanie...' visible={loading} />
              <Formik
                enableReinitialize
                initialValues={formValues}
                onSubmit={(values, { setErrors, setFieldError, setSubmitting }) => {
                  setIsConfirmView(true);
                }}
                validationSchema={isFile ? validationSchemaWithFile : validationSchema}>
                {({ handleSubmit, errors, values, isSubmitting }) => (
                  <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                    <div className={'d-flex flex-column gap-2'}>
                      {!isFile && (
                        <>
                          <p className='tw-text-lg tw-text-teal-700'>Od kiedy potrzebujesz ochrony ubezpieczeniowej?</p>
                          <div className='tw-flex'>
                            <div className='tw-w-1/3 tw-pr-1.5'>
                              <MyTextInput
                                label='Rok'
                                name='insuranceStartYear'
                                type='number'
                                min='2024'
                                onChange={(e) => handleInsuranceStartYearChange(e.target.value, undefined)}
                              />
                            </div>
                            <div className='tw-w-1/3 tw-px-1.5'>
                              <MyTextInput
                                label='Miesiąc'
                                name='insuranceStartMonth'
                                type='number'
                                min='1'
                                max='12'
                                value={formValues.insuranceStartMonth}
                                onChange={(e) => handleInsuranceStartYearChange(undefined, e.target.value)}
                              />
                            </div>
                            <div className='tw-w-1/3 tw-pl-1.5'>
                              <MyTextInput
                                label='Dzień'
                                name='insuranceStartDay'
                                type='number'
                                min='1'
                                max={
                                  formValues.insuranceStartMonth && formValues.insuranceStartYear
                                    ? DateHelpers.getMonthLength(
                                        formValues.insuranceStartYear,
                                        formValues.insuranceStartMonth,
                                      ).toString()
                                    : '31'
                                }
                                onChange={(e) => handleChangeValue('insuranceStartDay', e.target.value)}
                              />
                            </div>
                          </div>
                          <p className='tw-text-lg tw-text-teal-700'>Dane właściciela nieruchomości</p>
                          <div className='tw-flex'>
                            <div className='tw-w-1/2 tw-pr-1.5'>
                              <MyTextInput
                                label='Imię'
                                name='firstName'
                                onChange={(e) => handleChangeValue('firstName', e.target.value)}
                              />
                            </div>
                            <div className='tw-w-1/2 tw-pl-1.5'>
                              <MyTextInput
                                label='Nazwisko'
                                name='lastName'
                                onChange={(e) => handleChangeValue('lastName', e.target.value)}
                              />
                            </div>
                          </div>
                          <MyTextInput
                            label='PESEL'
                            name='pesel'
                            onChange={(e) => handleChangeValue('pesel', e.target.value)}
                            mask={MaskHelpers.pesel}
                          />
                          <MyTextInput
                            label='Email'
                            name='email'
                            onChange={(e) => handleChangeValue('email', e.target.value)}
                          />
                          <MyTextInput
                            label='Numer telefonu'
                            name='phone'
                            onChange={(e) => handleChangeValue('phone', e.target.value)}
                            mask={MaskHelpers.phone}
                          />
                          <MyCheckbox
                            label='Współwłaściciel'
                            name='isCoOwner'
                            variant='big'
                            onChange={(e) => handleChangeValue('isCoOwner', e.target.checked)}
                          />
                          {formValues.isCoOwner && (
                            <>
                              <p className='tw-text-lg tw-text-teal-700'>Dane współwłaściciela nieruchomości</p>
                              <div className='tw-flex'>
                                <div className='tw-w-1/2 tw-pr-1.5'>
                                  <MyTextInput
                                    label='Imię współubezpieczonego'
                                    name='coOwnerFirstName'
                                    onChange={(e) => handleChangeValue('coOwnerFirstName', e.target.value)}
                                  />
                                </div>
                                <div className='tw-w-1/2 tw-pl-1.5'>
                                  {' '}
                                  <MyTextInput
                                    label='Nazwisko współubezpieczonego'
                                    name='coOwnerLastName'
                                    onChange={(e) => handleChangeValue('coOwnerLastName', e.target.value)}
                                  />
                                </div>
                              </div>

                              <MyTextInput
                                label='PESEL współubezpieczonego'
                                name='coOwnerPesel'
                                onChange={(e) => handleChangeValue('coOwnerPesel', e.target.value)}
                                mask={MaskHelpers.pesel}
                              />
                              <MyTextInput
                                label='Email współubezpieczonego'
                                name='coOwnerEmail'
                                onChange={(e) => handleChangeValue('coOwnerEmail', e.target.value)}
                              />
                              <MyTextInput
                                label='Numer telefonu współubezpieczonego'
                                name='coOwnerPhone'
                                onChange={(e) => handleChangeValue('coOwnerPhone', e.target.value)}
                                mask={MaskHelpers.phone}
                              />
                            </>
                          )}
                          <p className='tw-text-lg tw-text-teal-700'>Adres ubezpieczanego mienia</p>
                          <div className='tw-flex'>
                            <div className='tw-w-1/2 tw-pr-1.5'>
                              <MyTextInput
                                label='Ulica'
                                name='propertyAddressStreet'
                                onChange={(e) => handleChangeValue('propertyAddressStreet', e.target.value)}
                              />
                            </div>
                            <div className='tw-w-1/4 tw-px-1.5'>
                              <MyTextInput
                                label='Numer domu'
                                name='propertyAddressHouseNumber'
                                onChange={(e) => handleChangeValue('propertyAddressHouseNumber', e.target.value)}
                              />
                            </div>
                            <div className='tw-w-1/4 tw-pl-1.5'>
                              <MyTextInput
                                label='Numer mieszkania'
                                name='propertyAddressFlatNumber'
                                onChange={(e) => handleChangeValue('propertyAddressFlatNumber', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className='tw-flex'>
                            <div className='tw-w-1/2 tw-pr-1.5'>
                              <MyTextInput
                                label='Kod pocztowy'
                                name='propertyAddressPostCode'
                                onChange={(e) => handleChangeValue('propertyAddressPostCode', e.target.value)}
                                mask={MaskHelpers.postCode}
                              />
                            </div>
                            <div className='tw-w-1/2 tw-pl-1.5'>
                              <MyTextInput
                                label='Miejscowość'
                                name='propertyAddressPlace'
                                onChange={(e) => handleChangeValue('propertyAddressPlace', e.target.value)}
                              />
                            </div>
                          </div>
                          <p className='tw-text-lg tw-text-teal-700'>
                            Czy w zamieszkiwaniu nieruchomości występują przerwy dłuższe niż 3 miesiące?
                          </p>
                          <MyRadioButton
                            label='Czy w zamieszkiwaniu nieruchomości występują przerwy dłuższe niż 3 miesiące?'
                            name='breakInResidence'
                            value={formValues.breakInResidence}
                            options={[
                              { value: true, label: 'Tak' },
                              { value: false, label: 'Nie' },
                            ]}
                            column={true}
                            onChange={(e: boolean) => handleChangeValue('breakInResidence', e)}
                          />
                          <p className='tw-text-lg tw-text-teal-700'>Ściany zewnętrzne budynku</p>
                          <MyRadioButton
                            label='Ściany zewnętrzne budynku'
                            name='buildingWalls'
                            value={formValues.buildingWalls}
                            options={propertyWallTypeOptions}
                            column={true}
                            onChange={(e: boolean) => handleChangeValue('buildingWalls', e)}
                          />
                          <p className='tw-text-lg tw-text-teal-700'>Materiał pokrycia dachowego</p>
                          <MyRadioButton
                            label='Materiał pokrycia dachowego'
                            name='buildingRoof'
                            value={formValues.buildingRoof}
                            options={propertyRoofTypeOptions}
                            column={true}
                            onChange={(e: boolean) => handleChangeValue('buildingRoof', e)}
                          />
                          <p className='tw-text-lg tw-text-teal-700'>
                            W którym roku nieruchomość została oddana do użytku?
                          </p>
                          <div className='tw-flex'>
                            <div className='tw-w-1/3 tw-pr-1.5'>
                              <MyTextInput
                                label='Rok'
                                name='propertyCommissioningYear'
                                type='number'
                                min='1900'
                                onChange={(e) => handleChangeValue('propertyCommissioningYear', e.target.value)}
                              />
                            </div>
                          </div>
                          <p className='tw-text-lg tw-text-teal-700'>Jaka jest powierzchnia użytkowa nieruchomości?</p>
                          <div className='tw-w-1/3'>
                            <MyTextInput
                              name='propertySize'
                              onChange={(e) => handleChangeValue('propertySize', e.target.value)}
                            />
                          </div>
                          <p className='tw-text-lg tw-text-teal-700'>
                            Ile kondygnacji nadziemnych posiada nieruchomość?
                          </p>
                          <div className='tw-w-1/3'>
                            <MyTextInput
                              name='propertyNumberOfFloors'
                              onChange={(e) => handleChangeValue('propertyNumberOfFloors', e.target.value)}
                            />
                          </div>
                          <p className='tw-text-lg tw-text-teal-700'>
                            Czy w nieruchomości prowadzona jest działalność gospodarcza?
                          </p>
                          <MyRadioButton
                            label='Czy w nieruchomości prowadzona jest działalność gospodarcza?'
                            name='isBusinessActivity'
                            value={formValues.isBusinessActivity}
                            options={[
                              { value: true, label: 'Tak' },
                              { value: false, label: 'Nie' },
                            ]}
                            column={true}
                            onChange={(e: boolean) => handleChangeValue('isBusinessActivity', e)}
                          />
                          {formValues.isBusinessActivity == true && (
                            <MyTextInput
                              label='Kod PKD'
                              name='pkdCode'
                              onChange={(e) => handleChangeValue('pkdCode', e.target.value)}
                              className='tw-w-1/3'
                            />
                          )}
                          <p className='tw-text-lg tw-text-teal-700'>Czy nieruchomość przeznaczona na wynajem?</p>
                          <MyRadioButton
                            label='Czy nieruchomość przeznaczona na wynajem?'
                            name='isForRent'
                            value={formValues.isForRent}
                            options={[
                              { value: true, label: 'Tak' },
                              { value: false, label: 'Nie' },
                            ]}
                            column={true}
                            onChange={(e: boolean) => handleChangeValue('isForRent', e)}
                          />
                          <p className='tw-text-lg tw-text-teal-700'>
                            Czy nieruchomość wyposażona jest w pompę ciepła?
                          </p>
                          <MyRadioButton
                            label='Czy nieruchomość wyposażona jest w pompę ciepła?'
                            name='heatPumpInstallation'
                            value={formValues.heatPumpInstallation}
                            options={[
                              { value: true, label: 'Tak' },
                              { value: false, label: 'Nie' },
                            ]}
                            column={true}
                            onChange={(e: boolean) => handleChangeValue('heatPumpInstallation', e)}
                          />
                          {formValues.heatPumpInstallation == true && (
                            <MyTextInput
                              label='Deklarowana wartość pompy ciepłą'
                              name='valueOfHeatPumpInstallation'
                              onChange={(e) => handleChangeValue('valueOfHeatPumpInstallation', e.target.value)}
                            />
                          )}
                          <p className='tw-text-lg tw-text-teal-700'>
                            Czy nieruchomość wyposażona jest w panele fotowoltaiczne?
                          </p>
                          <MyRadioButton
                            label='Czy nieruchomość wyposażona jest w panele fotowoltaiczne?'
                            name='photoVoltaicInstallation'
                            value={formValues.photoVoltaicInstallation}
                            options={[
                              { value: true, label: 'Tak' },
                              { value: false, label: 'Nie' },
                            ]}
                            column={true}
                            onChange={(e: boolean) => handleChangeValue('photoVoltaicInstallation', e)}
                          />
                          {formValues.photoVoltaicInstallation == true && (
                            <>
                              <div className='tw-ml-4'>
                                <MyTextInput
                                  label='Deklarowana wartość instalacji'
                                  name='valueOfPhotoVoltaicInstallation'
                                  onChange={(e) => handleChangeValue('valueOfPhotoVoltaicInstallation', e.target.value)}
                                />
                              </div>
                              <p className='tw-text-lg tw-text-teal-700'>Gdzie zamontowane są panele fotowoltaiczne?</p>
                              <div className='tw-ml-4'>
                                <MyRadioButton
                                  name='placeOfPhotoVoltaicInstallation'
                                  value={formValues.placeOfPhotoVoltaicInstallation}
                                  options={photoVoltaicInstallationPlaceOptions}
                                  column={true}
                                  onChange={(e: boolean) => handleChangeValue('placeOfPhotoVoltaicInstallation', e)}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <p className='tw-text-lg tw-text-teal-700'>Preferowany zakres ubezpieczenia</p>
                      <MyCheckbox
                        label='Ubezpieczenie nieruchomości od zdarzeń losowych'
                        name='insurancePropertyAccidentalEvents'
                        variant='big'
                        onChange={(e) => handleChangeValue('insurancePropertyAccidentalEvents', e.target.checked)}
                      />
                      <MyCheckbox
                        label='Ubezpieczenie nieruchomości od kradzieży'
                        name='insurancePropertyTheft'
                        variant='big'
                        onChange={(e) => handleChangeValue('insurancePropertyTheft', e.target.checked)}
                      />
                      <MyCheckbox
                        label='OC w życiu prywatnym'
                        name='insuranceLiabilityInPrivateLife'
                        variant='big'
                        onChange={(e) => handleChangeValue('insuranceLiabilityInPrivateLife', e.target.checked)}
                      />
                      <MyCheckbox
                        label='Assistance'
                        name='insuranceAssistance'
                        variant='big'
                        onChange={(e) => handleChangeValue('insuranceAssistance', e.target.checked)}
                      />
                      <MyCheckbox
                        label='Ubezpieczenie od stłuczenia szyb'
                        name='insuranceGlassBreakage'
                        variant='big'
                        onChange={(e) => handleChangeValue('insuranceGlassBreakage', e.target.checked)}
                      />
                      <MyTextArea
                        label='Masz uwagi?'
                        placeholder='Wpisz je tutaj...'
                        rows={5}
                        name='comments'
                        onChange={(e) => handleChangeValue('comments', e.target.value)}
                      />
                      <div className='d-flex justify-content-center'>
                        <Button type='submit' disabled={isSubmitting} variant='primary'>
                          {isSubmitting ? (
                            <>
                              <Spinner animation='border' size='sm' />
                              Wysyłanie...
                            </>
                          ) : (
                            'Wyślij'
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </>
      )}
    </>
  );
});
