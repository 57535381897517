import { Accordion } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { myMarginTop } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import BirthdayCodeInstructionContent from './BirthdayCodeInstructionFormContent';
import { colorOrange1 } from '../../../theme/colors';
import { BirthdayCodeCampaignM, BirthdayCodeVerificationStatus } from '../../../types/birthdayCode';
import { DiscountCodeLeadM } from '../../../types/discountCode';
import { toast } from 'react-toastify';
import { useStore } from '../../../store/store';
import BirthdayCodeInstructionLeadSentContent from './BirthdayCodeInstructionLeadStatusContent';

export interface IBirthdayCodeFreeCodesProps {
  freeCodesCampaigns: BirthdayCodeCampaignM[];
  accordionOpenOnStart?: boolean;
}

export default observer((props: IBirthdayCodeFreeCodesProps) => {
  const { discountCodeStore } = useStore();
  const [leadSentSuccessful, setLeadSentSuccessful] = useState<boolean>(false);
  const [leadVerified, setLeadVerified] = useState<boolean>(false);

  const bk3 = props.freeCodesCampaigns.find((elem) => elem.campaignCode === 'bk3');
  const bk4 = props.freeCodesCampaigns.find((elem) => elem.campaignCode === 'bk4');

  useEffect(() => {
    setLeadVerified(false);
    setLeadSentSuccessful(false);

    if (props.freeCodesCampaigns.every((elem) => elem.verificationStatus === BirthdayCodeVerificationStatus.Verified)) {
      setLeadVerified(true);
    } else if (
      props.freeCodesCampaigns.every((elem) => elem.verificationStatus !== BirthdayCodeVerificationStatus.NotSent)
    ) {
      setLeadSentSuccessful(true);
    }
  }, [props.freeCodesCampaigns.length]);
  const onSendLeadForm = (discountCodeLead: DiscountCodeLeadM) => {
    return discountCodeStore
      .sendBirthdayCodeLead(discountCodeLead)
      .then(() => {
        setLeadSentSuccessful(true);
      })
      .catch((error) => toast.error(error));
  };

  const getAppropriateContent = () => {
    if (props.freeCodesCampaigns.length === 0) {
      return <BirthdayCodeInstructionLeadSentContent text={'Brak dostępnych bonów.'} />;
    }
    if (leadVerified) {
      return <BirthdayCodeInstructionLeadSentContent text={'Twoje bony zostały już aktywowane.'} />;
    }
    if (leadSentSuccessful) {
      return <BirthdayCodeInstructionLeadSentContent text={'Dziękujemy! Poinformujemy Cię o aktywacji E-bonu.'} />;
    }
    return <BirthdayCodeInstructionContent bk3={bk3} bk4={bk4} onSendLeadForm={onSendLeadForm} />;
  };

  return (
    <Accordion defaultActiveKey={props.accordionOpenOnStart ? '0' : null} style={myMarginTop}>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          Odbierz E-bony GRATIS
          <FontAwesomeIcon color={colorOrange1} icon={faCaretRight} size='2x' />
        </Accordion.Header>
        <Accordion.Body style={styles.accordionBody}>{getAppropriateContent()}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
});
