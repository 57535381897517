import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect } from 'react';
import { useStore } from '../../../store/store';
import { Form, Formik } from 'formik';
import * as styles from './styles';
import _ from 'lodash';
import ButtonsSubmitCancel from '../../../components/Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { BirthdayCodeCampaignM, BirthdayCodeCampaignOrderM } from '../../../types/birthdayCode';
import { ocDirectorCampaign } from '../birthdayCodesLib';

export default observer((props: { birthdayCampaignCode: BirthdayCodeCampaignM }) => {
  const { discountCodeStore, modalStore } = useStore();

  const handleClose = () => modalStore.closeModal();
  return (
    <Formik
      initialValues={{
        error: null,
      }}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(true);
        const birthdayCampaignOrderModel: BirthdayCodeCampaignOrderM = {
          campaignCode: props.birthdayCampaignCode.campaignCode,
          amount: props.birthdayCampaignCode.amount,
          policyId: props.birthdayCampaignCode.policyId,
        };
        discountCodeStore
          .orderBirthdayCode(birthdayCampaignOrderModel)
          .then(() => {
            handleClose();
            history.push(PathRoute.MY_BIRTHDAY_CODES);
          })
          .finally(() => setSubmitting(false));
      }}>
      {({ handleSubmit, isSubmitting }) => (
        <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
          <div>
            <p
              style={_.merge(
                {},
                styles.marginBottomZero as React.CSSProperties,
                styles.applicationText as React.CSSProperties,
                { lineHeight: 'normal' },
              )}>
              <span>
                Potwierdzam, że na dzień złożenia oświadczenia co najmniej 50% osób zatrudnionych w Placówce Oświatowej
                podlega ochronie ubezpieczeniowej w ramach grupowego ubezpieczenia oferowanego przez InterRisk
                Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group z siedzibą w Warszawie pn. Bezpieczna Rodzina
              </span>
            </p>
          </div>

          <ButtonsSubmitCancel
            buttonTextCancel='Anuluj'
            buttonTextSubmit='Potwierdzam'
            cancelHandler={handleClose}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
});
