import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import agent from '../../../api/agent';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import * as Yup from 'yup';
import MySelectInput from '../../../components/FormControls/MySelectInput/MySelectInput';
import { CustomerWithCashbackDataDTO, ICashbackSopPolicyGroupDto } from '../../../types/cashback';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { mutate } from 'swr';
import getUrls from '../../../api/getUrls';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { toast } from 'react-toastify';

const citySearchValidationSchema = Yup.object().shape({
  zipCode: Yup.string(),
  place: Yup.string(),
});

interface ICashbackCustomerSearchProps {
  onSave?: () => void;
}
const CashbackCustomerSearch = (props: ICashbackCustomerSearchProps) => {
  const [areCitiesLoading, setAreCitiesLoading] = useState<boolean>(false);
  const [citiesFromApi, setCitiesFromApi] = useState<string[]>([]);
  const [cashbackCustomersAddresses, setCashbackCustomersAddresses] = useState<CustomerWithCashbackDataDTO[]>([]);
  const [areCashbackCustomersAddressesLoading, setCashbackCustomersAddressesLoading] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(0);

  const loadCities = (zipCode: string, place: string) => {
    setAreCitiesLoading(true);
    if (zipCode === '' && place.length <= 2) {
      setAreCitiesLoading(false);

      return;
    }
    agent.Cashback.getCustomersWithCashbackAvailableCities(zipCode, place)
      .then((resp) => {
        setCitiesFromApi(resp);
      })
      .finally(() => {
        setAreCitiesLoading(false);
      });
  };

  const loadCashbackCustomersAddresses = (zipCode: string, place: string) => {
    setCashbackCustomersAddressesLoading(true);
    agent.Cashback.getCashbackCustomersAvailableAddresses(zipCode, place)
      .then((resp) => {
        setCashbackCustomersAddresses(resp);
      })
      .finally(() => {
        setCashbackCustomersAddressesLoading(false);
      });
  };

  const getAvailablePoliciesOptions = (customerId: number) => {
    return cashbackCustomersAddresses
      .filter((obj) => obj.id == customerId)
      .map((obj) => ({ value: obj.policyId, label: obj.insuranceCompanyPolicyNumber }));
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          zipCode: '',
          place: '',
        }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          if (values.zipCode.length <= 2) {
            setSubmitting(false);
            return;
          }
          setAreCitiesLoading(true);
          agent.Cashback.getCustomersWithCashbackAvailableCities(values.zipCode, values.place)
            .then((resp) => {
              setCitiesFromApi(resp);
            })
            .finally(() => {
              setSubmitting(false);
              setAreCitiesLoading(false);
            });
        }}
        validationSchema={citySearchValidationSchema}>
        {({ handleSubmit, values, isSubmitting, setFieldValue, errors }) => (
          <Form autoComplete='off' onChange={handleSubmit}>
            <div className={'tw-mx-auto  tw-pb-8 tw-text-center tw-text-xl tw-text-nau-green-dark '}>
              Sprawdź status weryfikacji procesu Programu <span className={'tw-whitespace-nowrap'}>Zwrot składki</span>.
            </div>
            <div
              className={
                'tw-mx-auto tw-max-w-sm tw-pb-8 tw-text-center tw-text-xl tw-text-nau-green-dark sm:tw-max-w-lg'
              }>
              W tym celu wypełnij dane swojej Placówki.
            </div>
            <div className={'tw-flex tw-flex-col tw-justify-center tw-px-2 '}>
              <div className={'tw-flex tw-w-full tw-justify-center tw-gap-4 tw-font-normal'}>
                <MyTextInput
                  mask={MaskHelpers.postCode}
                  name='zipCode'
                  onChange={(value) => {
                    setFieldValue('zipCode', value.target.value);
                    setFieldValue('place', '');
                  }}
                  placeholder='Kod pocztowy'
                  wrapperClassName={'tw-max-w-[150px]'}
                />
                <div className={'tw-grow'}>
                  <MySelectInput
                    bodyPortal={true}
                    className={'tw-w-full'}
                    isLoading={areCitiesLoading}
                    name={'place'}
                    onChange={(val) => {
                      setSelectedCity(val as string);
                      loadCashbackCustomersAddresses(values.zipCode, val as string);
                    }}
                    onInputChange={(val) => {
                      loadCities(values.zipCode, val as string);
                    }}
                    options={citiesFromApi.map((city) => ({ value: city, label: city }))}
                    placeholder={'Miasto'}
                    value={values.place}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {selectedCity && (
        <Formik
          enableReinitialize={true}
          initialValues={{
            city: selectedCity,
            street: '',
            customerId: 0,
            policyId: 0,
          }}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            const result = cashbackCustomersAddresses.find(
              (obj) =>
                (!values.street || obj.street == values.street) &&
                obj.id == values.customerId &&
                obj.policyId == values.policyId,
            );

            if (!result) {
              setSubmitting(false);
              toast.error('Wystąpił błąd');
              return;
            }

            const requestBody: ICashbackSopPolicyGroupDto = {
              policyId: result.policyId,
              customerId: result.id,
              policyNumber: result.insuranceCompanyPolicyNumber,
            };

            agent.Cashback.assignCashbackToUser(requestBody)
              .then((resp) => {
                mutate(getUrls.Cashback.getCashbackBenefitInfo);
                props.onSave?.();
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validationSchema={citySearchValidationSchema}>
          {({ handleSubmit, values, isSubmitting, setFieldValue, errors }) => (
            <Form autoComplete='off' onSubmit={handleSubmit}>
              <div className={'tw-mt-4 tw-flex tw-flex-col tw-justify-center tw-px-2'}>
                <div
                  className={'tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-font-normal'}>
                  <div className={'tw-w-full'}>
                    <MySelectInput
                      bodyPortal={true}
                      className={'tw-w-full'}
                      isClearable={true}
                      isLoading={areCashbackCustomersAddressesLoading}
                      name={'street'}
                      onChange={() => setFieldValue('customerId', 0)}
                      options={[...new Set(cashbackCustomersAddresses.map((obj) => obj.street))].map((street) => ({
                        value: street,
                        label: street,
                      }))}
                      placeholder={'Ulica'}
                      value={values.street}
                    />
                  </div>
                  <div className={'tw-w-full'}>
                    <MySelectInput
                      bodyPortal={true}
                      className={'tw-w-full'}
                      isLoading={areCashbackCustomersAddressesLoading}
                      name={'customerId'}
                      onChange={(val) => {
                        const availablePolicies = getAvailablePoliciesOptions(val as number);
                        if (availablePolicies.length === 1) {
                          setFieldValue('policyId', availablePolicies[0].value);
                        }
                        setSelectedCustomerId(val as number);
                      }}
                      options={cashbackCustomersAddresses
                        .filter((obj) => !values.street || obj.street == values.street)
                        .filter(
                          (obj, index, self) =>
                            index === self.findIndex((t) => t.id === obj.id && t.fullName === obj.fullName),
                        )
                        .map((obj) => ({ value: obj.id, label: obj.fullName }))}
                      placeholder={'Placówka'}
                      value={values.customerId}
                    />
                  </div>

                  {!!values.customerId && (
                    <>
                      {getAvailablePoliciesOptions(values.customerId).length > 1 ? (
                        <div className={'tw-w-full'}>
                          <MySelectInput
                            bodyPortal={true}
                            className={'tw-w-full'}
                            isLoading={areCashbackCustomersAddressesLoading}
                            name={'policyId'}
                            options={getAvailablePoliciesOptions(values.customerId)}
                            placeholder={'Numer polisy'}
                            value={values.policyId}
                          />
                        </div>
                      ) : (
                        <div className={'tw-flex tw-w-full tw-gap-2 tw-font-semibold tw-text-nau-gray-dark'}>
                          <div className={''}>Numer polisy:</div>
                          {
                            getAvailablePoliciesOptions(values.customerId).find((obj) => obj.value == values.policyId)
                              ?.label
                          }
                        </div>
                      )}
                    </>
                  )}
                </div>
                {!!values.policyId && (
                  <MyButton
                    className={'tw-mt-8 tw-w-full'}
                    isLoading={isSubmitting}
                    type='submit'
                    variant={'greenDark'}>
                    Sprawdź status
                  </MyButton>
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default CashbackCustomerSearch;
