import { makeAutoObservable, runInAction } from 'mobx';
import {
  CircleKCardModel,
  CircleKContractFormValues,
  CircleKMyExpensesModel,
  CircleKSettlementModel,
  CircleKTransactionsModel,
  CircleStation,
} from '../../types/circleK';
import agent from '../../api/agent';
import { CircleKContractStatus } from '../../enums/circleKContractStatus.enums';

export default class CircleKStore {
  circleKCard: CircleKCardModel[] = new Array<CircleKCardModel>();
  circleKHistoryTransaction: CircleKTransactionsModel[] = new Array<CircleKTransactionsModel>();
  circleKSettlements: CircleKSettlementModel[] = new Array<CircleKSettlementModel>();
  circleKMyExpenses: CircleKMyExpensesModel | null = null;
  newContractExists = false;
  oldContractVersionExists = false;
  hasAnyNauProfitCard = false;
  stationCircleK: CircleStation[] = new Array<CircleStation>();
  circleKBalance = '';

  constructor() {
    makeAutoObservable(this);
  }
  get hasNewContract() {
    return this.newContractExists;
  }
  get hasOldContractVersion() {
    return this.oldContractVersionExists;
  }
  checkExistsNewContract = () => {
    let isNew = false;
    this.circleKCard.forEach((card) => {
      if (card.contractStatus == CircleKContractStatus.New) {
        isNew = true;
      }
    });
    //MZ: Jeśli klient nie posiada kart prywatnych to nie pozwalamy wnioskować o kolejną kartę
    //if(this.circleKCard.filter(card => !card.isInstitutional).length <= 0 ){
    //  isNew = true;
    //}
    runInAction(() => {
      this.newContractExists = isNew;
    });
  };

  checkHasSignedContract = () => {
    let hasContract = false;
    this.circleKCard.forEach((card) => {
      if (card.contractStatus > CircleKContractStatus.New) {
        hasContract = true;
      }
    });
    return hasContract;
  };

  checkHasOldContractVersion = () => {
    let oldContractVersionExists = false;
    this.circleKCard.forEach((card) => {
      if (card.versionNumber < 3) {
        oldContractVersionExists = true;
      }
    });
    runInAction(() => {
      this.oldContractVersionExists = oldContractVersionExists;
    });
  };

  checkHasAnyNauProfitCard = () => {
    let hasAnyNauProfitCard = false;
    this.circleKCard.forEach((card) => {
      if (card.versionNumber >= 3) {
        hasAnyNauProfitCard = true;
      }
    });
    runInAction(() => {
      this.hasAnyNauProfitCard = hasAnyNauProfitCard;
    });
  };

  createCircleKCard = async (request: CircleKContractFormValues) => {
    const contractIdx = await agent.CircleKCard.createCircleKCard(request);
    await this.getCircleKCardsByUserId();
    return contractIdx;
  };

  createCircleKCardOperatorChange = async (request: CircleKContractFormValues) => {
    const contractIdx = await agent.CircleKCard.createCircleKCardOperatorChange(request);
    await this.getCircleKCardsByUserId();
    return contractIdx;
  };

  getCircleKCardsByUserId = async () => {
    const response = await agent.CircleKCard.getCircleKCardsByUserId();
    runInAction(() => {
      this.circleKCard = response;
      this.checkExistsNewContract();
      this.checkHasOldContractVersion();
      this.checkHasAnyNauProfitCard();
    });
  };
  getCircleKBalanceByUserId = async () => {
    const response = await agent.CircleKCard.getCircleKBalanceByUserId();
    runInAction(() => {
      // @ts-ignore
      this.circleKBalance = response;
    });
  };

  getCircleKMyExpensesByUserId = async () => {
    const response = await agent.CircleKCard.getCircleKMyExpensesByUserId();
    runInAction(() => {
      // @ts-ignore
      this.circleKMyExpenses = response;
    });
  };

  circleKBlockCardRequest = async (cardId: number, message: string) => {
    await agent.CircleKCard.circleKBlockCardRequest(cardId, message);
  };

  circleKUnblockCardRequest = async (cardId: number, message: string) => {
    await agent.CircleKCard.circleKUnblockCardRequest(cardId, message);
  };

  circleKCancellationCardRequest = async (cardId: number, message: string) => {
    await agent.CircleKCard.circleKCancellationCardRequest(cardId, message);
  };

  getCircleKAllTransactionByUserId = async () => {
    const response = await agent.CircleKCard.getCircleKAllTransactionByUserId();
    runInAction(() => {
      // @ts-ignore
      this.circleKHistoryTransaction = response;
    });
  };

  getCircleKAllTransactionByCardId = async (cardId: number) => {
    const response = await agent.CircleKCard.getCircleKAllTransactionByCardId(cardId);
    runInAction(() => {
      // @ts-ignore
      this.circleKHistoryTransaction = response;
    });
  };
  getUserCircleKContractById = async (contractId?: number) => {
    try {
      return agent.CircleKCard.getUserCircleKContractById(contractId);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  getUserCircleKContractFormOperatorChange = async () => {
    try {
      return agent.CircleKCard.getUserCircleKContractFormOperatorChange();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  editUserCircleKContract = async (request: CircleKContractFormValues) => {
    try {
      const contract = await agent.CircleKCard.editUserCircleKContract(request);
      await this.getCircleKCardsByUserId();
      return contract;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  generateTokenToCircleKContract = async (contractId: number) => {
    try {
      return agent.CircleKCard.generateTokenToCircleKContract(contractId);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserCircleKContractVersion = async (contractId: number) => {
    try {
      return agent.CircleKCard.getUserCircleKContractVersion(contractId);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  signCircleKContract = async (contractId: number, token: string) => {
    try {
      const response = await agent.CircleKCard.signCircleKContract(contractId, token);
      await this.getCircleKCardsByUserId();
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  getCircleStationByPostCode = async (postcode: string) => {
    try {
      const response = await agent.CircleKCard.getCircleStationByPostCode(postcode);

      runInAction(() => {
        // @ts-ignore
        this.stationCircleK = response.data;
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  getCircleStationByCity = async (city: string) => {
    try {
      const response = await agent.CircleKCard.getCircleStationByCity(city);
      runInAction(() => {
        // @ts-ignore
        this.stationCircleK = response.data;
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  getCircleKSettlements = async () => {
    await agent.CircleKSettlements.getUserSettlements().then((response) => {
      runInAction(() => {
        // @ts-ignore
        this.circleKSettlements = response;
      });
    });
  };
  getInstitutionByPostCodeAndPlace = async (postCode: string, place: string) => {
    try {
      const response = await agent.CircleKCard.getInstitutionByPostCodeAndPlace(postCode, place);
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
}
