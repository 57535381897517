export const userIcon = {
  width: '35px',
  height: '35px',
  borderRadius: '35px',
  background: '#626D80',
  padding: 0,
  border: 'none',
} as React.CSSProperties;

export const notificationIcon = {
  width: '35px',
  height: '35px',
  borderRadius: '35px',
  background: '#626D80',
  padding: 0,
  border: 'none',
  marginRight: '10px',
  position: 'relative',
} as React.CSSProperties;

export const notificationNewIcon = {
  width: '35px',
  height: '35px',
  borderRadius: '35px',
  background: '#d20303',
  padding: 0,
  border: 'none',
  marginRight: '10px',
  position: 'relative',
} as React.CSSProperties;

export const deleteIcon = {
  background: 'transparent',
  padding: '2px',
  border: 'none',
  borderRadius: '35px',
  color: '#0bc268',
} as React.CSSProperties;

export const deleteReadIcon = {
  width: '28px',
  background: 'black',
  padding: '2px',
  border: 'none',
  borderRadius: '35px',
} as React.CSSProperties;

export const hamburgerIcon = {
  width: '35px',
  height: '35px',
  background: 'none',
  padding: 0,
  border: 'none',
} as React.CSSProperties;

export const logoImage = {
  width: 'auto',
  height: '35px',
  background: 'none',
  padding: 0,
  border: 'none',
} as React.CSSProperties;

export const headerContainer = {
  width: '100vw',
  height: '75px',
  background: '#fff',
  padding: 0,
  display: 'flex',
  justifyContent: 'space-between',
  border: 'none',
  position: 'fixed',
  top: 0,
  right: 0,
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '20px',
  paddingBottom: '20px',
  zIndex: 50,
} as React.CSSProperties;

export const webContainer = {
  paddingLeft: '20px',
  paddingRight: '20px',
} as React.CSSProperties;

export const logoElectroMachinist = {
  marginTop: '-35px',
  marginBottom: '-30px',
  height: '50px',
} as React.CSSProperties;
export const logoJusticeSystem = {
  marginTop: '-35px',
  marginBottom: '-30px',
  height: '60px',
} as React.CSSProperties;

export const logoFirefighter = {
  marginTop: '-35px',
  marginBottom: '-30px',
  height: '80px',
} as React.CSSProperties;
export const logoMiners = {
  marginTop: '-35px',
  marginBottom: '-30px',
  height: '60px',
} as React.CSSProperties;
