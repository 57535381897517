export const bodyWithPadding = { padding: '10px 20px' };

export const tabsBox = {
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  paddingTop: '20px',
  background: 'linear-gradient(to right,#00635c, #0bc268)',
} as React.CSSProperties;

export const container = {
  borderRadius: '10px',
  background: '#fff',
} as React.CSSProperties;

export const textStyle = {
  fontSize: '16px',
  color: '#00635C',
  marginBottom: '10px',
  lineHeight: '1.5',
} as React.CSSProperties;

export const headingTextStyle = {
  fontSize: '20px',
  color: '#00635C',
  fontWeight: 600,
  marginBottom: '10px',
  lineHeight: '1.2',
} as React.CSSProperties;

export const littleTextStyle = {
  fontSize: '12px',
  fontStyle: 'italic',
  color: '#00635C',
  marginBottom: '10px',
  lineHeight: '1.5',
} as React.CSSProperties;
