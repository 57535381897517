import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import { SportCardCompany } from '../../../enums/sportCardCompany.enums';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import { openLink } from '../../../utils/linkUtils';
import agent from '../../../api/agent';
import { InstitutionSearchByAddressForm } from '../../../components/Institution/InstitutionSearchByAddressForm';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { InstitutionNotAllowedToGetSportCardsInfo } from '../InstitutionNotAllowedToGetSportCardsInfo';
import { useStore } from '../../../store/store';

interface SportCardRequestFormValues {
  pesel: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  company: number;
  institutionSopId?: number;
  institutionName: string;
  institutionRegon: string;
}

interface ISportCardApplicationFormProps {
  sportCardCompany: SportCardCompany;
  onSave?: () => void;
}
export const SportCardRequestForm = (props: ISportCardApplicationFormProps) => {
  const { data: userProfile, isLoading: isLoadingUserProfile } = useSwrAgent().Users.GetUserProfile();
  const { modalStore } = useStore();
  const [showForm, setShowForm] = React.useState<boolean>(true);
  const [copied, setCopied] = useState(false);
  const textToCopy = 'NauczycielskaagencjaubezpieczeniowaMS2024';

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      toast.success('skopiowano do schowka');
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const validationSchema = () =>
    Yup.object().shape({
      pesel: Yup.string().required('Pole jest wymagane'),
      firstName: Yup.string().required('Pole jest wymagane'),
      lastName: Yup.string().required('Pole jest wymagane'),
      email: Yup.string().email('Nieprawidłowy email').required('Pole jest wymagane'),
      phoneNumber: Yup.string()
        .matches(/^\d{9}$/, 'Nieprawidłowy numer telefonu')
        .required('Pole jest wymagane'),
      company: Yup.number().required('Pole jest wymagane'),
    });

  return (
    <div>
      {isLoadingUserProfile && <LoadingComponent />}

      {showForm ? (
        <Formik
          enableReinitialize={true}
          initialValues={{
            pesel: userProfile?.pesel ?? '',
            firstName: userProfile?.firstName ?? '',
            lastName: userProfile?.lastName ?? '',
            email: userProfile?.email ?? '',
            phoneNumber: '',
            company: props.sportCardCompany,
            institutionSopId: undefined,
            city: '',
            zipCode: '',
            street: '',
            institutionRegon: '',
            institutionName: '',
          }}
          onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<SportCardRequestFormValues>) => {
            try {
              if (!values.institutionSopId) {
                toast.error('Wybierz placówkę');
                return;
              }

              await agent.SportCard.createSportCardRequest(values);

              if (props.sportCardCompany === SportCardCompany.MultiSport) {
                const isCardAvailable = await agent.SportCard.checkSportCardIsAvailableInInstitution(
                  props.sportCardCompany,
                  values.institutionSopId,
                );

                if (!isCardAvailable) {
                  history.push(PathRoute.SPORT_CARD);
                  modalStore.openModal(<InstitutionNotAllowedToGetSportCardsInfo />, 'lg', () => <></>);
                  return;
                }
              }

              setShowForm(false);
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema()}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className='tw-w-full'>
              <div className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
                Podaj nazwę placówki, w celu weryfikacji usługi
              </div>
              <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'>
                <InstitutionSearchByAddressForm
                  cityFormikName={'city'}
                  institutionFullNameFormikName={'institutionName'}
                  institutionSopIdFormikName={'institutionSopId'}
                  postCodeFormikName={'zipCode'}
                  regonFormikName={'institutionRegon'}
                  streetFormikName={'street'}
                />
              </div>
              <div
                className='tw-mb-4 tw-mt-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'
                id={'sport-card-application-form-top'}>
                Zgłoszenie Karty Sportowej
              </div>
              <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'>
                <MyTextInput label='Pesel' name='pesel' />
                <MyTextInput label='Imię' name='firstName' />
                <MyTextInput label='Nazwisko' name='lastName' />
                <MyTextInput label='Email' name='email' />
                <MyTextInput label='Numer telefonu' name='phoneNumber' />
              </div>

              <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                <MyButton
                  className='tw-w-full'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                  variant={'greenDark'}>
                  Wyślij
                </MyButton>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div>
          {props.sportCardCompany === SportCardCompany.Medicover && (
            <div className='tw-my-6 tw-text-center tw-text-lg tw-font-semibold tw-text-nau-green-dark'>
              Aby dokończyć rejestrację, prosimy przejść pod poniższy link:
              <div
                className='tw-text-md tw-my-4 tw-cursor-pointer tw-break-words tw-font-normal tw-text-blue-500'
                onClick={() => openLink('https://medicoversport.pl/pakiety/nauczycielskaagencjaubezpieczeniowa')}>
                https://medicoversport.pl/pakiety/nauczycielskaagencjaubezpieczeniowa
              </div>
              <div className={'tw-font-normal'}>
                <p>Na stronie rejestracji należy wpisać kod:</p>{' '}
                <div
                  className={'tw-max-w-full'}
                  onClick={handleCopy}
                  style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
                  <span className={'tw-break-all tw-text-2xl tw-font-semibold'}>{textToCopy}</span>
                  <span style={{ marginLeft: '8px' }}>
                    {copied ? (
                      <i className='fas fa-check' style={{ color: 'green' }}></i>
                    ) : (
                      <i className='fas fa-clipboard'></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}
          {props.sportCardCompany === SportCardCompany.MultiSport && (
            <div className='tw-my-6 tw-text-center tw-text-lg tw-font-semibold tw-text-nau-green-dark'>
              Aby dokończyć rejestrację, prosimy przejść pod poniższy link:
              <div
                className='tw-text-md tw-my-4 tw-cursor-pointer tw-break-words tw-font-normal tw-text-blue-500'
                onClick={() => openLink('https://www.multisport.pl/')}>
                https://www.multisport.pl/
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
