import { useField } from 'formik';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import InputMask from 'react-input-mask';

import { MyTextInputProps } from './types';
import { twMerge } from '../../../index';
import { useState } from 'react';

export default function MyTextInput({
  name,
  label,
  type = 'text',
  mask,
  hidden,
  className,
  labelClassName,
  wrapperClassName,
  onBlur,
  onChange,
  suggestions = [],
  onSuggestionClick,
  ...restProps
}: MyTextInputProps) {
  const [field, meta, helpers] = useField(name);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const error = meta.touched && meta.error;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    if (onChange) {
      onChange(event);
    }
  };

  const handleSuggestionClick = (event: any, suggestion: string) => {
    helpers.setValue(suggestion);
    setIsFocused(false);
    field.onBlur(event);
    if (onBlur) {
      onBlur(event);
    }
    if (onSuggestionClick) {
      onSuggestionClick(suggestion);
    }
  };

  return (
    <div className={twMerge(hidden && 'tw-hidden', wrapperClassName)}>
      {label && (
        <label
          className={twMerge(
            'tw-mb-1 tw-block tw-text-left tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900',
            labelClassName,
          )}
          htmlFor={name}>
          {label}
        </label>
      )}
      <div className='tw-relative  tw-rounded-md tw-shadow-sm'>
        {mask ? (
          <InputMask
            mask={mask}
            {...field}
            {...restProps}
            className={twMerge(
              'tw-block tw-w-full tw-rounded-xl tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-3 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400 sm:tw-text-sm sm:tw-leading-6 ',
              error && 'tw-placeholder:text-red-300 tw-pr-10 tw-text-red-900 tw-ring-red-300 focus:tw-ring-red-500',
              restProps.disabled && 'tw-bg-gray-100 tw-opacity-80',
              className,
            )}
            onBlur={(event) => {
              field.onBlur(event);
              if (onBlur) {
                onBlur(event);
              }
              setIsFocused(false);
            }}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
          />
        ) : (
          <input
            {...field}
            {...restProps}
            className={twMerge(
              'tw-block tw-w-full tw-rounded-xl tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-3 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400 sm:tw-text-sm sm:tw-leading-6 ',
              error && 'tw-placeholder:text-red-300 tw-pr-10 tw-text-red-900 tw-ring-red-300 focus:tw-ring-red-500',
              restProps.disabled && 'tw-bg-gray-100 tw-opacity-80',
              className,
            )}
            onBlur={(event) => {
              field.onBlur(event);
              if (onBlur) {
                onBlur(event);
              }
              setIsFocused(false);
            }}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            type={type}
          />
        )}
        {error && (
          <div className='tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3'>
            <ExclamationCircleIcon aria-hidden='true' className='tw-h-5 tw-w-5 tw-text-red-500' />
          </div>
        )}
      </div>
      <div className={'tw-relative tw-w-full'}>
        {isFocused && suggestions.length > 0 && (
          <ul className='tw-absolute tw-left-0 tw-right-0 tw-top-[5px] tw-z-50 tw-max-h-40 tw-overflow-auto tw-rounded-b-md tw-bg-white tw-shadow-md'>
            {suggestions.map((suggestion, index) => (
              <li
                className='tw-cursor-pointer tw-px-3 tw-py-2 hover:tw-bg-gray-100'
                key={index}
                onMouseDown={(event) => handleSuggestionClick(event, suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div
        className={`tw-transition-grid-rows tw-grid tw-overflow-hidden tw-duration-200  ${
          error ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]'
        }`}>
        <p className='tw-min-h-0 tw-text-left tw-text-sm tw-text-red-600' id='email-error'>
          {error} &nbsp; {/*&nbsp; is necessary for animation */}
        </p>
      </div>
    </div>
  );
}
