import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useStore } from '../../store/store';
import { FuelCardCompany } from '../../enums/fuelCardCompany.enums';
import SearchStationByPostCode from './SearchStationByPostCode';
import SearchStationByCity from './SearchStationByCity';
import MyDisclosure from '../Disclosure/MyDisclosure';
import { cardCompanyArray } from '../../helpers/ArraySelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from '../../index';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

interface ISearchStationDisclosureProps {
  fuelCardCompany: FuelCardCompany;
}

export default observer((props: ISearchStationDisclosureProps) => {
  const { circleKStore } = useStore();

  return (
    <>
      <MyDisclosure
        header={(open) => (
          <div className={'tw-flex tw-items-center tw-p-2'}>
            <div className={'tw-grow tw-text-left tw-text-nau-sea-green'}>
              Znajdź stację {cardCompanyArray.find((obj) => obj.value == props.fuelCardCompany)?.label}{' '}
            </div>
            <div className={'tw-flex tw-items-center'}>
              <FontAwesomeIcon
                className={twMerge('tw-text-nau-sea-green tw-transition-all', open && 'tw-rotate-90')}
                icon={faCaretRight}
                size='2x'
              />
            </div>
          </div>
        )}
        renderBody={(open) => (
          <div className={'tw-p-2'}>
            <SearchStationByPostCode fuelCardCompany={props.fuelCardCompany} />
            <SearchStationByCity fuelCardCompany={props.fuelCardCompany} />
          </div>
        )}
      />
    </>
  );
});
