import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function PlusButtonIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge('tw-h-5 tw-w-5', props.className)} height='47' viewBox='0 0 47 47' width='47'>
      <g data-name='Group 1006' id='Group_1006' transform='translate(-171 -382)'>
        <rect
          data-name='Rectangle 434'
          fill='#dcdcdc'
          height='47'
          id='Rectangle_434'
          rx='10'
          transform='translate(171 382)'
          width='47'
        />
        <path
          d='M-1172.657-2066.5h28.906'
          data-name='Path 3965'
          fill='none'
          id='Path_3965'
          stroke='#0aa9b0'
          strokeWidth='3'
          transform='translate(1352.704 2472)'
        />
        <path
          d='M-1172.657-2066.5h28.906'
          data-name='Path 3966'
          fill='none'
          id='Path_3966'
          stroke='#0aa9b0'
          strokeWidth='3'
          transform='translate(-1872 1563.704) rotate(90)'
        />
      </g>
    </svg>
  );
}
