import * as React from 'react';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../index';
import * as styles from './styles';
import { PathRoute } from '../../constants/pathRoute/Route';

interface IDocumentsVerificationProps {
  header: React.ReactNode;
  getBottomText: () => JSX.Element | string;
}

export default function DocumentsVerification(props: IDocumentsVerificationProps) {
  const handleConfirm = async () => {
    history.push(PathRoute.PUSTY);
  };

  return (
    <div>
      <div className='col-md-6 offset-md-3' style={styles.container}>
        {props.header}
        <div style={styles.flexCenter as React.CSSProperties}>
          <div style={styles.flexColumnContainer as React.CSSProperties}>
            <p style={styles.selfStyles as React.CSSProperties}>
              Wniosek został wysłany. <br />
              Trwa weryfikacja dokumentów.
            </p>
            <Image src='assets/clock.svg' style={styles.heightTop as React.CSSProperties} />
            <p style={styles.selfStyles as React.CSSProperties}>{props.getBottomText()}</p>
            <Button onClick={handleConfirm} style={styles.top as React.CSSProperties}>
              Strona główna
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
