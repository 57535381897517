import React from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import CinemaVoucherHeader from '../../components/CinemaVoucher/CinemaVoucherHeader/CinemaVoucherHeader';
import { CinemaVoucherPromoLinkExpired } from './CinemaVoucherPromoLinkExpired';

export const CinemaVoucherPromoLinkExpiredLayout = () => {
  return (
    <>
      <CustomerDashboard>
        <CinemaVoucherPromoLinkExpired />
      </CustomerDashboard>
    </>
  );
};
