import React from 'react';
import { observer } from 'mobx-react-lite';
import MyAccordion from '../../Accordion/MyAccordion';
import FileHelper from '../../../helpers/FileHelper';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ButtonOpenLinkNewTab from '../../Buttons/ButtonOpenLinkNewTab/ButtonOpenLinkNewTab';

export default observer(() => {
  return (
    <div className={'tw-flex tw-flex-col tw-gap-3'}>
      <MyAccordion className={'tw-w-full'} title={'Jak korzystać?'}>
        <div>
          <p className={'tw-text-nau-green-dark tw-leading-normal'}>
            1. Kup kupon do wybranego kina w aplikacji Klub NAU
            <br />
            2. Numer kuponu otrzymasz w aplikacji Klub NAU oraz w wiadomości e-mail
            <br />
            3. Podaj numer kuponu:
            <p className={'tw-pl-[20px]'}>
              • w kasie kina
              <br />• w aplikacji mobilnej
              <br />• na stronie internetowej
            </p>
            4. Gotowe – ciesz się seansem!
          </p>
        </div>
      </MyAccordion>
      <MyAccordion className={'tw-w-full'} title={'Sprawdź repertuar'}>
        <div className={'tw-flex tw-flex-col tw-gap-1'}>
          <ButtonOpenLinkNewTab buttonText={'Helios'} link={PathRoute.HELIOS_PROGRAMME} />
          <ButtonOpenLinkNewTab buttonText={'Multikino'} link={PathRoute.MULTIKINO_PROGRAMME} />
          <ButtonOpenLinkNewTab buttonText={'Cinema City'} link={PathRoute.CINEMA_CITY_PROGRAMME} />
        </div>
      </MyAccordion>
      <div
        className={'tw-relative tw-cursor-pointer'}
        onClick={() => {
          FileHelper.downloadFile(PathRoute.CINEMA_VOUCHERS_TERMS_PDF, 'Regulamin_Bilety.pdf');
        }}>
        <div className={'tw-absolute tw-inset-0 tw-z-50'}></div>
        <MyAccordion
          className={'tw-w-full'}
          title={() => {
            return (
              <div className={'tw-text-nau-green-light tw-flex tw-justify-between tw-items-center tw-w-full'}>
                <div>Regulamin "Idź do kina z NAU"</div>
                <FontAwesomeIcon color='#0BC268' icon={faCaretRight} size='2x' />
              </div>
            );
          }}></MyAccordion>
      </div>
    </div>
  );
});
