export enum BirthdayCodesCampaign {
  CoffeeVoucher1 = 'bk19',
  CoffeeVoucher2 = 'bk20',
  CoffeeVoucher3 = 'bk21',
}

export const BirthdayCodesCoffeeVoucherCampaign = [
  BirthdayCodesCampaign.CoffeeVoucher1,
  BirthdayCodesCampaign.CoffeeVoucher2,
  BirthdayCodesCampaign.CoffeeVoucher3,
];
