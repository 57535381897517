import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import { MySelectInputOptions } from '../../FormControls/MySelectInput/types';
import AddLoanPaymentDate from './AddLoanPaymentDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DeleteLoanPaymentDate from './DeleteLoanPaymentDate';

export default observer((props: { institutionId: number }) => {
  const { modalStore, loansStore } = useStore();
  const [, setLoading] = useState<boolean>(false);
  const [loanPaymentDates, setloanPaymentDates] = useState<MySelectInputOptions[]>([]);

  useEffect(() => {
    loansStore
      .getLoanPaymentDates(props.institutionId)
      .then((data) => {
        setloanPaymentDates(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(`Error in promises ${error}`);
        setLoading(false);
        toast.error('Wystąpił błąd przy pobieraniu danych');
      });
  }, [loansStore]);
  return (
    <div>
      {loanPaymentDates && loanPaymentDates.length > 0 ? (
        <ul
          className='list-group'
          style={{ fontSize: '14px', width: '100%', marginBottom: '15px' } as React.CSSProperties}>
          {loanPaymentDates.map((loanPaymentDate) => (
            <>
              <li
                className='list-group-item row d-flex align-items-center'
                key={loanPaymentDate.value.toString()}
                style={{ fontWeight: 400, color: '#464646' }}>
                <div className='d-flex align-items-center justify-content-between w-100'>
                  <div>{loanPaymentDate.label} dzień każdego miesiąca</div>
                  <div>
                    <Button
                      className='btn-mini btn-danger'
                      onClick={() =>
                        modalStore.openModal(
                          <DeleteLoanPaymentDate
                            institutionId={props.institutionId}
                            loanPaymentDateId={Number(loanPaymentDate.value)}
                          />,
                          'lg',
                          'Usuń dzień spłaty',
                        )
                      }
                      style={{ fontSize: '13px', padding: '5px 15px' } as React.CSSProperties}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </div>
              </li>
            </>
          ))}
        </ul>
      ) : (
        <div
          style={
            {
              fontSize: '14px',
              width: '100%',
              marginBottom: '15px',
              display: 'flex',
              justifyContent: 'center',
            } as React.CSSProperties
          }>
          Nie odnaleziono żadnych dat wypłat wynagrodzenia
        </div>
      )}
      <div className='col-sm-12 col-lg-5 mb-4 mb-lg-0'>
        <Button
          className='btn-mini col-12'
          onClick={() =>
            modalStore.openModal(
              <AddLoanPaymentDate institutionId={props.institutionId} />,
              'lg',
              'Dodaj dzień wypłaty wynagrodzenia',
            )
          }
          style={{ fontSize: '13px', padding: '5px 15px' } as React.CSSProperties}>
          Dodaj datę wypłaty wynagrodzenia
        </Button>
      </div>
    </div>
  );
});
