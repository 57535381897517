import useSWR from 'swr';
import { DiscountCodeContractStatus, DiscountCodeM } from '../types/discountCode';
import useSwrArticlesAxios from './useSwrArticlesAxios';
import getUrls from './getUrls';
import { UserPayment } from '../types/payments';
import { UserMarketingData, UserProfileNewModel } from '../types/user';
import { ICashbackBalanceInfo, ICashbackBenefitInfo } from '../types/cashback';
import { CinemaVoucherM } from '../types/cinemaVoucher';
import { CinemaVoucherOrderPromo } from '../enums/cinemaVoucherOrderPromo.enum';
import { ISportCardDto } from '../types/sportCard';

export function useSwrAgent() {
  const Article = {
    getArticle: useSwrArticlesAxios(),
  };

  const BirthdayCodes = {
    GetUserBirthdayCodeCampaigns: () => useSWR<DiscountCodeM[]>(getUrls.BirthdayCodes.getUserBirthdayCodes),
  };

  const Cashback = {
    GetCashbackBenefitInfo: () => useSWR<ICashbackBenefitInfo>(getUrls.Cashback.getCashbackBenefitInfo),
    GetCashbackBalance: (shouldFetch = true) => {
      return useSWR<ICashbackBalanceInfo>(shouldFetch ? getUrls.Cashback.getCashbackBalance : null);
    },
  };

  const DiscountCodes = {
    GetUserDiscountCodes: () => useSWR<DiscountCodeM[]>(getUrls.DiscountCodes.getUserDiscountCodes),
    GetUserDiscountCodeContractStatus: () => useSWR<number>(getUrls.DiscountCodes.getUserDiscountCodeContractStatus),
    GetAllUserDiscountCodeContracts: () =>
      useSWR<DiscountCodeContractStatus[]>(getUrls.DiscountCodes.getAllUserDiscountCodeContracts),
    CheckHasNotSettledSettlements: () => useSWR<boolean>(getUrls.DiscountCodes.checkHasNotSettledSettlements),
  };

  const Payments = {
    GetUserPayments: () => useSWR<UserPayment[]>(getUrls.Payments.getUserPayments),
  };

  const Users = {
    GetUserMarketingData: () => useSWR<UserMarketingData>(getUrls.User.getUserMarketingData),
    HasUserPersonalData: () => useSWR<boolean>(getUrls.User.hasUserPersonalData),
    IsUserAssignedToInstitutionAsDirector: () => useSWR<boolean>(getUrls.User.isUserAssignedToInstitutionAsDirector),
    GetUserProfile: () => useSWR<UserProfileNewModel>(getUrls.User.getUserProfileNew),
  };

  const Wallet = {
    GetWalletContractStatus: () => useSWR<number>(getUrls.Wallet.getWalletContractStatus),
  };
  const CinemaVouchers = {
    GetUserCinemaVouchers: () => useSWR<CinemaVoucherM[]>(getUrls.CinemaVouchers.getUserCinemaVouchers),
    CheckUserHasCinemaVoucherOrderWithPromo: (promoId: CinemaVoucherOrderPromo) =>
      useSWR<boolean>(`/CinemaVoucher/CheckUserHasCinemaVoucherOrderWithPromo?promoId=${promoId}`),
  };

  const SportCards = {
    GetSportCardsForUser: (sportCardCompany?: number) =>
      useSWR<ISportCardDto[]>(
        sportCardCompany != null
          ? `${getUrls.SportCards.getSportCardsForUser}?sportCardCompany=${sportCardCompany}`
          : getUrls.SportCards.getSportCardsForUser,
      ),
  };

  return {
    Article,
    BirthdayCodes,
    Cashback,
    DiscountCodes,
    CinemaVouchers,
    Payments,
    Users,
    Wallet,
    SportCards,
  };
}
