import * as React from 'react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import './style.css';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import CashOut from '../CashOut/CashOut';
import { LoanDataCashmir } from '../../../types/loans';

export default observer(() => {
  const { loansStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [currentItems, setCurrentItems] = useState<LoanDataCashmir[]>();
  const [itemsPerPage] = useState(8);
  const [pageCount, setPageCount] = useState(0);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % loansStore.userLoanApplications.length;
    const endOffset = newOffset + itemsPerPage;
    setCurrentItems(loansStore.userLoanApplications.slice(newOffset, endOffset));
  };

  const successedDownloadData = () => {
    setLoading(false);
    setPageCount(Math.ceil(loansStore.userLoanApplications.length / itemsPerPage));
    handlePageClick({ selected: 0 });
  };

  useEffect(() => {
    if (loansStore.availableBenefitId != null) {
      loansStore
        .getUserLoansApplications(loansStore.availableBenefitId)
        .then(() => {
          successedDownloadData();
        })
        .catch((error) => {
          console.log(`Error in promises ${error}`);
          setLoading(false);
          toast.error('Wystąpił błąd przy pobieraniu historii pożyczek');
        });
    }
  }, []);

  return (
    <div style={styles.mainContainer as React.CSSProperties}>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <div>
          {currentItems == undefined || currentItems.length == 0 ? (
            <h4>Nie masz żadnych historycznych wniosków</h4>
          ) : (
            <>
              <div style={{ marginBottom: '65px' }}>
                {currentItems &&
                  currentItems.map((item, i) => (
                    <div key={i} style={styles.containerMarginBottom as React.CSSProperties}>
                      <CashOut loan={item} />
                    </div>
                  ))}
              </div>

              <div style={styles.paginationStyle as React.CSSProperties}>
                <ReactPaginate
                  activeClassName='active'
                  breakClassName='page-item'
                  breakLabel='...'
                  breakLinkClassName='page-link'
                  containerClassName='pagination justify-content-center'
                  marginPagesDisplayed={1}
                  nextClassName='page-item'
                  nextLabel='>>>'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageClassName='page-item'
                  pageCount={pageCount}
                  pageLinkClassName='page-link'
                  pageRangeDisplayed={2}
                  previousClassName='page-item'
                  previousLabel='<<<'
                  previousLinkClassName='page-link'
                  renderOnZeroPageCount={undefined}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
});
