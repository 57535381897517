import * as React from 'react';

export const barContainer = {
  position: 'fixed',
  left: 0,
  width: '100vw',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#0bc268',
  zIndex: 50,
} as React.CSSProperties;

export const barLink = {
  color: 'white',
} as React.CSSProperties;
