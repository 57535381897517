import React, { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';

interface ICustomToastProps {
  onClose: () => void;
  duration?: number;
  type: 'used' | 'notUsed';
}

const CustomToast: React.FC<ICustomToastProps> = ({ type, onClose, duration = 3000 }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);

    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          onClose();
          return 100;
        }
        return Math.min(oldProgress + 1, 100);
      });
    }, duration / 100);

    return () => {
      clearInterval(interval);
    };
  }, [duration, onClose, type]);

  return (
    <div
      className={'tw-rounded-md tw-p-4 tw-pr-6'}
      style={{
        position: 'fixed',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#00a3b4',
        color: '#fff',
        zIndex: 9999,
      }}>
      <div className={'tw-flex tw-gap-4 tw-font-semibold'}>
        <div>
          <div className={'tw-rounded-full tw-bg-white tw-p-2 '}>
            <CheckIcon className={'tw-h-6 tw-w-6 tw-text-gray-600'} />
          </div>
        </div>

        <div className={'text-lg'}>
          <div className={'tw-whitespace-nowrap'}>
            Przeniesiono do zakładki <br />
            <span className={'tw-text-xl tw-mt-1'}>„{type === 'used' ? 'Wykorzystane' : 'Niewykorzystane'}”</span>
          </div>
        </div>
      </div>
      <button onClick={onClose} style={{ position: 'absolute', top: 10, right: 10, color: '#fff' }}>
        x
      </button>
      <div style={{ height: '3px', backgroundColor: '#fff', marginTop: '10px' }}>
        <div style={{ height: '100%', width: `${progress}%`, backgroundColor: '#999' }} />
      </div>
    </div>
  );
};

export default CustomToast;
