import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect } from 'react';
import * as styles from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import UserSportCardsView from '../UserSportCardsView';
import SportCardAllProvidersOfferList from '../SportCardAllProvidersOfferList';
import OrderSportCardLayout from '../OrderSportCardLayout';
import { SportCardCompany } from '../../../enums/sportCardCompany.enums';

export enum SportCardTabsE {
  MyCards = 1,
  AllProvidersList = 2,
  OrderingView = 3,
}

export default observer(() => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  // Get active tab and ordering state from URL
  const tabParam = params.get('tab') || SportCardTabsE.MyCards.toString();
  const companyParam = params.get('company') || '';

  const activeTab = parseInt(tabParam, 10) as SportCardTabsE;
  const activeCompany = parseInt(companyParam, 10) as SportCardCompany;

  const setTabInUrl = (tab: SportCardTabsE) => {
    params.set('tab', tab.toString());
    history.push(`?${params.toString()}`);
  };

  const renderTab = (tab: number) => {
    switch (tab) {
      case SportCardTabsE.MyCards:
        return <UserSportCardsView />;
      case SportCardTabsE.AllProvidersList:
        return <SportCardAllProvidersOfferList />;
      case SportCardTabsE.OrderingView:
        return <OrderSportCardLayout sportCardCompany={activeCompany} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    // Set default tab in URL if not present
    if (!params.has('tab')) {
      setTabInUrl(SportCardTabsE.MyCards);
    }
  }, []);

  return (
    <>
      <div>
        <div>
          <div className={'tw-bg-gradient-green-2 tw-flex tw-justify-between'}>
            <span
              onClick={() => setTabInUrl(SportCardTabsE.MyCards)}
              style={activeTab === SportCardTabsE.MyCards ? styles.activeTabLink : styles.tabLink}>
              Moje karty
            </span>

            <span
              onClick={() => setTabInUrl(SportCardTabsE.AllProvidersList)}
              style={
                activeTab === SportCardTabsE.OrderingView || activeTab === SportCardTabsE.AllProvidersList
                  ? styles.activeTabLink
                  : styles.tabLink
              }>
              Zamów
            </span>
          </div>
        </div>
        <div className='tw-rounded-b-lg tw-bg-white tw-px-4 tw-pb-6 tw-pt-4'>{renderTab(activeTab)}</div>
      </div>
    </>
  );
});
