import React from 'react';
import { ICustomerInflow } from '../../../types/cashback';

interface ICashbackInflowsTableProps {
  inflows: ICustomerInflow[];
  header: string;
}

export const CashbackInflowsTable = (props: ICashbackInflowsTableProps) => {
  return (
    <>
      <p className='tw-font-semibold'>{props.header}</p>
      <div className={'tw-overflow-x-auto'}>
        <table className='tw-mt-2 tw-min-w-full tw-rounded-lg   tw-bg-white tw-shadow-md'>
          <thead>
            <tr className={'tw-bg-gray-200'}>
              <th className='tw-border tw-border-gray-900 tw-px-2 tw-py-2 tw-text-left tw-text-gray-700'>Saldo</th>
              <th className='tw-border tw-border-gray-900 tw-px-2 tw-py-2 tw-text-left tw-text-gray-700 '>
                Źródło naliczenia
              </th>
              <th className='tw-border tw-border-gray-900 tw-px-2 tw-py-2 tw-text-left tw-text-gray-700'>Produkt</th>
              <th className='tw-border tw-border-gray-900 tw-px-2 tw-py-2 tw-text-left tw-text-gray-700'>
                Okres ważności
              </th>
            </tr>
          </thead>
          <tbody>
            {props.inflows.map((inflow, i) => (
              <tr className='tw-text-md  tw-font-normal ' key={i}>
                <td className='tw-whitespace-nowrap tw-border  tw-border-gray-900 tw-px-2 tw-py-2 tw-text-gray-700'>
                  {inflow.cashbackAmount?.toFixed(2) ?? '-'} PLN
                </td>
                <td className='tw-border tw-border-gray-900 tw-px-2 tw-py-2 tw-text-gray-700'>
                  {inflow.policyNumber ?? inflow.cashbackInflowSourceName ?? 'Promocja'}
                </td>
                <td className='tw-border tw-border-gray-900 tw-px-2 tw-py-2 tw-text-gray-700'>
                  {inflow.productName}{' '}
                  {inflow.productName === 'Życie' && <div className={'tw-text-xs'}>({inflow.policyCustomerName})</div>}
                </td>
                <td className='tw-border  tw-border-gray-900 tw-px-2 tw-py-2 tw-text-gray-600'>
                  <div className={'tw-whitespace-nowrap'}>od {inflow.cashbackStartDate}</div>
                  <div className={'tw-whitespace-nowrap'}>do {inflow.cashbackEndDate}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
