import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useStore } from '../../store/store';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { toast } from 'react-toastify';
import { mainContainer } from './styles';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { UserFileModel, UserLoansFileModel } from '../../types/user';
import FileHelper from '../../helpers/FileHelper';

export default observer(() => {
  const { fileStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [downloadingFile, setDownloadingFile] = useState<number>(0);
  const [downloadingLoansFile, setDownloadingLoansFile] = useState<string>('');
  const [files, setFiles] = useState<UserFileModel[]>([]);
  const [loansFiles, setLoansFiles] = useState<UserLoansFileModel[]>([]);
  const [discountCodeFiles, setDiscountCodeFiles] = useState<UserFileModel[]>([]);
  const [walletApplicationFiles, setWalletApplicationFiles] = useState<UserFileModel[]>([]);

  useEffect(() => {
    Promise.allSettled([
      fileStore.getMyCircleKFiles().then((files) => {
        setFiles(files);
      }),
      fileStore.getMyLoansFiles().then((files) => {
        setLoansFiles(files);
      }),
      fileStore.getMyDiscountCodeFiles().then((files) => {
        setDiscountCodeFiles(files);
      }),
      fileStore.getMyWalletApplicationFiles().then((files) => {
        setWalletApplicationFiles(files);
      }),
    ])
      .catch((error) => {
        console.log(`Error in promises ${error}`);
        toast.error('Wystąpił błąd przy pobieraniu listy plików');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDownload = (id: number, fileName: string, mime: string) => {
    setDownloadingFile(id);
    fileStore
      .getFile(id)
      .then((resp) => {
        FileHelper.downloadFileFromBlob(resp, fileName);
        setDownloadingFile(0);
      })
      .catch(() => {
        setDownloadingFile(0);
        toast.error('Nie można pobrać pliku');
      });
  };

  const handleDownloadLoansFile = (
    clientId: string,
    documentId: string,
    fileName: string,
    getFileFunction: (clientId: string, documentId: string) => Promise<Blob>,
  ) => {
    setDownloadingLoansFile(documentId);
    getFileFunction(clientId, documentId)
      .then((resp) => {
        const fileExtension = FileHelper.getExtensionFromBlob(resp);
        fileName += fileExtension;
        FileHelper.downloadFileFromBlob(resp, fileName);
        setDownloadingLoansFile('');
      })
      .catch(() => {
        setDownloadingLoansFile('');
        toast.error('Nie można pobrać pliku');
      });
  };
  return (
    <>
      <LoadingComponent content='Ładowanie...' visible={loading} />
      <CustomerDashboard>
        <div className='col-md-6 offset-md-3'>
          <div className='ui form error' style={mainContainer}>
            <h3 style={{ color: 'teal', textAlign: 'left' }}>Moje pliki</h3>
            {files.length + loansFiles.length + discountCodeFiles.length + walletApplicationFiles.length <= 0 && (
              <div>Nie masz plików do pobrania</div>
            )}
            {files.length > 0 && (
              <div>
                <h6>Karty paliwowe</h6>
                <ul style={{ width: '100%' }}>
                  {files.map(({ fileId, description, fileName, mime }) => (
                    <li className='list-group-item' key={fileId}>
                      <div
                        className='m-2'
                        onClick={() => (downloadingFile != fileId ? handleDownload(fileId, fileName, mime) : null)}
                        style={{ color: 'GrayText', cursor: 'pointer' }}>
                        {downloadingFile == fileId ? (
                          <span className='m-1'>
                            <Spinner
                              animation='grow'
                              aria-hidden='true'
                              as='span'
                              role='status'
                              size='sm'
                              variant='dark'
                            />
                          </span>
                        ) : null}
                        <FontAwesomeIcon icon={faFile} />
                        <span style={{ paddingLeft: '20px' }}>{description}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {loansFiles.length > 0 && (
              <div>
                <h6>Wypłata od ręki</h6>
                <ul style={{ width: '100%' }}>
                  {loansFiles.map((item) => (
                    <li className='list-group-item' key={item.id}>
                      <div
                        className='m-2'
                        onClick={() =>
                          handleDownloadLoansFile(item.clientId, item.id, item.documentName, fileStore.getLoansFile)
                        }
                        style={{ color: 'GrayText', cursor: 'pointer' }}>
                        {downloadingLoansFile == item.id ? (
                          <span className='m-1'>
                            <Spinner
                              animation='grow'
                              aria-hidden='true'
                              as='span'
                              role='status'
                              size='sm'
                              variant='dark'
                            />
                          </span>
                        ) : null}
                        <FontAwesomeIcon icon={faFile} />
                        <span style={{ paddingLeft: '20px' }}>{item.documentName}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {discountCodeFiles.length > 0 && (
              <div>
                <h6>E-bony</h6>
                <ul style={{ width: '100%' }}>
                  {discountCodeFiles.map(({ fileId, description, fileName, mime }) => (
                    <li className='list-group-item' key={fileId}>
                      <div
                        className='m-2'
                        onClick={() => (downloadingFile != fileId ? handleDownload(fileId, fileName, mime) : null)}
                        style={{ color: 'GrayText', cursor: 'pointer' }}>
                        {downloadingFile == fileId ? (
                          <span className='m-1'>
                            <Spinner
                              animation='grow'
                              aria-hidden='true'
                              as='span'
                              role='status'
                              size='sm'
                              variant='dark'
                            />
                          </span>
                        ) : null}
                        <FontAwesomeIcon icon={faFile} />
                        <span style={{ paddingLeft: '20px' }}>{description}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {walletApplicationFiles.length > 0 && (
              <div>
                <h6>Portfel NAU</h6>
                <ul style={{ width: '100%' }}>
                  {walletApplicationFiles.map(({ fileId, fileName, mime }) => (
                    <li className='list-group-item' key={fileId}>
                      <div
                        className='m-2'
                        onClick={() => (downloadingFile != fileId ? handleDownload(fileId, fileName, mime) : null)}
                        style={{ color: 'GrayText', cursor: 'pointer' }}>
                        {downloadingFile == fileId ? (
                          <span className='m-1'>
                            <Spinner
                              animation='grow'
                              aria-hidden='true'
                              as='span'
                              role='status'
                              size='sm'
                              variant='dark'
                            />
                          </span>
                        ) : null}
                        <FontAwesomeIcon icon={faFile} />
                        <span style={{ paddingLeft: '20px' }}>{fileName}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </CustomerDashboard>
    </>
  );
});
