import { colorOrange1 } from '../../../theme/colors';

export const myMarginTop = { marginTop: '10px' };

export const accordionBody = {
  background: '#FFF',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
} as React.CSSProperties;

export const headingTextStyle = {
  fontSize: '20px',
  lineHeight: '1.5',
  color: colorOrange1,
  fontWeight: 600,
  marginBottom: '12px',
} as React.CSSProperties;

export const textStyle = {
  fontSize: '16px',
  color: '#00635C',
  marginBottom: '25px',
  lineHeight: '1.5',
} as React.CSSProperties;

export const textSmall = {
  fontSize: '12px',
  color: '#414957',
} as React.CSSProperties;

export const displayBetween = {
  display: 'flex',
  justifyContent: 'space-between',
} as React.CSSProperties;

export const fontHigh = {
  fontWeight: '700',
};
