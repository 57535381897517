import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useState } from 'react';
import { Image } from 'react-bootstrap';
import * as styles from './styles';
import { DiscountCodeTabsE } from '../../../enums/discountCodeTabs.enums';
import DiscountCodeCart from '../DiscountCodeCart/DiscountCodeCart';
import UserDiscountCodes from '../UserDiscountCodes/UserDiscountCodes';
import DiscountCodesInstruction from '../DiscountCodesInstruction/DiscountCodesInstruction';
import DiscountCodesOrders from '../DiscountCodesOrders/DiscountCodesOrders';
import UserBirthdayCodes from '../../BirthdayCode/UserBirthdayCodes/UserBirthdayCodes';
import BirthdayCodeHeader from '../../BirthdayCode/BirthdayCodeHeader/BirthdayCodeHeader';
import { useStore } from '../../../store/store';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { DiscountCodeContractStatusE } from '../../../enums/discountCodeContractStatus.enums';
import { Redirect } from 'react-router';
import { PathRoute } from '../../../constants/pathRoute/Route';
import ProgressDiscountCodeContract from '../ProgressDiscountCodeContract/ProgressDiscountCodeContract';
import RejectedDiscountCodeContract from '../RejectedDiscountCodeContract/RejectedDiscountCodeContract';
import ProgressDiscountCodeContractBody from '../ProgressDiscountCodeContract/ProgressDiscountCodeContractBody';
import RejectedDiscountCodeContractBody from '../RejectedDiscountCodeContract/RejectedDiscountCodeContractBody';

export default observer(() => {
  const [activeTab, setActiveTab] = useState<number>(DiscountCodeTabsE.Codes);
  const { data: contractStatus, isLoading, mutate } = useSwrAgent().DiscountCodes.GetUserDiscountCodeContractStatus();

  const renderTab = (tab: number) => {
    if (tab == DiscountCodeTabsE.Codes) {
      return (
        <>
          <UserDiscountCodes />
          <div className={'mt-3'} />
          <BirthdayCodeHeader />
          <UserBirthdayCodes />
        </>
      );
    } else if (tab == DiscountCodeTabsE.Cart) {
      return renderSwitch();
    }
    return null;
  };
  const renderSwitch = () => {
    switch (contractStatus) {
      case DiscountCodeContractStatusE.Active:
        return <DiscountCodeCart />;
      case DiscountCodeContractStatusE.Waiting:
        return <ProgressDiscountCodeContractBody />;
      case DiscountCodeContractStatusE.Rejected:
      case DiscountCodeContractStatusE.Blocked:
      case DiscountCodeContractStatusE.Canceled:
        return <RejectedDiscountCodeContractBody />;
      default:
        return <Redirect to={PathRoute.DISCOUNT_CODE_START} />;
    }
  };
  return (
    <>
      <div>
        <div className='col-md-6 offset-md-3'>
          <div style={styles.tabsBox}>
            <div>
              <h1 style={{ color: '#fff', textAlign: 'center' } as React.CSSProperties}>
                <Image
                  src='/assets/bony.svg'
                  style={{ marginLeft: '5px', marginRight: '15px', height: '25px' } as React.CSSProperties}
                />
                E-bony
              </h1>
            </div>
            <div
              style={
                {
                  paddingTop: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                } as React.CSSProperties
              }>
              <span
                onClick={() => setActiveTab(DiscountCodeTabsE.Codes)}
                style={activeTab == DiscountCodeTabsE.Codes ? styles.activeTabLink : styles.tabLink}>
                Moje E-bony
              </span>
              <span
                onClick={() => setActiveTab(DiscountCodeTabsE.Cart)}
                style={activeTab == DiscountCodeTabsE.Cart ? styles.activeTabLink : styles.tabLink}>
                Zamów
              </span>
            </div>
          </div>
          {renderTab(activeTab)}
        </div>
        <div className='discountCodes'>
          <div className='col-md-6 offset-md-3 discountCodes-instructions'>
            <DiscountCodesInstruction />
          </div>
          <div className='col-md-6 offset-md-3 discountCodes-instructions'>
            <DiscountCodesOrders />
          </div>
        </div>
      </div>
    </>
  );
});
