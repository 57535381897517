import { makeAutoObservable, runInAction } from 'mobx';
import {
  CreateUserPhoneModel,
  EditUserPhoneModel,
  UserPhoneModel,
  UserProfileModel,
  UserProfileModelResponse,
  VerifyUserPhoneModel,
} from '../../types/user';
import agent from '../../api/agent';
import { store } from '../store';

export default class UserProfileStore {
  userProfile: UserProfileModelResponse | null = null;
  userProfilePhones: UserPhoneModel[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getUserProfile = async () => {
    const response = await agent.UserProfile.getUserProfile();
    runInAction(() => {
      this.userProfile = response;
    });
  };

  getUserProfilePhones = async () => {
    const response = await agent.UserProfile.getUserProfilePhones();
    runInAction(() => {
      // @ts-ignore
      this.userProfilePhones = response;
    });
  };

  editUserProfile = async (userProfileModel: UserProfileModel) => {
    await agent.UserProfile.editUserProfile(userProfileModel)
      .then((resp) => {
        store.commonStore.setToken(resp.token);
        store.commonStore.setRefreshToken(resp.refreshToken);
        store.userStore.user = resp;
      })
      .catch((resp: Error) => {
        throw resp;
      });
  };

  editUserProfilePhone = async (editUserPhoneModel: EditUserPhoneModel) => {
    await agent.UserProfile.editUserProfilePhone(editUserPhoneModel);
  };

  deleteUserProfilePhone = async (phoneId: number) => {
    await agent.UserProfile.deleteUserProfilePhone(phoneId);
  };

  setMainUserProfilePhone = async (phoneId: number) => {
    await agent.UserProfile.setMainUserProfilePhone(phoneId);
  };

  verifyUserProfilePhone = async (verifyUserPhoneModev: VerifyUserPhoneModel) => {
    await agent.UserProfile.verifyUserProfilePhone(verifyUserPhoneModev);
  };

  requestVerifyUserProfilePhone = async (phoneId: number) => {
    await agent.UserProfile.requestVerifyUserProfilePhone(phoneId);
  };

  createUserProfilePhone = async (createUserPhoneModel: CreateUserPhoneModel) => {
    await agent.UserProfile.createUserProfilePhone(createUserPhoneModel);
  };
}
