import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import useQuery from '../../../hooks/useQuery';
import { PathRoute } from '../../../constants/pathRoute/Route';
import MyTextArea from '../../FormControls/MyTextArea/MyTextArea';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

interface StarProps {
  selected?: boolean;
  onSelect?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

function Star({ selected, onSelect, onMouseEnter, onMouseLeave }: StarProps) {
  return (
    <button
      className={` ${selected ? 'tw-text-yellow-400' : 'tw-text-gray-300'} hover:tw-text-yellow-400 tw-px-1`}
      onClick={onSelect}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type='button'>
      <svg height='53.857' viewBox='0 0 56.629 53.857' width='56.629' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M28.314,0l-8.75,17.729L0,20.572l14.157,13.8L10.815,53.858l17.5-9.2,17.5,9.2L42.472,34.372l14.157-13.8L37.064,17.729Z'
          data-name='Path 4754'
          fill='currentColor'
          id='Path_4754'
          transform='translate(0 0)'
        />
      </svg>
    </button>
  );
}

const RatingForm = () => {
  const { cinemaVoucherStore } = useStore();
  const query: any = useQuery();
  const orderId = query.get('OrderID');

  const [hoveredStar, setHoveredStar] = useState<number | null>(null);

  return (
    <Formik
      initialValues={{
        orderRate: 0,
        orderRateComment: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (!values.orderRate) {
          setSubmitting(false);
          toast.error('Proszę wybrać ocenę');
          return;
        }
        cinemaVoucherStore
          .rateCinemaVouchersOrder({
            orderId: orderId,
            orderRate: values.orderRate,
            orderRateComment: values.orderRateComment,
          })
          .then(() => {
            window.open(PathRoute.APP_CINEMA_VOUCHER, '_self', 'location=no');
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className='tw-p-4'>
          <p className={'tw-text-nau-green-dark tw-text-2xl tw-font-bold tw-text-center tw-mb-8 tw-mt-2'}>
            Jak oceniasz proces zakupu?
          </p>
          <div className='tw-flex tw-justify-center tw-mb-10'>
            {[...Array(5)].map((_, index) => (
              <Star
                key={index}
                onMouseEnter={() => setHoveredStar(index + 1)}
                onMouseLeave={() => setHoveredStar(null)}
                onSelect={() => setFieldValue('orderRate', index + 1)}
                selected={values.orderRate > index || hoveredStar! > index}
              />
            ))}
          </div>
          <label className='tw-block tw-text-sm tw-font-medium tw-text-gray-700' htmlFor='additionalComments'>
            Dodatkowe uwagi (opcjonalnie)
          </label>
          <div>
            <MyTextArea name={'orderRateComment'} placeholder={''} rows={6} />
          </div>

          <div className={' tw-pt-6 tw-flex tw-justify-center '}>
            {isSubmitting ? (
              <Button className='tw-w-[90%]' type='submit'>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Wysyłam…
              </Button>
            ) : (
              <Button className='tw-w-[90%]' type='submit'>
                Przejdź do "Moje kupony"
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RatingForm;
