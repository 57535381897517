export enum WalletSpecialOfferTypeE {
  NauWorker = 1,
  Znp = 2,
  Br = 3,
  TeacherPromo = 4,
  TradeUnionLikeZnpTerms = 5,
  TradeUnionLikeBrTerms = 6,
  TradeUnionLikeTeacherPromoTerms = 7,
  CinemaVouchersSchoolStuff = 8,
}
