import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/store';
import LogoHeader from '../LogoHeader/LogoHeader';
import MyFooterContact from '../MyFooterContact/MyFooterContact';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import MyPasswordInput from '../FormControls/MyPasswordInput/MyPasswordInput';
import * as styles from '../AuthorizeToken/styles';
import * as Yup from 'yup';
import { ICashbackSopPolicyGroupDto } from '../../types/cashback';
import { CASHBACK_POLICY_DATA, PROMO_CODE_KEY } from '../../constants/sessionStorageKeys';

export default observer(() => {
  const { userStore } = useStore();

  return (
    <Formik
      initialValues={{ token: '', password: '', confirmPassword: '', error: null }}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        const promoCode = sessionStorage.getItem(PROMO_CODE_KEY) ?? undefined;
        const cashbackPolicyData = sessionStorage.getItem(CASHBACK_POLICY_DATA);
        const cashbackPolicyLoginForm: ICashbackSopPolicyGroupDto | undefined = cashbackPolicyData
          ? JSON.parse(cashbackPolicyData)
          : undefined;

        let cashbackSopPolicyDto: ICashbackSopPolicyGroupDto | undefined = undefined;
        if (cashbackPolicyLoginForm) {
          cashbackSopPolicyDto = {
            policyNumber: cashbackPolicyLoginForm.policyNumber,
            policyId: cashbackPolicyLoginForm.policyId,
            customerId: cashbackPolicyLoginForm.customerId,
          };
        }
        userStore
          .verifyNewPassword({
            ...values,
            userId: userStore.userId,
            promoCode: promoCode,
            cashbackSopPolicyDto: cashbackSopPolicyDto,
          })
          .then(() => {
            sessionStorage.removeItem('cashbackPolicyData');
            sessionStorage.removeItem(PROMO_CODE_KEY);
          })
          .catch((error) => {
            setErrors({ error: error });
            setSubmitting(false);
          });
      }}
      validationSchema={Yup.object({
        password: Yup.string().required('Pole jest wymagane'),
        token: Yup.string().required('Pole jest wymagane'),
        confirmPassword: Yup.string()
          .required('Pole jest wymagane')
          .oneOf([Yup.ref('password'), null], 'Hasła nie są takie same'),
      })}>
      {({ handleSubmit, isSubmitting, errors: { error } }) => (
        <Form
          autoComplete='off'
          className='ui form col-md-6 offset-md-3'
          onSubmit={handleSubmit}
          style={{ paddingBottom: '55px', marginTop: '-5vh' } as React.CSSProperties}>
          <div style={styles.mainContainer as React.CSSProperties}>
            <LogoHeader />
          </div>
          <h2 style={styles.titleHeader as React.CSSProperties}>Wpisz swoje nowe hasło !</h2>
          <label
            style={
              { fontSize: '12px', color: '#626d80', marginTop: '10px', marginLeft: '10px' } as React.CSSProperties
            }>
            Hasło z tokena SMS
          </label>
          <MyTextInput hiddenLabel={true} name='token' placeholder='Hasło z tokena SMS' />
          <label
            style={
              { fontSize: '12px', color: '#626d80', marginTop: '10px', marginLeft: '10px' } as React.CSSProperties
            }>
            Nowe Hasło
          </label>
          <MyPasswordInput hiddenLabel={true} name='password' placeholder='Nowe Hasło' />
          <label
            style={
              { fontSize: '12px', color: '#626d80', marginTop: '10px', marginLeft: '10px' } as React.CSSProperties
            }>
            Potwierdź Hasło
          </label>
          <MyPasswordInput hiddenLabel={true} name='confirmPassword' placeholder='Potwierdź Hasło' />
          <ErrorMessage name='error' render={() => <label style={styles.labelError}>{error}</label>} />
          {isSubmitting ? (
            <Button className='btn-full-width mt-3' type='submit'>
              <span className='m-1'>
                <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
              </span>
              Wysyłam…
            </Button>
          ) : (
            <Button className='btn-full-width mt-3' type='submit'>
              Wyślij
            </Button>
          )}
          <div
            className='mt-2'
            style={
              {
                marginLeft: '5px',
                marginRight: '5px',
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
              } as React.CSSProperties
            }>
            <Col xs='auto'></Col>
            <Col xs='auto'>
              <Link className='fs--1 mb-0' style={{ textDecoration: 'auto', fontSize: '12px' }} to='/'>
                Powrót do logowania
              </Link>
            </Col>
          </div>
          <MyFooterContact />
        </Form>
      )}
    </Formik>
  );
});
