import React from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import CinemaVoucherHeader from '../../components/CinemaVoucher/CinemaVoucherHeader/CinemaVoucherHeader';

export const DirectorBadDataCinemaVoucher = () => {
  return (
    <>
      <CustomerDashboard>
        <div className={'tw-mx-auto tw-max-w-2xl '}>
          <CinemaVoucherHeader />
          <div className={'tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6'}>
            <div className={'tw-text-center  tw-text-nau-sea-green'}>
              <div className={'tw-text-xl tw-font-semibold'}>Prosimy o wprowadzenie poprawnych danych.</div>
              <div className={'tw-mt-8 tw-text-xl tw-font-semibold'}>
                Dyrektor / Dyrektorka danej placówki ma prawo do odbioru maksymalnie dwóch darmowych biletów.
              </div>
              <div className={'tw-mt-8 tw-text-xl tw-font-semibold'}>W przypadku pytań prosimy o kontakt:</div>
              <div className={'tw-mt-8 tw-text-lg'}>
                • Numer telefonu – <span className={'tw-font-semibold'}>22 696 72 70</span>
              </div>
              <div className={'tw-text-lg'}>
                • Adres e-mail – <span className={'tw-font-semibold'}>bok@nau.pl</span>
              </div>
            </div>
          </div>
        </div>
      </CustomerDashboard>
    </>
  );
};
