import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Image, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import { Form, Formik } from 'formik';
import MySelectInput from '../../../components/FormControls/MySelectInput/MySelectInput';
import MyTextMaskedInput from '../../../components/FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { initialValues, validationSchema } from './index';
import { toast } from 'react-toastify';
import * as styles from './styles';
import { MySelectInputOptions } from '../../../components/FormControls/MySelectInput/types';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { history } from '../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import { User } from '../../../types/user';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import FileHelper from '../../../helpers/FileHelper';
import { Benefit } from '../../../enums/benefits.enums';
import WalletNauHeader from '../../../components/WalletNau/WalletNauHeader/DiscountCodeHeader';
import { WalletNauContractData } from '../../../types/walletNau';
import _ from 'lodash';
import stylesScss from './styles.module.scss';
import EmploymentDataFormPart from './EmploymentDataFormPart/EmploymentDataFormPart';
import classNames from 'classnames';
import { WalletNauNewContractStatuses } from '../../../enums/WalletNauContractStatus.enums';
import BoolToString from '../../../helpers/BoolToString';
import { FormikScrollToFieldError } from '../../../components/FormikScrollToFieldError/FormikScrollToFieldError';
import { AddressPromptingForm } from '../../../components/Address/AddressPromptingForm';

export default observer(() => {
  const { userProfileStore, userStore, walletNauStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [isZnpHidden, setIsZnpHidden] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | undefined>(undefined);

  const [contract, setContract] = useState<WalletNauContractData>(initialValues);
  const user: User | undefined = userStore.getUserData();
  const [activePhonesSelectInput, setActivePhonesSelectInput] = useState<MySelectInputOptions[]>([]);
  const [differentCorrespondenceState, setDifferentCorrespondence] = useState<boolean>(false);

  const toggleDifferentCorrespondence = () => {
    setDifferentCorrespondence(!differentCorrespondenceState);
  };

  useEffect(() => {
    loadData().finally(() => setLoading(false));
  }, []);

  const loadData = async () => {
    if (!userStore.isActive) {
      toast.error('Twoje konto nie zostało aktywowane.');
      history.push(PathRoute.PUSTY);
      return;
    }

    const contractStatus = walletNauStore.getWalletContractStatus().then((contractStatus) => {
      if (!WalletNauNewContractStatuses.includes(contractStatus ?? 0)) {
        history.push(PathRoute.WALLET_NAU);
        return;
      }
    });

    const requiredApprovals = userStore.hasRequiredApprovalsForBenefit(Benefit.WalletNau).then((requiredApprovals) => {
      if (!requiredApprovals) {
        history.push(PathRoute.WALLET_NAU_APPROVALS);
        return;
      }
    });

    const contractData = userProfileStore.getUserProfilePhones().then(() => {
      prepareActivePhonesSelectInput();
      return userProfileStore.getUserProfile().finally(() => {
        return prepareContractData();
      });
    });

    return Promise.allSettled([contractStatus, requiredApprovals, contractData]);
  };

  const prepareActivePhonesSelectInput = () => {
    if (userProfileStore && userProfileStore.userProfilePhones) {
      const activePhonesSelectInput: MySelectInputOptions[] = [];

      userProfileStore.userProfilePhones.forEach((item) => {
        if (item.isActive && item) {
          activePhonesSelectInput.push({
            value: item.phone,
            label: item.phone,
          });
        }
      });

      setActivePhonesSelectInput(activePhonesSelectInput);
    }
  };

  const fillUserDataFromProfile = () => {
    const contractCopy: WalletNauContractData = _.cloneDeep(contract);

    if (userProfileStore.userProfile) {
      const address = userProfileStore.userProfile?.userAddresses.find((x) => !x.isCorrespondence);
      const correspondenceAddress = userProfileStore.userProfile?.userAddresses.find((x) => x.isCorrespondence);
      const differentCorrespondence =
        (address?.street ?? '' + address?.houseNumber + address?.flatNumber + address?.place + address?.postCode) !==
        (correspondenceAddress?.street ??
          '' +
            correspondenceAddress?.houseNumber +
            correspondenceAddress?.flatNumber +
            correspondenceAddress?.place +
            correspondenceAddress?.postCode);
      contractCopy.firstName = userProfileStore.userProfile.firstName;
      contractCopy.lastName = userProfileStore.userProfile.lastName;
      contractCopy.email = user ? user.username : contractCopy.email;
      contractCopy.pesel = userProfileStore.userProfile.pesel;
      contractCopy.street = address ? address.street : '';
      contractCopy.houseNo = address ? address.houseNumber : '';
      contractCopy.flatNo = address ? address.flatNumber : '';
      contractCopy.place = address ? address.place : '';
      contractCopy.postCode = address ? address.postCode : '';
      contractCopy.correspondenceStreet = correspondenceAddress ? correspondenceAddress.street : '';
      contractCopy.correspondenceHouseNo = correspondenceAddress ? correspondenceAddress.houseNumber : '';
      contractCopy.correspondenceFlatNo = correspondenceAddress ? correspondenceAddress.flatNumber : '';
      contractCopy.correspondencePlace = correspondenceAddress ? correspondenceAddress.place : '';
      contractCopy.correspondencePostCode = correspondenceAddress ? correspondenceAddress.postCode : '';
      contractCopy.differentCorrespondece = differentCorrespondence;
      setDifferentCorrespondence(differentCorrespondence);
    }
    setContract(contractCopy);
  };

  const prepareContractData = () => {
    return walletNauStore
      .getWalletNauContractUserData()
      .then((resp) => {
        setIsZnpHidden(resp.znpFromApi);
        setDifferentCorrespondence(resp.differentCorrespondece);
        setContract(resp);
      })
      .catch((error) => {
        toast.error(error);
        fillUserDataFromProfile();
      });
  };
  const sendWithoutFile = async (isNauWorker: boolean) => {
    const formData = new FormData();
    const emptyBlob = new Blob();
    await formData.append('formFile', emptyBlob);
    await formData.append('fileName', 'null');
    await formData.append('IsNauWorker', BoolToString.ToString(isNauWorker));

    await walletNauStore
      .saveWalletNauContractFile(formData)
      .then(() => {
        history.push(PathRoute.WALLET_NAU);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3' style={styles.container}>
            <WalletNauHeader />
            <div style={{ width: '100%' } as React.CSSProperties}>
              <Formik
                enableReinitialize
                initialValues={contract}
                onSubmit={(values, { setFieldError, setSubmitting }) =>
                  walletNauStore
                    .saveWalletNauContractUserData(values)
                    .then(() => {
                      if (!values.isNauWorker) {
                        history.push(PathRoute.FILE_CONTRACT_WALLET_NAU);
                      } else {
                        sendWithoutFile(values.isNauWorker);
                      }
                    })
                    .catch((error) => {
                      if (Array.isArray(error)) {
                        error.forEach((element) => {
                          const fieldName = element.fieldName.charAt(0).toLowerCase() + element.fieldName.slice(1);
                          setFieldError(fieldName, element.message);
                          setSubmitting(false);
                        });
                      } else {
                        setFormError(error);
                        setSubmitting(false);
                      }
                    })
                }
                validationSchema={validationSchema}>
                {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
                  <Form autoComplete='off' className={'ui form'} onSubmit={handleSubmit}>
                    <div style={styles.grayContainer}>
                      <FontAwesomeIcon color='#0bc268' icon={faCircleInfo} size='lg' />
                      <div style={{ paddingLeft: '10px' } as React.CSSProperties}>
                        <p style={styles.headingTextStyle}>Wymagane dokumenty</p>
                        <p>
                          Przygotuj skan zaświadczenia o dochodach od pracodawcy lub wyciąg z konta bankowego za okres 3
                          miesięcy.
                        </p>
                        <div
                          onClick={() =>
                            FileHelper.downloadFile(
                              PathRoute.EMPLOYMENT_STATEMENT_DISCOUNT_CODE,
                              'Wzor_zaswiadczenia_o_dochodach.pdf',
                            )
                          }
                          style={styles.displayFlexEnd as React.CSSProperties}>
                          <p style={styles.marginBottomLeft as React.CSSProperties}>
                            <a style={styles.hrefLink as React.CSSProperties}>Pobierz wzór zaświadczenia</a>{' '}
                          </p>
                          <Image src='/assets/arrowRightCash.svg' style={styles.heightCursor as React.CSSProperties} />
                        </div>
                        {!isZnpHidden && (
                          <>
                            <p className={'mt-3'}>Dodatkowo zaznacz poniższe pole, jeśli jesteś członkiem ZNP</p>
                            <MyCheckbox
                              checked={values.znp}
                              className={stylesScss.formCheckbox}
                              label='Jestem członkiem ZNP'
                              name={`znp`}
                              onChange={() => {
                                setFieldValue('znp', !values.znp);
                                if (!values.znp) {
                                  setFieldValue('znpBranch', '');
                                }
                              }}
                              type='checkbox'
                            />
                            {values.znp && (
                              <div className={classNames('mt-3', stylesScss.znpInput)}>
                                <MyTextInput
                                  className={stylesScss.znpStyle}
                                  label={'Wpisz nazwę oddziału do którego należysz:'}
                                  name={'znpBranch'}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div style={styles.formWrapper as React.CSSProperties}>
                      <div style={styles.goBackLink}>
                        <Link to={PathRoute.WALLET_NAU_APPROVALS}>
                          <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
                        </Link>
                      </div>
                      <div style={styles.headingTextStyle}>Uzupełnij informacje:</div>
                      <div className={stylesScss.formFieldsWrapper}>
                        <div style={styles.formTitle}>Dane osobiste</div>
                        <MyTextInput label={'Imię'} name={'firstName'} />
                        <MyTextInput label='Nazwisko' name={`lastName`} />
                        <MyTextMaskedInput
                          disabled={contract != null && contract.pesel != '' && contract.pesel.length == 11}
                          label='Numer PESEL'
                          mask={MaskHelpers.pesel}
                          name={`pesel`}
                        />
                        <MySelectInput
                          className='colorBlack marginBottom2px'
                          label='Telefon komórkowy'
                          name={`phone`}
                          options={activePhonesSelectInput}
                          placeholder={null}
                          value={values.phone}
                        />
                        <MyTextInput label='E-mail' name={`email`} />
                        <MyTextInput label='Seria i numer dowodu osobistego' name={'documentNumber'} />
                        <MyTextMaskedInput
                          label='Data ważności dowodu osobistego'
                          mask={MaskHelpers.fullDate}
                          name={`documentExpirationDate`}
                          placeholder='rrrr-MM-dd'
                        />
                        <MyTextInput label={'Numer rachunku bankowego'} name={'bankAccountNumber'} />
                        <p style={styles.formTitle}>Twój adres</p>
                        <AddressPromptingForm
                          cityFormikName={'place'}
                          flatNoFormikName={'flatNo'}
                          houseNoFormikName={'houseNo'}
                          postCodeFormikName={'postCode'}
                          streetFormikName={'street'}
                        />
                        <MyCheckbox
                          checked={differentCorrespondenceState}
                          className={stylesScss.formCheckbox}
                          id='differentCorrespondence'
                          label='Inny adres korespondencyjny'
                          name={`differentCorrespondence`}
                          onChange={() => toggleDifferentCorrespondence()}
                          type='checkbox'
                        />
                        {differentCorrespondenceState && (
                          <>
                            <p style={styles.formTitle}>Adres korespondencyjny</p>
                            <AddressPromptingForm
                              cityFormikName={'correspondencePlace'}
                              flatNoFormikName={'correspondenceFlatNo'}
                              houseNoFormikName={'correspondenceHouseNo'}
                              postCodeFormikName={'correspondencePostCode'}
                              streetFormikName={'correspondenceStreet'}
                            />
                          </>
                        )}
                        <p style={styles.formTitle}>Dane zatrudnienia</p>
                        <EmploymentDataFormPart />
                        <p style={styles.formTitle}>Dane dodatkowe</p>
                        <MyTextInput label={'Wysokość rat pożyczek miesięcznie'} name={'monthlyWalletInstallments'} />
                        <MyTextInput label='Wysokość przyznanych limitów kredytowych' name={`creditLimitsGranted`} />
                        <MyTextInput label='Liczba osób na utrzymaniu' name={`numberOfDependents`} />
                        <MyTextInput label='Miesięczne wydatki na utrzymanie gospodarstwa' name={`monthlyExpenses`} />
                      </div>
                      {formError && <label style={styles.errorLabel}>{formError}</label>}
                      {isSubmitting ? (
                        <Button className='btn-full-width mt-2' type='submit' variant='primary'>
                          <span className='m-1'>
                            <Spinner
                              animation='grow'
                              aria-hidden='true'
                              as='span'
                              role='status'
                              size='sm'
                              variant='light'
                            />
                          </span>
                          Trwa zapisywanie...
                        </Button>
                      ) : (
                        <Button className='btn-full-width mt-2' type='submit' variant='primary'>
                          Chcę złożyć wniosek
                        </Button>
                      )}
                    </div>
                    <FormikScrollToFieldError />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});
