import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import { Form, Formik } from 'formik';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import {
  initialValues,
  validationSchema,
  carParkingPlaceTypeOptions,
  carGearBoxTypeOptions,
  validationSchemaWithFile,
} from './index';
import { toast } from 'react-toastify';
import { FormikScrollToFieldError } from '../../FormikScrollToFieldError/FormikScrollToFieldError';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { handleErrorResponse } from '../../../utils/apiErrorHandle';
import { CarInsuranceBasicFormValues, CarInsuranceFormValues } from '../../../types/carInsurance';
import MyCheckbox from '../../FormControls/MyCheckbox/MyCheckbox';
import MyRadioButton from '../../FormControls/MyRadioButton/MyRadioButton';
import DateHelpers from '../../../helpers/DateHelpers';
import MyTextArea from '../../FormControls/MyTextArea/MyTextArea';
import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LeadCampaignType } from '../../../types/leads';
import MyButton from '../../Buttons/MyButton/MyButton';

export default observer((props: { values: CarInsuranceBasicFormValues; isSent: boolean }) => {
  const { leadStore, userStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSent, setIsSent] = useState<boolean>(props.isSent);
  const [isFile, setIsFile] = useState<boolean>(false);
  const [isConfirmView, setIsConfirmView] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<CarInsuranceFormValues>(initialValues);

  const getLeadCampaignHumanName = (leadCampaignType: LeadCampaignType): string => {
    switch (leadCampaignType) {
      case 'car':
        return 'OC_AC Samochodu';
      case 'property':
        return 'Ubezpieczenie majątkowe';
      case 'trip':
        return 'Ubezpieczenie turystyczne';
      case 'simNau':
        return 'Sim Nau';
      default:
        return '';
    }
  };

  const handleChangeValue = (name: string, value: any) => {
    let newFormValues = { ...formValues };
    if (value === 'true' || value === 'false') {
      const booleanValue = value === 'true' ? true : value === 'false' ? false : value;
      newFormValues = { ...formValues, [name]: booleanValue };
    } else {
      newFormValues = { ...formValues, [name]: value };
    }
    if (name === 'isCoOwner') {
      if (!value) {
        newFormValues.coOwners = [];
        setFormValues(newFormValues);
      } else {
        addCoOwner(newFormValues);
      }
    } else {
      setFormValues(newFormValues);
    }
  };
  const handleCoOwnerChangeValue = (name: string, index: number, value: any) => {
    const newFormValues = { ...formValues };
    newFormValues.coOwners[index] = { ...newFormValues.coOwners[index], [name]: value };
    setFormValues(newFormValues);
  };
  const addCoOwner = (newFormValues: CarInsuranceFormValues) => {
    const newCoOwners = newFormValues.coOwners;
    newCoOwners.push({
      firstName: '',
      lastName: '',
      pesel: '',
      email: '',
      phone: '',
      addressStreet: '',
      addressHouseNumber: '',
      addressFlatNumber: '',
      addressPostCode: '',
      addressPlace: '',
      obtainingDrivingLicenseYear: undefined,
      obtainingDrivingLicenseMonth: undefined,
      obtainingDrivingLicenseDay: undefined,
    });
    setFormValues({ ...formValues, coOwners: newCoOwners });
  };

  const removeCoOwner = (index: number) => {
    const newCoOwners = formValues.coOwners;
    newCoOwners.splice(index, 1);
    if (newCoOwners.length === 0) formValues.isCoOwner = false;
    setFormValues({ ...formValues, coOwners: newCoOwners });
  };
  const handleInsuranceStartDateChange = (startYear?: string, startMonth?: string) => {
    if (startYear) {
      const newFormValues = { ...formValues, insuranceStartYear: parseInt(startYear) };
      if (startYear && formValues.insuranceStartDay && formValues.insuranceStartMonth) {
        const monthLength = DateHelpers.getMonthLength(
          newFormValues.insuranceStartYear,
          formValues.insuranceStartMonth,
        );
        if (formValues.insuranceStartDay > monthLength) {
          newFormValues.insuranceStartDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
    if (startMonth) {
      if (parseInt(startMonth) <= 0 || parseInt(startMonth) >= 13) {
        startMonth = formValues.insuranceStartMonth?.toString() ?? '1';
      }
      const newFormValues = { ...formValues, insuranceStartMonth: parseInt(startMonth) };
      if (formValues.insuranceStartYear && startMonth && formValues.insuranceStartDay) {
        const monthLength = DateHelpers.getMonthLength(
          formValues.insuranceStartYear,
          newFormValues.insuranceStartMonth,
        );
        if (formValues.insuranceStartDay > monthLength) {
          newFormValues.insuranceStartDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
  };
  const handleOwnerDrivingLicenseStartDateChange = (startYear?: string, startMonth?: string) => {
    if (startYear) {
      const newFormValues = { ...formValues, obtainingDrivingLicenseYear: parseInt(startYear) };
      if (startYear && formValues.obtainingDrivingLicenseDay && formValues.obtainingDrivingLicenseMonth) {
        const monthLength = DateHelpers.getMonthLength(
          newFormValues.obtainingDrivingLicenseYear,
          formValues.obtainingDrivingLicenseMonth,
        );
        if (formValues.obtainingDrivingLicenseDay > monthLength) {
          newFormValues.obtainingDrivingLicenseDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
    if (startMonth) {
      if (parseInt(startMonth) <= 0 || parseInt(startMonth) >= 13) {
        startMonth = formValues.obtainingDrivingLicenseMonth?.toString() ?? '1';
      }
      const newFormValues = { ...formValues, obtainingDrivingLicenseMonth: parseInt(startMonth) };
      if (formValues.obtainingDrivingLicenseYear && startMonth && formValues.obtainingDrivingLicenseDay) {
        const monthLength = DateHelpers.getMonthLength(
          formValues.obtainingDrivingLicenseYear,
          newFormValues.obtainingDrivingLicenseMonth,
        );
        if (formValues.obtainingDrivingLicenseDay > monthLength) {
          newFormValues.obtainingDrivingLicenseDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
  };
  const handleCoOwnerDrivingLicenseStartDateChange = (index: number, startYear?: string, startMonth?: string) => {
    if (startYear) {
      const newFormValues = { ...formValues };
      newFormValues.coOwners[index].obtainingDrivingLicenseYear = parseInt(startYear);
      if (
        startYear &&
        newFormValues.coOwners[index].obtainingDrivingLicenseDay &&
        newFormValues.coOwners[index].obtainingDrivingLicenseMonth &&
        newFormValues.coOwners[index].obtainingDrivingLicenseYear
      ) {
        const monthLength = DateHelpers.getMonthLength(
          newFormValues.coOwners[index].obtainingDrivingLicenseYear ?? 0,
          newFormValues.coOwners[index].obtainingDrivingLicenseMonth ?? 0,
        );
        if (
          newFormValues.coOwners[index].obtainingDrivingLicenseDay &&
          (newFormValues.coOwners[index].obtainingDrivingLicenseDay ?? 0) > monthLength
        ) {
          newFormValues.coOwners[index].obtainingDrivingLicenseDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
    if (startMonth) {
      if (parseInt(startMonth) <= 0 || parseInt(startMonth) >= 13) {
        startMonth = formValues.coOwners[index].obtainingDrivingLicenseMonth?.toString() ?? '1';
      }
      const newFormValues = { ...formValues };
      newFormValues.coOwners[index].obtainingDrivingLicenseMonth = parseInt(startMonth);
      if (
        newFormValues.coOwners[index].obtainingDrivingLicenseYear &&
        startMonth &&
        newFormValues.coOwners[index].obtainingDrivingLicenseDay &&
        newFormValues.coOwners[index].obtainingDrivingLicenseMonth
      ) {
        const monthLength = DateHelpers.getMonthLength(
          newFormValues.coOwners[index].obtainingDrivingLicenseYear ?? 0,
          newFormValues.coOwners[index].obtainingDrivingLicenseMonth ?? 0,
        );
        const day = newFormValues.coOwners[index].obtainingDrivingLicenseDay as number;
        if (day > monthLength) {
          newFormValues.coOwners[index].obtainingDrivingLicenseDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
  };
  const handleYoungUserDrivingLicenseStartDateChange = (startYear?: string, startMonth?: string) => {
    if (startYear) {
      const newFormValues = { ...formValues, youngUserObtainingDrivingLicenseYear: parseInt(startYear) };
      if (
        startYear &&
        formValues.youngUserObtainingDrivingLicenseDay &&
        formValues.youngUserObtainingDrivingLicenseMonth
      ) {
        const monthLength = DateHelpers.getMonthLength(
          newFormValues.youngUserObtainingDrivingLicenseYear,
          formValues.youngUserObtainingDrivingLicenseMonth,
        );
        if (formValues.youngUserObtainingDrivingLicenseDay > monthLength) {
          newFormValues.youngUserObtainingDrivingLicenseDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
    if (startMonth) {
      if (parseInt(startMonth) <= 0 || parseInt(startMonth) >= 13) {
        startMonth = formValues.youngUserObtainingDrivingLicenseMonth?.toString() ?? '1';
      }
      const newFormValues = { ...formValues, youngUserObtainingDrivingLicenseMonth: parseInt(startMonth) };
      if (
        formValues.youngUserObtainingDrivingLicenseYear &&
        startMonth &&
        formValues.youngUserObtainingDrivingLicenseDay
      ) {
        const monthLength = DateHelpers.getMonthLength(
          formValues.youngUserObtainingDrivingLicenseYear,
          newFormValues.youngUserObtainingDrivingLicenseMonth,
        );
        if (formValues.youngUserObtainingDrivingLicenseDay > monthLength) {
          newFormValues.youngUserObtainingDrivingLicenseDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
  };
  const handleYoungUserBirthDateDateChange = (startYear?: string, startMonth?: string) => {
    if (startYear) {
      const newFormValues = { ...formValues, youngUserBirthDateYear: parseInt(startYear) };
      if (startYear && formValues.youngUserBirthDateDay && formValues.youngUserBirthDateMonth) {
        const monthLength = DateHelpers.getMonthLength(
          newFormValues.youngUserBirthDateYear,
          formValues.youngUserBirthDateMonth,
        );
        if (formValues.youngUserBirthDateDay > monthLength) {
          newFormValues.youngUserBirthDateDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
    if (startMonth) {
      if (parseInt(startMonth) <= 0 || parseInt(startMonth) >= 13) {
        startMonth = '1';
      }
      const newFormValues = { ...formValues, youngUserBirthDateMonth: parseInt(startMonth) };
      if (formValues.youngUserBirthDateYear && startMonth && formValues.youngUserBirthDateDay) {
        const monthLength = DateHelpers.getMonthLength(
          formValues.youngUserBirthDateYear,
          newFormValues.youngUserBirthDateMonth,
        );
        if (formValues.youngUserBirthDateDay > monthLength) {
          newFormValues.youngUserBirthDateDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
  };
  useEffect(() => {
    userStore
      .getUserPesel()
      .then((pesel) => {
        formValues.pesel = pesel?.toString() ?? '';
      })
      .finally(() => {
        formValues.firstName = props.values.firstName;
        formValues.lastName = props.values.lastName;
        formValues.phone = props.values.phone;
        formValues.fileId = props.values.fileId;
        formValues.campaignTypeHumanName = getLeadCampaignHumanName(formValues.campaignType);
        setIsFile(!!formValues.fileId);
        const user = userStore.userData;
        formValues.email = user?.email ?? '';
        setLoading(false);
      });
  }, []);

  return (
    <>
      {isSent ? (
        <div>
          <div className='tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
            Dziękujemy za zainteresowanie naszą ofertą.
          </div>

          <div className='tw-my-8 tw-text-center tw-text-base tw-text-nau-green-dark'>
            Jesteśmy w trakcie przetwarzania Twojego wniosku.
            <br />W razie pytań zapraszany do kontaktu:
          </div>

          <div className='tw-my-8 tw-text-center tw-text-base tw-text-nau-green-dark'>
            numer telefonu - <b>22 696 72 70</b>
            <br />
            adres e-mail - <b>bok@nau.pl</b>
          </div>
        </div>
      ) : (
        <>
          {isConfirmView ? (
            <Formik
              enableReinitialize
              initialValues={formValues}
              onSubmit={(values, { setErrors, setFieldError, setSubmitting }) => {
                leadStore
                  .sendCarInsuranceLead(formValues)
                  .then(() => {
                    setIsSent(true);
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    toast.error('Wystąpił błąd podczas wysyłania formularza');
                    handleErrorResponse(err, setFieldError);
                  });
              }}
              validationSchema={isFile ? validationSchemaWithFile : validationSchema}>
              {({ handleSubmit, errors, values, isSubmitting }) => (
                <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                  <div className='tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
                    Prosimy o potwierdzenie informacji kontaktowych, abyśmy mogli skontaktować się w sprawie oferty
                  </div>
                  <MyTextInput
                    label='Email'
                    name='email'
                    onChange={(e) => handleChangeValue('email', e.target.value)}
                  />
                  <MyTextInput
                    label='Numer telefonu'
                    name='phone'
                    onChange={(e) => handleChangeValue('phone', e.target.value)}
                    mask={MaskHelpers.phone}
                  />
                  <div className='d-flex justify-content-center'>
                    <MyButton
                      className={'tw-mt-8 tw-w-full'}
                      type='submit'
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      loadingText={'Wysyłanie...'}
                      variant={'primary'}>
                      Wyślij
                    </MyButton>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <>
              <LoadingComponent content='Ładowanie...' visible={loading} />
              <Formik
                enableReinitialize
                initialValues={formValues}
                onSubmit={(values, { setErrors, setFieldError, setSubmitting }) => {
                  setIsConfirmView(true);
                }}
                validationSchema={isFile ? validationSchemaWithFile : validationSchema}>
                {({ handleSubmit, errors, values, isSubmitting }) => (
                  <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                    <div className={'d-flex flex-column gap-2'}>
                      <p className='tw-text-lg tw-text-teal-900'>
                        Chcesz, aby agent przygotował dla Ciebie ofertę ubezpieczenia komunikacyjnego?
                      </p>
                      <p className='tw-text-lg tw-text-teal-900'>Podaj dane:</p>
                      {!isFile && (
                        <>
                          <p className='tw-text-lg tw-text-teal-700'>Od kiedy potrzebujesz ochrony ubezpieczeniowej?</p>
                          <div className='tw-flex'>
                            <div className='tw-w-1/3 tw-pr-1.5'>
                              <MyTextInput
                                label='Rok'
                                name='insuranceStartYear'
                                type='number'
                                min='2024'
                                onChange={(e) => handleInsuranceStartDateChange(e.target.value, undefined)}
                              />
                            </div>
                            <div className='tw-w-1/3 tw-px-1.5'>
                              <MyTextInput
                                label='Miesiąc'
                                name='insuranceStartMonth'
                                type='number'
                                min='1'
                                max='12'
                                value={formValues.insuranceStartMonth}
                                onChange={(e) => handleInsuranceStartDateChange(undefined, e.target.value)}
                              />
                            </div>
                            <div className='tw-w-1/3 tw-pl-1.5'>
                              <MyTextInput
                                label='Dzień'
                                name='insuranceStartDay'
                                type='number'
                                min='1'
                                max={
                                  formValues.insuranceStartMonth && formValues.insuranceStartYear
                                    ? DateHelpers.getMonthLength(
                                        formValues.insuranceStartYear,
                                        formValues.insuranceStartMonth,
                                      ).toString()
                                    : '31'
                                }
                                onChange={(e) => handleChangeValue('insuranceStartDay', e.target.value)}
                              />
                            </div>
                          </div>
                          <p className='tw-text-lg tw-text-teal-700'>Dane właściciela pojazdu</p>
                          <div className='tw-flex'>
                            <div className='tw-w-1/2 tw-pr-1.5'>
                              <MyTextInput
                                label='Imię'
                                name='firstName'
                                onChange={(e) => handleChangeValue('firstName', e.target.value)}
                              />
                            </div>
                            <div className='tw-w-1/2 tw-pl-1.5'>
                              <MyTextInput
                                label='Nazwisko'
                                name='lastName'
                                onChange={(e) => handleChangeValue('lastName', e.target.value)}
                              />
                            </div>
                          </div>
                          <MyTextInput
                            label='PESEL'
                            name='pesel'
                            onChange={(e) => handleChangeValue('pesel', e.target.value)}
                            mask={MaskHelpers.pesel}
                          />
                          <MyTextInput
                            label='Email'
                            name='email'
                            onChange={(e) => handleChangeValue('email', e.target.value)}
                          />
                          <MyTextInput
                            label='Numer telefonu'
                            name='phone'
                            onChange={(e) => handleChangeValue('phone', e.target.value)}
                            mask={MaskHelpers.phone}
                          />
                          <p className='tw-text-lg tw-text-teal-700'>Adres właściciela pojazdu</p>
                          <div className='tw-flex'>
                            <div className='tw-w-1/2 tw-pr-1.5'>
                              <MyTextInput
                                label='Ulica'
                                name='ownerAddressStreet'
                                onChange={(e) => handleChangeValue('ownerAddressStreet', e.target.value)}
                              />
                            </div>
                            <div className='tw-w-1/4 tw-px-1.5'>
                              <MyTextInput
                                label='Numer domu'
                                name='ownerAddressHouseNumber'
                                onChange={(e) => handleChangeValue('ownerAddressHouseNumber', e.target.value)}
                              />
                            </div>
                            <div className='tw-w-1/4 tw-pl-1.5'>
                              <MyTextInput
                                label='Numer mieszkania'
                                name='ownerAddressFlatNumber'
                                onChange={(e) => handleChangeValue('ownerAddressFlatNumber', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className='tw-flex'>
                            <div className='tw-w-1/2 tw-pr-1.5'>
                              <MyTextInput
                                label='Kod pocztowy'
                                name='ownerAddressPostCode'
                                onChange={(e) => handleChangeValue('ownerAddressPostCode', e.target.value)}
                                mask={MaskHelpers.postCode}
                              />
                            </div>
                            <div className='tw-w-1/2 tw-pl-1.5'>
                              <MyTextInput
                                label='Miejscowość'
                                name='ownerAddressPlace'
                                onChange={(e) => handleChangeValue('ownerAddressPlace', e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <p className='tw-text-lg tw-text-teal-700'>Data uzyskania uprawnień do kierowania pojazdem</p>
                      <div className='tw-flex'>
                        <div className='tw-w-1/3 tw-pr-1.5'>
                          <MyTextInput
                            label='Rok'
                            name='obtainingDrivingLicenseYear'
                            type='number'
                            min='1900'
                            onChange={(e) => handleOwnerDrivingLicenseStartDateChange(e.target.value, undefined)}
                          />
                        </div>
                        <div className='tw-w-1/3 tw-px-1.5'>
                          <MyTextInput
                            label='Miesiąc'
                            name='obtainingDrivingLicenseMonth'
                            type='number'
                            min='1'
                            max='12'
                            value={formValues.obtainingDrivingLicenseMonth}
                            onChange={(e) => handleOwnerDrivingLicenseStartDateChange(undefined, e.target.value)}
                          />
                        </div>
                        <div className='tw-w-1/3 tw-pl-1.5'>
                          <MyTextInput
                            label='Dzień'
                            name='obtainingDrivingLicenseDay'
                            type='number'
                            min='1'
                            max={
                              formValues.obtainingDrivingLicenseMonth && formValues.obtainingDrivingLicenseYear
                                ? DateHelpers.getMonthLength(
                                    formValues.obtainingDrivingLicenseYear,
                                    formValues.obtainingDrivingLicenseMonth,
                                  ).toString()
                                : '31'
                            }
                            onChange={(e) => handleChangeValue('obtainingDrivingLicenseDay', e.target.value)}
                          />
                        </div>
                      </div>
                      {!isFile && (
                        <>
                          <MyCheckbox
                            label='Współwłaściciel'
                            name='isCoOwner'
                            variant='big'
                            checked={formValues.coOwners.length > 0}
                            value={formValues.coOwners.length > 0}
                            onChange={(e) => handleChangeValue('isCoOwner', e.target.checked)}
                          />
                          {formValues.coOwners.length > 0 &&
                            formValues.coOwners.map((coOwner, index) => (
                              <div key={'coOwners_' + index} className='tw-mb-4'>
                                <div className='tw-flex tw-justify-between'>
                                  <p className='tw-text-lg tw-text-teal-700'>Dane współwłaściciela pojazdu</p>
                                  <div
                                    className='cursor-pointer tw-ml-2 tw-flex tw-items-end'
                                    onClick={() => removeCoOwner(index)}>
                                    <FontAwesomeIcon color='#00635c' icon={faCircleMinus} size='2x' />
                                  </div>
                                </div>
                                <div className='tw-flex'>
                                  <div className='tw-w-1/2 tw-pr-1.5'>
                                    <MyTextInput
                                      label='Imię'
                                      name={`coOwners[${index}].firstName`}
                                      value={formValues.coOwners[index].firstName}
                                      onChange={(e) => handleCoOwnerChangeValue(`firstName`, index, e.target.value)}
                                    />
                                  </div>
                                  <div className='tw-w-1/2 tw-pl-1.5'>
                                    {' '}
                                    <MyTextInput
                                      label='Nazwisko'
                                      name={`coOwners[${index}].lastName`}
                                      value={formValues.coOwners[index].lastName}
                                      onChange={(e) => handleCoOwnerChangeValue(`lastName`, index, e.target.value)}
                                    />
                                  </div>
                                </div>

                                <MyTextInput
                                  label='PESEL'
                                  name={`coOwners[${index}].pesel`}
                                  value={formValues.coOwners[index].pesel}
                                  onChange={(e) => handleCoOwnerChangeValue(`pesel`, index, e.target.value)}
                                  mask={MaskHelpers.pesel}
                                />
                                <MyTextInput
                                  label='Email'
                                  name={`coOwners[${index}].email`}
                                  value={formValues.coOwners[index].email}
                                  onChange={(e) => handleCoOwnerChangeValue(`email`, index, e.target.value)}
                                />
                                <MyTextInput
                                  label='Numer telefonu'
                                  name={`coOwners[${index}].phone`}
                                  value={formValues.coOwners[index].phone}
                                  onChange={(e) => handleCoOwnerChangeValue(`phone`, index, e.target.value)}
                                  mask={MaskHelpers.phone}
                                />
                                <p className='tw-text-lg tw-text-teal-700'>Adres współwłaściciela</p>
                                <div className='tw-flex'>
                                  <div className='tw-w-1/2 tw-pr-1.5'>
                                    <MyTextInput
                                      label='Ulica'
                                      name={`coOwners[${index}].addressStreet`}
                                      value={formValues.coOwners[index].addressStreet}
                                      onChange={(e) => handleCoOwnerChangeValue(`addressStreet`, index, e.target.value)}
                                    />
                                  </div>
                                  <div className='tw-w-1/4 tw-px-1.5'>
                                    <MyTextInput
                                      label='Numer domu'
                                      name={`coOwners[${index}].addressHouseNumber`}
                                      value={formValues.coOwners[index].addressHouseNumber}
                                      onChange={(e) =>
                                        handleCoOwnerChangeValue(`addressHouseNumber`, index, e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className='tw-w-1/4 tw-pl-1.5'>
                                    <MyTextInput
                                      label='Numer mieszkania'
                                      name={`coOwners[${index}].addressFlatNumber`}
                                      value={formValues.coOwners[index].addressFlatNumber}
                                      onChange={(e) =>
                                        handleCoOwnerChangeValue(`addressFlatNumber`, index, e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='tw-flex'>
                                  <div className='tw-w-1/2 tw-pr-1.5'>
                                    <MyTextInput
                                      label='Kod pocztowy'
                                      name={`coOwners[${index}].addressPostCode`}
                                      value={formValues.coOwners[index].addressPostCode}
                                      onChange={(e) =>
                                        handleCoOwnerChangeValue(`addressPostCode`, index, e.target.value)
                                      }
                                      mask={MaskHelpers.postCode}
                                    />
                                  </div>
                                  <div className='tw-w-1/2 tw-pl-1.5'>
                                    <MyTextInput
                                      label='Miejscowość'
                                      name={`coOwners[${index}].addressPlace`}
                                      value={formValues.coOwners[index].addressPlace}
                                      onChange={(e) => handleCoOwnerChangeValue(`addressPlace`, index, e.target.value)}
                                    />
                                  </div>
                                </div>
                                <p className='tw-text-lg tw-text-teal-700'>
                                  Data uzyskania uprawnień do kierowania pojazdem
                                </p>
                                <div className='tw-flex'>
                                  <div className='tw-w-1/3 tw-pr-1.5'>
                                    <MyTextInput
                                      label='Rok'
                                      name={`coOwners[${index}].obtainingDrivingLicenseYear`}
                                      value={formValues.coOwners[index].obtainingDrivingLicenseYear}
                                      type='number'
                                      min='1900'
                                      onChange={(e) =>
                                        handleCoOwnerDrivingLicenseStartDateChange(index, e.target.value, undefined)
                                      }
                                    />
                                  </div>
                                  <div className='tw-w-1/3 tw-px-1.5'>
                                    <MyTextInput
                                      label='Miesiąc'
                                      name={`coOwners[${index}].obtainingDrivingLicenseMonth`}
                                      value={formValues.coOwners[index].obtainingDrivingLicenseMonth}
                                      type='number'
                                      min='1'
                                      max='12'
                                      onChange={(e) =>
                                        handleCoOwnerDrivingLicenseStartDateChange(index, undefined, e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className='tw-w-1/3 tw-pl-1.5'>
                                    <MyTextInput
                                      label='Dzień'
                                      name={`coOwners[${index}].obtainingDrivingLicenseDay`}
                                      value={formValues.coOwners[index].obtainingDrivingLicenseDay}
                                      type='number'
                                      min='1'
                                      max={
                                        formValues.coOwners[index].obtainingDrivingLicenseMonth &&
                                        formValues.coOwners[index].obtainingDrivingLicenseYear
                                          ? DateHelpers.getMonthLength(
                                              formValues.coOwners[index].obtainingDrivingLicenseYear ?? 0,
                                              formValues.coOwners[index].obtainingDrivingLicenseMonth ?? 0,
                                            ).toString()
                                          : '31'
                                      }
                                      onChange={(e) =>
                                        handleCoOwnerChangeValue(`obtainingDrivingLicenseDay`, index, e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          {formValues.coOwners.length > 0 && (
                            <div
                              className='cursor-pointer tw-my-3 tw-flex tw-items-center '
                              onClick={() => addCoOwner(formValues)}>
                              <FontAwesomeIcon color='#00635c' icon={faCirclePlus} size='2x' />
                              <div className='tw-w-2/3 tw-pl-2'>Wprowadź dane dodatkowego współwłaściciela pojazdu</div>
                            </div>
                          )}
                        </>
                      )}
                      <p className='tw-text-lg tw-text-teal-700'>Preferowany zakres ubezpieczenia</p>
                      <MyCheckbox
                        label='OC'
                        name='insuranceOc'
                        onChange={(e) => handleChangeValue('insuranceOc', e.target.checked)}
                      />
                      <MyCheckbox
                        label='AC'
                        name='insuranceAc'
                        onChange={(e) => handleChangeValue('insuranceAc', e.target.checked)}
                      />
                      <MyCheckbox
                        label='NNW'
                        name='insuranceNnw'
                        onChange={(e) => handleChangeValue('insuranceNnw', e.target.checked)}
                      />
                      <MyCheckbox
                        label='Assistance'
                        name='insuranceAssistance'
                        onChange={(e) => handleChangeValue('insuranceAssistance', e.target.checked)}
                      />
                      <MyCheckbox
                        label='Ubezpieczenie szyb'
                        name='insuranceGlassBreakage'
                        onChange={(e) => handleChangeValue('insuranceGlassBreakage', e.target.checked)}
                      />
                      <MyCheckbox
                        label='Ubezpieczenie opon'
                        name='insuranceTires'
                        onChange={(e) => handleChangeValue('insuranceTires', e.target.checked)}
                      />
                      <p className='tw-text-lg tw-text-teal-700'>Gdzie parkujesz pojazd w nocy?</p>
                      <MyRadioButton
                        name='parkingPlace'
                        value={formValues.parkingPlace}
                        options={carParkingPlaceTypeOptions}
                        column={true}
                        onChange={(e: boolean) => handleChangeValue('parkingPlace', e)}
                      />
                      {!isFile && (
                        <>
                          <p className='tw-text-lg tw-text-teal-700'>Numer rejestracyjny pojazdu</p>
                          <MyTextInput
                            name='licensePlateNumber'
                            onChange={(e) => handleChangeValue('licensePlateNumber', e.target.value)}
                          />
                          <p className='tw-text-lg tw-text-teal-700'>Numer VIN</p>
                          <MyTextInput
                            name='vinNumber'
                            onChange={(e) => handleChangeValue('vinNumber', e.target.value)}
                          />
                          <p className='tw-text-lg tw-text-teal-700'>Rodzaj skrzyni biegów?</p>
                          <MyRadioButton
                            label='Rodzaj skrzyni biegów?'
                            name='gearBoxType'
                            value={formValues.gearBoxType}
                            options={carGearBoxTypeOptions}
                            column={true}
                            onChange={(e: boolean) => handleChangeValue('gearBoxType', e)}
                          />
                        </>
                      )}
                      {!isFile && (
                        <>
                          <p className='tw-text-lg tw-text-teal-700'>
                            Marka, model, wersja wyposażenia pojazdu (opcjonalnie)
                          </p>
                          <MyTextInput
                            name='equipmentVersion'
                            onChange={(e) => handleChangeValue('equipmentVersion', e.target.value)}
                          />
                        </>
                      )}
                      <p className='tw-text-lg tw-text-teal-700'>Aktualny przebieg</p>
                      <MyTextInput
                        name='currentMileage'
                        onChange={(e) => handleChangeValue('currentMileage', e.target.value)}
                      />
                      <p className='tw-text-lg tw-text-teal-700'>Czy będzie młody użytkownik pojazdu?</p>
                      <p className='tw-text-md'>
                        osoba - inna niż właściciel/współwłaściciel, która nie ukończyła 25. roku życia / posiada
                        uprawnienia do kierowania pojazdem krócej niż 3 lata
                      </p>
                      <MyRadioButton
                        name='isYoungUser'
                        column={true}
                        value={formValues.isYoungUser}
                        options={[
                          { value: true, label: 'Tak' },
                          { value: false, label: 'Nie' },
                        ]}
                        onChange={(e: boolean) => handleChangeValue('isYoungUser', e)}
                      />
                      {formValues.isYoungUser && (
                        <>
                          <p className='tw-text-lg tw-text-teal-700'>
                            Data uzyskania uprawnień do kierowania pojazdem przez młodego użytkownika
                          </p>
                          <div className='tw-flex'>
                            <div className='tw-w-1/3 tw-pr-1.5'>
                              <MyTextInput
                                label='Rok'
                                name='youngUserObtainingDrivingLicenseYear'
                                type='number'
                                min='1900'
                                onChange={(e) =>
                                  handleYoungUserDrivingLicenseStartDateChange(e.target.value, undefined)
                                }
                              />
                            </div>
                            <div className='tw-w-1/3 tw-px-1.5'>
                              <MyTextInput
                                label='Miesiąc'
                                name='youngUserObtainingDrivingLicenseMonth'
                                type='number'
                                min='1'
                                max='12'
                                value={formValues.youngUserObtainingDrivingLicenseMonth}
                                onChange={(e) =>
                                  handleYoungUserDrivingLicenseStartDateChange(undefined, e.target.value)
                                }
                              />
                            </div>
                            <div className='tw-w-1/3 tw-pl-1.5'>
                              <MyTextInput
                                label='Dzień'
                                name='youngUserObtainingDrivingLicenseDay'
                                type='number'
                                min='1'
                                max={
                                  formValues.youngUserObtainingDrivingLicenseMonth &&
                                  formValues.youngUserObtainingDrivingLicenseYear
                                    ? DateHelpers.getMonthLength(
                                        formValues.youngUserObtainingDrivingLicenseYear,
                                        formValues.youngUserObtainingDrivingLicenseMonth,
                                      ).toString()
                                    : '31'
                                }
                                onChange={(e) =>
                                  handleChangeValue('youngUserObtainingDrivingLicenseDay', e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <p className='tw-text-lg tw-text-teal-700'>Data urodzenia młodego użytkownika</p>
                          <div className='tw-flex'>
                            <div className='tw-w-1/3 tw-pr-1.5'>
                              <MyTextInput
                                label='Rok'
                                name='youngUserBirthDateYear'
                                value={formValues.youngUserBirthDateYear}
                                type='number'
                                min='1900'
                                onChange={(e) => handleYoungUserBirthDateDateChange(e.target.value, undefined)}
                              />
                            </div>
                            <div className='tw-w-1/3 tw-px-1.5'>
                              <MyTextInput
                                label='Miesiąc'
                                name='youngUserBirthDateMonth'
                                type='number'
                                min='1'
                                max='12'
                                onChange={(e) => handleYoungUserBirthDateDateChange(undefined, e.target.value)}
                              />
                            </div>
                            <div className='tw-w-1/3 tw-pl-1.5'>
                              <MyTextInput
                                label='Dzień'
                                name='youngUserBirthDateDay'
                                type='number'
                                min='1'
                                max={
                                  formValues.youngUserBirthDateMonth && formValues.youngUserBirthDateYear
                                    ? DateHelpers.getMonthLength(
                                        formValues.youngUserBirthDateYear,
                                        formValues.youngUserBirthDateMonth,
                                      ).toString()
                                    : '31'
                                }
                                onChange={(e) => handleChangeValue('youngUserBirthDateDay', e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <MyTextArea
                        label='Masz uwagi?'
                        placeholder='Wpisz je tutaj...'
                        rows={5}
                        name='comments'
                        onChange={(e) => handleChangeValue('comments', e.target.value)}
                      />
                      <div className='d-flex justify-content-center'>
                        <Button type='submit' disabled={isSubmitting} variant='primary'>
                          {isSubmitting ? (
                            <>
                              <Spinner animation='border' size='sm' />
                              Wysyłanie...
                            </>
                          ) : (
                            'Wyślij'
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </>
      )}
    </>
  );
});
