import React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { Image } from 'react-bootstrap';

export default observer((props: { text: string }) => {
  return (
    <div className={'d-flex flex-column align-items-center my-2 gap-3'}>
      <Image src='/assets/birthdayCodesOrange.svg' style={{ height: '90ox' } as React.CSSProperties} />
      <div className={'mb-0'} style={styles.headingTextStyle}>
        {props.text}
      </div>
    </div>
  );
});
