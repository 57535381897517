export const container = {
  backgroundColor: 'white',
  borderRadius: '36px',
  color: 'black',
  boxShadow: '0px 12px 30px #0000001a',
  WebkitBoxShadow: '0px 12px 30px #0000001a',
  height: '100%',
};
export const containerSecond = {
  height: '80px',
  width: '100%',
  borderRadius: '36px 36px 0px 0px',
  backgroundImage: 'linear-gradient(to right,#00635c, #0bc268)',
  display: 'flex',
  justifyContent: 'center',
};

export const Loan = {
  marginBottom: 0,
  alignSelf: 'center',
  color: '#fff',
  fontSize: '32px',
  fontFamily: 'Poppins',
  lineHeight: '42px',
};

export const flexCenter = { display: 'flex', justifyContent: 'center' };

export const sizeTopRevert = {
  fontSize: '100px',
  color: '#0BC268',
  marginTop: 'revert',
};

export const currency = {
  fontSize: '24px',
  color: '#00635C',
  display: 'flex',
  justifyContent: 'center',
  margin: 'revert',
};

export const wayStyles = {
  fontSize: '36px',
  color: '#00635C',
  display: 'flex',
  justifyContent: 'center',
  margin: 'revert',
  fontWeight: '300',
};

export const widthCenter = {
  width: '65%',
  margin: '0 auto',
  textAlign: 'center',
};

export const flexCenterAccount = {
  fontSize: '16px',
  color: '#00635C',
  display: 'flex',
  justifyContent: 'center',
  margin: 'revert',
};

export const flexPadding = {
  display: 'flex',
  justifyContent: 'center',
  padding: '15px',
};

export const width = { width: '90%' };
