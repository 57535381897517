import { Accordion, Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { myMarginTop } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { DiscountCodeOrderDataM } from '../../../types/discountCode';
import { toast } from 'react-toastify';
import FileHelper from '../../../helpers/FileHelper';

export default observer(() => {
  const { discountCodeStore, fileStore } = useStore();
  const [orders, setOrders] = useState<DiscountCodeOrderDataM[]>([]);
  const [currentMonthSum, setCurrentMonthSum] = useState<number | undefined>(undefined);
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    discountCodeStore.getUserDiscountCodeOrders().then((resp) => {
      setOrders(resp);
      setOrdersForCurrentMonth(resp);
    });
  }, []);

  const setOrdersForCurrentMonth = (orders: DiscountCodeOrderDataM[]) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const currentMonthOrders = orders.filter(
      (x) => new Date(x.orderDate).getMonth() === currentMonth && new Date(x.orderDate).getFullYear() === currentYear,
    );
    let total = 0;
    for (const item of currentMonthOrders) {
      total += item.amount;
    }
    setCurrentMonthSum(total);
  };

  const handleContractPdf = (id: string) => {
    if (!downloading) {
      setDownloading(true);
      fileStore
        .getDiscountCodeOrderPdf(id)
        .then((resp) => {
          setDownloading(false);
          const fileName = 'umowa_zamowienia' + id + '.pdf';
          FileHelper.downloadFileFromBlob(resp, fileName);
        })
        .catch(() => {
          setDownloading(false);
          toast.error('Wystąpił błąd przy pobieraniu pliku');
        });
    }
  };
  return (
    <Accordion alwaysOpen={true} style={myMarginTop}>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          Moje zamówienia
          <FontAwesomeIcon color='#0BC268' icon={faCaretRight} size='2x' />
        </Accordion.Header>
        <Accordion.Body style={styles.accordionBody}>
          <div>
            {orders != null &&
              orders.length > 0 &&
              orders.map((order) => (
                <div key={order.id} onClick={() => handleContractPdf(order.id)} style={styles.orderBox}>
                  <div style={styles.orderHeading}>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} size='2x' style={styles.orderText} />
                    <div style={styles.orderText}>
                      <div style={styles.orderTitle}>Pobierz PDF zamówienia</div>
                      <div style={styles.orderDetails}>z dn. {order.orderDate}</div>
                    </div>
                  </div>
                  <div style={styles.orderAmount}>{order.amount} zł</div>
                </div>
              ))}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      {currentMonthSum != undefined && (
        <Card>
          <Card.Body>
            <div style={styles.displayBetween as React.CSSProperties}>
              <p style={styles.font as React.CSSProperties}>Suma wydatków w tym miesiącu</p>
              <p style={styles.fontHigh as React.CSSProperties}>{currentMonthSum} zł</p>
            </div>
          </Card.Body>
        </Card>
      )}
    </Accordion>
  );
});
