import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { Image, OverlayTrigger, TooltipProps } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import BirthdayCodeHeader from '../../../components/BirthdayCode/BirthdayCodeHeader/BirthdayCodeHeader';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { history } from '../../../index';
import {
  BirthdayCodeCampaignM,
  BirthdayCodeCampaignOrderM,
  BirthdayCodeVerificationStatus,
} from '../../../types/birthdayCode';
import { DiscountCodeContractStatusE } from '../../../enums/discountCodeContractStatus.enums';
import LoadingComponentPartial from '../../../components/Loadings/LoadingComponentPartial/LoadingComponentPartial';
import { ocDirectorCampaign, specialCampaignsCodes } from '../birthdayCodesLib';
import { text14Bold } from '../../../theme/fonts';
import FileHelper from '../../../helpers/FileHelper';
import { BirthdayCodesCoffeeVoucherCampaign } from '../../../enums/birthdayCodesCampaign.enums';
import useSWR from 'swr';
import getUrls from '../../../api/getUrls';
import BirthdayCodesOcDirectorStatement from '../BirthdayCodesOcDirectorStatement/BirthdayCodesOcDirectorStatement';
import { useSwrAgent } from '../../../api/useSwrAgent';

export default observer(() => {
  const { discountCodeStore, modalStore } = useStore();
  const [loadingCampaignCode, setLoadingCampaignCode] = useState<string>('');
  const { data: birthdayCodeCampaigns, isLoading: isBirthdayCodeCampaignsLoading } = useSWR<BirthdayCodeCampaignM[]>(
    getUrls.BirthdayCodes.getUserBirthdayCodeCampaigns,
  );
  const { data: contractStatus, isLoading: isContractStatusLoading } = useSWR<number>(
    getUrls.DiscountCodes.getUserDiscountCodeContractStatus,
  );

  const { data: userMarketingData, isLoading } = useSwrAgent().Users.GetUserMarketingData();

  const isCampaignAvailable = (campaign: BirthdayCodeCampaignM) => {
    return (
      campaign.available ||
      campaign.hoverText ||
      campaign.verificationStatus === BirthdayCodeVerificationStatus.InProgress
    );
  };

  const isCampaignClickable = (campaign: BirthdayCodeCampaignM) => {
    return campaign.available && !campaign.hoverText;
  };
  const hasCoffeeVoucher = () => {
    const coffeeVoucherAvailableCodes = birthdayCodeCampaigns?.filter((x) =>
      BirthdayCodesCoffeeVoucherCampaign.some((y) => y == x.campaignCode),
    );
    return coffeeVoucherAvailableCodes != undefined && coffeeVoucherAvailableCodes?.length > 0;
  };
  const handleOnBirthdayCodeCampaignClick = (birthdayCampaignCodeM: BirthdayCodeCampaignM) => {
    if (birthdayCampaignCodeM.hoverText || !birthdayCampaignCodeM.available) {
      return;
    }
    if (
      specialCampaignsCodes.includes(birthdayCampaignCodeM.campaignCode) &&
      birthdayCampaignCodeM.verificationStatus === BirthdayCodeVerificationStatus.NotSent
    ) {
      history.push(PathRoute.BIRTHDAY_CODE_FOR_FREE);
      return;
    }
    if (birthdayCampaignCodeM.taken) {
      history.push(
        contractStatus === DiscountCodeContractStatusE.Active ? PathRoute.DISCOUNT_CODE : PathRoute.MY_BIRTHDAY_CODES,
      );
      return;
    }
    if (birthdayCampaignCodeM.campaignCode == ocDirectorCampaign) {
      modalStore.openModal(
        <BirthdayCodesOcDirectorStatement birthdayCampaignCode={birthdayCampaignCodeM} />,
        'lg',
        'Potwierdzenie',
      );
      return;
    }

    const birthdayCampaignOrderModel: BirthdayCodeCampaignOrderM = {
      campaignCode: birthdayCampaignCodeM.campaignCode,
      amount: birthdayCampaignCodeM.amount,
      policyId: birthdayCampaignCodeM.policyId,
      plate: birthdayCampaignCodeM.plate,
    };
    setLoadingCampaignCode(birthdayCampaignCodeM.campaignCode);
    discountCodeStore
      .orderBirthdayCode(birthdayCampaignOrderModel)
      .then(() => {
        history.push(PathRoute.MY_BIRTHDAY_CODES);
      })
      .finally(() => setLoadingCampaignCode(''));
  };

  const renderTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>,
    width: number,
    hoverText?: string,
  ) => {
    if (!hoverText) {
      return <></>;
    }
    return (
      <div
        ref={props.ref}
        style={{
          ...(styles.tooltipFirstContainerStyles as React.CSSProperties),
          ...props.style,
          width: width - 10,
        }}>
        <div style={styles.tooltipSecondContainerStyles as React.CSSProperties}>{hoverText}</div>
      </div>
    );
  };

  const getCampaignDivId = (birthdayCampaignCodeM: BirthdayCodeCampaignM) => {
    return `birthday-code-campaign${birthdayCampaignCodeM.campaignCode}`;
  };

  return (
    <>
      <LoadingComponent visible={isContractStatusLoading || isBirthdayCodeCampaignsLoading} />
      <div style={styles.container as React.CSSProperties}>
        <BirthdayCodeHeader />
        <div style={styles.bodyContainer as React.CSSProperties}>
          {/*
            <p style={styles.headingTextStyles as React.CSSProperties}>
              Przepraszamy, obecnie E-bony do Biedronki są niedostępne. Zapraszamy wkrótce.
            </p>
          */}
          <div style={styles.benefitsStyles as React.CSSProperties}>
            <p style={styles.headingTextStyles as React.CSSProperties}>
              Urodzinowe okazje od NAU.
              <br />
              Sprawdzaj promocje!
            </p>
          </div>
          <div className='row'>
            {birthdayCodeCampaigns?.map((item) => (
              <OverlayTrigger
                delay={{ show: 50, hide: 50 }}
                key={item.campaignCode}
                overlay={(props) =>
                  renderTooltip(
                    props,
                    document.getElementById(getCampaignDivId(item))?.clientWidth ?? 0,
                    item.hoverText,
                  )
                }
                placement='bottom'>
                <div
                  className={`col-12 col-md-6 col-lg-3 p-0 ${isCampaignClickable(item) && 'cursor-pointer'}`}
                  id={getCampaignDivId(item)}
                  key={item.campaignCode}
                  style={!isCampaignAvailable(item) ? styles.containerUnavailableStyles : {}}>
                  <div
                    onClick={() => handleOnBirthdayCodeCampaignClick(item)}
                    style={styles.firstContainerStyles as React.CSSProperties}>
                    <div style={styles.secondContainerStyles as React.CSSProperties}>
                      {loadingCampaignCode === item.campaignCode && (
                        <div
                          className={'position-absolute w-100 h-100 d-flex align-items-center justify-content-center'}>
                          <LoadingComponentPartial variant={'light'} />
                        </div>
                      )}
                      <div className={'d-flex justify-content-between align-items-center'}>
                        <Image src='/assets/birthdayCodes.svg' style={styles.imageStyles as React.CSSProperties} />
                        {(item.campaignCode == 'bk19' ||
                          item.campaignCode == 'bk20' ||
                          item.campaignCode == 'bk21') && (
                          <div style={styles.textCardStyles as React.CSSProperties}>Kawa gratis</div>
                        )}
                        {item.amount > 0 &&
                          item.campaignCode != 'bk19' &&
                          item.campaignCode != 'bk20' &&
                          item.campaignCode != 'bk21' && (
                            <div style={styles.textCardStyles as React.CSSProperties}>{item.amount} zł</div>
                          )}
                      </div>
                      <div
                        className={'d-flex justify-content-end'}
                        style={styles.descriptionCardStyles as React.CSSProperties}>
                        {item.description}
                      </div>
                    </div>
                  </div>
                </div>
              </OverlayTrigger>
            ))}
          </div>
          <div style={styles.lowerTextStyles as React.CSSProperties}>
            <p style={styles.bolderTextStyles as React.CSSProperties}>Świętuj z NAU cały rok!</p>
            <p style={styles.normalTextStyles as React.CSSProperties}>
              Bądź na bieżąco z urodzinową ofertą. <br /> Co miesiąc pojawi się nowa promocja.
            </p>

            <div>
              <p
                className={'cursor-pointer'}
                onClick={() =>
                  FileHelper.downloadFile(PathRoute.BIRTHDAY_CODES_TERMS_OC_TEACHER, 'Ebony-na-urodziny.pdf')
                }
                style={text14Bold as React.CSSProperties}>
                Regulamin promocji
              </p>
              <p
                className={'cursor-pointer'}
                onClick={() =>
                  FileHelper.downloadFile(PathRoute.BIRTHDAY_CODES_TERMS_OC_DIRECTOR, 'Ebony-na-urodziny-Dyrektor.pdf')
                }
                style={text14Bold as React.CSSProperties}>
                Regulamin promocji dla dyrektora
              </p>

              {userMarketingData?.isTeacherPromo && (
                <p
                  className={'cursor-pointer'}
                  onClick={() =>
                    FileHelper.downloadFile(PathRoute.PROMO_TEACHER_TERMS_PDF, 'Regulamin-Promocji-Nauczyciel-VIP.pdf')
                  }
                  style={text14Bold as React.CSSProperties}>
                  Regulamin promocji Nauczyciel VIP
                </p>
              )}
            </div>

            {hasCoffeeVoucher() && (
              <div>
                <p
                  className={'cursor-pointer'}
                  onClick={() =>
                    FileHelper.downloadFile(PathRoute.BIRTHDAY_CODES_TERMS_REFUEL, 'Regulamin-tankuj-i-zyskaj.pdf')
                  }
                  style={text14Bold as React.CSSProperties}>
                  Regulamin promocji tankuj i zyskaj
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});
