import { articleAxios } from './agent';
import useSWR from 'swr';
import { ArticleModel } from '../store/articlesStore/articlesStore';
import getUrls from './getUrls';

export default function useSwrArticlesAxios() {
  return useSWR<ArticleModel[]>(getUrls.Article.getArticle, (url: string) =>
    articleAxios.get<ArticleModel[]>(url).then((res) => res.data),
  );
}
