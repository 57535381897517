import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import LogoHeader from '../../../components/LogoHeader/LogoHeader';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { Button } from 'react-bootstrap';
import useQuery from '../../../hooks/useQuery';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import CinemaVoucherOrderSuccess from '../../../components/CinemaVoucher/CinemaVoucherOrderSuccess/CinemaVoucherOrderSuccess';
import KlThankYouPage from '../../Kl/ThankYouPage/KlThankYouPage';

export default observer(() => {
  const { paymentsStore } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCinemaVoucher, setIsCinemaVoucher] = useState<boolean>(false);
  const [isKl, setIsKl] = useState<boolean>(false);

  const query: any = useQuery();
  const orderId = query.get('OrderID');
  useEffect(() => {
    if (orderId) {
      paymentsStore
        .checkOrderCategory(orderId)
        .then((resp) => {
          if (resp === 1) setIsCinemaVoucher(true);
          else if (resp === 2) setIsKl(true);
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error);
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <>
      <LoadingComponent visible={isLoading} />
      {isCinemaVoucher ? (
        <CinemaVoucherOrderSuccess fromPayment={true} paymentOrderId={orderId} />
      ) : isKl ? (
        <KlThankYouPage />
      ) : (
        <div className='col-md-6 offset-md-3'>
          <div
            style={
              {
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
              } as React.CSSProperties
            }>
            <LogoHeader />
          </div>
          <h3 style={{ color: 'teal', textAlign: 'left' }}>Podziękowanie po płatności!</h3>
          <p style={{ color: 'teal', textAlign: 'left' }}>Numer zamówienia {orderId}.</p>
          <Button
            onClick={() => window.open(PathRoute.APP, '_self', 'location=no')}
            className='btn btn-full-width mt-3'>
            Powrót do aplikacji
          </Button>
        </div>
      )}
    </>
  );
});
