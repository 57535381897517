import * as React from 'react';
import { Button, Image } from 'react-bootstrap';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import { history } from '../../../index';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';

const handleConfirm = async () => {
  history.push(PathRoute.PUSTY);
};
const ProgressLoan = () => (
  <CustomerDashboard>
    <div style={styles.flexColumnContainer as React.CSSProperties}>
      <div style={styles.flexContainer as React.CSSProperties}>
        <Image
          onClick={() => history.goBack()}
          src='assets/arrow-Left.svg'
          style={styles.iconTransform as React.CSSProperties}
        />
        <div style={styles.flexWidthColumn as React.CSSProperties}>
          <label style={styles.labelStyle as React.CSSProperties}>Wypłata od ręki</label>
          <p style={styles.selfStyles as React.CSSProperties}>Trwa weryfikacja dokumentów</p>
        </div>
      </div>
      <Image src='assets/clock.svg' style={styles.heightTop as React.CSSProperties} />
      <p style={styles.textStyle as React.CSSProperties}>
        {' '}
        Oczekuj na SMS o pozytywnej weryfikacji i aktywację <strong>Wypłaty od ręki</strong>
      </p>
      <Button onClick={handleConfirm} style={styles.top as React.CSSProperties}>
        Rozumiem
      </Button>
    </div>
  </CustomerDashboard>
);

export default ProgressLoan;
