import * as React from 'react';
import * as styles from './styles';
import { Button, Image } from 'react-bootstrap';
import Slider from '../../Slider/Slider';
import loansStore from '../../../store/loanStore/loansStore';

export function GetViewForLimitBiggerhanZero(
  loansStore: loansStore,
  loading: boolean,
  value: number,
  setShowValueInput: React.Dispatch<React.SetStateAction<boolean>>,
  showValueInput: boolean,
  handleValueOnBlur: (e: any) => void,
  handleChangeInputRange: (e: any) => void,
  paymentValue: string,
  sendLoanApplication: () => void,
  handleValueOnChange: (e: any) => void,
): React.ReactNode {
  return (
    <div>
      <div style={styles.containerFirstSlider as React.CSSProperties}>
        <p style={styles.Loan as React.CSSProperties}>
          Miesięczna wysokość limitu Wypłaty od ręki. {loansStore.limitLoan} zł{' '}
        </p>
      </div>

      <p style={styles.availableText as React.CSSProperties}>dostępne</p>
      <div>
        <p style={styles.limitValue as React.CSSProperties}>
          {!loading ? loansStore.reaminingLimitLoan : ''}
          <span style={styles.currencyStyles as React.CSSProperties}>PLN</span>
        </p>
      </div>
      <div style={styles.secondContainerSlider as React.CSSProperties}>
        <div
          style={
            {
              width: `${Number((value * 100) / (loansStore.reaminingLimitLoan - 100))}%`,
              ...styles.limitBackground,
            } as React.CSSProperties
          }
        />
        <Image
          onClick={() => setShowValueInput(!showValueInput)}
          src='/assets/pen.svg'
          style={styles.imagePen as React.CSSProperties}
        />
        <div style={styles.valueLimit as React.CSSProperties}>
          {showValueInput ? (
            <input
              id='loanValueInput'
              max={loansStore.reaminingLimitLoan}
              min={100}
              onBlur={handleValueOnBlur}
              onChange={handleValueOnChange}
              style={styles.valueInput}
              type='number'
              value={value}
            />
          ) : (
            <p>{value}</p>
          )}
        </div>
        <p style={styles.limitCurrency as React.CSSProperties}>PLN</p>
        <Image src='/assets/money.svg' style={styles.moneySvg as React.CSSProperties} />
      </div>
      <Slider max={loansStore.reaminingLimitLoan} min={100} onChange={handleChangeInputRange} value={value} />
      <div style={styles.heightContainer as React.CSSProperties}>
        <div style={styles.displayCenter as React.CSSProperties}>
          opłata za wypłatę
          <div>&nbsp;</div>
          <p style={styles.currencyFont as React.CSSProperties}> {paymentValue} </p>
          <div>&nbsp;</div>
          PLN
        </div>
        <div>&nbsp;</div>
        <Image src={'/assets/arrowRightCash.svg'} style={styles.flexHeight as React.CSSProperties} />
      </div>
      <div style={styles.displayJustify as React.CSSProperties}>
        <Button onClick={() => sendLoanApplication()} style={styles.widthButton as React.CSSProperties}>
          Wypłać
        </Button>
      </div>
    </div>
  );
}
