import { observer } from 'mobx-react-lite';
import React from 'react';
import { openLink } from '../../utils/linkUtils';
import { Image } from 'react-bootstrap';
import { twMerge } from '../../index';
import PropertyInsuranceExternalPage from './PropertyInsuranceExternalPage';

export default observer(() => {
  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const isScrolledCheck = window.scrollY > 0;
      setIsScrolled(isScrolledCheck);
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={twMerge(
          'tw-ease tw-fixed tw-left-0 tw-right-0 tw-top-0  tw-z-20 tw-hidden tw-min-h-[111px] tw-items-center tw-justify-between tw-bg-white tw-px-8 tw-transition-all tw-duration-300 sm:tw-flex',
          isScrolled && 'tw-min-h-[82px] tw-rounded-b-[30px] tw-shadow-[0_12px_30px_#0000001a]',
        )}>
        <div>
          <div className={'tw-cursor-pointer'} onClick={() => openLink('https://nau.pl/')}>
            <Image
              alt='logo'
              className={twMerge(
                'tw-ease tw-h-[50px]  tw-transition-all tw-duration-300',
                isScrolled && 'sm:tw-h-[40px]',
              )}
              src='/assets/logo.svg'
            />
          </div>
        </div>
        <div className={''}>
          <span
            className='btn btn-primary btn-full-width'
            onClick={() => openLink('https://nau.pl/produkt/dom-mieszkanie/', false)}>
            Powrót
          </span>
        </div>
      </div>
      <div
        className={twMerge(
          'tw-ease tw-fixed tw-left-0 tw-right-0 tw-top-0  tw-z-20 tw-flex tw-min-h-[82px] tw-items-center tw-justify-between tw-bg-white tw-px-4 tw-shadow-[0_12px_30px_#0000001a] sm:tw-hidden',
        )}>
        <div>
          <div className={'tw-cursor-pointer'} onClick={() => openLink('https://nau.pl/')}>
            <Image
              alt='logo'
              className={twMerge('tw-ease tw-h-[40px]  tw-transition-all tw-duration-300')}
              src='/assets/logo.svg'
            />
          </div>
        </div>
        <div className={''}>
          <span
            className='btn btn-primary btn-full-width'
            onClick={() => openLink('https://nau.pl/produkt/dom-mieszkanie/', false)}>
            Powrót
          </span>
        </div>
      </div>
      <div className={twMerge('tw-ease tw-pt-[50px] tw-transition-all tw-duration-300', isScrolled && 'tw-pt-[20px]')}>
        <PropertyInsuranceExternalPage />
      </div>
    </>
  );
});
