import React from 'react';
import { observer } from 'mobx-react-lite';

export default observer(() => {
  return (
    <div className={'tw-p-4 tw-flex tw-justify-center'}>
      <div className={'tw-text-nau-green-dark tw-max-w-md tw-text-[22px] tw-leading-snug tw-text-center'}>
        Kup kupony do kin w atrakcyjnych cenach i ciesz się seansem z najbliższymi! W ofercie Multikino, Cinema City i
        Helios na terenie całej Polski
      </div>
    </div>
  );
});
