export default class BoolToString {
  static ToString(boolValue: boolean | null): string {
    let returnString = 'false';

    if (boolValue == true) {
      returnString = 'true';
    }

    return returnString;
  }
  static ToBool(stringValue: string): boolean | null {
    let returnValue: boolean | null = null;

    if (stringValue.toLowerCase().replace(' ', '') == 'true') {
      returnValue = true;
    }
    if (stringValue.toLowerCase().replace(' ', '') == 'false') {
      returnValue = false;
    }
    return returnValue;
  }
}
