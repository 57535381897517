export const containerStationStyles = {
  display: 'grid',
  alignItems: 'center',
  flexDirection: 'column',
  maxHeight: '440px',
  overflow: 'auto',
};

export const displayFlexStyles = {
  display: 'flex',
  justifyContent: 'space-evenly',
};

export const accordionMain = {
  margin: '5px',
  width: '100%',
};

export const accordionHeaderStyles = {
  color: 'red',
  fontSize: '12px !important',
};

export const textStyles = {
  margin: 0,
  marginLeft: '10px',
};

export const accordionBodyStyles = {
  display: 'flex',
  alignItems: 'baseline',
};

export const accordionTextStyles = {
  marginLeft: '5px',
  fontSize: '12px',
  color: 'grey',
};

export const textPStyles = {
  fontSize: '12px',
  color: 'grey',
  marginLeft: '5px',
};
