import { CASHBACK_POLICY_DATA } from '../../constants/sessionStorageKeys';
import { Tooltip } from 'react-tooltip';
import * as React from 'react';
import { useState } from 'react';
import { ICashbackSopPolicyGroupDto } from '../../types/cashback';

export default function CashbackPromoBox() {
  const [cashbackPolicyData, setCashbackPolicyData] = useState<string | undefined>(
    sessionStorage.getItem(CASHBACK_POLICY_DATA) ?? undefined,
  );

  const cashbackPolicyDataObject: ICashbackSopPolicyGroupDto | undefined = cashbackPolicyData
    ? JSON.parse(cashbackPolicyData)
    : undefined;

  return (
    <>
      {!!cashbackPolicyData && (
        <div className={'tw-flex tw-justify-center'}>
          <div
            className={
              'tw-mb-4 tw-flex tw-items-center tw-justify-between tw-gap-2 tw-rounded-xl tw-border-4 tw-border-nau-green-light tw-bg-white tw-p-2 tw-leading-snug'
            }>
            <div>
              Link zwrotu składki dla polisy{' '}
              <strong className={'tw-text-nau-green-dark'}>{cashbackPolicyDataObject?.policyNumber}</strong> aktywny
            </div>

            <Tooltip id='tooltip-cashback-promo-code-trash' />
          </div>
        </div>
      )}
    </>
  );
}
