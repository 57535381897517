import * as React from 'react';
import * as styles from './styles';
import Checkbox from '../../Checkbox/Checkbox';

export function GetFirstApprove(
  firstApprove: boolean,
  handleChangeFirstApprove: (e: any) => void,
  handlePdf: () => void,
) {
  return (
    <div className='col-12 ' style={styles.flexCenterContent as React.CSSProperties}>
      <Checkbox checked={firstApprove} handleChange={handleChangeFirstApprove} id={''} text='  ' type={''} />
      <span>
        {' '}
        Oświadczam, że zapoznałem/ am się z{' '}
        <a onClick={() => handlePdf()} style={styles.hrefLink as React.CSSProperties}>
          Formularzem Informacyjnym
        </a>{' '}
        oraz akceptuję jego warunki. <span style={{ color: 'red' }}>*</span>
      </span>
    </div>
  );
}
