export const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
};

export const container = {
  borderRadius: '10px',
  background: '#fff',
} as React.CSSProperties;

export const textStyle = {
  fontSize: '16px',
  color: '#00635C',
  marginBottom: '10px',
  lineHeight: '1.5',
} as React.CSSProperties;

export const headingTextStyle = {
  fontSize: '20px',
  color: '#00635C',
  fontWeight: 600,
  marginBottom: '10px',
  lineHeight: '1.2',
  paddingBottom: '20px',
} as React.CSSProperties;

export const representativeExample = {
  fontSize: '12px',
  lineHeight: '16px',
  color: '$color-gray-100',
  marginTop: '10px',
  textAlign: 'justify',
} as React.CSSProperties;
