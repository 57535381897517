import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../store/store';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { toast } from 'react-toastify';
import { mainContainer } from './styles';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';

export default observer(() => {
  const { userStore } = useStore();
  return (
    <CustomerDashboard>
      <div className='col-md-6 offset-md-3'>
        <Formik
          enableReinitialize
          initialValues={{
            error: null,
          }}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            userStore
              .sendVerificationEmail()
              .then(() => {
                toast.success('Pomyślnie wysłano email.');
                setSubmitting(false);
                history.push(PathRoute.PUSTY);
              })
              .catch((error: any) => {
                setErrors({ error });
                setSubmitting(false);
              });
          }} // == {error} shourtcut
        >
          {({ handleSubmit, isSubmitting, errors, isValid }) => (
            <Form autoComplete='off' className='ui form error' onSubmit={handleSubmit} style={mainContainer}>
              <h3 style={{ color: 'teal', textAlign: 'left' }}>Weryfikacja email</h3>
              <br />
              <p>Aby potwierdzić poprawność Twojego adresu email kliknij w link, który otrzymasz w wiadomości email.</p>
              <ErrorMessage
                name='error'
                render={() => <label style={{ marginBottom: 10, color: 'red' }}>{errors.error}</label>}
              />
              {isSubmitting ? (
                <Button className='btn-full-width mt-3' disabled={!isValid || isSubmitting} type='submit'>
                  <span className='m-1'>
                    <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                  </span>
                  Wysyłanie…
                </Button>
              ) : (
                <Button className='btn-full-width mt-3' disabled={!isValid || isSubmitting} type='submit'>
                  Wyślij email weryfikacyjny
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </CustomerDashboard>
  );
});
