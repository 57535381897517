import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function FuelIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      data-name='Group 201'
      height='20'
      id='Group_201'
      viewBox='0 0 20 20'
      width='20'>
      <defs>
        <clipPath id='clip-path'>
          <rect data-name='Rectangle 176' fill='currentColor' height='20' id='Rectangle_176' width='20' />
        </clipPath>
      </defs>
      <g clipPath='url(#clip-path)' data-name='Group 200' id='Group_200'>
        <path
          d='M13.575,17.675a.614.614,0,0,1,.175.45v1.25a.6.6,0,0,1-.625.625H.625a.614.614,0,0,1-.45-.175A.614.614,0,0,1,0,19.375v-1.25a.614.614,0,0,1,.175-.45.614.614,0,0,1,.45-.175h12.5a.614.614,0,0,1,.45.175M20,5.977v8.71a2.719,2.719,0,0,1-.918,2.09,2.756,2.756,0,0,1-2.207.723,2.635,2.635,0,0,1-1.777-.977,3,3,0,0,1-.723-1.992V13.437a1.537,1.537,0,0,0-1.563-1.562H12.5V16.25H1.25V2.5A2.4,2.4,0,0,1,1.992.742,2.4,2.4,0,0,1,3.75,0H10a2.4,2.4,0,0,1,1.758.742A2.4,2.4,0,0,1,12.5,2.5V10h.312a3.466,3.466,0,0,1,3.438,3.437v1.25a.938.938,0,1,0,1.875,0V8.4A2.18,2.18,0,0,1,16.8,7.675,2.054,2.054,0,0,1,16.25,6.25V3.828L14.765,2.343a.581.581,0,0,1-.155-.43.58.58,0,0,1,.155-.428l.47-.47a.672.672,0,0,1,.858,0L19.258,4.18A2.446,2.446,0,0,1,20,5.977M10,2.5H3.75v5H10Z'
          data-name='Path 3325'
          fill='currentColor'
          id='Path_3325'
        />
      </g>
    </svg>
  );
}
