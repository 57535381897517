import { FormikErrors } from 'formik';

export const getFieldErrorNames = (formikErrors: FormikErrors<unknown>): string[] => {
  const transformObjectToDotNotation = (obj: Record<string, any>, prefix = '', result: string[] = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};
