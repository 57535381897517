import * as React from 'react';
import * as styles from './styles';
import { GetThirdApprove } from './ThirdApprove';
import { SecondApprove } from './SecondApprove';
import { GetFirstApprove } from './FirstApprove';
import loansStore from '../../../store/loanStore/loansStore';

export function GetApprovals(
  firstApprove: boolean,
  handleChangeFirstApprove: (e: any) => void,
  handlePdf: () => void,
  secondApprove: boolean,
  handleChangeSecondApprove: (e: any) => void,
  loansStore: loansStore,
  thirdApprove: boolean,
  handleChangeThirdApprove: (e: any) => void,
  handleContractPdf: () => void,
  totalAmount: string,
) {
  return (
    <div className='row' style={styles.autoInfo as React.CSSProperties}>
      {GetFirstApprove(firstApprove, handleChangeFirstApprove, handlePdf)}
      {SecondApprove(secondApprove, handleChangeSecondApprove, handleContractPdf)}
      {loansStore.availableBenefitIsInstitutional
        ? GetThirdApprove(thirdApprove, handleChangeThirdApprove, totalAmount, loansStore.reaminingDays)
        : null}
    </div>
  );
}
