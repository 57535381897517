import { makeAutoObservable } from 'mobx';
import agent from '../../api/agent';

export default class CashbackStore {
  constructor() {
    makeAutoObservable(this);
  }
  getCashbackPoliciesByCashbackHash = async (cashbackHash: string) => {
    try {
      return await agent.Cashback.getCashbackPoliciesByCashbackHash(cashbackHash);
    } catch (e) {
      console.error(e);
    }
  };

  activateCashbackBenefit = async () => {
    try {
      return await agent.Cashback.activateCashbackBenefit();
    } catch (e) {
      console.error(e);
    }
  };
}
