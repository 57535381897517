export enum UserActivityCodeE {
  Login = 1,
  Register = 2,
  CircleKCardContractSign = 3,
  WalletNauContractApplication = 4,
  CompareCarInsurancePrice = 5,
  DiscountCodeContractSign = 6,
  OrderDiscountCode = 7,
  OpenLoanNauLink = 8,
  OpenNauMobileLink = 9,
}
