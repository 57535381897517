export enum DiscountCodeContractStatusE {
  NewNotCreated = 0,
  New = 1,
  Waiting = 2,
  Active = 3,
  Rejected = 4,
  Blocked = 5,
  Canceled = 6,
}

export const DiscountCodeNewContractStatuses = [
  DiscountCodeContractStatusE.NewNotCreated,
  DiscountCodeContractStatusE.New,
];
