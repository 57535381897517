import * as React from 'react';
import * as styles from './styles';

function MyFooterContact(props: { fixed?: boolean }) {
  return (
    <div style={(props.fixed ? styles.containerFooterFixed : styles.containerFooter) as React.CSSProperties}>
      <div style={styles.footerDisplay as React.CSSProperties}>
        <p style={styles.infoText as React.CSSProperties}> Infolinia</p>
      </div>
      <div style={styles.displayNumber}>
        <strong>
          <a className={'tw-text-2xl tw-text-nau-green-light'} href='tel:22 696 72 70'>
            22 696 72 70
          </a>
        </strong>
      </div>
    </div>
  );
}

export default MyFooterContact;
