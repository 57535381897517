import * as React from 'react';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import DiscountCodeHeader from '../DiscountCodeHeader/DiscountCodeHeader';
import ProgressDiscountCodeContractBody from './ProgressDiscountCodeContractBody';

const ProgressDiscountCodeContract = () => (
  <div>
    <div className='col-md-6 offset-md-3' style={styles.container}>
      <DiscountCodeHeader />
      <ProgressDiscountCodeContractBody></ProgressDiscountCodeContractBody>
    </div>
  </div>
);

export default ProgressDiscountCodeContract;
