import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';
import {
  AddCinemaVoucherToOrderM,
  CinemaVoucherCompaniesM,
  CinemaVoucherData,
  CinemaVoucherOrderClientDataM,
  CinemaVoucherTypeM,
  RateCinemaVouchersOrderM,
} from '../../types/cinemaVoucher';
import { UserAvailableBenefits } from '../../types/user';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import { toast } from 'react-toastify';
import { CinemaVoucherOrderPromo } from '../../enums/cinemaVoucherOrderPromo.enum';

export default class CinemaVoucherStore {
  reaminingLimitCinemaVoucher = 0;
  reaminingDays = 0;
  limitCinemaVoucher = 0;
  cinemaVoucherAmount = 0;
  hasCinemaVoucherPermission = false;
  contractStatus: number | null = null;
  isBlocked = false;
  hasInstitutionalBenefit = false;
  availableBenefits: UserAvailableBenefits[] = [];
  availableBenefitId: number | null = null;
  availableBenefitIsInstitutional: boolean | null = null;
  cinemaVoucherContractId: number | null = null;
  userCinemaVouchers: CinemaVoucherData[] = [];
  hasApprovals = false;
  cinemaVoucherCartQuantity = 0;
  companies: CinemaVoucherCompaniesM[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  reset = () => {
    this.reaminingLimitCinemaVoucher = 0;
    this.reaminingDays = 0;
    this.limitCinemaVoucher = 0;
    this.cinemaVoucherAmount = 0;
    this.hasCinemaVoucherPermission = false;
    this.isBlocked = false;
    this.hasInstitutionalBenefit = false;
    this.availableBenefits = [];
    this.availableBenefitId = null;
    this.availableBenefitIsInstitutional = null;
    this.cinemaVoucherContractId = null;
    this.userCinemaVouchers = [];
  };
  get getCinemaVoucherCartQuantity() {
    return this.cinemaVoucherCartQuantity;
  }
  setApprovals = async (hasApprovals: boolean) => {
    runInAction(() => {
      this.hasApprovals = hasApprovals;
    });
  };
  setCompanies = async (companies: CinemaVoucherCompaniesM[]) => {
    runInAction(() => {
      this.companies = companies;
      let quantity = 0;
      companies.forEach((element) => {
        element.types.forEach((type) => {
          quantity += type.quantity;
        });
      });
      this.cinemaVoucherCartQuantity = quantity;
    });
  };
  checkUserHasPermission = async () => {
    try {
      const hasAcceptedContract = false;
      runInAction(() => {
        this.hasCinemaVoucherPermission = hasAcceptedContract;
      });
    } catch (e) {
      console.error(e);
    }
  };

  getAvailableCinemaVoucherTypes = async (): Promise<CinemaVoucherCompaniesM[]> => {
    try {
      return agent.CinemaVouchers.getAvailableCinemaVoucherTypes();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  addCinemaVouchersToOrder = async (order: AddCinemaVoucherToOrderM): Promise<number> => {
    try {
      return agent.CinemaVouchers.addCinemaVouchersToOrder(order);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  orderCinemaVouchers = async (request: number): Promise<string> => {
    try {
      return agent.CinemaVouchers.orderCinemaVouchers(request);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getCinemaVoucherOrderData = async (id: string) => {
    try {
      return agent.CinemaVouchers.getCinemaVoucherOrderData(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserCinemaVoucherOrders = async () => {
    try {
      return agent.CinemaVouchers.getUserCinemaVoucherOrders();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  requestCinemaVoucherOrderAuthorizationToken = async (id: string) => {
    try {
      return agent.CinemaVouchers.requestCinemaVoucherOrderAuthorizationToken(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  verifyCinemaVoucherOrder = async (id: string) => {
    try {
      return agent.CinemaVouchers.verifyCinemaVoucherOrder(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  rateCinemaVouchersOrder = async (rateCinemaVouchersOrderM: RateCinemaVouchersOrderM) => {
    try {
      return agent.CinemaVouchers.rateCinemaVouchersOrder(rateCinemaVouchersOrderM);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  saveCinemaVouchersOrderClientData = async (cinemaVoucherOrderClientDataM: CinemaVoucherOrderClientDataM) => {
    try {
      return agent.CinemaVouchers.saveCinemaVouchersOrderClientData(cinemaVoucherOrderClientDataM);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserCinemaVoucherPersonalData = async () => {
    try {
      return agent.CinemaVouchers.getUserCinemaVoucherPersonalData();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  checkUserHasCinemaVoucherPersonalData = async () => {
    try {
      return agent.CinemaVouchers.checkUserHasCinemaVoucherPersonalData();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  downloadVoucherFile = async (id: number) => {
    try {
      return agent.CinemaVouchers.downloadVoucherFile(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserCinemaVouchers = async () => {
    try {
      return agent.CinemaVouchers.getUserCinemaVouchers();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  changeCinemaVoucherUsed = async (id: number, used: boolean) => {
    try {
      return agent.CinemaVouchers.changeCinemaVoucherUsed(id, used);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  checkCinemaVoucherIsAvailableForUser = async () => {
    try {
      return agent.CinemaVouchers.checkCinemaVoucherIsAvailableForUser();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  addCinemaVoucherAvailabilityNotification = async (cinemaCompanyId: number) => {
    try {
      await agent.CinemaVouchers.addCinemaVoucherAvailabilityNotification(cinemaCompanyId);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  checkCinemaVoucherOrderStatus = async (id: number) => {
    try {
      return agent.CinemaVouchers.checkCinemaVoucherOrderStatus(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  sendOrder = async (notRedirectAfter?: boolean, promoId?: number): Promise<number> => {
    return new Promise((resolve, reject) => {
      const types: CinemaVoucherTypeM[] = [];
      this.companies.forEach((company) => {
        company.types.forEach((type) => {
          if (type.quantity > 0) {
            types.push(type);
          }
        });
      });
      const order: AddCinemaVoucherToOrderM = {
        orderId: undefined,
        vouchers: types,
      };
      this.addCinemaVouchersToOrder(order)
        .then((orderId) => {
          if (promoId == CinemaVoucherOrderPromo.InstitutionDirectorPromo) {
            agent.CinemaVouchers.orderDirectorPromoCinemaVouchers(orderId)
              .then(() => {
                history.push(PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_ORDER_SUMMARY);
                resolve(orderId);
              })
              .catch((error) => {
                toast.error(error);
                reject();
              });
          } else if (promoId == CinemaVoucherOrderPromo.InstitutionDirectorTeachersDayPromo) {
            agent.CinemaVouchers.orderTeachersDayDirectorPromoCinemaVouchers(orderId)
              .then(() => {
                history.push(PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_ORDER_SUMMARY);
                resolve(orderId);
              })
              .catch((error) => {
                toast.error(error);
                reject();
              });
          } else if (!notRedirectAfter) {
            this.checkUserHasCinemaVoucherPersonalData().then((resp) => {
              resolve(orderId);
              if (resp) {
                history.push(PathRoute.CINEMA_VOUCHER_ORDER_SUMMARY + '/' + orderId);
              } else {
                history.push(PathRoute.CINEMA_VOUCHER_ORDER_CLIENT_DATA + '/' + orderId);
              }
            });
          } else {
            resolve(orderId);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
