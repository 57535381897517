import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';
import { TripInsuranceFormValues } from '../../../types/tripInsurance';
import { OptionValues } from '../../FormControls/MyRadioButton/types';
import { LeadCampaignType } from '../../../types/leads';

export const tripRoofTypeOptions: OptionValues[] = [
  { value: 'dachówka ceramiczna/cementowa', label: 'dachówka ceramiczna/cementowa' },
  { value: 'eternit', label: 'eternit' },
  { value: 'papa/bitumiczne/ondulina', label: 'papa/bitumiczne/ondulina' },
  { value: 'blacha/blachodachówka', label: 'blacha/blachodachówka' },
  { value: 'drewno/drewnopodobne', label: 'drewno/drewnopodobne' },
  { value: 'inne tworzywa/materiały sztuczne', label: 'inne tworzywa/materiały sztuczne' },
  { value: 'trzcina/słoma', label: 'trzcina/słoma' },
];

export const tripWallTypeOptions: OptionValues[] = [
  { value: 'Murowane', label: 'Murowane' },
  { value: 'Drewniane', label: 'Drewniane' },
];

export const photoVoltaicInstallationPlaceOptions: OptionValues[] = [
  { value: 'dach budynku mieszkalnego', label: 'dach budynku mieszkalnego' },
  { value: 'dach budynku gospodarczego', label: 'dach budynku gospodarczego' },
  { value: 'ziemia', label: 'ziemia' },
];

export const initialValues: TripInsuranceFormValues = {
  campaignType: LeadCampaignType.Trip,
  campaignTypeHumanName: 'Ubezpieczenie turystyczne',
  insuranceStartYear: undefined,
  insuranceStartMonth: undefined,
  insuranceStartDay: undefined,
  insuranceEndYear: undefined,
  insuranceEndMonth: undefined,
  insuranceEndDay: undefined,
  firstName: '',
  lastName: '',
  pesel: '',
  email: '',
  phone: '',
  country: '',
  addressStreet: '',
  addressHouseNumber: '',
  addressFlatNumber: '',
  addressPostCode: '',
  addressPlace: '',
  sportExtension: undefined,
  diseaseExtension: undefined,
  coInsuredPersons: [],
  comments: '',
};
export const validationSchema = Yup.object()
  .shape({
    campaignType: Yup.string().required('Wymagany jest rodzaj kampanii'),
    insuranceStartYear: Yup.number().required('Wymagany jest rok wyjazdu'),
    insuranceStartMonth: Yup.number().required('Wymagany jest miesiąc wyjazdu'),
    insuranceStartDay: Yup.number().required('Wymagany jest dzień wyjazdu'),
    insuranceEndYear: Yup.number().required('Wymagany jest rok powrotu'),
    insuranceEndMonth: Yup.number().required('Wymagany jest miesiąc powrotu'),
    insuranceEndDay: Yup.number().required('Wymagany jest dzień powrotu'),
    firstName: Yup.string().required('Wymagane jest imię'),
    lastName: Yup.string().required('Wymagane jest nazwisko'),
    pesel: Yup.string()
      .required('Pole jest wymagane')
      .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
      .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
    email: Yup.string().email('Nieprawidłowy adres email').required('Wymagany jest adres email'),
    phone: Yup.string()
      .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
      .required('Pole jest wymagane'),
    addressStreet: Yup.string().required('Wymagana jest ulica nieruchomości'),
    addressHouseNumber: Yup.string().required('Wymagany jest numer domu nieruchomości'),
    addressFlatNumber: Yup.string(),
    addressPostCode: Yup.string().required('Wymagany jest kod pocztowy nieruchomości'),
    addressPlace: Yup.string().required('Wymagana jest miejscowość nieruchomości'),
    sportExtension: Yup.boolean(),
    diseaseExtension: Yup.boolean(),
    coInsuredPersons: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required('Wymagane jest imię współubezpieczonego'),
        lastName: Yup.string().required('Wymagane jest nazwisko współubezpieczonego'),
        pesel: Yup.string()
          .required('Pole jest wymagane')
          .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
          .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
      }),
    ),
    comments: Yup.string(),
  })
  .test('is-future-start-date', 'Data wyjazdu nie może być wcześniejsza niż dzisiaj', function (values) {
    const { insuranceStartYear, insuranceStartMonth, insuranceStartDay } = values;
    if (insuranceStartYear && insuranceStartMonth && insuranceStartDay) {
      const startDate = new Date(insuranceStartYear, insuranceStartMonth - 1, insuranceStartDay);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (startDate < today) {
        return this.createError({
          path: 'insuranceStartDay',
          message: 'Data wyjazdu nie może być wcześniejsza niż dzisiaj',
        });
      }
    }
    return true;
  })
  .test('is-future-end-date', 'Data powrotu nie może być wcześniejsza niż dzisiaj', function (values) {
    const {
      insuranceEndYear,
      insuranceEndMonth,
      insuranceEndDay,
      insuranceStartYear,
      insuranceStartMonth,
      insuranceStartDay,
    } = values;

    if (insuranceEndYear && insuranceEndMonth && insuranceEndDay) {
      const endDate = new Date(insuranceEndYear, insuranceEndMonth - 1, insuranceEndDay);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (endDate < today) {
        return this.createError({
          path: 'insuranceEndDay',
          message: 'Data powrotu nie może być wcześniejsza niż dzisiaj',
        });
      }
      if (insuranceStartYear && insuranceStartMonth && insuranceStartDay) {
        const startDate = new Date(insuranceStartYear, insuranceStartMonth - 1, insuranceStartDay);
        if (endDate < startDate) {
          return this.createError({
            path: 'insuranceEndDay',
            message: 'Data powrotu nie może być wcześniejsza od daty wyjazdu',
          });
        }
      }
    }

    return true;
  });
