import { FormikErrors, useFormikContext } from 'formik';
import React from 'react';
import MySelectInput from '../../../../components/FormControls/MySelectInput/MySelectInput';
import MyTextInput from '../../../../components/FormControls/MyTextInput/MyTextInput';
import { CircleKCardFormValues, CircleKContractFormValues } from '../../../../types/circleK';
import { cardCompanyArray, limitCardArray } from '../../../../helpers/ArraySelect';
import { Button } from 'react-bootstrap';
import _ from 'lodash';

export default function CardDataFormPart(props: { idx: number; maxLimit?: number }) {
  const formikContext = useFormikContext<CircleKContractFormValues>();
  const { values, setFieldValue } = formikContext;
  const saveCard = (addNew: boolean) => {
    try {
      formikContext.validateForm().then((err) => {
        if (err.cards != undefined && err.cards[props.idx] != null) {
          if (typeof err.cards[props.idx] == 'object') {
            const errors = err.cards[props.idx] as FormikErrors<CircleKCardFormValues>;
            if (errors.licensePlateNumber) {
              formikContext.setFieldError(`cards[${props.idx}].licensePlateNumber`, errors.licensePlateNumber);
              formikContext.setFieldTouched(`cards[${props.idx}].licensePlateNumber`);
            }
            if (errors.limit) {
              formikContext.setFieldTouched(`cards.${props.idx}.limit`);
              formikContext.setFieldError(`cards.${props.idx}.limit`, errors.limit);
            }
            if (errors.companyId) {
              formikContext.setFieldError(`cards[${props.idx}].companyId`, errors.companyId);
              formikContext.setFieldTouched(`cards[${props.idx}].companyId`);
            }
          }
        } else {
          let cardsCopy = _.cloneDeep(values.cards);
          cardsCopy[props.idx].confirmed = true;
          if (addNew) {
            if (cardsCopy == null) {
              cardsCopy = [];
            }
            cardsCopy.push({
              limit: 0,
              licensePlateNumber: '',
              companyId: 1,
              confirmed: false,
            });
          }
          setFieldValue('cards', cardsCopy);
        }
      });
    } catch (error) {
      return false;
    }
  };

  const removeCard = () => {
    if (values.cards != null) {
      const filtered = values.cards.filter((card, idx) => idx !== props.idx);
      setFieldValue('cards', filtered);
    }
  };

  return (
    <div>
      <div
        style={
          {
            marginTop: '20px',
            marginBottom: '20px',
            padding: '15px',
            border: '2px solid #c7d3d0',
            borderRadius: '10px',
          } as React.CSSProperties
        }>
        {values.versionNumber > 1 && (
          <div
            style={
              {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              } as React.CSSProperties
            }>
            <span>{values.cards[props.idx].confirmed ? <b>Karta {props.idx + 1}</b> : <b>Nowa karta</b>}</span>
            {values.cards.length > 1 && (
              <button
                onClick={() => removeCard()}
                style={{ background: 'none', color: 'red', border: 'none', fontWeight: 600 } as React.CSSProperties}
                type='button'>
                - Usuń kartę
              </button>
            )}
          </div>
        )}
        <div style={values.cards[props.idx].confirmed ? { display: 'none' } : {}}>
          <div className={'d-flex flex-column gap-2'}>
            {values.versionNumber > 1 ? (
              <MySelectInput
                label='Dostawca karty'
                name={`cards[${props.idx}].companyId`}
                options={cardCompanyArray}
                placeholder='Dostawca karty'
                value={values.cards[props.idx].companyId}
              />
            ) : (
              <MyTextInput
                hidden={true}
                name={`cards[${props.idx}].companyId`}
                placeholder='Dostawca karty'
                value={1}
              />
            )}
            <MyTextInput label={'Numer rejestracyjny pojazdu'} name={`cards.${props.idx}.licensePlateNumber`} />
            <MySelectInput
              className='inputNumberHideArrow'
              label={'Limit'}
              name={`cards[${props.idx}].limit`}
              options={limitCardArray.filter((x) => props.maxLimit == undefined || x.value <= props.maxLimit)}
              placeholder={'limit'}
              value={formikContext.values.cards[props.idx].limit}
            />
          </div>
          {values.versionNumber > 1 && (
            <div
              style={
                {
                  width: '100%',
                  textAlign: 'right',
                  marginTop: '10px',
                } as React.CSSProperties
              }>
              <Button onClick={() => saveCard(false)} variant='primary'>
                Zapisz dane karty
              </Button>
            </div>
          )}
        </div>
        <div style={!values.cards[props.idx].confirmed ? { display: 'none' } : {}}>
          <div>
            <b>Nr rejestracyjny:</b> {values.cards[props.idx].licensePlateNumber}
          </div>
          <div>
            <b>Limit:</b> {values.cards[props.idx].limit}
          </div>
          <div>
            <b>Dostawca karty:</b> {cardCompanyArray.find(company => company.value === values.cards[props.idx].companyId)?.label}
          </div>
        </div>
      </div>
      {values.versionNumber > 1 && values.cards.length - 1 == props.idx && (
        <div
          style={
            {
              width: '100%',
              textAlign: 'left',
              marginTop: '10px',
            } as React.CSSProperties
          }>
          <Button
            onClick={() => {
              saveCard(true);
            }}
            variant='primary'>
            Dodaj kolejną kartę +
          </Button>
        </div>
      )}
    </div>
  );
}
