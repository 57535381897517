import { SportCardCompany } from '../enums/sportCardCompany.enums';

export const SportCardCompanies = [
  {
    company: SportCardCompany.PZU,
    label: 'PZU Sport',
    logoUrl: '/assets/sportCard/pzu_sport.png',
  },
  {
    company: SportCardCompany.Medicover,
    label: 'Medicover Sport',
    logoUrl: '/assets/sportCard/medicover.png',
  },
  {
    company: SportCardCompany.MultiSport,
    label: 'Multisport',
    logoUrl: '/assets/sportCard/multisport.png',
  },
] as const;
