import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect } from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PathRoute } from '../../constants/pathRoute/Route';
import { useStore } from '../../store/store';
import LogoHeader from '../LogoHeader/LogoHeader';
import MyFooterContact from '../MyFooterContact/MyFooterContact';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import * as styles from './styles';

export default observer(() => {
  const { userStore } = useStore();
  useEffect(() => {
    userStore.verifyToken();
  }, []);
  return (
    <Formik
      initialValues={{ token: '', error: null }}
      onSubmit={(values, { setErrors }) =>
        userStore.authorizeToken(values.token).catch((error) => setErrors({ error: error }))
      }>
      {({ handleSubmit, isSubmitting, errors: { error } }) => (
        <Form autoComplete='off' className='ui form col-md-6 offset-md-3' onSubmit={handleSubmit}>
          <div style={styles.mainContainer as React.CSSProperties}>
            <LogoHeader />
          </div>
          <h2 style={styles.titleHeader as React.CSSProperties}>
            Witaj ! Ostatni krok do korzystania z naszego serwisu !
          </h2>
          <Col xs='auto'>
            <Link className='fs--1 mb-0' style={styles.linkText as React.CSSProperties} to={PathRoute.LOGIN}>
              Zaloguj się
            </Link>
          </Col>
          <MyTextInput name='token' placeholder='Hasło z tokena SMS' />
          <ErrorMessage name='error' render={() => <label style={styles.labelError}>{error}</label>} />
          {isSubmitting ? (
            <Button className='btn-full-width mt-3' type='submit'>
              <span className='m-1'>
                <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
              </span>
              Wysyłam…
            </Button>
          ) : (
            <Button className='btn-full-width mt-3' type='submit'>
              Wyślij
            </Button>
          )}
          <MyFooterContact />
        </Form>
      )}
    </Formik>
  );
});
