import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { UserInstitution } from '../../../types/user';
import { Form, Formik } from 'formik';
import * as styles from './styles';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';

export default observer((props: { institutionId: number; loanPaymentDateId: number }) => {
  const { userStore, modalStore, loansStore } = useStore();
  const [, setUserInst] = useState<UserInstitution | null>(null);

  useEffect(() => {
    const userInst = userStore.getUserInstitution();
    setUserInst(userInst);
  }, [userStore]);
  const handleClose = () => modalStore.closeModal();
  return (
    <Formik
      initialValues={{
        error: null,
      }}
      //validationSchema={ChangeLimitSchema}
      onSubmit={(_, { setSubmitting }) => {
        loansStore
          .deleteLoanPaymentDate(props.institutionId, props.loanPaymentDateId)
          .then(() => {
            setSubmitting(false);
            toast.success('Data została usunięta.');
            handleClose();
          })
          .catch(() => {
            setSubmitting(false);
          });
      }}>
      {({ handleSubmit, isSubmitting }) => (
        <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
          <div>
            <p
              style={_.merge(
                {},
                styles.marginBottomZero as React.CSSProperties,
                styles.applicationText as React.CSSProperties,
                { lineHeight: 'normal' },
              )}>
              <span>Czy na pewno chcesz usunąć datę spłaty?</span>
            </p>
          </div>

          <ButtonsSubmitCancel
            buttonTextCancel='Nie'
            buttonTextSubmit='Tak'
            cancelHandler={handleClose}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
});
