import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { PaymentTabsE } from '../../enums/paymentTabs.enums';
import stylesScss from './styles.module.scss';
import { UserPayment } from '../../types/payments';
import PaymentsList from './PaymentsList/PaymentsList';
import AllPaymentsPaid from './AllPaymentsPaid/AllPaymentsPaid';
import { useSwrAgent } from '../../api/useSwrAgent';

export default observer(() => {
  const [activeTab, setActiveTab] = useState<number>(PaymentTabsE.ToPay);

  const { data: userPayments, isLoading } = useSwrAgent().Payments.GetUserPayments();
  const paymentsToPay = userPayments?.filter((payment) => !payment.settled) ?? [];
  const paymentsPaid = userPayments?.filter((payment) => payment.settled) ?? [];

  const getPaymentsToShow = (): UserPayment[] => {
    return activeTab === PaymentTabsE.ToPay ? paymentsToPay : paymentsPaid;
  };

  const renderContent = () => {
    if (activeTab === PaymentTabsE.ToPay && paymentsToPay.length === 0) {
      return <AllPaymentsPaid />;
    } else if (activeTab === PaymentTabsE.Paid && paymentsPaid.length === 0) {
      return <div className={'d-flex justify-content-center'}>Brak wpisów</div>;
    } else {
      return <PaymentsList paymentsList={getPaymentsToShow()} paymentTabType={activeTab} />;
    }
  };

  return (
    <CustomerDashboard>
      <LoadingComponent visible={isLoading} />
      <div>
        <div className='col-md-6 offset-md-3'>
          <div className={stylesScss.tabsBox}>
            <div>
              <h1 style={{ color: '#fff', textAlign: 'center' } as React.CSSProperties}>Płatności</h1>
            </div>
            <div className={stylesScss.tabsMenuContainer}>
              <span
                className={activeTab == PaymentTabsE.ToPay ? stylesScss.activeTabLink : stylesScss.tabLink}
                onClick={() => setActiveTab(PaymentTabsE.ToPay)}>
                Do zapłaty
              </span>
              <span
                className={activeTab == PaymentTabsE.Paid ? stylesScss.activeTabLink : stylesScss.tabLink}
                onClick={() => setActiveTab(PaymentTabsE.Paid)}>
                Zapłacone
              </span>
            </div>
          </div>
          <div className={stylesScss.paymentsListContainer}>{renderContent()}</div>
        </div>
      </div>
    </CustomerDashboard>
  );
});
