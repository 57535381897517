import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { PathRoute } from '../../constants/pathRoute/Route';
import * as styles from './styles';

export default observer(() => (
  <NavLink className='logo-header' to={PathRoute.PUSTY}>
    <Image alt='logo' className='logo' src='/assets/logo.svg' style={styles.marginImage} />
  </NavLink>
));
