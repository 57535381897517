import * as React from 'react';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import DiscountCodeHeader from '../DiscountCodeHeader/DiscountCodeHeader';

const handleConfirm = async () => {
  history.push(PathRoute.PUSTY);
};
const DiscountCodeTechnicalBreak = () => (
  <div>
    <div className='col-md-6 offset-md-3' style={styles.container}>
      <DiscountCodeHeader />
      <div style={styles.flexCenter as React.CSSProperties}>
        <div style={styles.flexColumnContainer as React.CSSProperties}>
          <Image src='assets/clock.svg' style={styles.heightTop as React.CSSProperties} />
          <p style={styles.selfStyles as React.CSSProperties}>
            Przepraszamy trwa aktualizacja systemu dystrybucji e-bonów
          </p>
          <Button onClick={handleConfirm} style={styles.top as React.CSSProperties}>
            Strona główna
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default DiscountCodeTechnicalBreak;
