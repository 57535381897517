import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './styles.module.scss';
import { Image } from 'react-bootstrap';
import { history, twMerge } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import _ from 'lodash';

export interface INewBenefitTileProps {
  text: string;
  iconSrc?: string;
  iconComponent?: React.ReactNode;
  route?: (typeof PathRoute)[keyof typeof PathRoute];
  iconHeight?: number;
  theme: 'green' | 'orange';
  wrapperClassName?: string | undefined;
  customBody?: () => ReactNode;
  onClick?: () => void;
}
export default observer((props: INewBenefitTileProps) => {
  return (
    <div className={_.isString(props.wrapperClassName) ? props.wrapperClassName : 'col-6 col-md-3 p-0'}>
      <div
        className={twMerge(
          'tw-h-[100px] tw-rounded-xl tw-bg-gradient-to-r tw-from-nau-green-dark tw-to-nau-green-light tw-p-[4px]',
          props.theme === 'orange' && 'tw-bg-nau-orange tw-bg-none',
          props.route && 'tw-cursor-pointer',
        )}
        onClick={() => (props.onClick ? props.onClick() : props.route ? history.push(props.route) : undefined)}>
        <div
          className={twMerge(
            'tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between tw-rounded-xl tw-border-[3px] tw-border-solid tw-border-white tw-bg-gradient-to-r tw-from-nau-green-dark tw-to-nau-green-light tw-p-[7px]',
            props.theme === 'orange' && 'tw-bg-nau-orange tw-bg-none',
          )}>
          {props.customBody ? (
            props.customBody()
          ) : (
            <>
              <div>
                {props.iconSrc && <Image src={props.iconSrc} style={{ height: props.iconHeight ?? '25px' }} />}
                {props.iconComponent && <div>{props.iconComponent}</div>}
              </div>
              <div className={`d-flex justify-content-end ${styles.textCardStyles}`}>{props.text}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});
