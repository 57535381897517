import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { ErrorMessage, Form, Formik } from 'formik';
import * as styles from './styles';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import { MySelectInputOptions } from '../../FormControls/MySelectInput/types';

export default observer((props: { userId: number; institutionId: number }) => {
  const { userStore, modalStore, loansStore } = useStore();
  const [, setLoading] = useState<boolean>(false);
  const [loanPaymentDates, setloanPaymentDates] = useState<MySelectInputOptions[]>([]);
  const [userLoanPaymentDate, setUserLoanPaymentDate] = useState<number | null>(null);

  useEffect(() => {
    loansStore
      .getLoanPaymentDates(props.institutionId)
      .then((data) => {
        setloanPaymentDates(data);
        loansStore
          .getUserLoanPaymentDate(props.userId, props.institutionId)
          .then((resp) => {
            setUserLoanPaymentDate(resp);
            setLoading(false);
          })
          .catch((error) => {
            console.log(`Error in promises ${error}`);
            setLoading(false);
            toast.error('Wystąpił błąd przy pobieraniu danych');
          });
      })
      .catch((error) => {
        console.log(`Error in promises ${error}`);
        setLoading(false);
        toast.error('Wystąpił błąd przy pobieraniu danych');
      });
  }, [userStore]);
  const handleClose = () => modalStore.closeModal();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        dayOfMonth: userLoanPaymentDate,
        error: null,
      }}
      onSubmit={({ dayOfMonth }, { setSubmitting }) => {
        loansStore
          .saveUserLoanPaymentDate(Number(dayOfMonth), props.userId, props.institutionId)
          .then(() => {
            setSubmitting(false);
            toast.success('Data została zapisana.');
            handleClose();
          })
          .catch(() => {
            setSubmitting(false);
          });
      }}>
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
          <div>
            <p
              style={_.merge(
                {},
                styles.marginBottomZero as React.CSSProperties,
                styles.applicationText as React.CSSProperties,
                { lineHeight: 'normal' },
              )}>
              <span>Wybierz dzień miesiąca spłaty</span>
            </p>
            <MySelectInput
              className='inputNumberHideArrow'
              name={'dayOfMonth'}
              options={loanPaymentDates}
              placeholder={'Dzień miesiąca'}
              value={userLoanPaymentDate}
            />
            <ErrorMessage name='error' render={() => <label style={styles.labelError}>{errors.error}</label>} />
          </div>

          <ButtonsSubmitCancel
            buttonTextCancel='Anuluj'
            buttonTextSubmit='Zapisz'
            cancelHandler={handleClose}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
});
