import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import * as styles from '../UserBirthdayCodes/styles';
import { Image } from 'react-bootstrap';
import BirthdayCodeFreeCode from '../../../components/BirthdayCode/BirthdayCodeFreeCode/BirthdayCodeFreeCode';
import NauClubInfo from '../../../components/BirthdayCode/NauClubInfo/NauClubInfo';
import { useStore } from '../../../store/store';
import { BirthdayCodeCampaignM } from '../../../types/birthdayCode';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { specialCampaignsCodes } from '../birthdayCodesLib';

export default observer(() => {
  const { discountCodeStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [freeCodeCampaigns, setFreeCodeCampaigns] = useState<BirthdayCodeCampaignM[]>([]);

  useEffect(() => {
    setLoading(true);
    discountCodeStore
      .getUserBirthdayCodeCampaigns()
      .then((response) => {
        return setFreeCodeCampaigns(
          response.filter((campaign) => specialCampaignsCodes.includes(campaign.campaignCode)),
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <CustomerDashboard>
        {loading && <LoadingComponent content='Ładowanie...' />}
        <div>
          <div className='col-md-6 offset-md-3'>
            <div style={styles.tabsBox}>
              <div>
                <h1 style={styles.sectionHeader as React.CSSProperties}>
                  <Image
                    src='/assets/birthdayCodes.svg'
                    style={{ marginLeft: '5px', marginRight: '15px', height: '25px' } as React.CSSProperties}
                  />
                  Twój E-bon
                </h1>
              </div>
            </div>
          </div>
          <div className='discountCodes'>
            <div className='col-md-6 offset-md-3 birthdayCode-instruction'>
              <BirthdayCodeFreeCode accordionOpenOnStart={true} freeCodesCampaigns={freeCodeCampaigns} />
            </div>
            <div className='col-md-6 offset-md-3 discountCodes-instructions'>
              <NauClubInfo />
            </div>
          </div>
        </div>
      </CustomerDashboard>
    </>
  );
});
