import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import MyTextArea from '../../components/FormControls/MyTextArea/MyTextArea';
import { DeleteAccountFormValues } from '../../types/contactForm';
import { useStore } from '../../store/store';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { toast } from 'react-toastify';
import { heading, phoneNumber } from './styles';
import React from 'react';
import RegexHelpers from '../../helpers/RegexHelpers';
import MyTextMaskedInput from '../../components/FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../helpers/MaskHelpers';
import Header from '../../components/Header/Header';
import LogoHeader from '../../components/LogoHeader/LogoHeader';

export default observer(() => {
  const { userStore } = useStore();
  const [contactValues, setContactValues] = useState<DeleteAccountFormValues>({
    email: '',
    name: '',
    surname: '',
    phone: '',
    reason: '',
  });
  return (
    <>
      <div className='col-md-6 offset-md-3'>
        <Formik
          enableReinitialize
          initialValues={{
            email: contactValues.email,
            name: contactValues.name,
            surname: contactValues.surname,
            phone: contactValues.phone,
            reason: contactValues.reason,
            error: null,
          }}
          onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
            userStore
              .sendDeleteAccountForm(values)
              .then(() => {
                toast.success('Pomyślnie wysłano formularz.');
                setContactValues({
                  email: '',
                  name: '',
                  surname: '',
                  phone: '',
                  reason: '',
                });
                setSubmitting(false);
                resetForm();
              })
              .catch((error: any) => {
                setErrors({ error });
                setSubmitting(false);
              });
          }} // == {error} shourtcut
          validationSchema={Yup.object({
            email: Yup.string().email('Podaj poprawny adres email').required('Pole jest wymagane'),
            name: Yup.string().required('Pole jest wymagane'),
            surname: Yup.string().required('Pole jest wymagane'),
          })}>
          {({ handleSubmit, isSubmitting, errors, isValid, values }) => (
            <Form autoComplete='off' className='ui form error' onSubmit={handleSubmit}>
              <div
                style={
                  {
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  } as React.CSSProperties
                }>
                <LogoHeader />
              </div>
              <h3 style={{ color: 'teal', textAlign: 'left' }}>Wyślij prośbę o usunięcie konta</h3>
              <MyTextInput name='email' placeholder='Email' value={values ? values.email : ''} />
              <MyTextInput name='name' placeholder='Imię' value={values ? values.name : ''} />
              <MyTextInput name='surname' placeholder='Nazwisko' value={values ? values.surname : ''} />
              <MyTextMaskedInput
                mask={MaskHelpers.phone}
                name={'phone'}
                placeholder='Numer telefonu (opcjonalnie)'
                value={values ? values.phone : ''}
              />
              <MyTextArea
                name='reason'
                placeholder='Powód rezygnacji (opcjonalnie)'
                rows={4}
                value={values ? values.reason : ''}
              />
              <ErrorMessage
                name='error'
                render={() => <label style={{ marginBottom: 10, color: 'red' }}>{errors.error}</label>}
              />
              {isSubmitting ? (
                <Button className='btn-full-width mt-3' disabled={!isValid || isSubmitting} type='submit'>
                  <span className='m-1'>
                    <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                  </span>
                  Wysyłanie…
                </Button>
              ) : (
                <Button className='btn-full-width mt-3' disabled={!isValid || isSubmitting} type='submit'>
                  Wyślij
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
});
