import { observer } from 'mobx-react-lite';
import React from 'react';
import stylesScss from './styles.module.scss';
import { UserPayment } from '../../../types/payments';
import { Image } from 'react-bootstrap';
import classNames from 'classnames';
import { PaymentTabsE } from '../../../enums/paymentTabs.enums';
import { Benefit } from '../../../enums/benefits.enums';

interface IPaymentsListProps {
  paymentsList: UserPayment[];
  paymentTabType: PaymentTabsE;
}

export default observer((props: IPaymentsListProps) => {
  const getIconSrc = (payment: UserPayment) => {
    if (payment.settled) {
      return 'assets/fileCheckedGreen.svg';
    }
    if (payment.late) {
      return 'assets/fileError.svg';
    }
    return 'assets/fileCash.svg';
  };
  return (
    <>
      {props.paymentsList.map((payment) => (
        <div className={stylesScss.paymentItem} key={payment.id}>
          <div className={stylesScss.icon}>
            <Image src={getIconSrc(payment)} style={{ width: '100%' }} />
          </div>
          <div className={stylesScss.dataContainer}>
            <div
              className={classNames(
                stylesScss.data,
                props.paymentTabType === PaymentTabsE.ToPay && payment.late && stylesScss.expired,
              )}>
              <div className={stylesScss.title}>{payment.benefit}</div>
              <div className={stylesScss.description}> {payment.description}</div>
              {payment.paymentDateFormatted && (
                <div className={stylesScss.paymentDate}>Termin zapłaty: {payment.paymentDateFormatted}</div>
              )}
              {payment.bankAccountNumber && (
                <div className={stylesScss.bankAccountNumber}>
                  Nr konta bankowego do spłaty należności: {payment.bankAccountNumber}
                </div>
              )}
            </div>
            {payment.benefit == 'Karta paliwowa' && props.paymentTabType === PaymentTabsE.Paid ? (
              <div className={stylesScss.amount}>{payment.amountFormatted}</div>
            ) : (
              <div className={stylesScss.amount}>{payment.amountToPayFormatted}</div>
            )}
          </div>
        </div>
      ))}
    </>
  );
});
