import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import { useStore } from '../../store/store';
import MyFooterContact from '../MyFooterContact/MyFooterContact';
import LogoHeader from '../LogoHeader/LogoHeader';
import MaskHelpers from '../../helpers/MaskHelpers';
import { PathRoute } from '../../constants/pathRoute/Route';
import PromoCodeBox from '../PromoCode/PromoCodeBox';
import MyButton from '../Buttons/MyButton/MyButton';
import { CASHBACK_POLICY_DATA, INSTITUTION_TOKEN, PROMO_CODE_KEY } from '../../constants/sessionStorageKeys';
import agent from '../../api/agent';
import * as Yup from 'yup';
import RegexHelpers from '../../helpers/RegexHelpers';
import SetNewPassword from '../SetNewPassword/SetNewPassword';
import CashbackPromoBox from '../PromoCode/CashbackPromoBox';
import InstitutionTokenBox from '../PromoCode/InstitutionCodeBox';
import { getPathOrRedirect } from '../../utils/linkUtils';

const registerValidationSchema = Yup.object().shape({
  email: Yup.string().nullable().email('Adres email jest niepoprawny').required('Pole jest wymagane'),
  phone: Yup.string()
    .nullable()
    .matches(RegexHelpers.phoneRegExp, 'Niepoprawny format. Numer powinien zawierać 9 cyfr')
    .required('Pole jest wymagane'),
});

export default observer((props: { firstLogin?: boolean }) => {
  const { userStore } = useStore();
  const [isNewPasswordView, setIsNewPasswordView] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const cashbackPolicyData = sessionStorage.getItem(CASHBACK_POLICY_DATA);

  if (userStore.isLoggedIn) {
    const redirectPath = getPathOrRedirect(PathRoute.PUSTY);
    return <Redirect to={redirectPath} />;
  }
  return isNewPasswordView ? (
    <SetNewPassword userId={userId} variant={'registration'} />
  ) : (
    <Formik
      initialValues={{
        email: '',
        phone: '',
        promoCode: sessionStorage.getItem(PROMO_CODE_KEY) ?? undefined,
        cashbackSopPolicyDto: cashbackPolicyData ? JSON.parse(cashbackPolicyData) : undefined,
        institutionToken: sessionStorage.getItem(INSTITUTION_TOKEN) ?? undefined,
        error: undefined,
      }}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        agent.Account.register(values)
          .then((userId) => {
            setUserId(userId.toString());
            setIsNewPasswordView(true);
          })
          .catch(() => {
            setSubmitting(false);
          });
      }}
      validationSchema={registerValidationSchema}>
      {({ values, handleSubmit, isSubmitting, errors }) => (
        <Form
          autoComplete='off'
          className='tw-mx-auto tw-max-w-2xl tw-px-2'
          onSubmit={handleSubmit}
          style={{ paddingBottom: '80px', marginTop: '-2em' } as React.CSSProperties}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '8vh',
            }}>
            <LogoHeader />
          </div>
          <h2
            style={{
              color: 'teal',
              textAlign: 'center',
              marginBottom: '50px',
              marginTop: '4vh',
            }}>
            Zarejestruj się
          </h2>
          <div className={'tw-flex tw-flex-col tw-gap-2'}>
            <PromoCodeBox />
            <CashbackPromoBox />
            <InstitutionTokenBox />
          </div>
          <div className='tw-mb-4 tw-font-normal tw-text-nau-green-dark'>Załóż konto</div>
          <div className={'tw-flex tw-flex-col tw-gap-4 tw-font-normal'}>
            <MyTextInput name='email' placeholder='Adres E-mail' type={'text'} />
            <MyTextInput mask={MaskHelpers.phone} name='phone' placeholder='Telefon komórkowy' type={'text'} />
          </div>

          <ErrorMessage
            name='error'
            render={() => <label style={{ marginTop: 5, color: 'red' }}>{errors.error}</label>}
          />

          <MyButton className={'tw-mt-8 tw-w-full'} isLoading={isSubmitting} type={'submit'} variant={'primary'}>
            Zarejestruj się
          </MyButton>

          <div className='tw-flex tw-justify-center tw-pt-12'>
            {!userStore.updateRequired && (
              <Link className={'tw-text-base tw-text-nau-green-dark hover:tw-text-nau-green-dark'} to={PathRoute.LOGIN}>
                Posiadasz już konto? <span className={'tw-font-semibold'}>Zaloguj się</span>
              </Link>
            )}
          </div>
          <MyFooterContact />
        </Form>
      )}
    </Formik>
  );
});
