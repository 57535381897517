import * as React from 'react';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import DiscountCodeHeader from '../DiscountCodeHeader/DiscountCodeHeader';

const handleConfirm = async () => {
  history.push(PathRoute.PUSTY);
};
const ProgressDiscountCodeContractBody = () => (
  <div style={styles.flexColumnContainer as React.CSSProperties}>
    <p style={styles.selfStyles as React.CSSProperties}>
      Wniosek został wysłany. <br />
      Trwa weryfikacja dokumentów
    </p>
    <Image src='assets/clock.svg' style={styles.heightTop as React.CSSProperties} />
    <p style={styles.selfStyles as React.CSSProperties}>
      {' '}
      Oczekuj na SMS o pozytywnej weryfikacji i aktywację{' '}
      <strong style={styles.fontSemiBold as React.CSSProperties}>E-bonów</strong>
    </p>
    <Button onClick={handleConfirm} style={styles.top as React.CSSProperties}>
      Strona główna
    </Button>
  </div>
);

export default ProgressDiscountCodeContractBody;
