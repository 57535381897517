import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { ErrorMessage, Form, Formik } from 'formik';
import * as styles from './styles';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';
import { UserPhoneModel } from '../../../types/user';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import { UserProfileState } from '../UserProfileState';
import LoadingComponentModal from '../../Loadings/LoadingComponentModal/LoadingComponentModal';
import { Image } from 'react-bootstrap';

export default observer(
  (props: { phone: UserPhoneModel; refreshView: (userProfileState: UserProfileState, refresh: boolean) => void }) => {
    const { modalStore, userProfileStore } = useStore();
    const handleClose = () => modalStore.closeModal();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      userProfileStore
        .requestVerifyUserProfilePhone(props.phone.id)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          props.refreshView(UserProfileState.ShowPhones, false);
          toast.error('Wystąpił błąd podczas wysyłania tokenu.');
        });
    }, []);

    return (
      <div style={_.merge({}, styles.mainContainer as React.CSSProperties)}>
        {loading ? (
          <LoadingComponentModal content='Ładowanie...' />
        ) : (
          <Formik
            initialValues={{
              token: '',
              error: null,
            }}
            onSubmit={({ token }, { setErrors, setSubmitting }) => {
              userProfileStore
                .verifyUserProfilePhone({ phoneId: props.phone.id, token: token })
                .then(() => {
                  setSubmitting(false);
                  toast.success('Numer został aktywowany.');
                  props.refreshView(UserProfileState.ShowPhones, true);
                  handleClose();
                })
                .catch(() => {
                  setSubmitting(false);
                  setErrors({ error: 'Token jest nieprawidłowy.' });
                });
            }}>
            {({ handleSubmit, isSubmitting, errors, values }) => (
              <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                <div style={_.merge({}, styles.center as React.CSSProperties)}>
                  <Image className='yellowIcon' src={'/assets/mobile-screen-solid.svg'} style={styles.imageStyle} />
                  <p style={styles.title}>Aktywuj numer telefonu</p>
                </div>

                <h6 style={styles.center as React.CSSProperties}>
                  Proszę podać otrzymany token. <br /> Numer: {props.phone.phone}
                </h6>

                <MyTextInput
                  label='Token'
                  name={'token'}
                  placeholder='Token'
                  style={styles.marginBottomField}
                  value={values.token}
                />
                <ErrorMessage name='error' render={() => <label style={styles.labelError}>{errors.error}</label>} />

                <ButtonsSubmitCancel
                  buttonTextCancel='Anuluj'
                  buttonTextSubmit='Wyślij'
                  cancelHandler={handleClose}
                  isSubmitting={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  },
);
