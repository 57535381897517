export const center = {
  textAlign: 'center',
};

export const marginBottomZero = {
  marginBottom: '0px',
};

export const imageStyle = {
  width: '68pt',
  height: '68pt',
  marginBottom: '25px',
  marginTop: '15px',
};

export const actualLimitStyle = {
  marginTop: '40px',
  marginBottom: '60px',
};

export const applicationText = {
  fontSize: '16pt',
  color: '#00635C',
};

export const divSpacing = {
  margin: '15px',
};

export const labelError = {
  marginTop: 5,
  color: 'red',
};
