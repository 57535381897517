import * as React from 'react';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import WalletNauHeader from '../WalletNauHeader/DiscountCodeHeader';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';

const RejectedWalletContract = () => {
  const { userStore } = useStore();
  React.useEffect(() => {
    setTimeout(() => userStore.logout(), 10000);
  }, [userStore]);

  const handleConfirm = async () => {
    userStore.logout();
  };
  return (
    <div>
      <div className='col-md-6 offset-md-3' style={styles.container}>
        <WalletNauHeader />
        <div style={styles.flexCenter as React.CSSProperties}>
          <div style={styles.flexColumnContainer as React.CSSProperties}>
            <Image src='assets/cancel.svg' style={styles.heightTop as React.CSSProperties} />
            <p style={styles.selfStyles as React.CSSProperties}>
              Negatywny wynik weryfikacji. Twoje konto zostało zablokowane. Za chwilę zostaniesz wylogowany z aplikacji.
            </p>
            <Button onClick={handleConfirm} style={styles.top as React.CSSProperties}>
              Rozumiem
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectedWalletContract;
