export const containerBackground = {
  padding: '20px',
  backgroundColor: 'white',
  borderRadius: '36px',
  color: 'black',
  marginTop: '25px',
  boxshadow: '0px 12px 30px #0000001a',
  WebkitBoxShadow: '0px 12px 30px #0000001a',
};

export const active = {
  color: '#0bc268',
  fontWeight: 'bold',
};

export const inactive = {
  color: '#c82333',
  fontWeight: 'bold',
};

export const center = {
  textAlign: 'center',
};

export const colTextLeft = {
  textAlign: 'left',
};

export const colTextRight = {
  textAlign: 'right',
};
