import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Image } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { ErrorMessage, Form, Formik } from 'formik';
import * as styles from './styles';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';
import { UserPhoneModel } from '../../../types/user';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import MyTextMaskedInput from '../../FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { UserProfileState } from '../UserProfileState';
import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';

export default observer(
  (props: { phone: UserPhoneModel; refreshView: (userProfileState: UserProfileState, refresh: boolean) => void }) => {
    const { modalStore, userProfileStore } = useStore();
    const handleClose = () => modalStore.closeModal();

    return (
      <div style={_.merge({}, styles.divSpacing as React.CSSProperties)}>
        <Formik
          initialValues={{
            description: props.phone.description ?? '',
            phone: props.phone.phone ?? '',
            error: null,
          }}
          onSubmit={({ description, phone }, { setErrors, setSubmitting }) => {
            userProfileStore
              .editUserProfilePhone({ phoneId: props.phone.id, description: description, phoneNumber: phone })
              .then(() => {
                setSubmitting(false);
                toast.success('Dane zostały zmienione.');
                props.refreshView(UserProfileState.ShowPhones, true);
                handleClose();
              })
              .catch(() => {
                setSubmitting(false);
                setErrors({ error: 'Dane są nieprawidłowe, proszę sprawdzić ich poprawność' });
              });
          }}
          validationSchema={Yup.object().shape({
            phone: Yup.string()
              .required('Pole jest wymagane')
              .matches(RegexHelpers.phoneRegExp, 'Numer telefonu jest nieprawidłowy'),
          })}>
          {({ handleSubmit, isSubmitting, errors, values }) => (
            <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
              <div style={_.merge({}, styles.center as React.CSSProperties)}>
                <Image className='greenIcon' src={'/assets/mobile-screen-solid.svg'} style={styles.imageStyle} />
                <p style={styles.title}>Edycja numeru</p>
              </div>

              <MyTextInput
                label='Opis'
                name={'description'}
                placeholder='Opis'
                style={styles.marginBottomField}
                value={values.description}
              />
              <MyTextMaskedInput
                label='Numer telefonu'
                mask={MaskHelpers.phone}
                name={'phone'}
                placeholder='Numer telefonu'
                style={styles.marginBottomField}
                value={values.phone}
              />

              <ErrorMessage name='error' render={() => <label style={styles.labelError}>{errors.error}</label>} />
              <ButtonsSubmitCancel
                buttonTextCancel='Anuluj'
                buttonTextSubmit='Wyślij'
                cancelHandler={handleClose}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  },
);
