export const marginBottomZero = {
  marginBottom: '0px',
};

export const applicationText = {
  fontSize: '16pt',
  color: '#00635C',
};

export const divSpacing = {
  margin: '15px',
};

export const labelError = {
  marginTop: 5,
  color: 'red',
};

export const mainContainer = {
  minHeight: '200px',
};
