import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { PathRoute } from '../constants/pathRoute/Route';

export const openLink = async (link: string, newTab = true) => {
  if (Capacitor.isNativePlatform()) {
    await Browser.open({ url: link });
  } else {
    const target = newTab ? '_blank' : '_self';
    window.open(link, target, 'noreferrer');
  }
};

export const getPathOrRedirect = (path: (typeof PathRoute)[keyof typeof PathRoute]) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('redirect') || path;
};

export const getPathWithRedirectIfExists = (path: (typeof PathRoute)[keyof typeof PathRoute]) => {
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  if (redirect) {
    return `${path}?redirect=${encodeURIComponent(redirect)}`;
  }

  return path;
};
