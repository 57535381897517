import { colorOrange1 } from '../../../theme/colors';

export const containerBackground = {
  backgroundColor: 'white',
  borderRadius: '36px',
  color: 'black',
  marginTop: '25px',
  boxShadow: '0px 12px 30px #0000001a',
  WebkitBoxShadow: '0px 12px 30px #0000001a',
  height: '100%',
};

export const containerConfirm = {
  padding: '20px',
  paddingBottom: 0,
};

export const flexStart = {
  display: 'flex',
  justifyContent: 'flex-start',
};

export const confirmTitle = {
  marginBottom: 0,
  fontSize: '24px',
  lineHeight: '30px',
  color: '#00635C',
  marginTop: '2px',
  fontWeight: 400,
};

export const marginTop20 = { marginTop: '20px' };
export const marginTop10 = { marginTop: '10px' };

export const LoanAmount = {
  marginLeft: '10px',
  marginBottom: '5px',
  fontSize: '16px',
  color: '#626D80',
};

export const containerAmountTax = {
  marginLeft: '10px',
  marginTop: '10px',
  fontSize: '16px',
  color: '#00635C',
  display: 'flex',
};

export const textWeight = { fontWeight: '700' };

export const marginLeftPLN = { marginLeft: '5px' };

export const textStyle = {
  marginLeft: '10px',
  marginBottom: '5px',
  fontSize: '16px',
  color: '#626D80',
};

export const documentsContainer = {
  marginTop: '20px',
  fontSize: '14px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  rowGap: '10px',
};

export const displayFlex = { display: 'flex' };

export const heightCursor = {
  height: '23px',
  marginLeft: '10px',
  marginTop: '-2px',
  cursor: 'pointer',
};

export const flexWeightCenter = {
  display: 'flex',
  fontWeight: '100',
  justifyContent: 'center',
  marginTop: '10px',
  padding: '5px 0',
};

export const pinContainer = {
  display: 'flex',
  justifyContent: 'center',
  background: '#0BC068',
  padding: '10px',
};

export const textSMS = {
  margin: '10px 0',
  display: 'flex',
  fontWeight: '100',
  justifyContent: 'center',
  color: '#0BC268',
  cursor: 'pointer',
};

export const autoContainerHeight = {
  height: 'auto',
  width: '90%',
  borderRadius: '16px',
  border: '1px solid #0bc268',
  margin: '0 auto',
  padding: '15px',
};

export const fontColor = { fontWeight: '600', color: '#00635C' };

export const colorBottomWeight = {
  fontWeight: '300',
  color: '#626D80',
};

export const accountMember = {
  fontSize: '14px',
  fontWeight: '700',
  color: '#626D80',
};
export const widthButton = {
  width: '90%',
  fontSize: '24px',
  marginTop: '20px',
  marginBottom: '20px',
};
export const hrefLink = {
  color: 'green',
  textDecoration: 'none',
  cursor: 'pointer',
};

export const hrefLinkOrange = {
  ...hrefLink,
  color: colorOrange1,
};
