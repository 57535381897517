import * as React from 'react';
import styles from './styles.module.scss';
import { Button } from 'react-bootstrap';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { useStore } from '../../../store/store';
import { Spinner } from 'react-bootstrap/cjs';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import MyCheckbox from '../../FormControls/MyCheckbox/MyCheckbox';
import FormCheck from 'react-bootstrap/FormCheck';
import useWalletDocumentsDownloadHandlers from '../ConfirmWalletPayout/useWalletDocumentsDownloadHandlers';

interface IConfirmWalletFormProps {
  walletApplicationId: number;
  pin: string;
}
export default function ConfirmWalletApprovalsForm(props: IConfirmWalletFormProps) {
  const { walletNauStore } = useStore();
  const walletDocumentsDownloadHandlers = useWalletDocumentsDownloadHandlers();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstApproval: false,
        secondApproval: false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        walletNauStore
          .verifyWalletApplicationToken(props.walletApplicationId, props.pin)
          .then(() => {
            history.push(PathRoute.WALLET_NAU_WAY_CASH);
          })
          .catch(() => {
            setSubmitting(false);
          });
      }}
      validationSchema={Yup.object({
        firstApproval: Yup.bool().oneOf([true], 'Pole jest wymagane'),
        secondApproval: Yup.bool().oneOf([true], 'Pole jest wymagane'),
      })}>
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form autoComplete='off' className='ui form error' onSubmit={handleSubmit}>
          <div className={styles.autoInfo}>
            <div className={'d-flex flex-column'}>
              <div className='d-flex align-items-start'>
                <MyCheckbox name='firstApproval' />
                <FormCheck.Label className={styles.labelText} htmlFor={'firstApproval'}>
                  <span>
                    Oświadczam, że zapoznałem / am się z{' '}
                    <a
                      className={styles.hrefLink}
                      onClick={() =>
                        walletDocumentsDownloadHandlers.handleInformationFormPdfDownload(props.walletApplicationId)
                      }>
                      Formularzem Informacyjnym
                    </a>{' '}
                    oraz akceptuję jego warunki. <span style={{ color: 'red' }}>*</span>
                  </span>
                </FormCheck.Label>
              </div>
              {errors != undefined && errors.firstApproval && (
                <ErrorMessage
                  name={`firstApproval`}
                  render={() => <label className={styles.checkboxError}>{errors.firstApproval}</label>}
                />
              )}
            </div>
            <div className={'d-flex flex-column'}>
              <div className='d-flex align-items-start'>
                <MyCheckbox name='secondApproval' />
                <FormCheck.Label className={styles.labelText} htmlFor={'secondApproval'}>
                  <span>
                    Oświadczam, iż zapoznałem / am się z{' '}
                    <a
                      className={styles.hrefLinkOrange}
                      onClick={() =>
                        walletDocumentsDownloadHandlers.handleContractPdfDownload(props.walletApplicationId)
                      }>
                      Umową Portfela NAU
                    </a>{' '}
                    oraz akceptuję jej warunki. <span style={{ color: 'red' }}>*</span>
                  </span>
                </FormCheck.Label>
              </div>
              {errors != undefined && errors.secondApproval && (
                <ErrorMessage
                  name={`secondApproval`}
                  render={() => <label className={styles.checkboxError}>{errors.secondApproval}</label>}
                />
              )}
            </div>
            <div className={styles.formInfoText}>
              Aby potwierdzić złożenie powyższych oświadczeń oraz zawarcie Umowy Portfela NAU, wprowadź otrzymany kod
              SMS i kliknij "Zatwierdź"
            </div>
          </div>
          <div className={'d-flex justify-content-center'}>
            {isSubmitting ? (
              <Button className={styles.widthButton} disabled={true}>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Zatwierdzanie…
              </Button>
            ) : (
              <Button className={styles.widthButton} type='submit'>
                Zatwierdź
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
