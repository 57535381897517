import React, { useState } from 'react';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { useStore } from '../../store/store';
import CallBooking from '../../components/CallBooking/CallBooking';
import { LeadCampaignType } from '../../types/leads';

interface ContactFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  contactApproval: boolean;
}

export const InstitutionNotAllowedToGetSportCardsInfo = () => {
  const { modalStore } = useStore();

  const [showContactForm, setShowContactForm] = useState(false);

  const handleConversationClick = () => {
    setShowContactForm(true);
  };

  return (
    <div className='tw-mx-auto tw-max-w-md  tw-bg-white tw-p-4 '>
      {!showContactForm ? (
        <div>
          <div className='tw-mb-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
            Niestety, Twój pracodawca jeszcze nie dołączył do Kafeterii NAU.
          </div>
          <p className='tw-mb-4 tw-text-center'>
            Aby skorzystać z tego benefitu, zachęcamy do poinformowania swojego pracodawcy o możliwości przystąpienia do
            naszej oferty.
          </p>
          <div className='tw-mb-8 tw-text-center'>
            W razie pytań zapraszamy do kontaktu:
            <ul className='tw-list-inside tw-list-disc tw-text-nau-sea-green'>
              <li>
                Adres e-mail: <a href='mailto:benefity@nau.pl'>benefity@nau.pl</a>
              </li>
            </ul>
          </div>
          <div className='tw-flex tw-flex-col tw-gap-4'>
            <MyButton onClick={handleConversationClick} variant={'greenDark'}>
              Zamów rozmowę
            </MyButton>
            <MyButton
              onClick={() => {
                modalStore.closeModal();
              }}
              variant={'primary'}>
              Wróć
            </MyButton>
          </div>
        </div>
      ) : (
        <CallBooking leadCampaignType={LeadCampaignType.SportCard} />
      )}
    </div>
  );
};
