import { Link } from 'react-router-dom';
import { Button, Container, Image } from 'react-bootstrap';
import { PathRoute } from '../../constants/pathRoute/Route';

export default function EmailConfirmationSuccess() {
  return (
    <Container>
      <Link to={PathRoute.PUSTY}>
        <Image alt='logo' className='logo' src='/assets/logo.svg' style={{ marginBottom: '40px', height: '60px' }} />
      </Link>
      <div style={{ textAlign: 'center', marginBottom: '20px', fontSize: '20px' }}>
        Dziękujemy! Pomyślnie potwierdzono adres email.
      </div>
      <div className='d-flex justify-content-center'>
        <Link to={PathRoute.PUSTY}>
          <Button variant='outline-light'>Przejdź do aplikacji</Button>
        </Link>
      </div>
    </Container>
  );
}
