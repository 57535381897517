import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import MyTextMaskedInput from '../FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../helpers/MaskHelpers';
import { useStore } from '../../store/store';
import * as styles from './styles';
import { history } from '../../index';
import { FuelCardCompany } from '../../enums/fuelCardCompany.enums';

interface ISearchStationByPostCodeProps {
  fuelCardCompany: FuelCardCompany;
}
export default observer((props: ISearchStationByPostCodeProps) => {
  const { circleKStore } = useStore();

  return (
    <Formik
      initialValues={{
        postcode: '',
        city: '',
      }}
      onSubmit={({ postcode }, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        circleKStore
          .getCircleStationByPostCode(postcode)
          .then(() => {
            history.push('/stacje-blisko-domu');
          })
          .catch((error) => {
            // setErrors({ error: error });
          });
      }}>
      {({ handleSubmit, isSubmitting, dirty, errors }) => (
        <Form autoComplete='off' className='ui form error col-md-6 offset-md-3' onSubmit={handleSubmit}>
          <h4 style={styles.textCircleStyle as React.CSSProperties}>Znajdź inną stację Circle K</h4>
          <MyTextMaskedInput mask={MaskHelpers.postCode} name={'postcode'} placeholder='Kod pocztowy' />

          {isSubmitting ? (
            <Button active={dirty} className='btn-full-width mt-3' type='submit'>
              <span className='m-1'>
                <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
              </span>
              Przetwarzanie...
            </Button>
          ) : (
            <Button className='btn-full-width mt-3' type='submit'>
              Szukaj
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
});
