import { ErrorMessage, FieldArray, Form, Formik, FormikContextType } from 'formik';
import { observer } from 'mobx-react-lite';
import './style.css';
import { useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import * as styles from './styles';
import { UserProfileState } from '../UserProfileState';
import { UserProfileEditModel } from './types';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import MyCheckbox from '../../FormControls/MyCheckbox/MyCheckbox';
import { UserProfileModelResponse } from '../../../types/user';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import { Countries } from '../../../enums/countries.enums';
import { CountryEnum } from '../../../enums/country.enum';
import { AddressPromptingForm } from '../../Address/AddressPromptingForm';

export default observer(
  (props: {
    refreshView: (userProfileState: UserProfileState, refresh: boolean) => void;
    userProfile: UserProfileModelResponse;
  }) => {
    const { userProfileStore, circleKStore } = useStore();

    const ref = useRef(null);

    const setResidenceAddressAsCorrespondenceAddress = (
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    ) => {
      if (ref.current != null) {
        const userProfileModel =
          (ref.current as FormikContextType<UserProfileEditModel>).values?.userProfileModel ?? null;

        if (
          userProfileModel != null &&
          userProfileModel.userAddresses != null &&
          userProfileModel.userAddresses.length == 2
        ) {
          setFieldValue('userProfileModel.userAddresses.1.street', userProfileModel.userAddresses[0].street, true);
          setFieldValue(
            'userProfileModel.userAddresses.1.houseNumber',
            userProfileModel.userAddresses[0].houseNumber,
            true,
          );
          setFieldValue(
            'userProfileModel.userAddresses.1.flatNumber',
            userProfileModel.userAddresses[0].flatNumber,
            true,
          );
          setFieldValue('userProfileModel.userAddresses.1.postCode', userProfileModel.userAddresses[0].postCode, true);
          setFieldValue('userProfileModel.userAddresses.1.place', userProfileModel.userAddresses[0].place, true);
          setFieldValue('userProfileModel.userAddresses.1.post', userProfileModel.userAddresses[0].post, true);
          setFieldValue('userProfileModel.userAddresses.1.country', userProfileModel.userAddresses[0].country, true);
          setFieldValue('userProfileModel.userAddresses.1.commune', userProfileModel.userAddresses[0].commune, true);
          setFieldValue('userProfileModel.userAddresses.1.district', userProfileModel.userAddresses[0].district, true);
        }
      }
    };

    return (
      <>
        <h2 style={{ color: 'teal', textAlign: 'center' }}>Edycja</h2>
        <Formik<UserProfileEditModel>
          enableReinitialize={true}
          initialValues={{
            error: null,
            userProfileModel: {
              firstName:
                props.userProfile != null && props.userProfile.firstName != null ? props.userProfile.firstName : '',
              lastName:
                props.userProfile != null && props.userProfile.lastName != null ? props.userProfile.lastName : '',
              pesel: props.userProfile != null && props.userProfile.pesel != null ? props.userProfile.pesel : '',
              userAddresses: [
                {
                  id:
                    props.userProfile != null && props.userProfile.userAddresses[0].id != null
                      ? props.userProfile.userAddresses[0].id
                      : 0,
                  postCode:
                    props.userProfile != null && props.userProfile.userAddresses[0].postCode != null
                      ? props.userProfile.userAddresses[0].postCode
                      : '',
                  place:
                    props.userProfile != null && props.userProfile.userAddresses[0].place != null
                      ? props.userProfile.userAddresses[0].place
                      : '',
                  commune:
                    props.userProfile != null && props.userProfile.userAddresses[0].commune != null
                      ? props.userProfile.userAddresses[0].commune
                      : '',
                  post:
                    props.userProfile != null && props.userProfile.userAddresses[0].post != null
                      ? props.userProfile.userAddresses[0].post
                      : '',
                  street:
                    props.userProfile != null && props.userProfile.userAddresses[0].street != null
                      ? props.userProfile.userAddresses[0].street
                      : '',
                  houseNumber:
                    props.userProfile != null && props.userProfile.userAddresses[0].houseNumber != null
                      ? props.userProfile.userAddresses[0].houseNumber
                      : '',
                  flatNumber:
                    props.userProfile != null && props.userProfile.userAddresses[0].flatNumber != null
                      ? props.userProfile.userAddresses[0].flatNumber
                      : '',
                  district:
                    props.userProfile != null && props.userProfile.userAddresses[0].district != null
                      ? props.userProfile.userAddresses[0].district
                      : '',
                  country:
                    props.userProfile != null &&
                    props.userProfile.userAddresses[0].country != null &&
                    props.userProfile.userAddresses[0].country > 0
                      ? props.userProfile.userAddresses[0].country
                      : CountryEnum.Polska,
                  isCorrespondence:
                    props.userProfile != null && props.userProfile.userAddresses[0].isCorrespondence != null
                      ? props.userProfile.userAddresses[0].isCorrespondence
                      : false,
                },
                {
                  id:
                    props.userProfile != null && props.userProfile.userAddresses[1].id != null
                      ? props.userProfile.userAddresses[1].id
                      : 0,
                  postCode:
                    props.userProfile != null && props.userProfile.userAddresses[1].postCode != null
                      ? props.userProfile.userAddresses[1].postCode
                      : '',
                  place:
                    props.userProfile != null && props.userProfile.userAddresses[1].place != null
                      ? props.userProfile.userAddresses[1].place
                      : '',
                  commune:
                    props.userProfile != null && props.userProfile.userAddresses[1].commune != null
                      ? props.userProfile.userAddresses[1].commune
                      : '',
                  post:
                    props.userProfile != null && props.userProfile.userAddresses[1].post != null
                      ? props.userProfile.userAddresses[1].post
                      : '',
                  street:
                    props.userProfile != null && props.userProfile.userAddresses[1].street != null
                      ? props.userProfile.userAddresses[1].street
                      : '',
                  houseNumber:
                    props.userProfile != null && props.userProfile.userAddresses[1].houseNumber != null
                      ? props.userProfile.userAddresses[1].houseNumber
                      : '',
                  flatNumber:
                    props.userProfile != null && props.userProfile.userAddresses[1].flatNumber != null
                      ? props.userProfile.userAddresses[1].flatNumber
                      : '',
                  district:
                    props.userProfile != null && props.userProfile.userAddresses[1].district != null
                      ? props.userProfile.userAddresses[1].district
                      : '',
                  country:
                    props.userProfile != null &&
                    props.userProfile.userAddresses[1].country != null &&
                    props.userProfile.userAddresses[0].country > 0
                      ? props.userProfile.userAddresses[1].country
                      : CountryEnum.Polska,
                  isCorrespondence:
                    props.userProfile != null && props.userProfile.userAddresses[1].isCorrespondence != null
                      ? props.userProfile.userAddresses[1].isCorrespondence
                      : true,
                },
              ],
            },
          }}
          innerRef={ref}
          onSubmit={(values, { setErrors, setSubmitting, setFieldError }) =>
            userProfileStore
              .editUserProfile(values.userProfileModel)
              .then(() => {
                setSubmitting(false);
                toast.success('Zapisano dane.');
                props.refreshView(UserProfileState.ShowProfile, true);
              })
              .catch((error) => {
                if (Array.isArray(error)) {
                  error.forEach((element) => {
                    const fieldName = element.fieldName.charAt(0).toLowerCase() + element.fieldName.slice(1);
                    setFieldError(fieldName, element.message);
                  });
                } else {
                  setErrors({ error: error });
                }
                setSubmitting(false);
              })
          }
          validationSchema={Yup.object().shape({
            userProfileModel: Yup.object().shape({
              firstName: Yup.string().required('Pole jest wymagane'),
              lastName: Yup.string().required('Pole jest wymagane'),
              pesel: Yup.string()
                .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
                .test('control-sum-test', 'Nieprawidłowy PESEL', (value) =>
                  ValidationHelper.checkPeselControlSum(value),
                ),
            }),
          })}>
          {({ handleSubmit, isSubmitting, errors, values, handleChange, setFieldValue }) => (
            <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
              <div style={styles.containerBackground}>
                <Row>
                  <Col>
                    <MyTextInput
                      disabled={circleKStore.checkHasSignedContract()}
                      label='Imię'
                      name='userProfileModel.firstName'
                      onChange={handleChange}
                      value={values.userProfileModel.firstName}
                    />
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <MyTextInput
                      disabled={circleKStore.checkHasSignedContract()}
                      label='Nazwisko'
                      name='userProfileModel.lastName'
                      onChange={handleChange}
                      value={values.userProfileModel.lastName}
                    />
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <MyTextInput
                      disabled={!!props.userProfile?.pesel}
                      label='Pesel'
                      name='userProfileModel.pesel'
                      onChange={handleChange}
                      value={values.userProfileModel.pesel}
                    />
                  </Col>
                </Row>
              </div>

              <FieldArray name='userProfileModel.userAddresses'>
                {() =>
                  values.userProfileModel.userAddresses.map((address, i) => {
                    return (
                      <div key={i} style={styles.containerBackground}>
                        <MyTextInput
                          name={`userProfileModel.userAddresses.${i}.id`}
                          type='hidden'
                          value={address.id.toString()}
                        />
                        <MyCheckbox
                          checked={address.isCorrespondence}
                          hidden={true}
                          name={`userProfileModel.userAddresses.${i}.isCorrespondence`}
                          type='hidden'
                        />

                        <div style={styles.marginBottomField}>
                          <p>{address.isCorrespondence ? 'Adres do korespondencji' : 'Adres zamieszkania'}</p>
                        </div>

                        {address.isCorrespondence ? (
                          <Button
                            onClick={() => {
                              setResidenceAddressAsCorrespondenceAddress(setFieldValue);
                            }}
                            style={{ width: '100%', marginBottom: '20px' }}>
                            Taki sam jak adres zamieszkania.
                          </Button>
                        ) : null}

                        <div className={'tw-mb-4 tw-flex tw-flex-col tw-gap-4'}>
                          <AddressPromptingForm
                            cityFormikName={`userProfileModel.userAddresses.${i}.place`}
                            flatNoFormikName={`userProfileModel.userAddresses.${i}.flatNumber`}
                            houseNoFormikName={`userProfileModel.userAddresses.${i}.houseNumber`}
                            postCodeFormikName={`userProfileModel.userAddresses.${i}.postCode`}
                            streetFormikName={`userProfileModel.userAddresses.${i}.street`}
                          />
                        </div>

                        <MyTextInput
                          label='Poczta'
                          name={`userProfileModel.userAddresses.${i}.post`}
                          onChange={handleChange}
                          style={styles.marginBottomField}
                          value={address.post}
                        />
                        <MySelectInput
                          className='colorBlack marginBottom20px'
                          label='Kraj'
                          name={`userProfileModel.userAddresses.${i}.country`}
                          options={Countries}
                          placeholder='Kraj'
                          value={address.country}
                        />
                        <MyTextInput
                          label='Gmina'
                          name={`userProfileModel.userAddresses.${i}.commune`}
                          onChange={handleChange}
                          style={styles.marginBottomField}
                          value={address.commune}
                        />
                        <MyTextInput
                          label='Dzielnica'
                          name={`userProfileModel.userAddresses.${i}.district`}
                          onChange={handleChange}
                          style={styles.marginBottomField}
                          value={address.district}
                        />
                      </div>
                    );
                  })
                }
              </FieldArray>

              <ErrorMessage name='error' render={() => <label style={styles.labelError}>{errors.error}</label>} />

              <ButtonsSubmitCancel
                buttonTextCancel='Anuluj'
                buttonTextSubmit='Wyślij'
                cancelHandler={() => {
                  props.refreshView(UserProfileState.ShowProfile, false);
                }}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </>
    );
  },
);
