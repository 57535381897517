import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';

export default class PaymentsStore {
  constructor() {
    makeAutoObservable(this);
  }

  checkOrderCategory = async (orderId: number): Promise<number> => {
    return agent.Payments.checkOrderCategory(orderId);
  };
}
