import * as Yup from 'yup';

export const initialValues = {
  id: 0,
  street: '',
  houseNo: '',
  flatNo: '',
  place: '',
  postCode: '',
  correspondenceStreet: '',
  correspondenceHouseNo: '',
  correspondenceFlatNo: '',
  correspondencePlace: '',
  correspondencePostCode: '',
  differentCorrespondece: false,
  error: null,
};

export const validationSchema = Yup.object().shape({
  street: Yup.string().nullable().required('Pole jest wymagane'),
  houseNo: Yup.string().nullable().required('Pole jest wymagane'),
  place: Yup.string().nullable().required('Pole jest wymagane'),
  postCode: Yup.string().nullable().required('Pole jest wymagane'),
  correspondenceStreet: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  correspondenceHouseNo: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  correspondencePlace: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  correspondencePostCode: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
});
