import { ReactNode } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { twMerge } from '../../index';

interface IDisclosureProps {
  header: (open: boolean) => ReactNode;
  children?: ReactNode;
  renderBody?: (open: boolean) => ReactNode;
  defaultOpen?: boolean;
  wrapperClassName?: string;
}

const MyDisclosure = (props: IDisclosureProps) => {
  return (
    <Disclosure as='div' className={twMerge(props.wrapperClassName)} defaultOpen={props.defaultOpen}>
      {({ open }) => (
        <div className={''}>
          <Disclosure.Button className={'tw-w-full'}>{props.header(open)}</Disclosure.Button>
          <Transition
            className={`tw-transition-grid-rows tw-grid tw-overflow-hidden tw-duration-200 ${
              open ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]'
            }`}
            enter='tw-transition-all tw-duration-400 tw-ease-out'
            enterFrom='tw-transform tw-scale-95 tw-opacity-0 tw-grid-rows-[0fr]'
            enterTo='tw-transform tw-scale-100 tw-opacity-100 tw-grid-rows-[1fr]'
            leave='tw-transition-all  tw-duration-400 tw-ease-out'
            leaveFrom='tw-transform tw-scale-100 tw-opacity-100 tw-grid-rows-[1fr]'
            leaveTo='tw-transform tw-scale-95 tw-opacity-0 tw-grid-rows-[0fr]'>
            <div className={'tw-min-h-0 tw-overflow-hidden'}>
              {props.children}
              {props.renderBody?.(open)}
            </div>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};

export default MyDisclosure;
