import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useStore } from '../../store/store';
import parse, { attributesToProps } from 'html-react-parser';
import { history } from '../../index';

export default observer(({ content }: { content: string; id: number }) => {
  const { modalStore } = useStore();

  const SingleNotificationRedirectButton = ({ url, text }: { url: string; text: string }) => {
    const goToAddress = (url: string) => {
      history.push(url);
      modalStore.closeModal();
    };
    return (
      <span
        className='btn btn-primary'
        onClick={() => goToAddress(url)}
        style={{ marginTop: '30px', minWidth: '300px', textAlign: 'center' } as React.CSSProperties}>
        {text}
      </span>
    );
  };
  const options = {
    //@ts-ignore
    replace: (domNode) => {
      //@ts-ignore
      if (domNode.attribs && domNode.name === 'singlenotificationredirectbutton') {
        //@ts-ignore
        const props = attributesToProps(domNode.attribs);
        //@ts-ignore
        return <SingleNotificationRedirectButton {...props} />;
      }
    },
  };
  const htmlFromCMS = content;
  return <div className='d-flex justify-content-center pb-3'>{parse(htmlFromCMS, options)}</div>;
});
