import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import { UserAvailableBenefits } from '../../../types/user';

export default observer(() => {
  const { loansStore } = useStore();
  const [individualBenefit, setIndividualBenefit] = useState<UserAvailableBenefits | null>(null);

  const setAvailableBenefitId = async (benefitId: number) => {
    let isInstitutional = false;
    const benefit = loansStore.availableBenefits.find((b) => {
      return b.userAvailableBenefitId == benefitId;
    });
    if (benefit !== undefined && benefit !== null) {
      isInstitutional = benefit.isInstitutional;
    }

    loansStore.setAvailableBenefitIsInstitutional(isInstitutional);
    loansStore.setAvailableBenefitId(benefitId).then(() => {
      loansStore.loanMainLoadings = true;
      loansStore.checkUserHasPermission();
      if (benefit?.isBlocked) {
        loansStore.setIsBlocked(benefit.isBlocked);
      }
    });
  };

  useEffect(() => {
    const userAvailableBenefits: UserAvailableBenefits[] | undefined = loansStore.availableBenefits;

    if (userAvailableBenefits != undefined && userAvailableBenefits.length == 1) {
      loansStore.setAvailableBenefitIsInstitutional(userAvailableBenefits[0].isInstitutional);
      loansStore.setAvailableBenefitId(userAvailableBenefits[0].userAvailableBenefitId);
    } else if (userAvailableBenefits != undefined) {
      //@ts-ignore
      const individualBenefits = userAvailableBenefits
        .filter(
          //@ts-ignore
          (ui: { IsInstitutional: boolean; InstitutionId }) =>
            ui.IsInstitutional == false && ui.InstitutionId != null && ui.InstitutionId != undefined,
        )
        //@ts-ignore
        .sort((ui) => ui.InstitutionId);
      if (individualBenefits != undefined && individualBenefits.length > 0) {
        setIndividualBenefit(individualBenefits[0]);
      }
    }
  }, []);
  return (
    <CustomerDashboard>
      <div style={{ textAlign: 'center' }}>
        <h4>Wybierz placówkę dla benefitu</h4>
        <div className='d-flex flex-column justify-content-center align-items-center w-100'>
          {loansStore.availableBenefits &&
            loansStore.availableBenefits
              .filter((ui: { isInstitutional: boolean }) => ui.isInstitutional == true)
              .map((item) => (
                <div
                  className='institution-select-item'
                  key={item.userAvailableBenefitId}
                  onClick={() => setAvailableBenefitId(item.userAvailableBenefitId)}>
                  {item.institutionName}
                </div>
              ))}
          {individualBenefit != null && (
            <div
              className='institution-select-item'
              key={individualBenefit.userAvailableBenefitId}
              onClick={() => setAvailableBenefitId(individualBenefit.userAvailableBenefitId)}>
              Benefit prywatny
            </div>
          )}
        </div>
      </div>
    </CustomerDashboard>
  );
});
