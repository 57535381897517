import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../store/store';
import {
  hamburgerIcon,
  headerContainer,
  logoElectroMachinist,
  logoFirefighter,
  logoImage,
  logoJusticeSystem,
  logoMiners,
  userIcon,
  webContainer,
} from './styles';
import _ from 'lodash';
import Notification from '../../containers/Notification/Notification';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import CinemaVoucherCartIcon from '../CinemaVoucher/CinemaVoucherCartIcon/CinemaVoucherCartIcon';

export default observer(() => {
  const { menuStore, userStore } = useStore();
  const toggleMainCustomer = () => {
    menuStore.toggleMainCustomer();
  };
  const toggleProfile = () => {
    menuStore.toggleProfile();
  };
  return (
    <header
      className='navbar fixed-top navbar-expand-lg'
      style={menuStore.isMobileView ? _.merge({}, headerContainer) : _.merge({}, headerContainer, webContainer)}>
      <div className={'sm:tw-flex-1'}>
        <button onClick={toggleMainCustomer} style={hamburgerIcon}>
          <FontAwesomeIcon color='#626D80' icon={faBars} size='2x' />
        </button>
      </div>

      <div className='d-flex align-items-center'>
        <Image
          alt='logo'
          className='logo cursor-pointer'
          onClick={() => history.push(PathRoute.PUSTY)}
          src='/assets/logo.svg'
          style={logoImage}
        />
        {userStore.isUserFirefighter && (
          <Image
            alt='logo'
            className='logo'
            src='/assets/logo_strazacy.svg'
            style={{ ...logoImage, ...logoFirefighter }}
          />
        )}
        {userStore.isUserElectroMachinist && (
          <Image
            alt='logo'
            className='logo'
            src='/assets/logo_przemysl_elektromaszynowy.png'
            style={{ ...logoImage, ...logoElectroMachinist }}
          />
        )}
        {userStore.isUserJusticeSystem && (
          <Image
            alt='logo'
            className='logo'
            src='/assets/logo_wymiar_sprawiedliwosci.png'
            style={{ ...logoImage, ...logoJusticeSystem }}
          />
        )}
        {userStore.isUserSolidarityPodbeskidzie && (
          <Image
            alt='logo'
            className='logo'
            src='/assets/logo_solidarnosc_podbeskidzie.png'
            style={{ ...logoImage, ...logoJusticeSystem }}
          />
        )}
        {userStore.isUserMiner && (
          <Image alt='logo' className='logo' src='/assets/logo_gornicy.svg' style={{ ...logoImage, ...logoMiners }} />
        )}
        {userStore.isZnpRybnik && (
          <Image alt='logo' className='logo' src='/assets/logo_znp.png' style={{ ...logoImage, ...logoMiners }} />
        )}
        {userStore.isOpzz && (
          <Image alt='logo' className='logo' src='/assets/logo_opzz.jpeg' style={{ ...logoImage, ...logoMiners }} />
        )}
      </div>

      <div className={'tw-flex tw-items-center tw-justify-end sm:tw-flex-1'}>
        <Notification />
        <CinemaVoucherCartIcon />
        <button onClick={toggleProfile} style={userIcon}>
          <FontAwesomeIcon color='#fff' icon={faUser} size='lg' />
        </button>
      </div>
    </header>
  );
});
