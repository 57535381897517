import * as React from 'react';
import { Accordion, Image } from 'react-bootstrap';
import { useStore } from '../../store/store';
import * as styles from './styles';

const Station = () => {
  const { circleKStore } = useStore();
  return (
    <div style={styles.containerStationStyles as React.CSSProperties}>
      {circleKStore.stationCircleK.map(({ adress, city, manager_phone, region }) => (
        <div key={adress.trim()} style={styles.displayFlexStyles as React.CSSProperties}>
          <Accordion style={styles.accordionMain as React.CSSProperties}>
            <Accordion.Item eventKey='0' id='something'>
              <Accordion.Header id='Station' style={styles.accordionHeaderStyles as React.CSSProperties}>
                <Image src={'/assets/circleK.svg'} />
                <p style={styles.textStyles as React.CSSProperties}>{`Circle K ${city}, ${region} `}</p>
              </Accordion.Header>
              <Accordion.Body>
                <div style={styles.accordionBodyStyles as React.CSSProperties}>
                  <Image src={'/assets/station.svg'} />
                  <p style={styles.accordionTextStyles as React.CSSProperties}>{adress}</p>
                </div>
                <div style={styles.accordionBodyStyles as React.CSSProperties}>
                  <Image src={'/assets/phone.svg'} />
                  <p style={styles.textPStyles as React.CSSProperties}>{manager_phone}</p>
                </div>
                <div style={styles.accordionBodyStyles as React.CSSProperties}>
                  <Image src={'/assets/time.svg'} />
                  <p style={styles.textPStyles as React.CSSProperties}> Otwarte 24/7</p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ))}
    </div>
  );
};
export default Station;
