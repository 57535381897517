import * as React from 'react';
import * as styles from './styles';
import Checkbox from '../../Checkbox/Checkbox';

export function GetThirdApprove(
  thirdApprove: boolean,
  handleChangeThirdApprove: (e: any) => void,
  totalAmount: string,
  remainingDays: number,
): React.ReactNode {
  const date = new Date(Date.now());
  date.setDate(date.getDate() + remainingDays - 4);
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return (
    <>
      <div className='col-12 mt-3' style={styles.flexCenterContent as React.CSSProperties}>
        <Checkbox checked={thirdApprove} handleChange={handleChangeThirdApprove} id={''} text='  ' type={''} />
        <span>
          Zgodnie z art. 91 Kodeksu Pracy oświadczam, że zgadzam się na potrącenie przez pracodawcę z mojego
          wynagrodzenia za pracę za miesiąc {month} {year} należności w kwocie {totalAmount} zł z tytułu wypłaty środków
          z przyznanego limitu w ramach benefitu pracowniczego „Wypłata od Ręki".
          <span style={{ color: 'red' }}>*</span>
        </span>
      </div>
    </>
  );
}
