export const textStyle = {
  fontSize: '16px',
  color: '#00635C',
  marginBottom: '25px',
  lineHeight: '1.5',
} as React.CSSProperties;

export const headingTextStyle = {
  fontSize: '20px',
  lineHeight: '1.5',
  color: '#00635C',
  fontWeight: 600,
  marginBottom: '12px',
} as React.CSSProperties;
