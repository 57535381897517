export const title = {
  fontSize: '40px',
  lineHeight: 'normal',
};

export const imageStyle = {
  width: '68pt',
  height: '68pt',
  marginBottom: '25px',
  marginTop: '15px',
};

export const center = {
  textAlign: 'center',
};

export const contentStyle = {
  marginTop: '40px',
  marginBottom: '40px',
};

export const divSpacing = {
  margin: '15px',
};
