import { observer } from 'mobx-react-lite';
import { Overlay, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBell } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { useStore } from '../../store/store';
import SingleNotification from '../../components/SingleNotification/SingleNotification';
import '../../components/SingleNotification/styles.css';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import styles from './styles.module.scss';
import { notificationIcon } from '../../components/Header/styles';

export default observer(() => {
  const [show, setShow] = React.useState(false);
  const [, setIsRead] = React.useState(true);
  const { notificationStore, modalStore } = useStore();
  const target = React.useRef(null);

  React.useEffect(() => {
    if (notificationStore.counter > 0) {
      setIsRead(false);
    }
  }, []);

  const handleModalNotification = (content: string, id: number) => {
    modalStore.openModal(<SingleNotification content={content} id={id} />, 'lg', null, () => {
      setTimeout(() => {
        setShow(true);
      }, 200);
    });
    notificationStore.readNotificationsUser(id).then(() => {
      notificationStore.notification.map((notification) => {
        if (notification.id === id) {
          return {
            ...notification,
            read: true,
          };
        }
      });
    });
    setShow(false);
  };

  return (
    <>
      <button onClick={() => setShow(!show)} ref={target} style={notificationIcon}>
        <FontAwesomeIcon color='#fff' icon={faBell} size='lg' />
        {notificationStore.counter > 0 && <span className={styles.counter}>{notificationStore.counter}</span>}
      </button>
      <Overlay onHide={() => setShow(false)} placement='bottom' rootClose={true} show={show} target={target.current}>
        <Popover className={styles.notificationPopover} id='popover-basic'>
          <Popover.Header as='h3' className={styles.popoverHeader}>
            Powiadomienia
          </Popover.Header>
          <Popover.Body className={styles.popoverBody}>
            {notificationStore.notification.slice(0, 5).map(({ content, createDate, id, title, read }) => (
              <div
                className={styles.notificationRowWrapper}
                key={id}
                onClick={() => handleModalNotification(content, id)}>
                <div className={styles.notificationRow}>
                  <div>
                    <div className={read ? styles.textRead : styles.textUnRead}>{title}</div>
                    <p className={styles.textContent}>{createDate}</p>
                  </div>
                  <div className={styles.greenArrow}>
                    <FontAwesomeIcon color='#0bc268' icon={faArrowRight} size='lg' />
                  </div>
                </div>
              </div>
            ))}
            <div className={styles.pickNotification}>
              {notificationStore.notification.length == 0 ? (
                <div style={{ padding: '15px', fontWeight: '500' } as React.CSSProperties}>Nie masz powiadomień</div>
              ) : null}
            </div>
            <button
              className={styles.showMore}
              onClick={() => {
                history.push(PathRoute.MY_NOTIFICATIONS);
                setShow(false);
              }}>
              Pokaż wszystkie powiadomienia
            </button>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
});
