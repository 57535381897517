import { twMerge } from 'tailwind-merge';
import { IIconComponentProps } from '../../types/icons';

export default function LinkIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge(props.className)} fill='none' stroke='currentColor' strokeWidth='1.5' viewBox='0 0 24 24'>
      <path
        d='M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
