import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function HouseIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge(props.className)} height='63.079' viewBox='0 0 70.563 63.079' width='70.563'>
      <defs>
        <clipPath id='clip-path'>
          <rect className='cls-1' data-name='Rectangle 459' height='63.079' id='Rectangle_459' width='70.563' />
        </clipPath>
      </defs>
      <g data-name='Group 1123' id='Group_1123' transform='translate(-7 -9.276)'>
        <g className='cls-2' data-name='Group 1122' id='Group_1122' transform='translate(7 9.276)'>
          <path
            className='cls-3'
            d='M13.075,23.718,8.91,19.552,10.5,17.959l2.572,2.571,7.75-7.75,1.593,1.594Zm3.15,13.292c16.838-9.655,15.062-30.9,15.043-31.114a1.126,1.126,0,0,0-.981-1.013C30.2,4.87,21.44,3.735,16.3.2a1.125,1.125,0,0,0-1.279,0C9.89,3.735,1.133,4.87,1.044,4.882A1.126,1.126,0,0,0,.063,5.895c-.019.214-1.8,21.459,15.042,31.114a1.129,1.129,0,0,0,1.121,0M15.665,2.474C20.253,5.348,26.674,6.6,29.069,6.98c.121,3.893-.242,19.765-13.4,27.744C2.529,26.758,2.147,10.876,2.263,6.98c2.4-.385,8.817-1.632,13.4-4.505M59.132,61.952V32.827a1.124,1.124,0,0,0-.5-.935L37.509,17.644l-1.26,1.868L56.88,33.426v27.4H45.048V45.407a1.126,1.126,0,0,0-1.127-1.127H29.837a1.127,1.127,0,0,0-1.127,1.127V60.826H16.88V39.454H14.626v22.5a1.127,1.127,0,0,0,1.127,1.127H29.837a1.127,1.127,0,0,0,1.127-1.127V46.533H42.8V61.952a1.126,1.126,0,0,0,1.126,1.127H58.006a1.127,1.127,0,0,0,1.127-1.127m6.423-23.074a1.125,1.125,0,0,0,.719-.476l4.1-6.072a1.127,1.127,0,0,0-.3-1.565L54.682,20.39V8.4a1.127,1.127,0,0,0-1.127-1.127H45.949A1.127,1.127,0,0,0,44.822,8.4v5.342L37.509,8.811a1.126,1.126,0,0,0-1.26,0L32.56,11.3l1.26,1.868,3.06-2.064,8.44,5.693a1.126,1.126,0,0,0,1.757-.934V9.529h5.352V20.99a1.124,1.124,0,0,0,.5.935L67.872,32l-2.836,4.2L37.509,17.644a1.126,1.126,0,0,0-1.26,0l-6.475,4.366,1.26,1.868,5.845-3.941L64.711,38.707a1.128,1.128,0,0,0,.63.192,1.147,1.147,0,0,0,.215-.02'
            data-name='Path 4765'
            fill='currentColor'
            id='Path_4765'
            transform='translate(0 0)'
          />
        </g>
      </g>
    </svg>
  );
}
