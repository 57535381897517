export const positionAbsolute = {
  position: 'absolute',
};

export const mainContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  marginLeft: '32px',
  height: '18px',
};

export const fontWeight = {
  fontSize: '16px',
  fontWeight: '600',
};

export const font = {
  fontSize: '16px',
};

export const fuelStation = {
  display: 'flex',
  justifyContent: 'center',
  marginRight: '7%',
  fontSize: '12px',
};

export const myMoney = {
  display: 'flex',
  justifyContent: 'end',
  fontSize: '16px',
  color: '#0BC268',
};

export const displayBetween = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const fontHigh = {
  fontWeight: '700',
};

export const colorFont = { color: '#0BC268', fontWeight: '600' };
