import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as styles from './styles';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import ReactCodeInput from 'react-code-input';
import { Image } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { WalletApplicationUserData } from '../../../types/walletNau';
import WalletNauHeader from '../WalletNauHeader/DiscountCodeHeader';
import ConfirmWalletApprovalsForm from '../ConfirmWalletApprovalsForm/ConfirmWalletApprovalsForm';
import useWalletDocumentsDownloadHandlers from './useWalletDocumentsDownloadHandlers';
import { toast } from 'react-toastify';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import WalletNauInstructionBankFooter from '../WalletNauInstruction/WalletNauInstructionBankFooter/WalletNauInstructionBankFooter';
import useResendSmsTimer from '../../../hooks/useResendSmsTimer';

export default observer(() => {
  const { walletNauStore } = useStore();
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [walletUserData, setWalletUserData] = React.useState<WalletApplicationUserData | null>(null);
  const { id }: any = useParams();
  const walletDocumentsDownloadHandlers = useWalletDocumentsDownloadHandlers();
  const { remainingSecondsSmsDisabled, setIsResendSmsDisabled, isResendSmsDisabled, enableResendSms } =
    useResendSmsTimer();

  useEffect(() => {
    walletNauStore
      .getWalletApplicationUserData(id)
      .then((resp) => {
        if (resp != undefined) {
          if (resp.wasSent) {
            history.push(PathRoute.WALLET_NAU);
          } else {
            setWalletUserData(resp);
            walletNauStore.requestWalletAuthorizationToken(id);
          }
        }
      })
      .finally(() => setLoading(false));
  }, []);
  const handleChangePin = (e: any) => setPin(e);

  const resendWalletAuthorizationToken = () => {
    if (isResendSmsDisabled) {
      toast.error(`Proszę poczekać ${remainingSecondsSmsDisabled} sekund na wysłanie kolejnego kodu.`);
      return;
    }
    setIsResendSmsDisabled(true);
    walletNauStore
      .requestWalletAuthorizationToken(id)
      .then(() => {
        toast.success('Pomyślnie wysłano kod SMS');
      })
      .catch(() => {
        enableResendSms();
      });
  };

  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div style={styles.containerBackground as React.CSSProperties}>
            <WalletNauHeader />
            <div style={styles.containerConfirm as React.CSSProperties}>
              <div style={styles.flexStart as React.CSSProperties}>
                <p style={styles.confirmTitle as React.CSSProperties}>Potwierdzenie zamówienia</p>
              </div>
              <div style={styles.documentsContainer as React.CSSProperties}>
                <div style={styles.displayFlex as React.CSSProperties}>
                  <a
                    onClick={() => walletDocumentsDownloadHandlers.handleInformationFormPdfDownload(id)}
                    style={styles.hrefLink as React.CSSProperties}>
                    Formularz Informacyjny:
                  </a>{' '}
                  <Image src='/assets/arrowRightCash.svg' style={styles.heightCursor as React.CSSProperties} />
                </div>
                <div style={styles.displayFlex as React.CSSProperties}>
                  <a
                    onClick={() => walletDocumentsDownloadHandlers.handleContractPdfDownload(id)}
                    style={styles.hrefLinkOrange as React.CSSProperties}>
                    Umowa Portfela NAU
                  </a>{' '}
                  <Image src='/assets/arrowRightCashOrange.svg' style={styles.heightCursor as React.CSSProperties} />
                </div>
              </div>
              <div style={styles.marginTop20 as React.CSSProperties}>
                <p style={styles.LoanAmount as React.CSSProperties}>Kwota Portfela NAU:</p>
                <div style={styles.containerAmountTax as React.CSSProperties}>
                  <p style={styles.textWeight as React.CSSProperties}>{walletUserData?.walletAmount}</p>
                  <p style={styles.marginLeftPLN as React.CSSProperties}>PLN</p>
                </div>
              </div>
              <div style={styles.marginTop10 as React.CSSProperties}>
                <p style={styles.textStyle as React.CSSProperties}>Całkowita opłata za wypłatę:</p>
                <div style={styles.containerAmountTax as React.CSSProperties}>
                  <p style={styles.textWeight as React.CSSProperties}>{walletUserData?.paymentAmount}</p>
                  <p style={styles.marginLeftPLN as React.CSSProperties}>PLN</p>
                </div>
              </div>
              <div style={styles.marginTop10 as React.CSSProperties}>
                <p style={styles.LoanAmount as React.CSSProperties}>Data umowy:</p>
                <div style={styles.containerAmountTax as React.CSSProperties}>
                  <p style={styles.textWeight as React.CSSProperties}>{walletUserData?.applicationDate}</p>
                </div>
              </div>
              <div style={styles.marginTop10 as React.CSSProperties}>
                <p style={styles.LoanAmount as React.CSSProperties}>Termin spłaty:</p>
                <div style={styles.containerAmountTax as React.CSSProperties}>
                  <p style={styles.textWeight as React.CSSProperties}>{walletUserData?.paymentDate}</p>
                </div>
              </div>
              <div style={styles.flexWeightCenter as React.CSSProperties}>
                <p>Podaj KOD SMS</p>
              </div>
            </div>
            <div style={styles.pinContainer as React.CSSProperties}>
              <ReactCodeInput
                fields={6}
                inputMode='numeric'
                name='numeric'
                onChange={(e) => handleChangePin(e)}
                type='password'
                value={pin}
              />
            </div>
            <div onClick={() => resendWalletAuthorizationToken()} style={styles.textSMS as React.CSSProperties}>
              <p>Wyślij ponownie SMS</p>
            </div>
            <div style={styles.autoContainerHeight as React.CSSProperties}>
              <p className={'tw-mb-2'} style={styles.fontColor as React.CSSProperties}>
                Przelew na konto
              </p>
              <div className={'tw-flex tw-flex-wrap tw-mb-3 tw-gap-1'}>
                <p className={'me-1'} style={styles.colorBottomWeight as React.CSSProperties}>
                  Właściciel konta:
                </p>
                <p style={styles.accountMember as React.CSSProperties}>
                  {walletUserData?.firstName + ' ' + walletUserData?.lastName}
                </p>
              </div>
              <div className={'tw-flex tw-flex-wrap tw-mb-3 tw-gap-1'}>
                <p className={'me-1'} style={styles.colorBottomWeight as React.CSSProperties}>
                  Nr konta klienta do wypłaty:
                </p>
                <p style={styles.accountMember as React.CSSProperties}>PL{walletUserData?.bankAccountNumber}</p>
              </div>
              <div className={'tw-flex tw-flex-wrap tw-gap-1'}>
                <p className={'me-1'} style={styles.colorBottomWeight as React.CSSProperties}>
                  Czas realizacji:
                </p>
                <p style={styles.accountMember as React.CSSProperties}>Przelew natychmiastowy*</p>
              </div>
            </div>
            <ConfirmWalletApprovalsForm pin={pin} walletApplicationId={id} />
            <div style={{ padding: '20px' } as React.CSSProperties}>
              <WalletNauInstructionBankFooter prefix='*' />
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});
