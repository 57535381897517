import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect } from 'react';
import { useStore } from '../../../store/store';
import * as style from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { Benefit } from '../../../enums/benefits.enums';
import { history } from '../../../index';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import WalletNauHeader from '../../../components/WalletNau/WalletNauHeader/DiscountCodeHeader';
import { WalletNauNewContractStatuses } from '../../../enums/WalletNauContractStatus.enums';
import ApprovalsForm from '../../../components/ApprovalsForm/ApprovalsForm';

export default observer(() => {
  const { walletNauStore } = useStore();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    walletNauStore
      .getWalletContractStatus()
      .then((contractStatus) => {
        if (!WalletNauNewContractStatuses.includes(contractStatus ?? 0)) {
          history.push(PathRoute.WALLET_NAU);
          return;
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3' style={style.container}>
            <WalletNauHeader />
            <div style={style.contentBox}>
              <ApprovalsForm
                benefitId={Benefit.WalletNau}
                hideHeader={true}
                redirectPath={PathRoute.USER_DATA_CONTRACT_WALLET_NAU}
              />
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});
