import React from 'react';

export const KlInsuranceExtensionInfo = () => {
  return (
    <div>
      Rozszerzenia zakresu ochrony - informacja o wysokości składek:
      <div className={'tw-flex-gap tw-mt-2 tw-flex tw-flex-col tw-gap-2 tw-text-sm tw-font-normal tw-text-gray-900'}>
        <div>
          a) składka za osobodzień za wyjazd na terenie Europy + basen Morza Śródziemnego (Turcja, Egipt, Tunezja,
          Maroko, Wyspy Kanaryjskie) – 2,00 zł (składka naliczana od 15 dnia pobytu za granicą za 15-sty i każdy
          następny dzień ubezpieczenia w przypadku pierwszego wyjazdu Ubezpieczonego)
        </div>
        <div>
          b) składka za osobodzień za wyjazd na terenie Europy + basen Morza Śródziemnego (Turcja, Egipt, Tunezja,
          Maroko, Wyspy Kanaryjskie) – 2,00 zł (składka naliczana za każdy dzień pobytu za granicą w przypadku drugiego
          i każdego kolejnego wyjazdu Ubezpieczonego)
        </div>
        <div>
          c) składka za osobodzień za wyjazd poza Europę – 3,00 zł (składka naliczana za każdy dzień pobytu
          Ubezpieczonego za granicą)
        </div>
        <div>
          d) składka za rozszerzenie zakresu o następstwa choroby przewlekłej za osobodzień – 2,00 zł (składka naliczana
          za każdy dzień pobytu Ubezpieczonego za granicą)
        </div>
        <div>
          e) składka za rozszerzenie zakresu o następstwa nieszczęśliwego wypadku lub choroby powstałej w związku z
          amatorskim uprawianiem narciarstwa, snowboardingu, narciarstwa wodnego lub windsurfingu za osobodzień – 2,50
          zł (składka naliczana za każdy dzień pobytu Ubezpieczonego za granicą)
        </div>
      </div>
    </div>
  );
};
