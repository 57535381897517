import MyButton from '../Buttons/MyButton/MyButton';
import { useStore } from '../../store/store';
import { useState } from 'react';

interface IYesNoQuestionModalProps {
  question: string;
  onAgree: () => Promise<void>;
  buttonAgreeText?: string;
}
export const YesNoQuestionModal = (props: IYesNoQuestionModalProps) => {
  const { modalStore } = useStore();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <div>
      <div className={'tw-flex tw-justify-center tw-font-semibold'}>{props.question}</div>

      <div className={'tw-mt-6 tw-flex tw-justify-end tw-gap-2'}>
        <MyButton onClick={() => modalStore.closeModal()} variant='gray'>
          Anuluj
        </MyButton>

        <MyButton
          isLoading={isSubmitting}
          onClick={() => {
            setIsSubmitting(true);
            props.onAgree().finally(() => {
              setIsSubmitting(false);
              modalStore.closeModal();
            });
          }}
          type='button'
          variant='primary'>
          {props.buttonAgreeText ?? 'Tak'}
        </MyButton>
      </div>
    </div>
  );
};
