import * as React from 'react';

export const barContainer = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#0bc268',
  zIndex: 10,
} as React.CSSProperties;

export const barLink = {
  color: 'white',
  textDecoration: 'underline',
  cursor: 'pointer',
} as React.CSSProperties;
