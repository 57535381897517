function formatDateQueryParam(key: string, value: any): string {
  if (value instanceof Date) {
    const isoDateString = value.toISOString();
    return `${key}=${encodeURIComponent(isoDateString)}`;
  }
  throw new Error(`Invalid date value for key ${key}`);
}

function formatQueryParam(key: string, value: any): string {
  return `${key}=${encodeURIComponent(value.toString().trim())}`;
}

export function extractQueryParams(searchModel: Record<string, any> | undefined): string {
  if (!searchModel) {
    return '';
  }

  return Object.entries(searchModel)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => {
      if (value instanceof Date) {
        return formatDateQueryParam(key, value);
      }
      return formatQueryParam(key, value);
    })
    .join('&');
}
