import { IIconComponentProps } from '../../types/icons';
import { twMerge } from '../../index';

export default function CarIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge(props.className)} height='32.113' viewBox='0 0 39 32.113' width='39'>
      <defs>
        <clipPath id='clip-path'>
          <rect data-name='Rectangle 397' fill='none' height='32.113' id='Rectangle_397' width='39' />
        </clipPath>
      </defs>
      <g data-name='Group 782' id='Group_782' transform='translate(0 0)'>
        <g clipPath='url(#clip-path)' data-name='Group 781' id='Group_781' transform='translate(0 0)'>
          <path
            d='M7.693,15.779a3.45,3.45,0,1,0,3.449,3.449,3.454,3.454,0,0,0-3.449-3.449m0,5.659a2.209,2.209,0,1,1,2.21-2.21,2.211,2.211,0,0,1-2.21,2.21m23.5-5.659a3.45,3.45,0,1,0,3.449,3.449,3.453,3.453,0,0,0-3.449-3.449m0,5.659a2.209,2.209,0,1,1,2.208-2.21,2.211,2.211,0,0,1-2.208,2.21m3.041-8.615h-.421l-.444-2.046a.62.62,0,0,0-1.212.263l.386,1.782H30.283l-.444-2.046a.62.62,0,0,0-1.212.263l.387,1.782H9.865L10.6,8.654c0-.017.007-.034.012-.051l.007-.034c0-.011,0-.022.007-.032l.639-3.506H27.959a.62.62,0,0,0,0-1.24H10.751a.621.621,0,0,0-.612.52L9.414,8.3l0,.011c-.011.041-.019.081-.028.126l-.777,4.389h-2.2L7.555,6.7a2.435,2.435,0,0,1,.063-.256.429.429,0,0,0,.016-.066l.851-4.666a.117.117,0,0,1,.115-.1H27.959a.62.62,0,0,0,0-1.24H8.6A1.357,1.357,0,0,0,7.265,1.488L6.42,6.122a3.521,3.521,0,0,0-.084.351L5.145,12.823H4.61c-2.336,0-4.235,1.617-4.235,3.6v9.085h1.8v4.282a1.713,1.713,0,0,0,1.628,1.783H9.112a1.713,1.713,0,0,0,1.628-1.783V25.512h17.4v4.282a1.713,1.713,0,0,0,1.628,1.783h5.307A1.713,1.713,0,0,0,36.7,29.794V25.512h1.759V16.427c0-1.987-1.9-3.6-4.233-3.6M9.5,29.794c0,.3-.178.544-.388.544H3.806c-.211,0-.388-.249-.388-.544V25.512H9.5Zm25.964,0c0,.3-.177.544-.388.544H29.769c-.211,0-.388-.249-.388-.544V25.512h6.084Zm1.759-5.521H1.615V16.427c0-1.3,1.344-2.365,3-2.365H34.23c1.651,0,2.994,1.061,2.994,2.365Z'
            data-name='Path 3887'
            fill='currentColor'
            id='Path_3887'
            transform='translate(0.08 0.08)'
          />
          <path
            d='M7.693,15.779a3.45,3.45,0,1,0,3.449,3.449A3.454,3.454,0,0,0,7.693,15.779Zm0,5.659a2.209,2.209,0,1,1,2.21-2.21A2.211,2.211,0,0,1,7.693,21.437Zm23.5-5.659a3.45,3.45,0,1,0,3.449,3.449A3.453,3.453,0,0,0,31.189,15.779Zm0,5.659a2.209,2.209,0,1,1,2.208-2.21A2.211,2.211,0,0,1,31.189,21.437Zm3.041-8.615h-.421l-.444-2.046a.62.62,0,0,0-1.212.263l.386,1.782H30.283l-.444-2.046a.62.62,0,0,0-1.212.263l.387,1.782H9.865L10.6,8.654c0-.017.007-.034.012-.051l.007-.034c0-.011,0-.022.007-.032l.639-3.506H27.959a.62.62,0,0,0,0-1.24H10.751a.621.621,0,0,0-.612.52L9.414,8.3l0,.011c-.011.041-.019.081-.028.126l-.777,4.389h-2.2L7.555,6.7a2.435,2.435,0,0,1,.063-.256.429.429,0,0,0,.016-.066l.851-4.666a.117.117,0,0,1,.115-.1H27.959a.62.62,0,0,0,0-1.24H8.6A1.357,1.357,0,0,0,7.265,1.488L6.42,6.122a3.521,3.521,0,0,0-.084.351L5.145,12.823H4.61c-2.336,0-4.235,1.617-4.235,3.6v9.085h1.8v4.282a1.713,1.713,0,0,0,1.628,1.783H9.112a1.713,1.713,0,0,0,1.628-1.783V25.512h17.4v4.282a1.713,1.713,0,0,0,1.628,1.783h5.307A1.713,1.713,0,0,0,36.7,29.794V25.512h1.759V16.427C38.463,14.44,36.564,12.823,34.23,12.823ZM9.5,29.794c0,.3-.178.544-.388.544H3.806c-.211,0-.388-.249-.388-.544V25.512H9.5Zm25.964,0c0,.3-.177.544-.388.544H29.769c-.211,0-.388-.249-.388-.544V25.512h6.084Zm1.759-5.521H1.615V16.427c0-1.3,1.344-2.365,3-2.365H34.23c1.651,0,2.994,1.061,2.994,2.365Z'
            data-name='Path 3888'
            fill='none'
            id='Path_3888'
            stroke='currentColor'
            strokeWidth='0.75'
            transform='translate(0.08 0.08)'
          />
        </g>
      </g>
    </svg>
  );
}
