import { observer } from 'mobx-react-lite';
import * as React from 'react';
import * as styles from './styles';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import WalletNauHeader from '../WalletNauHeader/DiscountCodeHeader';
import { PathRoute } from '../../../constants/pathRoute/Route';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';

export default observer(() => {
  const handleConfirm = async () => {
    history.push(PathRoute.PUSTY);
  };
  return (
    <CustomerDashboard>
      <div style={styles.container as React.CSSProperties}>
        <WalletNauHeader />
        <div style={styles.imageWrapper as React.CSSProperties}>
          <Image src='assets/walletGreen.svg' style={{ height: '140px' }} />
        </div>
        <p style={styles.textStyle as React.CSSProperties}> Pieniądze wkrótce będą na Twoim koncie!</p>
        <div style={styles.flexPadding as React.CSSProperties}>
          <Button onClick={handleConfirm} style={styles.width as React.CSSProperties}>
            Korzystaj
          </Button>
        </div>
      </div>
    </CustomerDashboard>
  );
});
