import { observer } from 'mobx-react-lite';
import * as React from 'react';
import CustomerDashboard from '../../containers/CustomerDashboard/CustomerDashboard';
import * as styles from './styles';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import { useStore } from '../../store/store';

export default observer(() => {
  const { loansStore } = useStore();

  const handleConfirm = async () => {
    history.push(PathRoute.PUSTY);
  };
  return (
    <CustomerDashboard>
      <div style={styles.container as React.CSSProperties}>
        <div style={styles.containerSecond as React.CSSProperties}>
          <p style={styles.Loan as React.CSSProperties}>Wypłata od ręki</p>
        </div>
        <div style={styles.flexCenter as React.CSSProperties}>
          <p style={styles.sizeTopRevert as React.CSSProperties}>{loansStore.loanAmount}</p>
        </div>
        <p style={styles.currency as React.CSSProperties}>PLN</p>
        <div style={styles.flexCenter as React.CSSProperties}>
          <Image src='assets/peopleTogether.svg' />
        </div>
        <p style={styles.wayStyles as React.CSSProperties}> już w drodze !</p>
        <div style={styles.widthCenter as React.CSSProperties}>
          <p style={styles.flexCenterAccount as React.CSSProperties}>
            Zlecono przesłanie środków. Już niedługo będą na Twoim koncie!
          </p>
        </div>
        <div style={styles.flexPadding as React.CSSProperties}>
          <Button onClick={handleConfirm} style={styles.width as React.CSSProperties}>
            Dalej
          </Button>
        </div>
      </div>
    </CustomerDashboard>
  );
});
