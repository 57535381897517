import * as React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { CircleKTransactionsModel } from '../../../types/circleK';
import CircleKTransaction from '../CircleKTransaction/CircleKTransaction';
import { useStore } from '../../../store/store';
import HistoryTransaction from '../HistoryTransaction/HistoryTransaction';
import { twMerge } from 'tailwind-merge';

export default observer((props: { items: CircleKTransactionsModel[]; cardId: number | null }) => {
  const { modalStore } = useStore();

  return (
    <div>
      {props.items.map((item, i) => (
        <div key={i} style={styles.containerMarginBottom as React.CSSProperties}>
          <CircleKTransaction transaction={item} />
        </div>
      ))}

      {props.items.length > 0 ? (
        <div style={styles.transactionHistory as React.CSSProperties}>
          <p
            className={twMerge('greenColor', 'tw-cursor-pointer tw-mx-auto tw-text-xl')}
            onClick={() =>
              modalStore.openModal(<HistoryTransaction cardId={props.cardId} />, 'lg', 'Wszystkie transakcje CircleK')
            }>
            Historia transakcji CircleK
          </p>
        </div>
      ) : null}
    </div>
  );
});
