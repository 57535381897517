import React from 'react';
import * as styles from './styles';
import { Button } from 'react-bootstrap';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import DiscountCodeHeader from '../../../components/DiscountCode/DiscountCodeHeader/DiscountCodeHeader';
import DiscountCodesInstructionContent from '../../../components/DiscountCode/DiscountCodesInstruction/DiscountCodesInstructionContent';

const goToContract = () => history.push(PathRoute.DISCOUNT_CODE_APPROVALS);

const StartDiscountCode = () => (
  <div>
    <div className='col-md-6 offset-md-3' style={styles.container}>
      <DiscountCodeHeader />
      <div style={styles.bodyContainer as React.CSSProperties}>
        <DiscountCodesInstructionContent />
        <div className={'px-lg-4'}>
          <Button className='btn-full-width' onClick={() => goToContract()}>
            Złóż wniosek
          </Button>
        </div>
      </div>
      <div></div>
    </div>
  </div>
);

export default StartDiscountCode;
