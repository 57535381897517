import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';

export interface NotifcationModel {
  id: number;
  content: string;
  title: string;
  createDate: string;
  read: boolean;
}
export default class NotificationStore {
  notification: NotifcationModel[] = new Array<NotifcationModel>();
  counter = 0;
  constructor() {
    makeAutoObservable(this);
  }

  getNotificationsUser = async () => {
    try {
      const response = await agent.Notification.getUserNotifications();
      runInAction(() => {
        // @ts-ignore
        this.notification = response;
      });
      this.setCounter(response);
    } catch (e) {
      console.log(e);
    }
  };
  setCounter = async (notification: NotifcationModel[]) => {
    try {
      let counter = 0;
      notification.map((item) => {
        if (!item.read) {
          counter++;
        }
      });
      runInAction(() => {
        // @ts-ignore
        this.counter = counter;
      });
    } catch (e) {
      console.log(e);
    }
  };
  deleteNotificationsUser = async (id: number) => {
    try {
      await agent.Notification.deleteUserNotifications(id);
      this.getNotificationsUser();
    } catch (e) {
      console.log(e);
    }
  };
  readNotificationsUser = async (id: number) => {
    try {
      await agent.Notification.ReadUserNotification(id);
      this.getNotificationsUser();
    } catch (e) {
      console.log(e);
    }
  };
}
