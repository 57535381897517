export const errorStyles = { marginTop: 10, color: 'red' };

export const textCircleStyle = {
  textAlign: 'left',
  fontSize: '16px',
  color: 'teal',
  fontWeight: '500',
  marginBottom: 0,
  marginTop: '15px',
  marginLeft: '5px',
};
