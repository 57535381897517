import { observer } from 'mobx-react-lite';
import React from 'react';
import PZUSportCardApplicationForm from './SportCardApplicationForms/PZUSportCardApplicationForm';
import { SportCardCompany } from '../../enums/sportCardCompany.enums';
import { BenefitApprovalsLayout } from '../../components/ApprovalsForm/BenefitApprovalsLayout';
import { Benefit } from '../../enums/benefits.enums';
import { SportCardRequestForm } from './SportCardApplicationForms/SportCardRequestForm';

interface IOrderSportCardLayoutProps {
  sportCardCompany: SportCardCompany;
}
export default observer((props: IOrderSportCardLayoutProps) => {
  return (
    <div className={'tw-relative'}>
      <BenefitApprovalsLayout benefitId={Benefit.SportCard}>
        <>
          {props.sportCardCompany === SportCardCompany.PZU && (
            <PZUSportCardApplicationForm sportCardCompany={props.sportCardCompany} />
          )}
          {props.sportCardCompany === SportCardCompany.MultiSport && (
            <SportCardRequestForm sportCardCompany={props.sportCardCompany} />
          )}
          {props.sportCardCompany === SportCardCompany.Medicover && (
            <SportCardRequestForm sportCardCompany={props.sportCardCompany} />
          )}
        </>
      </BenefitApprovalsLayout>
    </div>
  );
});
