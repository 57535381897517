import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import FileHelper from '../../../helpers/FileHelper';

export default function useWalletDocumentsDownloadHandlers() {
  const { fileStore } = useStore();

  const handleInformationFormPdfDownload = (walletApplicationId: number) => {
    fileStore
      .getWalletApplicationFormPdf(walletApplicationId)
      .then((resp) => {
        const fileName = 'formularz_informacyjny_' + walletApplicationId + '.pdf';
        FileHelper.downloadFileFromBlob(resp, fileName);
      })
      .catch((error) => {
        console.log(`Error in promises ${error}`);
        toast.error('Wystąpił błąd przy pobieraniu pliku');
      });
  };
  const handleContractPdfDownload = (walletApplicationId: number) => {
    fileStore
      .getWalletApplicationContractPdf(walletApplicationId)
      .then((resp) => {
        const fileName = 'umowa_portfel_nau_' + walletApplicationId + '.pdf';
        FileHelper.downloadFileFromBlob(resp, fileName);
      })
      .catch((error) => {
        console.log(`Error in promises ${error}`);
        toast.error('Wystąpił błąd przy pobieraniu pliku');
      });
  };

  return { handleInformationFormPdfDownload, handleContractPdfDownload };
}
