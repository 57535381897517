import { observer } from 'mobx-react-lite';
import * as React from 'react';
import * as styles from './styles';
import { openLink } from '../../../utils/linkUtils';

export default observer((props: { buttonText: string; link: string }) => {
  return (
    <div style={styles.flexCenter as React.CSSProperties}>
      <span className='btn btn-primary btn-full-width' onClick={() => openLink(props.link)}>
        {props.buttonText}
      </span>
    </div>
  );
});
